import React from "react";
import CustomizedLookup from "./Lookup";
import { DocumentSettings } from "../../utils/documentSettings";
import { Column } from "devextreme-react/data-grid";

const DocumentNumberLookup = ({
  formValue,
  setFormValue,
  tempForm = {}, 
  formID = { current: undefined },
  lockedForm,
  fiscalYearLocked = false, 
  isEditing = false, 
  valueOnChange,
}) => {
  const handleSelectionChanged = (e) => {
    valueOnChange(e, "DocumentNo");
  };

  const handleNextDocSelectionChanged = (e) => {
    if (tempForm) {
      // Update tempForm if it exists
      tempForm.current = {
        ...tempForm.current,
        NextDocNo: e.value,
        DocumentPrefix: e.selectedItem?.Prefix || "",
      };
    }
    setFormValue((prevState) => ({
      ...prevState,
      NextDocNo: e.value,
      DocumentPrefix: e.selectedItem?.Prefix || "",
    }));
  };

  // Safe access for formID.current
  const isDisabled = !formID?.current
    ? isEditing || lockedForm
    : formID.current !== "new" || lockedForm || fiscalYearLocked; 
  const isEdit = !formID?.current
    ? isEditing || lockedForm
    : formID.current !== "new"; 
    
  return (
    <CustomizedLookup
      displayExpr="DocumentNumber"
      valueExpr="DocumentNumber"
      dropdownClassName="custom-dropdown"
      value={formValue["DocumentNo"]}
      onSelectionChanged={handleSelectionChanged}
      nextDocSelectionChanged={handleNextDocSelectionChanged}
      documentNumber={true}
      placeholder="<NEW>"
      isEdit={isEdit} 
      disabled={isDisabled}
      dataSourceURL={DocumentSettings.APIEndpoints.SelectDocNo(
        formValue["DocumentTypeID"],
        formValue["DocumentDate"]
      )}
      acceptCustomValue={true}
      refreshDataSource={true}
    >
      <Column dataField="Name" width={DocumentSettings.DocumentTypeWidth.Name} />
      <Column
        dataField="Format"
        width={DocumentSettings.DocumentTypeWidth.Format}
      />
      <Column
        dataField="RunningNumber"
        width={DocumentSettings.DocumentTypeWidth.RunningNumber}
      />
    </CustomizedLookup>
  );
};

export default DocumentNumberLookup;
