import React, { useEffect, useState } from 'react';
import { Column, Lookup, } from 'devextreme-react/data-grid';
import { forexOptions } from '../../../utils/lookupstore';
import APPurchaseForm from './form';
import CashPurchaseForm from '../../Purchase-Module/cash-purchase/form';
import PurchaseInvoiceForm from '../../Purchase-Module/invoice/form';
import Container from '../../../components/container';
import Listing from '../../../components/listing/newListing';
import utils from '../../../utils/common';
import EInvoiceMain from "../../../components/e-invoice/EInvoiceMain";

export default function APPurchaseListing() {

    const apiURL = `/api/APPurchase`;
    const nonIntegerRegex = /[a-zA-Z-]+/g;

    const [currentEditID, setCurrentEditID] = useState(null);
    const [currentEditID2, setCurrentEditID2] = useState(null);
    const [currentEditID3, setCurrentEditID3] = useState(null);

    const [invType, setInvType] = useState(null);
    const [currentDetailID, setCurrentDetailID] = useState(null);


    function handleEdit(e){
        const rowData = e.row.data;
        let valueToCheck = rowData.TransactionTypeCode.trim().match(nonIntegerRegex);
        
        if (rowData.hasOwnProperty("OriginalDocumentID") && rowData.OriginalDocumentID) {
            valueToCheck = rowData.OriginalTransactionTypeCode.trim().match(nonIntegerRegex);
        } else {
            valueToCheck = rowData.TransactionTypeCode.trim().match(nonIntegerRegex);
        }

        valueToCheck = valueToCheck ? valueToCheck.join("") : "";

        utils.transactionCodeList.find((item) => {
            if (item.TransType === valueToCheck) {
                if (item.DocumentType === "CashPurchase") {
                    setCurrentEditID2(rowData.OriginalDocumentID);
                } else if (item.DocumentType === "Purchase Invoice") {
                    setCurrentEditID3(rowData.OriginalDocumentID);
                } else {
                    setCurrentEditID(rowData.id);
                }
            }
        })
    }

    const closePopup = (id) => {
        setCurrentEditID(id);
        setCurrentEditID2(id);
        setCurrentEditID3(id);
        setCurrentDetailID(id);
    }

    function handleEInvoice(e) {
		setInvType('01-OPP');
		setCurrentDetailID(e.row.data.DocumentNo);
	}
    
    return <Container>
        <div id='normal-listing'>
        <Listing
            className="ap-listing-datagrid"
            apiURL={apiURL}
            listingURL="/api/APPurchase/list"
            sortColumn={"DocumentDate"}
            sortOrder={"desc"}
            listingTitle={"AP Purchase"}
            storageName={"APPurchaseListingColumnWidth"}
            onAddClick={setCurrentEditID}
            handleEdit={handleEdit}
            einvoiceshow={true}
            handleEInvoice={handleEInvoice}
        >
            <Column dataField="DocumentDate" format="dd/MM/yyyy" dataType={"date"}/>
            <Column dataField="DocumentNo" />
            <Column dataField="SupplierCode" />
            <Column dataField="SupplierName" />
            <Column dataField="ForexCode" caption="Forex"/>
            <Column dataField="MainForexAmount" caption={"Amount"} format={{ type: "fixedPoint", precision: 2}} />
            <Column dataField="MainLocalAmount" caption={"Local Amount"} format={{ type: "fixedPoint", precision: 2}} />
            <Column dataField="OutstandingAmount" caption={"Outstanding Amount"} format={{ type: "fixedPoint", precision: 2}} />
        </Listing>
        </div>

         {currentDetailID !== null && (
                <EInvoiceMain
                    formID={currentDetailID}
                    closePopup={closePopup}
                    apiURL={apiURL}
                    title={"E-Invoice Main"}
                    invoiceType={invType}
                />
            )}

            {currentEditID !== null && (
                <APPurchaseForm
                    formID={currentEditID}
                    closePopup={closePopup}
                    title={"A/P Invoice"}
                    apiURL={apiURL}
                />
            )}

            {currentEditID2 !== null && (
                <CashPurchaseForm
                    formID={currentEditID2}
                    closePopup={closePopup}
                    title={"Cash Purchase"}
                    apiURL={`/api/PurchaseCashPurchase`}
                />
            )}

            {currentEditID3 !== null && (
                <PurchaseInvoiceForm
                    formID={currentEditID3}
                    closePopup={closePopup}
                    title={"Purchase Invoice"}
                    apiURL={`/api/PurchaseInvoice`}
                />
            )}

    </Container>;
}