import React, { useState, useEffect, useCallback, useRef } from "react";
import PopupForm from "../../../components/popup-form/PopupFormV2";
import baseapi from "../../../api/baseapi";
import utils, { CheckChequeNoValid } from "../../../utils/common";
import DataGrid, { Column, Editing, Selection, LoadPanel, ColumnChooser, Summary, TotalItem, Toolbar, Item as ToolbarItem } from "devextreme-react/data-grid";
import { NumberBox } from "devextreme-react/number-box";
import { CheckBox } from "devextreme-react/check-box";
import { TextBox } from "devextreme-react/text-box";
import { loading, closeLoading } from "../../../utils/common";
import CustomizedLookup from "../../../components/lookup/Lookup";
import CustomizedGridLookup from "../../../components/lookup/GridLookup";
import { Template } from "devextreme-react/core/template";
import { Validator, RequiredRule as ValidatorRequired, CustomRule } from "devextreme-react/validator";
import { paymentReportNames } from "../../../utils/lookupstore";
import ChildrenDatagrid from "../../../components/children-datagrid/ChildrenDatagrid";
import { moduleCalculation, ParentValueCalculation } from "../../../utils/module-calculation";
import GainAndLossForm from "../../../components/popup-form/gain-and-loss-form/GainAndLossForm";
import AsyncDateBox from "../../../components/async-date-box/AsyncDateBox";
import { DocumentSettings } from "../../../utils/documentSettings";
import DocumentNumberLookup from "../../../components/lookup/DocumentNoLookup";
export default function APPaymentForm(props) {
	const formID = useRef(null);
	const formRef = useRef(null);
	const originalSupplierId = useRef(null);

	const offsetGridRef = useRef(null);
	const offsetOldValue = useRef(null);
	const childrenTempValue = useRef(null);
	const gainAndLossRef = useRef(null);

	const [isEditing, setIsEditing] = useState(false);
	const [startValidation, setStartValidation] = useState(0);
	const [lockedForm, setLockedForm] = useState(false);
	const [fiscalYearLocked, setFiscalYearLocked] = useState(false);
	const [refundAmount, setRefundAmount] = useState(0);
	const [defaultValue, setDefaultValue] = useState({});

	const supplierLookupRef = useRef(null);
	const journalTypeLookupRef = useRef(null);
	const forexLookupRef = useRef(null);
	const offsetCheckboxRef = useRef(null);
	const areaLookupRef = useRef(null);
	const salesmanLookupRef = useRef(null);
	const projectLookupRef = useRef(null);
	const [formValue, setFormValue] = useState({});
	const [offsetForm, setOffsetForm] = useState({ Parent: {}, Children: [] });
	const childrenGridRef = useRef(null);
	const [gridLookupOpen, setGridLookupOpen] = useState(false);
	const totalPaymentAmount = useRef(0);
	const [reportDocumentNo, setReportDocumentNo] = useState(null);
	const [offsetGridDataValid, setOffsetGridDataValid] = useState(true)

	// Ref to track if we are in the middle of a bulk update
	const isBulkUpdating = useRef(false);

	// useEffect to re-enable onValueChanged handlers after state update
	useEffect(() => {
		if (isBulkUpdating.current) {
			// Re-enable handlers after the state update is finished
			isBulkUpdating.current = false;
		}
	}, [formValue]); // This effect runs whenever formValues is updated

	useEffect(() => {
		if (!utils.isNullOrEmpty(formValue["DocumentNo"])) {
			setReportDocumentNo(formValue["DocumentNo"]);
		}
	}, [formValue["DocumentNo"]]);

	useEffect(() => {
		if (!utils.isNullOrEmpty(formValue["SupplierID"])) {
			baseapi.httpget("/api/APPaymentOffset/Get?id=" + formID.current + "&supplierID=" + formValue["SupplierID"]).then((response) => {
				const { data } = response;
				const copiedChildren = [...data.model.Children];

				for (var i = 0; i < copiedChildren.length; i++) {
					if (copiedChildren[i]["OffsetAmount"] === 0) {
						copiedChildren[i]["OffsetLogDate"] = "";
					} else {
						copiedChildren[i]["OffsetLogDate"] = copiedChildren[i]["OffsetLogDate"];
					}
				}

				setOffsetForm({
					Parent: null,
					Children: copiedChildren
				});
			});

			// This condition prevents overwriting the local rate saved for this document
			// with the supplier's local rate upon loading a saved edited document.
			if (formValue["SupplierID"] !== originalSupplierId.current) {		
				baseapi.httpget(`api/Supplier/Get`, { id: formValue["SupplierID"] }).then((response) => {
					// console.log(response.data)
					const { data } = response;
	
					const copied = {};
	
					copied["SupplierName"] = data.model.Name;
					copied["ForexID"] = data.model.ForexID;
					copied["SalesmanID"] = data.model.SalesmanID;
					copied["SalesmanCode"] = data.model.SalesmanCode;
					copied["AreaID"] = data.model.AreaID;
					copied["AreaCode"] = data.model.AreaCode;
					copied["CreditTermsID"] = data.model.CreditTermID;
					copied["CreditTermsCode"] = data.model.CreditTermCode;
	
					baseapi.httpget(`/api/Forex/Get`, { id: data.model.ForexID }).then((response) => {
						const { data } = response;
						// console.log(data)
						copied["LocalRate"] = data.model.LocalRate;
						copied["ForexRate"] = data.model.ForexRate;
						copied["ForexCode"] = data.model.Code
	
						setFormValue((prevValues) => ({
							...prevValues,
							...copied,
							LocalAmount: data.model.LocalRate * prevValues["MainLocalAmount"]
						}));
					});
				});
			}
		}
	}, [formValue["SupplierID"]]);

	useEffect(() => {
		if(!utils.isNullOrEmpty(formValue["LocalRate"]) && !fiscalYearLocked){
			setFormValue((prevValues) => ({
				...prevValues,
				MainLocalAmount: prevValues["LocalRate"] * getTotalForexAmount()
			}));
		}

		// Call the utils triggerOnRowUpdating
		utils.triggerOnRowUpdating(childrenGridRef, formValue);
	}, [formValue["LocalRate"]]);

	useEffect(() => {
		if(!utils.isNullOrEmpty(formValue["DocumentDate"])){
			onRowUpdated({ BankInDateChanged: true });
		}
	}, [formValue["DocumentDate"]]);


	useEffect(() => {
		// console.log("Form ID Changed", props.formID);
		if (props.formID !== null) {
			formID.current = props.formID;
			utils.popupFormOpen(formRef);

			if (formID.current !== "new") {
				setIsEditing(true);
			} else {
				setIsEditing(false);
			}
		}
	}, [props.formID]);

	useEffect(() => {
		if (childrenGridRef.current !== null) {
			setOffsetOldValue(offsetForm.Children);
			updateUnappliedAmount(childrenTempValue.current, offsetForm.Children);
		}
	}, [offsetForm.Children]);

	// Handler for setting values for all TextBoxes
	const setAllValues = (newValues) => {
		isBulkUpdating.current = true; // Disable onValueChanged handlers
		setFormValue(newValues); // Set form values
	};

	// Handler for individual TextBox value change
	const handleValueChange = (e, receivedDataField = null) => {
		if (!isBulkUpdating.current) {
			try {
				const dataField = receivedDataField !== null ? receivedDataField : e.element.getAttribute("dataField");
				setFormValue((prevValues) => ({
					...prevValues,
					[dataField]: e.value
				}));
			} catch (error) {
				console.log("error", error)
			}	
		}
	};

	function handleSubmit(stay = false) {
		if (!offsetGridDataValid) {
			utils.popupFormSetErrorForm(formRef, {
				visible: true,
				message: "Invalid offset data.",
				type: "Danger"
			});
			return;
		}

		const validationRules = [
			{ name: "ChartAccountID", rules: [{ name: "Required" }] },
			{ name: "Description", rules: [{ name: "Required" }] },
			{ name: "ForexAmount", rules: [{ name: "Min", value: 1 }] }
		];

		const children = utils.childrenGridGetSource(childrenGridRef);
		const filteredChildren = utils.filterGridValue(children, validationRules);

		// Set document number prefix
		DocumentSettings.setDocumentPrefix(formValue["DocumentPrefix"]);
		
		const submittedForm = {
			Parent: formValue,
			Children: filteredChildren
		};

		loading(`Saving ${props.title}...`);

		baseapi
			.httppost(
				utils.extendUrlVar("/api/APPayment/save", {
					id: formID.current || "",
					del: false
				}),
				submittedForm
			)
			.then((response) => {
				const { data } = response;
				if (!data.status) {
					utils.popupFormSetErrorForm(formRef, {
						visible: true,
						message: data.message,
						type: "Danger"
					});
					closeLoading();
					return; 
				}
				baseapi
					.httppost(utils.extendUrlVar("/api/APPaymentOffset/save", { id: response.data.id || "", del: false }), {
						Parent: formValue,
						Children: getOffsetChildren().filter((row) => row.OffsetAmount > 0)
					})
					.then((resp) => {
						const { data } = resp;
						utils.popupFormSuccessfulSubmit(formRef, data);
						utils.popupFormSetErrorForm(formRef, {
							visible: true,
							message: data.message,
							type: data.status ? "Success" : "Danger",
							action: data.action
						});
						if (!data.status) utils.popupFormCloseButtonAction(formRef);
						if (data.docNo) setReportDocumentNo(data.docNo);
					});
			});
	}

	function onInitialized(e) {
		if (formID.current === "new") loading(`Loading New ${props.title}...`);
		else loading(`Loading Existing ${props.title}...`);

		baseapi.httpget("/api/APPayment/Get", { id: formID.current || "",  documentNumberPrefix: DocumentSettings.getDocumentPrefix() || "", userID: utils.getUserID() }).then((response) => {
			const { data } = response;

			originalSupplierId.current = data.model.Parent.SupplierID;

			utils.popupFormAfterInitialization(formRef, props, data);

			//Set Default Values
			if(!utils.isNullOrEmpty(data.defaultValues)){
				setDefaultValue(data.defaultValues);
			}

			if (formID.current === "new") {
				utils.childrenGridAddRow(childrenGridRef); //Add row for the frist grid
			} else {
				// console.log("Form", data)
				childrenTempValue.current = data.model.Children; //For offset calculation purpose in editing state
				utils.childrenGridSetSource(childrenGridRef, data.model.Children);
			}

			if(!utils.isNullOrEmpty(data.refundAmount) && data.refundAmount > 0){
				setRefundAmount(data.refundAmount);
			}
			else{
				setRefundAmount(0);
			}

			setAllValues(data.model.Parent);
			closeLoading();
			setLockedForm(utils.receiptMessage(formRef, data));
			setOffsetGridDataValid(true);
		});
	}

	const getTotalForexAmount = (children = null) => {
		const childrenCopied = children === null ? utils.childrenGridGetSource(childrenGridRef) : children;
		var totalForexAmount = fiscalYearLocked ? formValue["MainForexAmount"] : 0;

		for (var i = 0; i < childrenCopied.length; i++) {
			const rowData = childrenCopied[i];
			totalForexAmount += parseFloat(rowData["ForexAmount"]);
		}

		return totalForexAmount - refundAmount;
	};

	const getTotalOffsetAmount = (children = null) => {
		const childrenCopied = children === null ? offsetGridRef.current.instance.getVisibleRows() : children;
		var totalOffsetAmount = 0;

		if (children) {
			for (var i = 0; i < childrenCopied.length; i++) {
				const rowData = childrenCopied[i];
				totalOffsetAmount += parseFloat(rowData["OffsetAmount"]);
			}
		} else {
			for (var i = 0; i < childrenCopied.length; i++) {
				const rowData = childrenCopied[i]["data"];
				totalOffsetAmount += parseFloat(rowData["OffsetAmount"]);
			}
		}

		return totalOffsetAmount - refundAmount;
	};

	const updateUnappliedAmount = (parent = null, children = null) => {
		if (offsetGridDataValid) {
			// Calculation for unapplied amount
			setFormValue((prevValues) => ({
				...prevValues,
				OutstandingAmount: getTotalForexAmount(parent) - getTotalOffsetAmount(children)
			}));
		}
	};

	const onRowUpdating2 = (e) => {
		const oldData = e.oldData;
		const currentEditingRow = e.newData;

		currentEditingRow["OutstandingAmount"] = parseFloat(oldData["OutstandingAmount"]) - parseFloat(currentEditingRow["OffsetAmount"]) + parseFloat(oldData["OffsetAmount"]);

		if (currentEditingRow.OffsetAmount === 0) {
			currentEditingRow["OffsetLogDate"] = "";
		} else {
			currentEditingRow["OffsetLogDate"] = new Date();
		}

		updateUnappliedAmount();
	};

	const getOffsetChildren = () => {
		const childrenCopied = offsetGridRef.current.instance.getVisibleRows();
		const offsetChildren = [];

		for (var i = 0; i < childrenCopied.length; i++) {
			const rowData = childrenCopied[i]["data"];
			offsetChildren.push(rowData);
		}

		return offsetChildren;
	};

	const validateOffsetAmount = (e) => {
		const oldValue = offsetOldValue.current;
		const found = oldValue.find((row) => row.OffsetDocumentID === e.data.OffsetDocumentID);
		const oldOffsetAmount = found.OffsetAmount ?? 0;
		const oldOutstandingAmount = found.OutstandingAmount ?? 0;
		const maximumAmount = oldOutstandingAmount + oldOffsetAmount;

		if (e.value > maximumAmount) {
			return false;
		}

		// Ensure the offset amount is not negative.
		if (e.value < 0) {
			return false;
		}

		if (getTotalOffsetAmount() <= getTotalForexAmount()) {
			return true;
		}

		return false;
	};

	const setOffsetOldValue = (value = null) => {
		if (value) {
			offsetOldValue.current = value;
			// saveToLS("AROffsetOldValue", value);
		} else {
			const childrenCopied = offsetGridRef.current.instance.getVisibleRows();
			const data = [];

			for (var i = 0; i < childrenCopied.length; i++) {
				const rowData = childrenCopied[i]["data"];
				data.push(rowData);
			}
			offsetOldValue.current = data;
			// saveToLS("AROffsetOldValue", data);
		}
	};

	const selectionAutomation = (selectedRow, args) => {
		const currentValue = args.component.option("value");
		const offsetCopied = getOffsetChildren();
		const rowData = selectedRow.data;
		const foundIndex = offsetCopied.findIndex((row) => row.OffsetDocumentID === rowData.OffsetDocumentID);
		const totalCreditAmount = getTotalForexAmount();

		if (offsetGridDataValid) {
			if (foundIndex !== -1) {
				if (!currentValue) {
					rowData.OutstandingAmount += rowData.OffsetAmount;
					rowData.OffsetAmount = 0;
					rowData.OffsetLogDate = "";
				} else {
					const totalOffsetAmount = getTotalOffsetAmount();
					const PaymentAfterOffset = parseFloat(rowData.OutstandingAmount);
					const TotalReceiptAfterOffset = totalCreditAmount - totalOffsetAmount;
					const checkExceedTotalAmount = PaymentAfterOffset > TotalReceiptAfterOffset;
	
					if (totalOffsetAmount > totalCreditAmount) {
						return;
					}
	
					// If the amount does not exceed then automatically take the full amount
					if (!checkExceedTotalAmount) {
						rowData.OffsetAmount = rowData.OutstandingAmount;
						rowData.OutstandingAmount = 0;
					}
					//If not take the maximum amount
					else {
						const maximumToOffset = totalCreditAmount - totalOffsetAmount;
						rowData.OffsetAmount += maximumToOffset;
						rowData.OutstandingAmount -= maximumToOffset;
					}
	
					if (rowData.OffsetAmount === 0) {
						rowData.OffsetLogDate = "";
					} else {
						rowData.OffsetLogDate = new Date();
					}
				}
	
				offsetGridRef.current.instance.cellValue(foundIndex, "OffsetAmount", rowData.OffsetAmount);
				offsetGridRef.current.instance.cellValue(foundIndex, "OutstandingAmount", rowData.OutstandingAmount);
				offsetGridRef.current.instance.cellValue(foundIndex, "OffsetLogDate", rowData.OffsetLogDate);
			}
	
			// Calculation for unapplied amount
			setFormValue((prevValues) => ({
				...prevValues,
				OutstandingAmount: totalCreditAmount - getTotalOffsetAmount()
			}));
		}
	};

	const offsetCheckBox = (e) => {
		const rowData = e.data;
		return <CheckBox id='offset-check-btn' onValueChanged={(args) => selectionAutomation(e, args)} defaultValue={rowData.OffsetAmount > 0} disabled={rowData["OffsetTypeID"] === "PRF"}/>;
	};

	const offsetSelectAllChecker = (e) => {
		const offsetSelectAll = e.value;
		const childrenCopied = offsetGridRef.current.instance.getVisibleRows();
		const totalCreditAmount = getTotalForexAmount();
		var totalOffset = 0;

		if (offsetSelectAll) {
			for (var i = 0; i < childrenCopied.length; i++) {
				const rowData = childrenCopied[i]["data"];
				if(rowData["OffsetTypeID"] !== "PRF"){
					const checkExceedOutstandingAmount = totalOffset + rowData["OutstandingAmount"] + rowData["OffsetAmount"] > totalCreditAmount;

					if(totalOffset < totalCreditAmount){
						rowData["OffsetLogDate"] = new Date();

						if (!checkExceedOutstandingAmount) {
							rowData["OffsetAmount"] += rowData["OutstandingAmount"];
							rowData["OutstandingAmount"] = 0;
						} else {
							rowData["OutstandingAmount"] += rowData["OffsetAmount"];
							const maximumToOffset = totalCreditAmount - totalOffset;
							rowData["OffsetAmount"] = maximumToOffset;
							rowData["OutstandingAmount"] -= maximumToOffset;
						}
					}
					else{
						rowData["OffsetLogDate"] = "";
						rowData["OutstandingAmount"] += rowData["OffsetAmount"];
						rowData["OffsetAmount"] = 0;
					}
					//Add the latest offset
					totalOffset += rowData["OffsetAmount"];

					offsetGridRef.current.instance.cellValue(i, "OffsetAmount", rowData.OffsetAmount);
					offsetGridRef.current.instance.cellValue(i, "OutstandingAmount", rowData.OutstandingAmount);
					offsetGridRef.current.instance.cellValue(i, "OffsetLogDate", rowData.OffsetLogDate);
				}
			}
		} else {
			for (var i = 0; i < childrenCopied.length; i++) {
				const rowData = childrenCopied[i]["data"];
				if(rowData["OffsetTypeID"] !== "PRF"){
					rowData["OutstandingAmount"] += rowData["OffsetAmount"];
					totalOffset -= rowData["OffsetAmount"];
					rowData["OffsetAmount"] = 0;
					rowData["OffsetLogDate"] = "";
	
					offsetGridRef.current.instance.cellValue(i, "OffsetAmount", rowData.OffsetAmount);
					offsetGridRef.current.instance.cellValue(i, "OutstandingAmount", rowData.OutstandingAmount);
					offsetGridRef.current.instance.cellValue(i, "OffsetLogDate", rowData.OffsetLogDate);
				}
			}
		}

		// Calculation for unapplied amount
		updateUnappliedAmount();
	};

	const onInitNew = () => {
		return {
			Line: utils.childrenGridLength(childrenGridRef) + 1,
			BankCharges: 0,
			LocalPaymentAmount: 0,
			ProjectID: utils.popupFormGetDefaultValues(formRef)["ProjectID"],
			ProjectCode: utils.popupFormGetDefaultValues(formRef)["ProjectCode"],
			BankInDate: formValue["DocumentDate"] ?? new Date(),
			NettAmount: 0,
			DetailLN: 0,
			Point: 0,
			NettLocalAmount: 0,
			Project: ""
		};
	};

	const onRowUpdating = (e) => {
		const oldValue = { ...e.oldData };
		const newValue = e.newData;
		const currentRow = Object.assign(oldValue, newValue);
		const parentValue = { ...formValue };
		e.newData = utils.roundUpObjectProperties(moduleCalculation(currentRow, parentValue));

		if (currentRow["ForexAmount"] !== e.oldData["ForexAmount"]) {
			offsetSelectAllChecker({ value: false });
		}
	};

	const onRowUpdated = (e, children = null) => {
		if(!fiscalYearLocked){
			const ParentValue = { ...formValue };
			const childrenCopied = children !== null ? children : utils.childrenGridGetSource(childrenGridRef);
			for (var i = 0; i < childrenCopied.length; i++) {
				if (e.BankInDateChanged) {
					childrenCopied[i]["BankInDate"] = ParentValue["DocumentDate"];
				}
			}
			const calculationResult = ParentValueCalculation(childrenCopied, ParentValue);
			setAllValues(calculationResult.parent);
			utils.childrenGridSetSource(childrenGridRef, childrenCopied);
			updateUnappliedAmount();
		}
	};

	const clearForm = () => {
		props.closePopup(null);
		offsetOldValue.current = null;
		childrenTempValue.current = null;
		setAllValues({});
		setOffsetForm({ Parent: {}, Children: [] });
		setOffsetGridDataValid(true);
	};

	const gridOnSelectionChanged = (e, columnName) => {
		setGridLookupOpen(false);
		const currentFocusCellIndex = utils.childrenGridGetFocusCellIndex(childrenGridRef);
		const currentRecords = utils.childrenGridGetSource(childrenGridRef);
		const rowIndex = currentFocusCellIndex.rowIndex;
		const key = currentFocusCellIndex.rowID;
		const recordIndex = currentRecords.findIndex(record => record.ID === key)
		const selectedItem = e.selectedItem;
		currentRecords[recordIndex][columnName] = e.value;

		if (columnName === "ChartAccountID") {
			currentRecords[recordIndex]["Description"] = selectedItem?.name;
			currentRecords[recordIndex]["ChartAccountCode"] = selectedItem?.code;
		} else if (columnName === "TaxCodeID") {
			currentRecords[recordIndex]["TaxRate"] = selectedItem?.rate ?? 0;
			currentRecords[recordIndex]["TaxCode"] = selectedItem?.code;
		} else if (columnName === "BankChargesChartAccountID") {
			currentRecords[recordIndex]["BankChargesChartAccountCode"] = selectedItem?.code;
		} else if (columnName === "ProjectID") {
			currentRecords[recordIndex]["ProjectCode"] = selectedItem?.code;
		}

		e["key"] = key;
		e["data"] = currentRecords[recordIndex];
		onRowUpdated(e, currentRecords);
	};

	const calculateDataGridTotal = useCallback((data) => {
		return data.valueText.replace("Sum: ", "");
	}, []);

	const customizedAsyncValidation = async () => {
		const children = utils.childrenGridGetSource(childrenGridRef);
		const chequeNo = [];

		if (Array.isArray(children)) {
			for (var i = 0; i < children.length; i++) {
				const record = children[i];
				if (!utils.isNullOrEmpty(record["ChequeNo"])) {
					chequeNo.push(utils.trim(record["ChequeNo"]));
				}
			}
		}

		return await CheckChequeNoValid(formRef, chequeNo, formValue["DocumentNo"]);
	};

	const offsetEditorPreparing = (e) => {
		//console.log('onEditorPreparing')
		if (e.row.data.OffsetTypeID === "PRF") {
			e.editorOptions.disabled = true;
		}
	};

	return (
		<div>
			<PopupForm
				ref={formRef}
				childrenGridRef={childrenGridRef}
				offsetGridClear={setOffsetForm}
				offsetValue={offsetForm}
				ReportDocumentNo={reportDocumentNo}
				onClosing={clearForm}
				fullScreen={false}
				width={"95%"}
				height={"90%"}
				headerClassName={"ap-module-form-title-bg popup-form-title"}
				title={props.title}
				apiURL={props.apiURL}
				creditChecking={false}
				customizedAsyncValidation={customizedAsyncValidation}
				requiredColumns={{
					Children: ["ChartAccountID"]
				}}
				filterRules={{
					Children: [
						{ name: "ChartAccountID", rules: [{ name: "Required" }] },
						{ name: "Description", rules: [{ name: "Required" }] },
						{ name: "ForexAmount", rules: [{ name: "Min", value: 1 }] }
					]
				}}
				onShown={onInitialized}
				onSuccessfulSubmit={({ stay }) => {
					if (stay) onInitialized();
				}}
				copyFields={{
					Parent: formValue,
					Children: utils.childrenGridGetSource(childrenGridRef)
				}}
				onPasteForm={(e) => {
					setAllValues(e.Parent);
				}}
				isBeforeFiscalYear={setFiscalYearLocked}
				actionButtonEnabled={!fiscalYearLocked}
				saveButtonOnly={fiscalYearLocked}
				disableButtons={refundAmount === 0 && lockedForm}
				copyStorage={"APPaymentCopiedData"}
				formData={formValue}
				setFormValue={(newValues) => setFormValue((prevState) => ({ ...prevState, ...newValues })) }
				defaultSelectedReport={"w5B27vxuyxE%3d"}
				reportURL={paymentReportNames.url} // Need to fix the payment report name query values
				onValidating={setStartValidation}
				startValidation={startValidation}
				isEditing={isEditing}
				lookupValidateRef={[supplierLookupRef, forexLookupRef, projectLookupRef, areaLookupRef, salesmanLookupRef]}
				onSaving={(e) => {
					if (!offsetGridDataValid) {
						utils.popupFormSetErrorForm(formRef, {
							visible: true,
							message: "Invalid offset data.",
							type: "Danger"
						});
						return;
					}

					utils.gainAndLossFormOpen(gainAndLossRef, {
						Parent: formValue,
						Children: getOffsetChildren().filter((row) => row?.OffsetAmount > 0)
					});
				}}
				cancelDocumentCustomization={
					<span className='popup-form-header-cancel'>
						<CheckBox
							value={formValue["Cancelled"]}
							elementAttr={{ dataField: "Cancelled" }}
							onValueChanged={(e) => {
								handleValueChange(e);
							}}
							alignment='left'
							width={"12px"}
							height={"12px"}
							iconSize={"12px"}
							readOnly={lockedForm || fiscalYearLocked}
							className='popup-header-cancel-input'
						/>

						<span className='popup-header-cancel-text'>Cancelled</span>
					</span>
				}>
				<div className='popup-form-main-content'>
					<div className='ap-module-border-bottom custom-form-grid-section1'>
						<div className=''>
							<div className='popup-form-item-container2'>
								<div className='popup-group-form-item'>
									<div className='popup-group-form-label'>Supplier Code </div>

									<div className='popup-group-form-input'>
										<CustomizedLookup
											ref={supplierLookupRef}
											className={"ap-listing-datagrid"}
											displayExpr={"code"}
											valueExpr={"id"}
											value={formValue["SupplierID"]}
											onSelectionChanged={(e) => {
												handleValueChange(e, "SupplierID");
											}}
											startValidation={startValidation !== 0}
											required={true}
											requiredMessage={"Supplier Code is required!"}
											dataSourceURL={"api/Utilities/GetSuppliers"}
											readOnly={lockedForm || fiscalYearLocked}
											displayText={formValue["SupplierCode"]}>
											<Column dataField='code'></Column>
											<Column dataField='name' caption={"Supplier Name"}></Column>
											<Column dataField='forexCode' caption='Currency'></Column>
										</CustomizedLookup>
									</div>
								</div>
							</div>

							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Supplier Name </div>

								<div className='popup-group-form-input'>
									<TextBox
										value={formValue["SupplierName"]}
										elementAttr={{ dataField: "SupplierName" }}
										onValueChanged={(e) => {
											handleValueChange(e);
										}}
										alignment='left'
										width={"auto"}
										readOnly={lockedForm || fiscalYearLocked}
									>
										<Validator>{startValidation !== 0 && <ValidatorRequired message={"Supplier Name is required!"} />}</Validator>
									</TextBox>
								</div>
							</div>

							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Project </div>

								<div className='popup-group-form-input' id='customized-lookup1'>
									<CustomizedLookup
										ref={projectLookupRef}
										className={"ar-listing-datagrid"}
										displayExpr={"code"}
										valueExpr={"id"}
										value={formValue["ProjectID"]}
										onSelectionChanged={(e) => {
											handleValueChange(e, "ProjectID");
										}}
										startValidation={startValidation !== 0}
										required={true}
										requiredMessage={"Project is required!"}
										dataSourceURL={"api/Utilities/GetProjects"}
										readOnly={lockedForm || fiscalYearLocked}
										displayText={formValue["ProjectCode"]}>
										<Column dataField='code'></Column>
										<Column dataField='name' caption={"Project Name"}></Column>
									</CustomizedLookup>
								</div>
							</div>
						</div>

						<div>
							<div className='popup-form-item-container2'>
								<div className='popup-group-form-item'>
									<div className='popup-group-form-label next-doc-label'>Payment No </div>

									<div className='popup-group-form-input'>
										<div className='next-doc-display'>Next Doc {formValue["NextDocNo"]}</div>
										<DocumentNumberLookup
											formValue={formValue}
											setFormValue={setFormValue}
											formID={formID}
											lockedForm={lockedForm}
											valueOnChange={handleValueChange}
											fiscalYearLocked={fiscalYearLocked}
										/>
									</div>
								</div>

								<div className='popup-group-form-item'>
									<div className='popup-group-form-label'>Date </div>

									<div className='popup-group-form-input'>
										<AsyncDateBox
											value={formValue["DocumentDate"]}
											elementAttr={{ dataField: "DocumentDate" }}
											onValueChanged={(e) => {
												handleValueChange(e);
											}}
											alignment='left'
											width={"auto"}
											displayFormat='dd/MM/yyyy'
											dateSerializationFormat='yyyy-MM-dd'
											useMaskBehavior={true}
											readOnly={lockedForm}
											disabled={fiscalYearLocked}
											asyncURL={"api/Utilities/CheckAfterFiscalYear"}
											asyncMessage={"Date cannot set before current fiscal year"}
											asyncProperty={"inputDate"}
										/>
									</div>
								</div>

								<div className='popup-group-form-item'>
									<div className='popup-group-form-label'>Ref. No </div>

									<div className='popup-group-form-input'>
										<TextBox
											value={formValue["Remarks"]}
											elementAttr={{ dataField: "Remarks" }}
											onValueChanged={(e) => {
												handleValueChange(e);
											}}
											alignment='left'
											width={"auto"}
											readOnly={lockedForm || fiscalYearLocked}
										/>
									</div>
								</div>

								<div className='popup-group-form-item'>
									<div className='popup-group-form-label'>Terms </div>

									<div className='popup-group-form-input'>
										<CustomizedLookup
											className={"ar-listing-datagrid"}
											displayExpr={"code"}
											valueExpr={"id"}
											value={formValue["CreditTermsID"]}
											onSelectionChanged={(e) => {
												handleValueChange(e, "CreditTermsID");
											}}
											startValidation={startValidation !== 0}
											required={true}
											requiredMessage={"Credit Term is required!"}
											displayText={formValue["CreditTermsCode"]}
											dataSourceURL={"api/Utilities/GetCreditTerms"}
											readOnly={lockedForm || fiscalYearLocked}
										>
											<Column dataField='code'></Column>
											<Column dataField='name' caption='Days'></Column>
										</CustomizedLookup>
									</div>
								</div>

								<div className='popup-group-form-item'>
									<div className='popup-group-form-label'>Salesman </div>

									<div className='popup-group-form-input'>
										<CustomizedLookup
											ref={salesmanLookupRef}
											className={"ar-listing-datagrid"}
											displayExpr={"code"}
											valueExpr={"id"}
											value={formValue["SalesmanID"]}
											onSelectionChanged={(e) => {
												handleValueChange(e, "SalesmanID");
											}}
											dataSourceURL={"api/Utilities/GetSalesmans"}
											displayText={formValue["SalesmanCode"]}
											readOnly={lockedForm || fiscalYearLocked}
											startValidation={startValidation !== 0}
											required={true}
											requiredMessage={"Salesman is required!"}>
											<Column dataField='code'></Column>
											<Column dataField='name'></Column>
										</CustomizedLookup>
									</div>
								</div>

								<div className='popup-group-form-item'>
									<div className='popup-group-form-label'>Area </div>

									<div className='popup-group-form-input'>
										<CustomizedLookup
											ref={areaLookupRef}
											className={"ap-listing-datagrid"}
											displayExpr={"code"}
											valueExpr={"id"}
											value={formValue["AreaID"]}
											onSelectionChanged={(e) => {
												handleValueChange(e, "AreaID");
											}}
											displayText={formValue["AreaCode"]}
											dataSourceURL={"api/Utilities/GetAreas"}
											readOnly={lockedForm || fiscalYearLocked}
											startValidation={startValidation !== 0}
											required={true}
											requiredMessage={"Area is required!"}>
											<Column dataField='code'></Column>
											<Column dataField='name'></Column>
										</CustomizedLookup>
									</div>
								</div>

								<div className='popup-group-form-item'>
									<div className='popup-group-form-label'>Forex </div>

									<div className='popup-group-form-input'>
										<CustomizedLookup
											ref={forexLookupRef}
											className={"ap-listing-datagrid"}
											displayExpr={"code"}
											valueExpr={"id"}
											value={formValue["ForexID"]}
											displayText={formValue["ForexCode"]}
											onSelectionChanged={(e) => {
												handleValueChange(e, "ForexID");
											}}
											required={true}
											requiredMessage={"Forex is required!"}
											readOnly={true}
											dataSourceURL={"api/Utilities/GetForexs"}
											>
											<Column dataField='code'></Column>
											<Column dataField='name'></Column>
										</CustomizedLookup>
									</div>
								</div>

								<div className='popup-group-form-item'>
									<div className='popup-group-form-label'>Local Rate </div>

									<div className='popup-group-form-input'>
										<NumberBox
											value={formValue["LocalRate"]}
											elementAttr={{ dataField: "LocalRate" }}
											onValueChanged={(e) => {
												handleValueChange(e);
											}}
											alignment='left'
											width={"auto"}
											format={ utils.getDecimalFormat(defaultValue["LocalRateFixCheck"], defaultValue["LocalRateFormat"]) }
											readOnly={formValue["ForexID"] === defaultValue["ForexID"] || fiscalYearLocked}>
											<Validator>{startValidation !== 0 && <ValidatorRequired message={"Local Rate is required!"} />}</Validator>
										</NumberBox>
									</div>
								</div>

								<div className='popup-group-form-item'>
									<div className='popup-group-form-label'>Issue By </div>

									<div className='popup-group-form-input'>
										<TextBox
											value={formValue["IssueBy"]}
											elementAttr={{ dataField: "IssueBy" }}
											onValueChanged={(e) => {
												handleValueChange(e);
											}}
											alignment='left'
											width={"auto"}
											readOnly={true}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className='ap-module-border-bottom' style={{ padding: "0 0" }}>
						<ChildrenDatagrid
							ref={childrenGridRef}
							name={"Children"}
							allowColumnReordering={true}
							allowColumnResizing={true}
							keyExpr='ID'
							showBorders={true}
							defaultColumnValues={onInitNew}
							lookupFields={["ProjectID", "ChartAccountID", "TaxCodeID", "BankChargesChartAccountID"]}
							onRowUpdating={onRowUpdating}
							onRowUpdated={onRowUpdated}
							onRowRemoved={onRowUpdated}
							storageName={"APPaymentChildrenGrid"}
							disabled={lockedForm || fiscalYearLocked}
							onParentValueChanged={handleValueChange}
							defaultDescription={formValue["Description"]}
							disabledAdd={true}
							autoAddRow={false}
							allowDeleting={false}
							height={"auto"}
						>
							<Column dataField='Line' dataType='number' editorOptions={{ readOnly: true }} />

							<Column
								visible={true}
								dataField='ChartAccountID'
								dataType='string'
								caption='Payment Method'
								cellRender={(e) => {
									const data = e.data;
									return data.ChartAccountCode;
								}}
								editCellComponent={(component) => (
									<CustomizedGridLookup {...component} opened={gridLookupOpen} className={"ap-listing-datagrid"} onSelectionChanged={(e) => gridOnSelectionChanged(e, "ChartAccountID")} dataSourceURL={"api/Utilities/GetCashSalesPaymentMethod"}>
										<Column dataField='code'></Column>
										<Column dataField='name' caption={"Description"} width={"300px"}></Column>
									</CustomizedGridLookup>
								)}></Column>

							<Column visible={true} dataField='Description' />

							<Column
								visible={true}
								dataField='ChequeNo'
								width={"150px"}
								// editCellComponent={(component) => (
								// 	<DataGridAsyncBox
								// 		{...component}
								// 		message='Duplicate Cheque No!'
								// 		asyncURL={"api/Utilities/CheckChequeNoValid"}
								// 		parameter={"chequeNo"}
								// 		mergeParams={{ documentNo: utils.isNullOrEmpty(formValue["DocumentNo"]) ? "<NEW>" : formValue["DocumentNo"] }}
								// 	/>
								// )}
							/>

							<Column visible={false} dataField='BankInDate' format='dd/MM/yyyy' dataType={"date"} />

							<Column visible={true} dataField='ForexAmount' dataType={"number"} editorOptions={{ inputAttr: { autocomplete: "off" } }} format='#,##0.00' />

							<Column visible={true} dataField='BankCharges' format='#,##0.00' />

							<Column
								visible={true}
								dataField='ProjectID'
								dataType='string'
								cellRender={(e) => {
									const data = e.data;
									return data.ProjectCode;
								}}
								editCellComponent={(component) => (
									<CustomizedGridLookup {...component} opened={gridLookupOpen} className={"ap-listing-datagrid"} onSelectionChanged={(e) => gridOnSelectionChanged(e, "ProjectID")} dataSourceURL={"/api/Utilities/GetProjects"}>
										<Column dataField='code'></Column>
										<Column dataField='name' caption={"Description"} width={"300px"}></Column>
									</CustomizedGridLookup>
								)}></Column>

							<Column
								visible={false}
								dataField='TaxCodeID'
								colSpan={2}
								cellRender={(e) => {
									const data = e.data;
									return data.TaxCode;
								}}
								editCellComponent={(component) => (
									<CustomizedGridLookup {...component} opened={gridLookupOpen} className={"ap-listing-datagrid"} onSelectionChanged={(e) => gridOnSelectionChanged(e, "TaxCodeID")} dataSourceURL={"api/Utilities/GetGSTRate"}>
										<Column dataField='code'></Column>
										<Column dataField='name' caption={"Description"} width={"300px"}></Column>
									</CustomizedGridLookup>
								)}></Column>

							<Column visible={false} dataField='TaxRate' dataType='number' editorOptions={{ readOnly: true }} format='#,##0.00' />

							<Column visible={false} dataField='TaxInclusive' dataType='boolean' />

							<Column visible={false} dataField='ForexTaxAmount' dataType='number' format='#,##0.00' />

							<Column visible={false} dataField='TotalAmount' dataType='number' editorOptions={{ readOnly: true }} caption={"Sub Total"} format='#,##0.00' />

							<Column visible={false} dataField='DetailLN' />

							<Column visible={false} dataField='Point' />

							<Column visible={false} dataField='Project' />

							<Column visible={false} dataField='ForexTaxable' dataType='number' format='#,##0.00' />

							<Column visible={false} dataField='ForexTaxAmountOrignal' dataType='number' format='#,##0.00' />

							<Column visible={false} dataField='ForexTaxAdjust' dataType='number' format='#,##0.00' />

							<Column visible={false} dataField='ForexTaxablePlusTax' dataType='number' format='#,##0.00' />

							<Column visible={false} dataField='LocalTaxable' dataType='number' format='#,##0.00' />

							<Column visible={false} dataField='LocalTaxAmount' dataType='number' format='#,##0.00' />

							<Column visible={false} dataField='LocalTaxAmountOrignal' dataType='number' format='#,##0.00' />

							<Column visible={false} dataField='LocalTaxAdjust' dataType='number' editorOptions={{ readOnly: true }} format='#,##0.00' />

							<Column visible={false} dataField='LocalTaxablePlusTax' dataType='number' format='#,##0.00' />

							<Column visible={false} dataField='NettAmount' dataType='number' format='#,##0.00' />

							<Column visible={false} dataField='NettLocalAmount' dataType='number' format='#,##0.00' />

							<Column visible={false} dataField='BankChargesProjectID' />

							<Column visible={false} dataField='AccountType' />

							<Column visible={false} dataField='ForexTaxAutoAdjust' />

							<Column visible={false} dataField='LocalTaxAutoAdjust' />

							<Column visible={false} dataField='FinalLocalAmountAdjust' />

							<Column visible={false} dataField='LocalAmount' dataType='number' format='#,##0.00' />

							<Summary recalculateWhileEditing={true}>
								<TotalItem column='ForexAmount' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

								<TotalItem column='BankCharges' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

								<TotalItem column='ForexTaxablePlusTax' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

								<TotalItem column='ForexTaxable' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

								<TotalItem column='LocalTaxable' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

								<TotalItem column='ForexTaxAmount' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

								<TotalItem column='ForexTaxAmountOrignal' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

								<TotalItem column='LocalTaxablePlusTax' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

								<TotalItem column='LocalTaxAmount' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

								<TotalItem column='LocalTaxAmountOrignal' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

								<TotalItem column='TotalAmount' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />
							</Summary>
						</ChildrenDatagrid>
					</div>

					<div className='ap-module-border-bottom custom-form-grid-section2'>
						<div className='popup-group-form-item'>
							<div className='popup-group-form-label'>Local Amount </div>

							<div className='popup-group-form-input' style={{ width: "150px" }}>
								<NumberBox
									value={formValue["MainLocalAmount"]}
									elementAttr={{ dataField: "MainLocalAmount" }}
									onValueChanged={(e) => {
										handleValueChange(e);
									}}
									alignment='left'
									width={"auto"}
									format='#,##0.00'
									readOnly={true}>
									{/* <Validator>
                      <ValidatorRequired message={"Local Amount is required!"} />
                  </Validator> */}
								</NumberBox>
							</div>
						</div>

						<div className='popup-form-item-container2 custom-form-item-container2'>
							<div></div>

							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Unapplied Amount </div>

								<div className='popup-group-form-input'>
									<NumberBox
										value={formValue["OutstandingAmount"]}
										elementAttr={{
											dataField: "OutstandingAmount",
											class: "outstanding-amount-color"
										}}
										onValueChanged={(e) => {
											handleValueChange(e);
										}}
										alignment='left'
										width={"auto"}
										readOnly={true}
										format='#,##0.00'>
										{/* <Validator 
                                    >
                                        <ValidatorRequired message={"Outstanding is required!"} />
                                    </Validator> */}
									</NumberBox>
								</div>
							</div>
						</div>
					</div>

					<div className='ap-module-border-bottom' style={{ padding: "0 0" }}>
						<DataGrid
							ref={offsetGridRef}
							dataSource={offsetForm.Children}
							keyExpr='OffsetDocumentID'
							showBorders={true}
							onRowUpdating={onRowUpdating2}
							onRowUpdated={() => setOffsetOldValue()}
							columnAutoWidth={true}
							allowColumnReordering={true}
							allowColumnResizing={true}
							disabled={refundAmount === 0 && lockedForm}
							onEditorPreparing={offsetEditorPreparing}
							onRowValidating={(e) => {setOffsetGridDataValid(e.isValid)}}
							onEditCanceled={() => {setOffsetGridDataValid(true)}}
							paging={{ enabled: false }} 
							height={400}
						>
							<LoadPanel enabled={false} />
							<Editing mode='cell' allowAdding={false} allowDeleting={false} allowUpdating={true} />
							<Selection mode='multiple' showCheckBoxesMode='always' allowSelectAll={false} />
							<Column type={"selection"} width={"50px"} headerCellTemplate={"selectAllChecker"} cellRender={offsetCheckBox}>
								<Template name='selectAllChecker'>
									<CheckBox ref={offsetCheckboxRef} id='offset-check-btn' onValueChanged={offsetSelectAllChecker} />
								</Template>
							</Column>
							<Column dataField='DocumentDate' allowEditing={false} caption={"Date"} format='dd/MM/yyyy' dataType={"date"} />
							<Column dataField='OffsetDocumentNo' caption='Invoice No' allowEditing={false} />
							<Column dataField='OffsetTypeID' caption='Type' allowEditing={false} allowSorting={false} />
							{/* <Column dataField="Total" caption="Total Amount" allowEditing={false} /> */}
							<Column dataField='MainForexAmount' caption='Total Amount' allowEditing={false} visible={true} format='#,##0.00' />
							<Column dataField='OutstandingAmount' caption='Outstanding Amount' allowEditing={false} format='#,##0.00' />
							<Column dataField='OffsetAmount' caption='Offset Amount' format='#,##0.00'>
								<CustomRule message={"Not enough amount to offset or more than the payment amount!"} validationCallback={validateOffsetAmount} />
							</Column>
							{/* <Column dataField="IssueBy" allowEditing={false} /> */}
							<Column dataField='OffsetLogDate' caption='Offset Log Date' allowEditing={false} allowSorting={false} dataType={"string"} cellRender={utils.offsetLogDateDisplay} />
							<Column dataField='ForexName' allowEditing={false} visible={false} />
							<Column dataField='ForexRate' caption='Rate' dataType='string' allowEditing={false} visible={false} />
							<Column dataField='LocalOffsetAmount' caption='Local Offset' allowEditing={false} visible={false} />
							<Column dataField='GainLoss' caption='Gain/Loss' allowEditing={false} visible={false} />
							<Column dataField='GainLossDate' allowEditing={false} allowSorting={false} visible={false} />
							<Column dataField='BadDebtsReliefNo' allowEditing={false} visible={false} />
							<Column dataField='BadDebtsReliefDate' allowEditing={false} allowSorting={false} visible={false} />
							<ColumnChooser enabled={true} />

							<Summary recalculateWhileEditing={true}>
								<TotalItem column='LocalPaymentAmount' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

								<TotalItem column='OutstandingAmount' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

								<TotalItem column='OffsetAmount' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />
							</Summary>

							<Toolbar>
								<ToolbarItem location='before'>
									<div className='popup-form-toolbar-section'>
										<div style={{ fontWeight: "bold" }}>Offset Details</div>
									</div>
								</ToolbarItem>

								<ToolbarItem location='before'></ToolbarItem>

								<ToolbarItem name='columnChooserButton' />
							</Toolbar>
						</DataGrid>
					</div>
				</div>
			</PopupForm>

			<GainAndLossForm 
				ref={gainAndLossRef} 
				onSaving={handleSubmit} 
				apiPath={"/api/APPaymentOffset/CalculateGainAndLoss"}
				defaultValue={defaultValue}
			/>
		</div>
	);
}
