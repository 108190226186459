import React, { useEffect, useState, useRef } from 'react';
import { Column, Lookup, } from 'devextreme-react/data-grid';
import ARSalesForm from './form';
import SalesCashSalesForm from '../../Sales-Module/cash-sales/form';
import SalesInvoiceMainForm from '../../Sales-Module/invoice-main/form';
import WorkshopInvoiceForm from '../../Workshop-Module/Invoice/form';
import Container from '../../../components/container';
import Listing from '../../../components/listing/newListing';
import utils from '../../../utils/common';
import EInvoiceMain from "../../../components/e-invoice/EInvoiceMain";

export default function ARSalesListing() {

    const apiURL = `/api/ARSales`;
    const nonIntegerRegex = /[a-zA-Z-]+/g;

    const [currentEditID, setCurrentEditID] = useState(null);
    const [currentEditID2, setCurrentEditID2] = useState(null);
    const [currentEditID3, setCurrentEditID3] = useState(null);
    const [currentEditID4, setCurrentEditID4] = useState(null);
    const [invType, setInvType] = useState(null);
    const [currentDetailID, setCurrentDetailID] = useState(null);

    function handleEdit(e) {
        const rowData = e.row.data;
        let valueToCheck = rowData.TransactionTypeCode.trim().match(nonIntegerRegex);
        
        if (rowData.hasOwnProperty("OriginalDocumentID") && rowData.OriginalDocumentID) {
            valueToCheck = rowData.OriginalTransactionTypeCode.trim().match(nonIntegerRegex);
        } else {
            valueToCheck = rowData.TransactionTypeCode.trim().match(nonIntegerRegex);
        }

        valueToCheck = valueToCheck ? valueToCheck.join("") : "";

        utils.transactionCodeList.find((item) => {
            if (item.TransType === valueToCheck) {
                if (item.DocumentType === "CashSales") {
                    setCurrentEditID2(rowData.OriginalDocumentID);
                } else if (item.DocumentType === "Sales Invoice") {
                    setCurrentEditID3(rowData.OriginalDocumentID);
                } else if (item.DocumentType === "WKInvoice") {
                    setCurrentEditID4(rowData.OriginalDocumentID);
                } else {
                    setCurrentEditID(rowData.id);
                }
            }
        })
    }

    const closePopup = (id) => {
        setCurrentEditID(id);
        setCurrentEditID2(id);
        setCurrentEditID3(id);
        setCurrentEditID4(id);
        setCurrentDetailID(id);
    }


    function handleEInvoice(e) {
        setInvType('01-SA');
        setCurrentDetailID(e.row.data.DocumentNo);
    }

    return (
    <Container>
        <div id="normal-listing">
            <Listing
                className="ar-listing-datagrid"
                apiURL={apiURL}
                listingURL="/api/ARSales/list"
                sortColumn={"DocumentDate"}
                sortOrder={"desc"}
                listingTitle={"AR Invoice"}
                storageName={"ARSalesListingColumnWidth"}
                onAddClick={setCurrentEditID}
                handleEdit={handleEdit}
                einvoiceshow={true}
                handleEInvoice={handleEInvoice}
            >
                <Column dataField="DocumentDate" format="dd/MM/yyyy" dataType={"date"} />
                <Column dataField="DocumentNo" />
                <Column dataField="CustomerCode" />
                <Column dataField="CustomerName" />
                <Column dataField="ForexCode" caption="Forex" />
                <Column dataField="MainForexAmount" caption={"Amount"} format={{ type: "fixedPoint", precision: 2 }} />
                <Column dataField="MainLocalAmount" caption={"Local Amount"} format={{ type: "fixedPoint", precision: 2 }} />
                <Column dataField="OutstandingAmount" caption={"Outstanding Amount"} format={{ type: "fixedPoint", precision: 2 }} />
            </Listing>
        </div>

        {currentDetailID !== null && (
            <EInvoiceMain
                formID={currentDetailID}
                closePopup={closePopup}
                apiURL={apiURL}
                title={"E-Invoice Main"}
                invoiceType={invType}
            />
        )}

        {currentEditID !== null && (
            <ARSalesForm
                formID={currentEditID}
                closePopup={closePopup}
                title={"A/R Invoice"}
                apiURL={apiURL}
            />
        )}

        {currentEditID2 !== null && (
            <SalesCashSalesForm
                formID={currentEditID2}
                closePopup={closePopup}
                title={"Cash Sales Entry"}
                apiURL={`/api/SalesCashSales`}
            />
        )}

        {currentEditID3 !== null && (
            <SalesInvoiceMainForm
                formID={currentEditID3}
                closePopup={closePopup}
                title={"Invoice Entry"}
                apiURL={`/api/SalesInvoiceMain`}
            />
        )}

        {currentEditID4 !== null && (
            <WorkshopInvoiceForm
                formID={currentEditID4}
                closePopup={closePopup}
                title={"Workshop Invoice Entry"}
                apiURL={`/api/SalesInvoiceMain`}
            />
        )}
    </Container>
);

}