export const DocumentSettings = {
    DocumentTypeWidth: {
        Name: 170, 
        Format: 150, 
        RunningNumber: 125, 
    },
    APIEndpoints: {
        SelectDocNo: (documentTypeID, documentDate) => {
            const formattedDate = documentDate ? encodeURIComponent(documentDate) : ''; // Ensure the date is URI encoded
            return `api/Utilities/SelectDocNo?DocumentTypeID=${documentTypeID}&documentDate=${formattedDate}`;
        },
    },
    NextDocNoApiEndpoints: {
        SelectDocNo: (documentNumberPrefix, documentDate) => {
            const formattedDate = documentDate ? encodeURIComponent(documentDate) : ''; // Ensure the date is URI encoded
            return `api/Utilities/NextDocNo?documentNumberPrefix=${documentNumberPrefix}&documentDate=${formattedDate}`;
        },
    },
    documentPrefix: "", 
    setDocumentPrefix(prefix) {
        this.documentPrefix = prefix;
    },
    getDocumentPrefix() {
        return this.documentPrefix;
    },
};
