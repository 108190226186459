import React, { useState, useRef, useEffect } from "react";
import DataGrid, { Pager, Paging, SearchPanel, Toolbar, Column, Selection } from "devextreme-react/data-grid";
import Box, { Item } from 'devextreme-react/box';
import { Button } from "devextreme-react/button";
import { SelectBox, DateBox, TextBox, DropDownBox, Popover } from "devextreme-react";
import { ScrollView } from 'devextreme-react/scroll-view';
import { Popup } from "devextreme-react/popup";
import utils from "../../../utils/common";
import baseapi from "../../../api/baseapi";
import CustomStore from "devextreme/data/custom_store";
import CustomizedLookup from "../../../components/lookup/Lookup";
import ErrorPopUpForm from "../../../components/popup-form/ErrorPopupForm";
import PathIndicator from "../../../components/path-indicator/PathIndicator";
import { menuOptions, userOptions, actionOptions } from "../../../utils/lookupstore";
import ARCreditNoteForm from "../../AR-Module/credit-notes/form";
import ARDebitNoteForm from "../../AR-Module/debit-notes/form";
import ARReceiptForm from "../../AR-Module/receipt/form";
import ARSalesForm from "../../AR-Module/sales/form";
import APCreditNoteForm from "../../AP-Module/credit-notes/form";
import APDebitNoteForm from "../../AP-Module/debit-notes/form";
import APPaymentForm from "../../AP-Module/payment/form";
import APPurchaseForm from "../../AP-Module/purchase/form";
import JournalEntryForm from "../../GL-Module/journal-entry/form";
import CashBookEntryForm from "../../GL-Module/cash-book-entry/form";
import ContraForm from "../../contra/form";
import ARRefundForm from "../../AR-Module/refund/form";
import APRefundForm from "../../AP-Module/refund/form";
import SalesInvoiceMainForm from "../../Sales-Module/invoice-main/form";
import SalesCashSalesForm from "../../Sales-Module/cash-sales/form";
import PurchaseInvoiceForm from "../../Purchase-Module/invoice/form";
import CashPurchaseForm from "../../Purchase-Module/cash-purchase/form";
import PurchaseQuotationForm from "../../Purchase-Module/quotation/form";
import SalesQuotationForm from "../../Sales-Module/quotation/form";
import SalesOrderForm from "../../Sales-Module/order/form";
import SalesDeliveryOrderForm from "../../Sales-Module/delivery-order/form";
import SalesDebitNoteForm from "../../Sales-Module/debit-note/form";
import SalesCreditNoteForm from "../../Sales-Module/credit-note/form";
import SalesOrderAdjustmentForm from "../../Sales-Module/order-adjustment/form";
import PurchaseRequestForm from "../../Purchase-Module/request/form";
import PurchaseOrderForm from "../../Purchase-Module/order/form";
import PurchaseOrderAdjustmentForm from "../../Purchase-Module/order-adjustment/form";
import PurchaseDebitNoteForm from "../../Purchase-Module/debit-note/form";
import PurchaseReceivedForm from "../../Purchase-Module/received/form";
import PurchaseReturnForm from "../../Purchase-Module/purchase-return/form";
import StockAdjustmentFrom from "../../Stock-Module/adjustment/form";
import StockReceivedFrom from "../../Stock-Module/received/form";
import StockIssueFrom from "../../Stock-Module/issue/form";
import StockTransferFrom from "../../Stock-Module/transfer/form";
import StockWriteOffForm from "../../Stock-Module/write-off/form";
import StockTakeFrom from "../../Stock-Module/take/form";
import WorkshopInvoiceForm from "../../Workshop-Module/Invoice/form";
import JobSheetForm from "../../Workshop-Module/job-sheet/form";

export default function AuditTrailListing() {
	const keyDetail = "CreatedDate";

	const datagridRef = useRef(null);
	const tempForm = useRef(null);
	const [dropDownList, setDropDownList] = useState({
		reportList: []
	});
	const currentToken = localStorage.getItem("Authorization").substring(7);
	const userID = JSON.parse(localStorage.getItem("data"));
	const [mainDataSource, setMainDataSource] = useState([]);
	const [detailDataSource, setDetailDataSource] = useState({});
	const [openReportPop, setOpenReportPop] = useState(false);
	const [reportName, setReportName] = useState("");
	const [printRepDisabled, setPrintRepDisabled] = useState(true);
	const popupMessageRef = useRef(null);

    const [fromDate, setFromDate] = useState(
		new Date(new Date().setDate(1)).toLocaleDateString("en-US", {
			year: "numeric",
			month: "2-digit",
			day: "2-digit"
		})
	);
	const [toDate, setToDate] = useState(
		new Date(new Date()).toLocaleDateString("en-US", {
			year: "numeric",
			month: "2-digit",
			day: "2-digit"
		})
	);
    const [paramValue, setParamValue] = useState([
		{
			ParameterName: "FromDate",
			ParameterValue: utils.dateboxFormat(fromDate)
		},
		{
			ParameterName: "ToDate",
			ParameterValue: utils.dateboxFormat(toDate)
		},
		// {
		// 	ParameterName: "ReportName",
		// 	ParameterValue: "GL - Ledger (Standard)"
		// },
		// {
		// 	ParameterName: "ReportID",
		// 	ParameterValue: "15cdsGV76JY%3d"
		// }
	]);    
	const fromTransTypeLookupRef = useRef(null);
	const toTransTypeLookupRef = useRef(null);
	const [fromTransTypeList, setFromTransTypeList] = useState([]);
	const [toTransTypeList, setToTransTypeList] = useState([]);
	const [selectedTransType, setSelectedTransType] = useState(0);
	const [openTransTypeRange, setOpenTransTypeRange] = useState();
    const fromUserIDLookupRef = useRef(null);
	const toUserIDLookupRef = useRef(null);
	const [fromUserIDList, setFromUserIDList] = useState([]);
	const [toUserIDList, setToUserIDList] = useState([]);
	const [selectedUserID, setSelectedUserID] = useState(0);
	const [openUserIDRange, setOpenUserIDRange] = useState();
	const [selectedAction, setSelectedAction] = useState(0);

	const gridRef = useRef(null);
	const searchBoxRef = useRef(null);
	const currentFocusIndex = useRef(null);
	const dropdownRef = useRef(null);
    const remoteOperations = {
		groupPaging: false,
		paging: true,
		filtering: true,
		sorting: true,
		grouping: false,
		summary: true
	};
    const [isPopupVisible, setPopupVisible] = useState(false);
	const [currentEditID, setCurrentEditID] = useState("");
	const [documentDate, setDocumentDate] = useState(null);
	const [documentNo, setDocumentNo] = useState("");
	const [form, setForm] = useState("");
	const query = useRef(null);
	const pageSize = useRef(25);
	const selectedPage = useRef(1);

	const refresh = () => {
		tempForm.current = [...paramValue];

		tempForm.current.forEach((param) => {
			if (param.ParameterName.includes("List") && Array.isArray(param.ParameterValue)) {
				param.ParameterValue = param.ParameterValue.join("$%");
			}

			if (typeof param.ParameterValue !== "string") {
				param.ParameterValue = String(param.ParameterValue);
			}
		});

		var copied = tempForm.current;
		setMainDataSource({
			store: new CustomStore({
				key: "ID",
				load: () => {
					return baseapi
                    .httppost(`/api/AuditTrail/GetParent?q=${query.current}&FromDate=${utils.dateboxFormat(fromDate)}&ToDate=${utils.dateboxFormat(toDate)}
                    &page=${selectedPage.current}&rows=${pageSize.current}`, copied)
						.then((response) => {
                            // console.log(response);
							return {
								data: response.data.items,
								totalCount: response.data.total
							};
						})
						.catch(() => {
							throw "Network error";
						});
				},
			})
		});
	};
    
    const filterTypes = [
		{
			ID: 0,
			Type: "No Filter"
		},
		{
			ID: 1,
			Type: "Filter By Range"
		},
		{
			ID: 2,
			Type: "Filter By Multi-Select"
		}
	];

    const docType = [
        // AR
		{
			TransType: "RI",
			DocumentType: "Sales"
		},
		{
			TransType: "RR",
			DocumentType: "Receipt"
		},
		{
			TransType: "RD",
			DocumentType: "ARDebitNote"
		},
		{
			TransType: "RC",
			DocumentType: "ARCreditNote"
		},
		{
			TransType: "RRF",
			DocumentType: "ARRefund"
		},
		{
			TransType: "RCT",
			DocumentType: "Contra"
		},
        // AP
		{
			TransType: "PP",
			DocumentType: "Purchase"
		},
		{
			TransType: "PV",
			DocumentType: "Payment"
		},
		{
			TransType: "PC",
			DocumentType: "APCreditNote"
		},
		{
			TransType: "PD",
			DocumentType: "APDebitNote"
		},
		{
			TransType: "PCT",
			DocumentType: "APContra"
		},
		{
			TransType: "PRF",
			DocumentType: "APRefund"
		},
        // SALES
		{
			TransType: "SCS",
			DocumentType: "CashSales"
		},
		{
			TransType: "INV",
			DocumentType: "SalesInvoice"
		},
		{
			TransType: "SC",
			DocumentType: "SalesCreditNote"
		},
		{
			TransType: "SOQ",
			DocumentType: "SalesQuotation"
		},
		{
			TransType: "SO",
			DocumentType: "SalesOrder"
		},
		{
			TransType: "DO",
			DocumentType: "SalesDeliveryOrder"
		},
		{
			TransType: "SD",
			DocumentType: "SalesDebitNote"
		},
		{
			TransType: "SPADJ",
			DocumentType: "SalesOrderAdjustment"
		},
        // PURCHASE
		{
			TransType: "PPI",
			DocumentType: "PurchaseInvoice"
		},
		{
			TransType: "PCP",
			DocumentType: "CashPurchase"
		},
		{
			TransType: "PQ",
			DocumentType: "PurchaseRequest"
		},
		{
			TransType: "POQ",
			DocumentType: "PurchaseQuotation"
		},
		{
			TransType: "PO",
			DocumentType: "PurchaseOrder"
		},
		{
			TransType: "PPR",
			DocumentType: "PurchaseReceived"
		},
		{
			TransType: "PPD",
			DocumentType: "PurchaseDebitNote"
		},
		{
			TransType: "PRE",
			DocumentType: "PurchaseReturn"
		},
		{
			TransType: "POADJ",
			DocumentType: "PurchaseOrderAdjustment"
		},
        // GL
		{
			TransType: "JV",
			DocumentType: "Journal"
		},
		{
			TransType: "GL-PV",
			DocumentType: "GLPayment"
		},
		{
			TransType: "GL-OR",
			DocumentType: "GLReceipt"
		},
        // STOCK
		{
			TransType: "SR",
			DocumentType: "StockReceive"
		},
		{
			TransType: "IS",
			DocumentType: "StockIssue"
		},
		{
			TransType: "AJ",
			DocumentType: "StockAdjustment"
		},
		{
			TransType: "ST",
			DocumentType: "StockTake"
		},
		{
			TransType: "WOFF",
			DocumentType: "StockWriteOff"
		},
		{
			TransType: "XF",
			DocumentType: "StockTransfer"
		},
        // WORKSHOP
		{
			TransType: "WI",
			DocumentType: "WorkshopInvoice"
		},
		{
			TransType: "JS",
			DocumentType: "WorkshopJobsheet"
		},
	];

	useEffect(() => {
		Promise.allSettled([menuOptions.store.load(), userOptions.store.load(), actionOptions.store.load()]).then(
			(lists) => {
				setDropDownList({
					menuList: lists[0].value.data,
					userList: lists[1].value.data,
                    actionList: lists[2].value.data,
				});
				// console.log(lists[0].value.data)
			}
		);
	}, []);

    function valueOnChange(e, type = null) {
		try {
			if (e.value !== undefined) {
				const dataField = e.element.getAttribute("dataField");
				setPrintRepDisabled(true);
				if (dataField === "FromDate") {
					setFromDate(utils.dateboxFormat(e.value));
				} else if (dataField === "ToDate") {
					setToDate(utils.dateboxFormat(e.value));
				} else if (dataField === "FilterNumber") {
					if (e.value === 1) {
						switch (type) {
							case "TransType":
								setSelectedTransType(e.value);
								setOpenTransTypeRange(true);
								break;
							case "UserID":
								setSelectedUserID(e.value);
								setOpenUserIDRange(true);
								break;
							default:
								// setSelectedID(0);
								break;
						}
					}
					if (e.value === 2) {
						switch (type) {
							case "TransType":
								setSelectedTransType(e.value);
								break;
							case "UserID":
								setSelectedUserID(e.value);
								break;
							case "Action":
								setSelectedAction(e.value);
								break;
							default:
								// setSelectedID(0);
								break;
						}
					}
				}

				if (dataField !== "FilterNumber" && dataField !== "FromDate" && dataField !== "ToDate") updateParamValue(dataField, e.value);
                clearData();
			}
		} catch (err) {
			console.log(err);
		}
	}

	// const handlePrintBtn = (e) => {
	// 	if (reportName) {
	// 		baseapi.httpget(`${utils.ReportURL}/api/Report/getReportDetailsByReportName?reportID=${reportName}&accessToken=${currentToken}`).then((response) => {
	// 			const { data } = response;
	// 			data.map((items) => {
	// 				confirmReportParameters(items.ID, items.ViewerAccessURL);
	// 			});
	// 		});
	// 	}
	// };   

	const onInitialized = () => {
		// var parameters = `?userID=${userID?.userID}&reportListingType=${reportListing}&reportName=${returnSelectedValue("ReportName")}`;
		tempForm.current = [...paramValue];

		tempForm.current.forEach((param) => {
			if (param.ParameterName.includes("List") && Array.isArray(param.ParameterValue)) {
				param.ParameterValue = param.ParameterValue.join("$%");
			}

			if (typeof param.ParameterValue !== "string") {
				param.ParameterValue = String(param.ParameterValue);
			}
		});

		var copied = tempForm.current;
		// console.log(copied);

		// baseapi
		// 	.httppost(`/api/AuditTrail/GetParent?FromDate=${utils.dateboxFormat(fromDate)}&ToDate=${utils.dateboxFormat(toDate)}`, copied)
		// 	.then((response) => {
		// 		const { data } = response;
		// 		// console.log(data);
		// 		if (data !== null && data !== undefined) {
		// 			var mainDataArray = [];
        //             var detailsDataArray = [];
		// 			// data.map((e) => {
		// 			// 	console.log(e.DocumentID !== "");
		// 			// });

        //             setMainDataSource(data.items);

		// 			// for (var i = 0; i < data.length; i++) {
        //             //     // console.log(data[i]);
		// 			// 	// if (data[i].DocumentID !== "")
		// 			// 	mainDataArray.push(data[i].Parent);

        //             //     if(data[i].Children.length > 0) {
        //             //         for (var j = 0; j < data[i].Children.length; j++) {
        //             //             // console.log(data[i].Details[j]);
        //             //             //if (data[i].Details[j].DocumentID !== null)
        //             //             detailsDataArray = detailsDataArray.concat(data[i].Children[j]);
        //             //         }
        //             //     }
		// 			// }
		// 			// setMainDataSource(mainDataArray);
		// 			// setDetailDataSource(detailsDataArray);

		// 			// if (data.length > 0) {
		// 			// 	setPrintRepDisabled(false);
		// 			// }
		// 		}

		// 		if (data.status) {
		// 			utils.displayPopupMessage(popupMessageRef, {
		// 				visible: true,
		// 				message: data.message,
		// 				type: "Danger"
		// 			});
		// 		}
		// 	})
		// 	.catch((e) => {
		// 		console.log(e);
		// 	});

        setMainDataSource({
            store: new CustomStore({
                key: "ID",
                load: () => {
                    return baseapi
                        .httppost(`/api/AuditTrail/GetParent?q=${query.current}&FromDate=${utils.dateboxFormat(fromDate)}&ToDate=${utils.dateboxFormat(toDate)}
                        &page=${selectedPage.current}&rows=${pageSize.current}`, copied)
                        .then((response) => {
                            // console.log(response);
                            return {
                                data: response.data.items,
                                totalCount: response.data.total
                            };
                        })
                        .catch(() => {
                            throw "Network error";
                        });
                },
            })
        });

		tempForm.current.forEach((param) => {
			if (param.ParameterName === "IsZero") {
				param.ParameterValue = param.ParameterValue === "true";
			}
		});
	};

	// const getTasks = (key) =>
	// 	new DataSource({
	// 		store: new ArrayStore({
	// 			data: detailDataSource,
	// 			key: keyDetail
	// 		}),
	// 		filter: [keyDetail, "=", key]
	// 	});

	// function dataTemplate(props) {
	// 	// console.log(props.data);
	// 	const dataSource = getTasks(props.data.key);

    //     return (
    //         <DataGrid dataSource={dataSource} showBorders={true} allowColumnResizing={true} allowColumnReordering={false} columnAutoWidth={true} paging={false} >
    //             <Column key={"TableName"} dataField={"TableName"} />
    //             <Column key={"FieldName"} dataField={"FieldName"} />
    //             <Column key={"OldValue"} dataField={"OldValue"} />
    //             <Column key={"NewValue"} dataField={"NewValue"} />
    //         </DataGrid>
    //     );
	// }

    // useEffect(() => {
	// 	const toDateObj = new Date(toDate);
	// 	const fromDateObj = new Date(fromDate);

	// 	if (fromDateObj > toDateObj) {
	// 		utils.displayPopupMessage(popupMessageRef, {
	// 			visible: true,
	// 			message: "To Date cannot be earlier than From Date.",
	// 			type: "Warning"
	// 		});

	// 		const setDate = new Date(); // current date

	// 		if (fromDateObj > setDate) {
	// 			setToDate(utils.dateboxFormat(fromDate));
	// 		} else {
	// 			setToDate(utils.dateboxFormat(setDate));
	// 		}
	// 	}
	// }, [toDate, fromDate]);

    function clearData() {
        // setDetailDataSource([]);
        setMainDataSource([]);
    }

    const updateParamValue = (key, value) => {
		setParamValue((prevParamValue) => {
			const updatedParamValue = [...prevParamValue]; // Create a copy of the previous state array

			// Find the index of the item with the matching key
			const index = updatedParamValue.findIndex((item) => item.ParameterName === key);

			if (index !== -1) {
				// Update the existing item if it exists
				updatedParamValue[index] = { ParameterName: key, ParameterValue: value };
			} else {
				// Add a new item if the key doesn't exist
				updatedParamValue.push({ ParameterName: key, ParameterValue: value });
			}

			return updatedParamValue;
		});

        clearData();
	};

	const removeParamValue = (key) => {
		setParamValue((prevParamValue) => {
			// Filter out the item with the matching ParameterName
			var updatedParamValue = null;
			updatedParamValue = prevParamValue.filter((item) => item.ParameterName !== key);
			return updatedParamValue;
		});
	};

	const returnSelectedKeys = (type) => {
		const paramObject = paramValue.reduce((acc, curr) => {
			acc[curr.ParameterName] = curr.ParameterValue;
			return acc;
		}, {});

		let selectedKeys = [];

		if (type === "TransType" && paramObject["TransTypeList"]) {
			selectedKeys = ensureArray(paramObject["TransTypeList"]);
		} else if (type === "UserID" && paramObject["UserIDList"]) {
			selectedKeys = ensureArray(paramObject["UserIDList"]);
		} else if (type === "Action" && paramObject["ActionList"]) {
			selectedKeys = ensureArray(paramObject["ActionList"]);
		}

		return selectedKeys;
	};

	const ensureArray = (value) => {
		if (Array.isArray(value)) {
			return value;
		} else {
			return value.split("$%");
		}
	};

	const returnSelectedValue = (type) => {
		const filtered = paramValue.filter((x) => x.ParameterName === type);
		return filtered.length > 0 ? filtered[0].ParameterValue : null;
	};

	const returnDropDownList = (type) => {
		if (type === "TransType") {
			return dropDownList.menuList;
		} else if (type === "UserID") {
			return dropDownList.userList;
		} else if (type === "Action") {
			return dropDownList.actionList;
		} 
	};

    function clearFilters () {
        removeParamValue("TransTypeList");
        removeParamValue("FromTransType");
        removeParamValue("ToTransType");
        setSelectedTransType(0);
        setSelectedUserID(0);
        removeParamValue("UserIDList");
        removeParamValue("FromUserID");
        removeParamValue("ToUserID");
        setSelectedAction(0);
        removeParamValue("ActionList");
        clearData();
    }

	function DataGridMultiRender(type) {
		let selectedKeys = returnSelectedKeys(type);
		// console.log(type);

		return (
			<div className='customized-lookup-container'>
				<div className='customized-lookup-search-container'>
					<div>
						<TextBox
							ref={searchBoxRef}
							placeholder='Search...'
							width={250}
							valueChangeEvent='keyup'
							showClearButton={true}
							onValueChanged={(e) => {
								gridRef.current.instance.searchByText(e.value);
								// updateDropDownList(e.value, type, pagingIndex.current["page"], false);
							}}
							onKeyDown={(e) => {
								// console.log("key down", e.event)
								if (e.event.key === "ArrowDown") {
									gridRef.current.instance.focus();
								}
							}}
						/>
					</div>

					<div className='customized-lookup-btn-section'>
						<Button
							text='Clear'
							onClick={(e) => {
								// if (type === "Project") {
								// 	removeParamValue("ProjectList");
								// } else if (type === "Salesman") {
								// 	removeParamValue("SalesList");
								// } else if (type === "Area") {
								// 	removeParamValue("AreaList");
								// } else if (type === "ChartAccount") {
								// 	removeParamValue("ChartList");
								// } else if (type === "Supplier") {
								// 	removeParamValue("SuppList");
								// } else if (type === "Customer") {
								// 	removeParamValue("CustList");
								// }

								if (searchBoxRef.current !== null) searchBoxRef.current.instance.reset();
								// refreshLookupData(type);
							}}
						/>
					</div>

					<div>
						<Button
							style={{ marginLeft: "5px" }}
							text='Clear Filter'
							stylingMode='text'
							type='default'
							onClick={() => {
								if (type === "TransType") {
									removeParamValue("TransTypeList");
									removeParamValue("FromTransType");
									removeParamValue("ToTransType");
									setSelectedTransType(0);
								} else if (type === "UserID") {
									setSelectedUserID(0);
									removeParamValue("UserIDList");
									removeParamValue("FromUserID");
									removeParamValue("ToUserID");
								} else if (type === "Action") {
									setSelectedAction(0);
									removeParamValue("ActionList");
								} 
							}}
							elementAttr={{ dataField: "FilterNumber" }}
						/>
					</div>
				</div>
				<DataGrid
					ref={gridRef}
					height={200}
					className={"lookup-datagrid gl-listing-datagrid"}
					showBorders={true}
					dataSource={returnDropDownList(type)}
					scrolling={{ columnRenderingMode: "standard", showScrollbar: "onHover" }}
					columnChooser={{ enabled: false }}
					allowColumnResizing={true}
					allowColumnReordering={true}
					hoverStateEnabled={true}
					paging={{ enabled: false }}
					keyExpr={type !== "TransType" ? 'code' : "name"}
					focusedRowEnabled={true}
					focusedRowKey={selectedKeys}
					selectedRowKeys={selectedKeys}
					onSelectionChanged={(e) => dataGridOnSelectionChanged(e, type)}
					// onContentReady={(element) => {
					// 	const scrollable = element.component.getScrollable();
					// 	if (scrollable !== undefined) {
					// 		scrollable.on("scroll", function (e) {
					// 			if (e.reachedBottom) {
					// 				if (pagingIndex.current["loading"] === false) {
					// 					// console.log("page", pagingIndex.current["page"]);
					// 					pagingIndex.current["loading"] = true;
					// 					// updateDropDownList("", type, pagingIndex.current["page"], true);
					// 					// console.log(e);
					// 				}
					// 			}
					// 		});
					// 	}
					// }}
					onKeyDown={(e) => {
						// if(e.event.key === "Enter"){
						//     setDropdownOpened(false);
						// }
						if (e.event.key === "ArrowUp") {
							// If focus is one the first row then brings focus back to the search box
							if (currentFocusIndex.current === 0) {
								if (type === "TransType") {
									removeParamValue("TransTypeList");
								} else if (type === "UserID") {
									removeParamValue("UserIDList");
								} else if (type === "Action") {
									removeParamValue("ActionList");
								}
							}
						}
					}}
					onFocusedRowChanging={(e) => {
						if (e.event === null) {
							e.cancel = true;
						} else if (e.event.key === undefined) {
							e.cancel = true;
						}
					}}
					// onFocusedRowChanged={(e) => {
					//   const data = e.row.data;
					//   currentFocusIndex.current = e.rowIndex;
					//   if(e.event.key === "Enter") {
					//     setSelectedCustKey(data.id);
					//   }
					// }}
				>
					<Column
						dataField='code'
						elementAttr={{
							dataField: type === "TransType" ? "TransTypeCode" : type === "UserID" ? "UserIDCode" : "ActionCode"
						}}
						caption={type === "TransType" ? "Trans Type" : type === "UserID" ? "User Code" : "Action"}
						visible={true}
						alignment='left'
						allowGrouping={false}
					/>

                    {type !== "TransType" && (
                        <Column
                            dataField='name'
                            elementAttr={{ dataField: "UserIDName" }}
                            caption={ "User Name" }
                            visible={type !== "Action"}
                            alignment='left'
                            allowGrouping={false}
                        />
                    )}

					<Selection mode='multiple' showSelectAll={true} selectAllMode='page' showCheckBoxesMode={"always"} />
					<Toolbar visible={false} />
				</DataGrid>
			</div>
		);
	}

	const dataGridOnSelectionChanged = (e, type) => {
		if (type === "TransType") {
            // console.log(e)
			updateParamValue("TransTypeList", e.selectedRowKeys);
		} else if (type === "UserID") {
			updateParamValue("UserIDList", e.selectedRowKeys);
		} else if (type === "Action") {
			updateParamValue("ActionList", e.selectedRowKeys);
		} 
	};

	const popFunction = (e, type) => {
		if (e.cancel === false) {
			if (type === "TransType") {
				setOpenTransTypeRange(false);
			} else if (type === "UserID") {
				setOpenUserIDRange(false);
			} 
		} else {
			if (type === "TransType") {
				setOpenTransTypeRange(true);
			} else if (type === "UserID") {
				setOpenUserIDRange(true);
			} 
		}
	};

	// const arrayToString = (arr) => {
	// 	if (Array.isArray(arr)) {
	// 		return arr.join(" ");
	// 	} else {
	// 		return arr;
	// 	}
	// };

    const displayDetails = async (open = false, id) => {
		try {
			const response = await baseapi.httppost(`/api/AuditTrail/GetDetails?id=${id}`);
			const { data } = response;
	
			if (!utils.isNullOrEmpty(data) && data.status === undefined) {
				const newValueObj = JSON.parse(data.newValue);
				const oldValueObj = JSON.parse(data.oldValue);
				// const newDetailValueObj = JSON.parse(data.detailsNewValues);
				// const oldDetailValueObj = JSON.parse(data.detailsPrevValues);
				var detailsOldArr = [];
				var detailsNewArr = [];
				// console.log(data);
				var mergedDetailFields = [];
	
				const mergedFields = Object.keys(newValueObj || oldValueObj).map((key) => ({
					fieldName: key,
					oldValue: utils.isNullOrEmpty(oldValueObj) ? '-' : oldValueObj[key],
					newValue: utils.isNullOrEmpty(newValueObj) ? '-' : newValueObj[key],
				}));

				if(data.detailsNewValues.length > 0) {
					for(var i = 0; i < data.detailsNewValues.length; i++) {
						detailsNewArr.push(JSON.parse(data.detailsNewValues[i]));

						if(!utils.isNullOrEmpty(data.detailsPrevValues[i])) {
							detailsOldArr.push(JSON.parse(data.detailsPrevValues[i]));
						}
					}
				}

				if(detailsNewArr.length > 0) {
					for(var i = 0; i < detailsNewArr.length; i++) {
						mergedDetailFields.push(Object.keys(detailsNewArr[i] || detailsOldArr[i]).map((key) => ({
							fieldName: key,
							oldValue: utils.isNullOrEmpty(detailsOldArr[i]) ? '-' : detailsOldArr[i][key],
							newValue: utils.isNullOrEmpty(detailsNewArr[i]) ? '-' : detailsNewArr[i][key],
						})));
					}
				}
	
				setDetailDataSource({
					module: data.module,
					documentNo: data.documentNo,
					action: data.action,
					transactionTypeCode: data.transactionTypeCode,
					mergedFields,
					mergedDetailFields,
				});
	
				// Set visibility of popup
				setPopupVisible(open);
			} else {
				setDetailDataSource({}); // Empty state if no data
			}
		} catch (error) {
			console.error("Failed to fetch details:", error);
		}
	};	

	const renderItems = () => {
		if (!detailDataSource) return <div>No Data Available</div>; // Handle empty state
	
		if (!utils.isEmptyObject(detailDataSource)) {	
			const { mergedDetailFields, mergedFields, module, documentNo, action, transactionTypeCode } = detailDataSource;
			// console.log(detailDataSource);
			// console.log(mergedFields.length);
			// console.log(mergedDetailFields.reduce((a, obj) => a + Object.keys(obj).length, 0));

			var height = mergedFields.length + mergedDetailFields.reduce((a, obj) => a + Object.keys(obj).length, 0);
			var fixHeight = 30;

			if(height < 8) {
				height = "auto";
			} else if (height > 27) {
				height = "80vh";
			} else if (height >= 8 && height <= 27) {
				height = height + (mergedDetailFields.reduce((a, obj) => a + Object.keys(obj).length, 0) * 2.23) + fixHeight;
				height = height + "vh";
			}

			return (
        <div>
          <ScrollView
            id="audit-trial-popup"
            scrollByContent={true}
            scrollByThumb={true}
            height={height}
            direction="vertical"
          >
            {/* Main Datagrid Container */}
            <div className="audit-trail-datagrid">
              <Box direction="col" width="100%" height="auto" padding={2}>
                {/* Main Document No */}
                <Item ratio={1} baseSize={30}>
                  <h5>
                    {action === "Insert"
                      ? "New"
                      : action === "Delete"
                      ? "Deleted"
                      : "Edit"}{" "}
                    {module} Entry: <b>{documentNo || ""}</b> (Data Changes)
                  </h5>
                </Item>

                {/* Main Datagrid Header */}
                <Item ratio={1} baseSize={25}>
                  <Box direction="col" width="100%" height="100%" padding={1}>
                    <Item ratio={1} baseSize={20}>
                      <Box
                        direction="row"
                        width="100%"
                        height="100%"
                        padding={1}
                        className="ViewDetailBox"
                      >
                        <Item ratio={1}>
                          <div>
                            <strong>Field Name</strong>
                          </div>
                        </Item>
                        <Item ratio={1}>
                          <div>
                            <strong>Old Value</strong>
                          </div>
                        </Item>
                        <Item ratio={1}>
                          <div>
                            <strong>New Value</strong>
                          </div>
                        </Item>
                      </Box>
                    </Item>
                  </Box>
                </Item>

                {/* Main Datagrid Content */}
                {mergedFields.map(
                  ({ fieldName, oldValue, newValue }, index) => (
                    <Item ratio={1} baseSize={25} key={index}>
                      <Box
                        direction="col"
                        width="100%"
                        height="100%"
                        padding={1}
                      >
                        <Item ratio={1} baseSize={25}>
                          <Box
                            direction="row"
                            width="100%"
                            height="100%"
                            padding={1}
                            className="ViewDetailBox"
                          >
                            <Item ratio={1} baseSize={10}>
                              <div>{fieldName}</div>
                            </Item>
                            <Item ratio={1} baseSize={10}>
                              <div>{oldValue}</div>
                            </Item>
                            <Item ratio={1} baseSize={10}>
                              <div>{newValue}</div>
                            </Item>
                          </Box>
                        </Item>
                      </Box>
                    </Item>
                  )
                )}
              </Box>
            </div>

            {/* Nested Datagrid */}
            {mergedDetailFields.length > 0 && (
              <div className="nested-datagrid-container">
                {mergedDetailFields.map((innerFields, outerIndex) => (
                  <Box
                    className="audit-trail-datagrid"
                    key={outerIndex}
                    direction="col"
                    width="100%"
                    height="auto"
                    style={{ marginTop: "2%" }}
                    padding={2}
                  >
                    {/* Title for Each Section */}
                    <Item ratio={1} baseSize={30}>
                      <h5>
                        {transactionTypeCode + "DTL - "}
                        {action === "Insert" ? "Added" : "Modified"}
                      </h5>
                    </Item>

                    {/* Nested Datagrid Header */}
                    <Item ratio={1} baseSize={25}>
                      <Box
                        direction="col"
                        width="100%"
                        height="100%"
                        padding={1}
                      >
                        <Item ratio={1} baseSize={20}>
                          <Box
                            direction="row"
                            width="100%"
                            height="100%"
                            padding={1}
                            className="ViewDetailBox"
                          >
                            <Item ratio={1}>
                              <div>
                                <strong>Field Name</strong>
                              </div>
                            </Item>
                            <Item ratio={1}>
                              <div>
                                <strong>Old Value</strong>
                              </div>
                            </Item>
                            <Item ratio={1}>
                              <div>
                                <strong>New Value</strong>
                              </div>
                            </Item>
                          </Box>
                        </Item>
                      </Box>
                    </Item>

                    {/* Mapped Fields */}
                    {innerFields.map(
                      ({ fieldName, oldValue, newValue }, index) => (
                        <Item ratio={1} baseSize={25} key={index}>
                          <Box
                            direction="col"
                            width="100%"
                            height="100%"
                            padding={1}
                          >
                            <Item ratio={1} baseSize={25}>
                              <Box
                                direction="row"
                                width="100%"
                                height="100%"
                                padding={1}
                                className="ViewDetailBox"
                              >
                                <Item ratio={1} baseSize={10}>
                                  <div>{fieldName}</div>
                                </Item>
                                <Item ratio={1} baseSize={10}>
                                  <div>{oldValue}</div>
                                </Item>
                                <Item ratio={1} baseSize={10}>
                                  <div>{newValue}</div>
                                </Item>
                              </Box>
                            </Item>
                          </Box>
                        </Item>
                      )
                    )}
                  </Box>
                ))}
              </div>
            )}
          </ScrollView>
        </div>
      );
		}
	};

	const cellRender = (col) => {
		// console.log("col", col.data);
		return (
            <Button
                className='DetailAction'
                width={"auto"}
                text='View'
                type='normal'
                icon='detailslayout'
                stylingMode='text'
                onClick={(e) => {
					displayDetails(true, col.data.ID);
                }}
            />
		);
	};

    function getDocTransCode(documentNo) {
		return baseapi.httpget("/api/Utilities/CheckDocumentNoExist", { documentNo: documentNo || "" }).then((response) => {
			const { data } = response;
			if (data.length > 0) {
				// console.log(data[0]);
				return data[0];
			} else {
				return null;
			}
		});
	}
      
	return (
		<div>
            <div>
                {/* <Popup visible={openReportPop} width={"auto"} height={"auto"} dragEnabled={false} title='Print Report' showCloseButton={false}>
                    <div className='popup-group-form-item'>
                        <div className='popup-group-form-label'>Report: </div>
                        <SelectBox
                            value={returnSelectedValue("ReportID")}
                            elementAttr={{ dataField: "ReportID" }}
                            onValueChanged={(e) => {
                                // console.log(e.value);
                                updateParamValue("ReportID", e.value);
                            }}
                            alignment='left'
                            width={"450px"}
                            dataSource={dropDownList.reportList}
                            displayExpr={"code"}
                            valueExpr={"id"}
                            searchEnabled={true}
                            showClearButton={true}
                        />
                    </div>
                    <div className='popup-group-form-item' style={{ marginTop: "15px", justifyContent: "flex-end" }}>
                        <Button text='Cancel' type='normal' width={"auto"} style={{ marginLeft: "20px" }} onClick={(e) => setOpenReportPop(false)} />
                        <Button text='Print Report' type='success' width={"auto"} style={{ marginLeft: "20px" }} onClick={(e) => handlePrintBtn(e)} />
                    </div>
                </Popup> */}

                <div className={"content-block"}>
                    <div>
                        <div className='listing-page-title-container'>
                            <span className='datagrid-customized-title'>
                                <PathIndicator />
                            </span>
                        </div>
                        <div style={{ marginTop: "10px" }}>
                            <div style={{ backgroundColor: "#EFEFEF", borderRadius: "15px", width: "85%", padding: "0.7%" }}>
                                <div className='popup-form-item-container2'>
                                    <div className='popup-group-form-item'>
                                        <div className='popup-group-form-label'>Date</div>
                                        <DateBox
                                            type='date'
                                            value={fromDate}
                                            elementAttr={{ dataField: "FromDate" }}
                                            useMaskBehavior={true}
                                            label='From'
                                            labelMode='floating'
                                            displayFormat='dd/MM/yyyy'
                                            dateSerializationFormat='yyyy-MM-dd'
                                            onValueChanged={(e) => {
                                                valueOnChange(e, "FromDate");
                                            }}
                                        />

                                        <DateBox
                                            type='date'
                                            value={toDate}
                                            elementAttr={{ dataField: "ToDate" }}
                                            useMaskBehavior={true}
                                            label='To'
                                            labelMode='floating'
                                            displayFormat='dd/MM/yyyy'
                                            dateSerializationFormat='yyyy-MM-dd'
                                            onValueChanged={(e) => {
                                                valueOnChange(e, "ToDate");
                                            }}
                                            style={{ marginLeft: "5%" }}
                                        />
                                    </div>

                                    <div className='popup-group-form-item'>
                                        <div className='popup-group-form-label'> Transaction Type </div>
                                        {selectedTransType === 0 && (
                                            <SelectBox
                                                width={"250px"}
                                                dataSource={filterTypes}
                                                value={selectedTransType}
                                                displayExpr={"Type"}
                                                valueExpr={"ID"}
                                                elementAttr={{ dataField: "FilterNumber" }}
                                                onValueChanged={(e) => {
                                                    valueOnChange(e, "TransType");
                                                }}
                                            />
                                        )}
                                        {selectedTransType === 1 && (
                                            <div onClick={() => setOpenTransTypeRange(true)}>
                                                <TextBox
                                                    value={returnSelectedValue("FromTransType") || returnSelectedValue("ToTransType") ? `${returnSelectedValue("FromTransType")} to ${returnSelectedValue("ToTransType")}` : "No Range Selected Yet"}
                                                    // useMaskBehavior={true} // Enable mask behavior
                                                    // mask="cccccc" // Define the input mask
                                                    id='TransType'
                                                    style={{ marginRight: "10px" }}
                                                    width={"250px"}>
                                                    <Popover
                                                        target='#TransType'
                                                        showEvent={"click"}
                                                        // hideEvent="mouseleave"
                                                        position='bottom'
                                                        visible={openTransTypeRange}
                                                        onHiding={(e) => popFunction(e, "TransType")}
                                                        dragEnabled={false}
                                                        hideOnOutsideClick={true}
                                                        width={500}
                                                        height={125}>
                                                        <div>
                                                            <div className='popup-form-item-container2'>
                                                                <div>
                                                                    <label>From</label>
                                                                    <CustomizedLookup
                                                                        ref={fromTransTypeLookupRef}
                                                                        className={"ar-listing-datagrid"}
                                                                        dataSource={fromTransTypeList}
                                                                        displayExpr={"code"}
                                                                        valueExpr={"code"}
                                                                        value={returnSelectedValue("FromTransType")}
                                                                        onSelectionChanged={(e) => {
                                                                            updateParamValue("FromTransType", e.value);
                                                                        }}
                                                                        dataSourceURL={"api/Utilities/GetMenus"}
                                                                        displayText={returnSelectedValue("FromTransType")}
                                                                        onDataSourceChanged={setFromTransTypeList}>
                                                                        <Column dataField='code' elementAttr={{ dataField: "TransTypeCode" }} visible={true} caption='Trans Type Code' alignment='left' allowGrouping={false} />
                                                                        <Column dataField='name' elementAttr={{ dataField: "TransTypeName" }} visible={true} caption='Trans Type Name' alignment='left' allowGrouping={false} />
                                                                    </CustomizedLookup>
                                                                </div>
                                                                <div>
                                                                    <label>To</label>

                                                                    <CustomizedLookup
                                                                        ref={toTransTypeLookupRef}
                                                                        className={"ar-listing-datagrid"}
                                                                        dataSource={toTransTypeList}
                                                                        displayExpr={"code"}
                                                                        valueExpr={"code"}
                                                                        value={returnSelectedValue("ToTransType")}
                                                                        displayText={returnSelectedValue("ToTransType")}
                                                                        onSelectionChanged={(e) => {
                                                                            updateParamValue("ToTransType", e.value);
                                                                        }}
                                                                        dataSourceURL={"api/Utilities/GetMenus"}
                                                                        onDataSourceChanged={setToTransTypeList}>
                                                                        <Column dataField='code' elementAttr={{ dataField: "TransTypeCode" }} visible={true} caption='Trans Type Code' alignment='left' allowGrouping={false} />
                                                                        <Column dataField='name' elementAttr={{ dataField: "TransTypeName" }} visible={true} caption='Trans Type Name' alignment='left' allowGrouping={false} />
                                                                    </CustomizedLookup>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <Button
                                                                    style={{ position: "absolute", top: "65%", left: "75%" }}
                                                                    text='Clear Filter'
                                                                    stylingMode='text'
                                                                    type='default'
                                                                    onClick={() => {
                                                                        removeParamValue("TransTypeList");
                                                                        removeParamValue("FromTransType");
                                                                        removeParamValue("ToTransType");
                                                                        setSelectedTransType(0);
                                                                    }}
                                                                    elementAttr={{ dataField: "FilterNumber" }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Popover>
                                                </TextBox>
                                            </div>
                                        )}
                                        {selectedTransType === 2 && (
                                            <div>
                                                <TextBox
                                                    value={returnSelectedKeys("TransType").length !== 0 ? `${returnSelectedKeys("TransType").length} Selected  (${returnSelectedKeys("TransType")})` : `${returnSelectedKeys("TransType").length} Selected`}
                                                    style={{ marginTop: "-35px" }}
                                                    height={0}
                                                    showClearButton={false}
                                                    width={"250px"}>
                                                    <DropDownBox
                                                        // onClosed={(e) => closeDropDownBox(e, "Project")}
                                                        ref={dropdownRef}
                                                        // opened={openCustSpecific}
                                                        valueExpr='id'
                                                        deferRendering={false}
                                                        displayExpr='code'
                                                        showClearButton={false}
                                                        contentRender={(e) => DataGridMultiRender("TransType")}
                                                        defaultOpened={true}
                                                        width={"250px"}
                                                        dropDownOptions={{ width: "auto" }}
                                                    />
                                                </TextBox>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className='popup-form-item-container2'>
                                    <div className='popup-group-form-item'>
                                        <div className='popup-group-form-label'> User ID </div>
                                        {selectedUserID === 0 && (
                                            <SelectBox
                                                width={"250px"}
                                                dataSource={filterTypes}
                                                value={selectedUserID}
                                                displayExpr={"Type"}
                                                valueExpr={"ID"}
                                                elementAttr={{ dataField: "FilterNumber" }}
                                                onValueChanged={(e) => {
                                                    valueOnChange(e, "UserID");
                                                }}
                                            />
                                        )}
                                        {selectedUserID === 1 && (
                                            <div onClick={() => setOpenUserIDRange(true)}>
                                                <TextBox
                                                    value={returnSelectedValue("FromUserID") || returnSelectedValue("ToUserID") ? `${returnSelectedValue("FromUserID")} to ${returnSelectedValue("ToUserID")}` : "No Range Selected Yet"}
                                                    // useMaskBehavior={true} // Enable mask behavior
                                                    // mask="cccccc" // Define the input mask
                                                    id='UserID'
                                                    style={{ marginRight: "10px" }}
                                                    width={"250px"}>
                                                    <Popover
                                                        target='#UserID'
                                                        showEvent={"click"}
                                                        // hideEvent="mouseleave"
                                                        position='bottom'
                                                        visible={openUserIDRange}
                                                        onHiding={(e) => popFunction(e, "UserID")}
                                                        dragEnabled={false}
                                                        hideOnOutsideClick={true}
                                                        width={500}
                                                        height={125}>
                                                        <div>
                                                            <div className='popup-form-item-container2'>
                                                                <div>
                                                                    <label>From</label>
                                                                    <CustomizedLookup
                                                                        ref={fromUserIDLookupRef}
                                                                        className={"ar-listing-datagrid"}
                                                                        dataSource={fromUserIDList}
                                                                        displayExpr={"code"}
                                                                        valueExpr={"code"}
                                                                        value={returnSelectedValue("FromUserID")}
                                                                        onSelectionChanged={(e) => {
                                                                            updateParamValue("FromUserID", e.value);
                                                                        }}
                                                                        dataSourceURL={"api/Utilities/GetUsers"}
                                                                        displayText={returnSelectedValue("FromUserID")}
                                                                        onDataSourceChanged={setFromUserIDList}>
                                                                        <Column dataField='name' elementAttr={{ dataField: "UserIDCode" }} visible={true} caption='User Code' alignment='left' allowGrouping={false} />
                                                                        <Column dataField='code' elementAttr={{ dataField: "UserIDName" }} visible={true} caption='User Name' alignment='left' allowGrouping={false} />
                                                                    </CustomizedLookup>
                                                                </div>
                                                                <div>
                                                                    <label>To</label>

                                                                    <CustomizedLookup
                                                                        ref={toUserIDLookupRef}
                                                                        className={"ar-listing-datagrid"}
                                                                        dataSource={toUserIDList}
                                                                        displayExpr={"code"}
                                                                        valueExpr={"code"}
                                                                        value={returnSelectedValue("ToUserID")}
                                                                        onSelectionChanged={(e) => {
                                                                            updateParamValue("ToUserID", e.value);
                                                                        }}
                                                                        dataSourceURL={"api/Utilities/GetUsers"}
                                                                        displayText={returnSelectedValue("ToUserID")}
                                                                        onDataSourceChanged={setToUserIDList}>
                                                                        <Column dataField='name' elementAttr={{ dataField: "UserIDCode" }} visible={true} caption='User Code' alignment='left' allowGrouping={false} />
                                                                        <Column dataField='code' elementAttr={{ dataField: "UserIDName" }} visible={true} caption='User Name' alignment='left' allowGrouping={false} />
                                                                    </CustomizedLookup>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <Button
                                                                    style={{ position: "absolute", top: "65%", left: "75%" }}
                                                                    text='Clear Filter'
                                                                    stylingMode='text'
                                                                    type='default'
                                                                    onClick={() => {
                                                                        removeParamValue("UserIDList");
                                                                        removeParamValue("FromUserID");
                                                                        removeParamValue("ToUserID");
                                                                        setSelectedUserID(0);
                                                                    }}
                                                                    elementAttr={{ dataField: "FilterNumber" }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Popover>
                                                </TextBox>
                                            </div>
                                        )}
                                        {selectedUserID === 2 && (
                                            <div>
                                                <TextBox
                                                    value={returnSelectedKeys("UserID").length !== 0 ? `${returnSelectedKeys("UserID").length} Selected  (${returnSelectedKeys("UserID")})` : `${returnSelectedKeys("UserID").length} Selected`}
                                                    style={{ marginTop: "-35px" }}
                                                    height={0}
                                                    showClearButton={false}
                                                    width={"250px"}>
                                                    <DropDownBox
                                                        ref={dropdownRef}
                                                        // opened={openSalesSpecific}
                                                        // onClosed={(e) => closeDropDownBox(e, "Salesman")}
                                                        valueExpr='id'
                                                        deferRendering={false}
                                                        displayExpr='code'
                                                        showClearButton={false}
                                                        contentRender={() => DataGridMultiRender("UserID")}
                                                        defaultOpened={true}
                                                        width={"250px"}
                                                        dropDownOptions={{ width: "auto" }}
                                                    />
                                                </TextBox>
                                            </div>
                                        )}
                                    </div>

                                    <div className='popup-group-form-item'>
                                        <div className='popup-group-form-label'> Action </div>
                                        {selectedAction === 0 && (
                                            <SelectBox
                                                width={"250px"}
                                                dataSource={filterTypes.filter(x => x.ID !== 1)}
                                                value={selectedAction}
                                                displayExpr={"Type"}
                                                valueExpr={"ID"}
                                                elementAttr={{ dataField: "FilterNumber" }}
                                                onValueChanged={(e) => {
                                                    valueOnChange(e, "Action");
                                                }}
                                            />
                                        )}
                                        {selectedAction === 2 && (
                                            <div>
                                                <TextBox
                                                    value={returnSelectedKeys("Action").length !== 0 ? `${returnSelectedKeys("Action").length} Selected  (${returnSelectedKeys("Action")})` : `${returnSelectedKeys("Action").length} Selected`}
                                                    style={{ marginTop: "-35px" }}
                                                    height={0}
                                                    showClearButton={false}
                                                    width={"250px"}>
                                                    <DropDownBox
                                                        ref={dropdownRef}
                                                        // opened={openAreaSpecific}
                                                        // onClosed={(e) => closeDropDownBox(e, "Area")}
                                                        valueExpr='id'
                                                        deferRendering={false}
                                                        displayExpr='code'
                                                        showClearButton={false}
                                                        contentRender={() => DataGridMultiRender("Action")}
                                                        defaultOpened={true}
                                                        width={"250px"}
                                                        dropDownOptions={{ width: "auto" }}
                                                    />
                                                </TextBox>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className='popup-form-item-container2'>
                                    <div className='popup-group-form-item' style={{ marginTop: "10px" }}>
                                        <Button text='Inquiry' type='success' width={"auto"} onClick={(e) => onInitialized(e)} />
                                        <Button text='Clear' type='success' stylingMode={"outlined"} style={{ marginLeft: "20px" }} width={"auto"} onClick={() => clearFilters()} />
                                        {/* <Button text='Print Report' disabled={printRepDisabled} type='success' stylingMode={"outlined"} width={"auto"} style={{ marginLeft: "20px" }} onClick={(e) => setOpenReportPop(true)} /> */}
                                    </div>
                                </div>
                            </div>
							<TextBox
								width={"400px"}
								height={"34px"}
								ref={searchBoxRef}
								className='listing-page-search-box'
								placeholder='Search'
								style={{marginTop: ".6%"}}
								value={query.current}
								valueChangeEvent='keyup'
								onValueChanged={(e) => {
									query.current = e.value;
									refresh();
									const arrayText = e.value.split(" ");
									datagridRef.current.instance.searchByText(arrayText[arrayText.length - 1]); // By default search the last space word
									// console.log("On change", e)
								}}
							/>
							
                            <DataGrid className="audit-trail-listing-datagrid"  ref={datagridRef} keyExpr={keyDetail} dataSource={mainDataSource} showBorders={true} showRowLines={true} remoteOperations={remoteOperations}>
                                {/* <MasterDetail enabled={true} component={dataTemplate}/> */}
                                <Column key={"ModuleID"} dataField={"ModuleID"} visible={false} />
                                <Column key={"CreatedDate"} dataField={"CreatedDate"} format='dd/MM/yyyy HH:mm:ss' dataType={"date"} />
                                <Column key={"UserName"} dataField={"UserName"} caption={"User ID"} />
                                <Column key={"Action"} dataField={"Action"} />
                                <Column key={"Module"} dataField={"Module"} />
                                <Column key={"Reference"} dataField={"Reference"} cellRender={(e) => {
                                    const data = e.data;
                                    const id = data.ModuleID;
                                    const nonIntegerRegex = /[a-zA-Z-]+/g;
                                    if (!utils.isNullOrEmpty(data.Reference)) {
                                        return (
                                            <span
                                                className={!data.IsDeleted ? 'clickable-span-tag' : ""}
                                                onClick={() => {
                                                    if(!data.IsDeleted) {
                                                        // console.log(data);
                                                        getDocTransCode(data.DocumentNo).then((res) => {
                                                            var valueToCheck = "";
                                                            // console.log(res);

                                                            if (res === null) {
                                                                setCurrentEditID(id);
                                                                valueToCheck = data.TransactionTypeCode.trim().match(nonIntegerRegex);
                                                            } else {
                                                                setCurrentEditID(res.DocumentID);
                                                                valueToCheck = res.transactionCode.trim().match(nonIntegerRegex);
                                                            }
                                                            valueToCheck = valueToCheck ? valueToCheck.join("") : "";
                                                            // console.log(valueToCheck);

                                                            docType.find((item) => {
                                                                if (item.TransType === valueToCheck) {
                                                                    setForm(item.DocumentType);
                                                                    // if (ledgerTypeParam.ParameterValue.includes("Customer") || ledgerTypeParam.ParameterValue.includes("Supplier")) {
                                                                        if (!utils.isNullOrEmpty(data.DocumentDate)) setDocumentDate(data.DocumentDate);
                                                                        setDocumentNo(data.DocumentNo);
                                                                    // }
                                                                }
                                                            });
                                                        });
                                                    }
                                                }}>
                                                {e.displayValue}
                                            </span>
                                        );
                                    } else {
                                        return '';
                                    }
                                }} />
                                {/* <Column key={"View"} dataField={"View"} ref={textRef} fixedPosition={true} cellRender={referenceCellTemplate} /> */}
							    <Column type='buttons' caption='Detail' cellRender={cellRender} width={"auto"}></Column>

                                <Pager allowedPageSizes={[10, 25, 50, 100]} showPageSizeSelector={true} visible={true} displayMode={"full"} showInfo={true} />
                                <Paging
                                    defaultPageSize={25}
                                    onPageSizeChange={(e) => {
                                        pageSize.current = e;
                                        refresh();
                                    }}
                                    onPageIndexChange={(e) => {
                                        selectedPage.current = e + 1;
                                        refresh();
                                    }}
                                />
                            </DataGrid>
                        </div>
                    </div>
                </div>
            </div>

			<ErrorPopUpForm ref={popupMessageRef} />

			<div>
                {/*AR*/}
				{form === "Sales" && <ARSalesForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Invoice Entry"} />}
				{form === "ARDebitNote" && <ARDebitNoteForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Debit Note Entry"} />}
				{form === "Receipt" && <ARReceiptForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Receipt Entry"} />}
				{form === "ARCreditNote" && <ARCreditNoteForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Credit Note Entry"} />}
				{form === "ARRefund" && <ARRefundForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Refund Entry"} />}
				{form === "Contra" && <ContraForm formType={"Customer"} formID={currentEditID} closePopup={setCurrentEditID} openedBefore={true} documentNo={documentNo} documentDate={documentDate} />}

                {/*AP*/}
				{form === "APDebitNote" && <APDebitNoteForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Debit Note Entry"} />}
				{form === "Payment" && <APPaymentForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Payment Entry"} />}
				{form === "Purchase" && <APPurchaseForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Purchase Entry"} />}
				{form === "APCreditNote" && <APCreditNoteForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Credit Note Entry"} />}
				{form === "APContra" && <ContraForm formType={"Supplier"} formID={currentEditID} closePopup={setCurrentEditID} openedBefore={true} documentNo={documentNo} documentDate={documentDate} />}
				{form === "APRefund" && <APRefundForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Refund Entry"} />}

                {/*SALES*/}
				{form === "SalesInvoice" && <SalesInvoiceMainForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Invoice Entry"} />}
				{form === "CashSales" && <SalesCashSalesForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Cash Sales Entry"} />}
				{form === "SalesQuotation" && <SalesQuotationForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Sales Quotation"} />}
				{form === "SalesDeliveryOrder" && <SalesDeliveryOrderForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Sales Delivery Order"} />}
				{form === "SalesDebitNote" && <SalesDebitNoteForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Debit Note"} />}
				{form === "SalesCreditNote" && <SalesCreditNoteForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Credit Note"} />}
				{form === "SalesOrder" && <SalesOrderForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Sales Order"} />}
				{form === "SalesOrderAdjustment" && <SalesOrderAdjustmentForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Sales Order Adjustment"} />}

                {/*PURCHASE*/}
				{form === "CashPurchase" && <CashPurchaseForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={true} title={"Cash Purchase Entry"} />}
				{form === "PurchaseInvoice" && <PurchaseInvoiceForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Invoice Entry"} />}
				{form === "PurchaseRequest" && <PurchaseRequestForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Purchase Request"} />}
				{form === "PurchaseQuotation" && <PurchaseQuotationForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Purchase Quotation"} />}
				{form === "PurchaseOrder" && <PurchaseOrderForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Purchase Order"} />}
				{form === "PurchaseReceived" && <PurchaseReceivedForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Purchase Received"} />}
				{form === "PurchaseDebitNote" && <PurchaseDebitNoteForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Purchase Debit"} />}
				{form === "PurchaseReturn" && <PurchaseReturnForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Purchase Return"} />}
				{form === "PurchaseOrderAdjustment" && <PurchaseOrderAdjustmentForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Purchase Order Adjustment"} />}

                {/*GL*/}
				{form === "Journal" && <JournalEntryForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Journal Entry"} />}
				{form === "GLPayment" && <CashBookEntryForm formType={"Payment Voucher"} formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Payment Voucher"} />}
				{form === "GLReceipt" && <CashBookEntryForm formType={"Official Receipt"} formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Official Receipt"} />}

                {/*STOCK*/}
				{form === "StockReceive" && <StockReceivedFrom formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Stock Received"} />}
				{form === "StockIssue" && <StockIssueFrom formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Stock Issue"} />}
				{form === "StockAdjustment" && <StockAdjustmentFrom formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Stock Adjustment"} />}
				{form === "StockTransfer" && <StockTransferFrom formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Stock Transfer"} />}
				{form === "StockWriteOff" && <StockWriteOffForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Stock Write Off"} />}
				{form === "StockTake" && <StockTakeFrom formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Stock Take"} />}

                {/*WORKSHOP*/}
				{form === "WorkshopJobsheet" && <JobSheetForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Job Sheet"} />}
				{form === "WorkshopInvoice" && <WorkshopInvoiceForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Invoice Entry"} />}
			</div>

			{/* Popup to display full value if truncated */}
			<Popup
				id="audit-trail-detail"
				visible={isPopupVisible}
				onHiding={() => {
					setPopupVisible(false);
					setDetailDataSource({});
				}}
				hideOnOutsideClick={false}
				dragEnabled={false}
				showCloseButton={true}
				title="Detail Changes"
				width="50%"
				height="auto"
			>
				<div>
					{renderItems()}
				</div>
			</Popup>
		</div>
	);
}
