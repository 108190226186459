import { Popup, Button } from "devextreme-react";
import { useEffect, useState, useRef, useImperativeHandle, ref, forwardRef } from "react";
import "../../../components/popup-form/StockFilterForm.scss";
import baseapi from "../../../api/baseapi";
import ErrorPopUpForm from "../../../components/popup-form/ErrorPopupForm";
import utils from "../../../utils/common";
import Validator, { RequiredRule } from "devextreme-react/validator";
import { TextBox, SelectBox, DropDownBox, Popover } from "devextreme-react";
import { loading, closeLoading } from "../../../utils/common";
import { DataGrid, Button as Pager, Toolbar, Column, Selection } from "devextreme-react/data-grid";
import React, { Component } from 'react';
import CustomizedLookup from "../../../components/lookup/Lookup";
import { stockOptions, stockGroupOptions, itemTypeOptions, customerOptions, locationOptions, supplierOptions, scTranTypeOptions } from "../../../utils/lookupstore";
import { index } from "devexpress-reporting/scopes/reporting-designer-controls-pivotGrid-metadata";
const AdvancedFilter = forwardRef((props, ref) => {
    const [visible, setVisible] = useState(false);
    const popupMessageRef = useRef(null);
    const tempForm = useRef(null);

    const [selectedTransaction, setSelectedTransaction] = useState(0);
    const [selectedCustomer, setSelectedCustomer] = useState(0);
    const [openCustomerRange, setOpenCustomerRange] = useState(null);
    const [selectedSupplier, setSelectedSupplier] = useState(0);
    const [openSupplierRange, setOpenSupplierRange] = useState(null);
    const [paramValue, setParamValue] = useState([]);
    const [openTransactionRange, setOpenTransactionRange] = useState(null);
    const fromLocationLookupRef = useRef(null);
    const toLocationLookupRef = useRef(null);
    const [fromLocationList, setFromLocationList] = useState([]);
    const [toLocationList, setToLocationList] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState(0);
    const [openLocationRange, setOpenLocationRange] = useState();


    const fromCustomerLookupRef = useRef(null);
    const toCustomerLookupRef = useRef(null);
    const [fromCustomerList, setFromCustomerList] = useState([]);
    const [toCustomerList, setToCustomerList] = useState([]);

    const fromSupplierLookupRef = useRef(null);
    const toSupplierLookupRef = useRef(null);
    const [fromSupplierList, setFromSupplierList] = useState([]);
    const [toSupplierList, setToSupplierList] = useState([]);

    const searchBoxRef = useRef(null);
    const gridRef = useRef(null);
    const dropdownRef = useRef(null);
    const currentFocusIndex = useRef(null);
    const [originalDropDownList, setOriginalDropDownList] = useState({});

    const pagingIndex = useRef({
        loading: false,
        page: 1
    });

  

    const returnSelectedValue = (type) => {
        const filtered = paramValue.filter((x) => x.ParameterName === type);
        return filtered.length > 0 ? filtered[0].ParameterValue : null;
    };

    const [dropDownList, setDropDownList] = useState({

        stockList: [],
        statementList: [],
        customerList: [],
        stockGroupList: [],
        typeList: [],
        supplierList: [],
        transactionList: [],
        locationList: []
    });

    useEffect(() => {
        if (pagingIndex.current["loading"] === true) {
            pagingIndex.current["page"] += 1;
            pagingIndex.current["loading"] = false;
        }

    }, [dropDownList]);

    useEffect(() => {
        Promise.allSettled([

            scTranTypeOptions.store.load(),
            customerOptions.store.load(),
            supplierOptions.store.load(),
            locationOptions.store.load(),
        ]).then((lists) => {
            setDropDownList({

                transactionList: lists[0].value.data,
                customerList: lists[1].value.data,
                supplierList: lists[2].value.data,
                locationList: lists[3].value.data,
            });
            setOriginalDropDownList({
                transactionList: lists[0].value.data,
                customerList: lists[1].value.data,
                supplierList: lists[2].value.data,
                locationList: lists[3].value.data,
            });


        });
    }, []);
    function refreshLookupData(type) {
        if (type === "Customer") {
            setDropDownList((prevState) => ({
                ...prevState,
                customerList: [...originalDropDownList.customerList]
            }));
        } else if (type === "Supplier") {
            setDropDownList((prevState) => ({
                ...prevState,
                supplierList: [...originalDropDownList.supplierList]
            }));
        } else if (type === "Location") {
            setDropDownList((prevState) => ({
                ...prevState,
                locationList: [...originalDropDownList.locationList]
            }));
        } else if (type === "Transaction") {
            setDropDownList((prevState) => ({
                ...prevState,
                transactionList: [...originalDropDownList.transactionList]
            }));
        }
    }


    const removeParamValue = (key) => {
        setParamValue((prevParamValue) => {
            const updatedParamValue = prevParamValue.filter((item) => item.ParameterName !== key);
            return updatedParamValue;
        });
    };


    const popFunction = (e, type) => {
        if (e.cancel === false) {
            if (type === "Transaction") {
                setOpenTransactionRange(false);

            }
            else if (type === "Customer") {
                setOpenCustomerRange(false);
            }
            else if (type === "Supplier") {
                setOpenSupplierRange(false);
            }
            else if (type === "Location") {
                setOpenLocationRange(false);
            }
        }
        else {
            if (type === "Transaction") {
                setOpenTransactionRange(true);
            }
            else if (type === "Customer") {
                setOpenCustomerRange(true);
            }
            else if (type === "Supplier") {
                setOpenSupplierRange(true);
            }
            else if (type === "Location") {
                setOpenLocationRange(true);
            }
        }
    };

    useImperativeHandle(ref, () => ({
        show: () => setVisible(true),
    }));

    function onClose() {

        setVisible(false);
    }

    function handleSubmit() {
        tempForm.current = [...paramValue];

        if (tempForm.current.some(e=>e.ParameterName == 'FromLocation' || e.ParameterName == 'ToLocation')) {
            const fromLocationValue = tempForm.current.find(e=>e.ParameterName == 'FromLocation')?.ParameterValue;
            const toLocationValue = tempForm.current.find(e=>e.ParameterName == 'ToLocation')?.ParameterValue;

            const startIndex = fromLocationList.indexOf(fromLocationList.find(e=>e.code == fromLocationValue));
            const endIndex = fromLocationList.indexOf(fromLocationList.find(e=>e.code == toLocationValue)) + 1;

            if (endIndex) {
            const parameterValue = fromLocationList.slice(startIndex == -1 ? 0 : startIndex, endIndex);

            const allCodes = parameterValue.map(e=>e.code);
            tempForm.current.push({ParameterName: 'LocationList', ParameterValue: allCodes});
            } else {
                const parameterValue = fromLocationList.slice(startIndex == -1 ? 0 : startIndex);

                const allCodes = parameterValue.map(e=>e.code);
                tempForm.current.push({ParameterName: 'LocationList', ParameterValue: allCodes});
            }
        }
        if (tempForm.current.some(e=>e.ParameterName == 'FromCustomer' || e.ParameterName == 'ToCustomer')) {
            const fromCustomerValue = tempForm.current.find(e=>e.ParameterName == 'FromCustomer')?.ParameterValue;
            const toCustomerValue = tempForm.current.find(e=>e.ParameterName == 'ToCustomer')?.ParameterValue;

            const startIndex = fromCustomerList.indexOf(fromCustomerList.find(e=>e.code == fromCustomerValue));
            const endIndex = fromCustomerList.indexOf(fromCustomerList.find(e=>e.code == toCustomerValue)) + 1;
            
            if (endIndex) {
            const parameterValue = fromCustomerList.slice(startIndex == -1 ? 0 : startIndex, endIndex);

            const allCodes = parameterValue.map(e=>e.name);
            tempForm.current.push({ParameterName: 'CustomerList', ParameterIDValue: allCodes});
            } else {
                const parameterValue = fromCustomerList.slice(startIndex == -1 ? 0 : startIndex);

                const allCodes = parameterValue.map(e=>e.name);
                tempForm.current.push({ParameterName: 'CustomerList', ParameterIDValue: allCodes});
            }
        }
        if (tempForm.current.some(e=>e.ParameterName == 'FromSupplier' || e.ParameterName == 'ToSupplier')) {
            const fromSupplierValue = tempForm.current.find(e=>e.ParameterName == 'FromSupplier')?.ParameterValue;
            const toSupplierValue = tempForm.current.find(e=>e.ParameterName == 'ToSupplier')?.ParameterValue;

            const startIndex = fromSupplierList.indexOf(fromSupplierList.find(e=>e.code == fromSupplierValue));
            const endIndex = fromSupplierList.indexOf(fromSupplierList.find(e=>e.code == toSupplierValue)) + 1;

            if (endIndex) {
            const parameterValue = fromSupplierList.slice(startIndex == -1 ? 0 : startIndex, endIndex);

            const allCodes = parameterValue.map(e=>e.name);
            tempForm.current.push({ParameterName: 'SupplierList', ParameterIDValue: allCodes});
            } else {
                const parameterValue = fromSupplierList.slice(startIndex == -1 ? 0 : startIndex);

                const allCodes = parameterValue.map(e=>e.name);
                tempForm.current.push({ParameterName: 'SupplierList', ParameterIDValue: allCodes});
            }
        }

        props.ReturnResult({ data: tempForm.current , clearFilter: ()=>{
            setSelectedLocation(0);
            setSelectedTransaction(0);
            setSelectedCustomer(0);
            setSelectedSupplier(0);
            setParamValue([]);
        }});

        onClose();
    }

    function valueOnChange(e, type = null) {
        try {

            if (e.value !== undefined) {
                const dataField = e.element.getAttribute("dataField");

                if (dataField === "FilterNumber") {
                    if (e.value === 1) {
                        switch (type) {

                            case "Transaction":
                                setSelectedTransaction(e.value);
                                setOpenTransactionRange(true);
                                break;
                            case "Customer":
                                setSelectedCustomer(e.value);
                                setOpenCustomerRange(true);
                                break;

                            case "Supplier":
                                setSelectedSupplier(e.value);
                                setOpenSupplierRange(true);
                                break;
                            case "Location":
                                setSelectedLocation(e.value);
                                setOpenLocationRange(true);
                                break;
                            default:
                                // setSelectedID(0);
                                break;
                        }
                    }
                    if (e.value === 2) {
                        switch (type) {

                            case "Transaction":
                                setSelectedTransaction(e.value);
                                break;
                            case "Customer":
                                setSelectedCustomer(e.value);
                                break;
                            case "Supplier":
                                setSelectedSupplier(e.value);
                                break;

                            case "Location":
                                setSelectedLocation(e.value);
                                break;
                            default:
                                break;
                        }
                    }
                }

                if (dataField !== "FilterNumber") updateParamValue(dataField, e.value);
            }
        } catch (err) {
            console.log(err);
        }
    }
    const returnSelectedKeys = (type) => {

        const paramObject = paramValue.reduce((acc, curr) => {
            acc[curr.ParameterName] = curr.ParameterValue;
            return acc;
        }, {});

        let selectedKeys = [];

        if (type === "Transaction" && paramObject["TransactionList"]) {
            selectedKeys = ensureArray(paramObject["TransactionList"]);
        } else if (type === "Customer" && paramObject["CustomerList"]) {
            selectedKeys = ensureArray(paramObject["CustomerList"]);
        } else if (type === "Supplier" && paramObject["SupplierList"]) {
            selectedKeys = ensureArray(paramObject["SupplierList"]);
        } else if (type === "Location" && paramObject["LocationList"]) {
            selectedKeys = ensureArray(paramObject["LocationList"]);
        }

        return selectedKeys;
    };

    const ensureArray = (value) => {
        if (Array.isArray(value)) {
            return value;
        } else {
            return value.split(",");
        }
    };

    const updateParamValue = (key, value, idList) => {
        setParamValue((prevParamValue) => {
            console.log(idList,"idList")
            const updatedParamValue = [...prevParamValue]; // Create a copy of the previous state array
            // Find the index of the item with the matching key
            const index = updatedParamValue.findIndex((item) => item.ParameterName === key);
            if (key.includes("List")) {
                const parameterIdList = idList.map(item => item.id).join(',');
                const parameterINameist = idList.map(item => item.name);

                if (index !== -1) {
                    // Update the existing item if it exists
                    updatedParamValue[index] = { ParameterName: key, ParameterValue: value, ParameterID: parameterIdList, ParameterIDValue: parameterINameist };
                } else {
                    // Add a new item if the key doesn't exist
                    updatedParamValue.push({ ParameterName: key, ParameterValue: value, ParameterID: parameterIdList, ParameterIDValue: parameterINameist});
                }
            } else {
                const parameterIdList = idList;
                if (index !== -1) {
                    // Update the existing item if it exists
                    updatedParamValue[index] = { ParameterName: key, ParameterValue: value, ParameterID: parameterIdList };
                } else {
                    // Add a new item if the key doesn't exist
                    updatedParamValue.push({ ParameterName: key, ParameterValue: value, ParameterID: parameterIdList });
                }
            }

            return updatedParamValue;
        });
    };

    function DataGridMultiRender(type) {
        let selectedKeys = returnSelectedKeys(type);

        return (
            <div className='customized-lookup-container'>
                <div className='stock-customized-lookup-search-container'>
                    <div>
                        <TextBox
                            ref={searchBoxRef}
                            placeholder='Search...'
                            width={250}
                            valueChangeEvent='keyup'
                            showClearButton={true}
                            onValueChanged={(e) => {
                                gridRef.current.instance.searchByText(e.value);
								updateDropDownList(e.value, type, pagingIndex.current["page"], false);
                            }}
                            onKeyDown={(e) => {
                                // console.log("key down", e.event)
                                if (e.event.key === "ArrowDown") {
                                    gridRef.current.instance.focus();
                                }
                            }}
                        />
                    </div>

                    <div className='customized-lookup-btn-section popup-form-item-container2'>
                        <Button
                            text='Clear'
                            onClick={(e) => {
                                if (searchBoxRef.current !== null) searchBoxRef.current.instance.reset();

                            }}
                        />
                        <Button
                            text='Clear Filter'
                            stylingMode='text'
                            type='default'
                            className='custom-button'
                            onClick={() => {
                                if (type === "Transaction") {
                                    removeParamValue("TransactionList");
                                    removeParamValue("FromTransaction");
                                    removeParamValue("ToTransaction");
                                    setSelectedTransaction(0);
                                }
                                else if (type === "Customer") {
                                    removeParamValue("CustomerList");
                                    removeParamValue("FromCustomer");
                                    removeParamValue("ToCustomer");
                                    setSelectedCustomer(0);
                                }
                                else if (type === "Supplier") {
                                    removeParamValue("SupplierList");
                                    removeParamValue("FromSupplier");
                                    removeParamValue("ToSupplier");
                                    setSelectedSupplier(0);
                                } else if (type === "Location") {
                                    removeParamValue("LocationList");
                                    removeParamValue("FromLocation");
                                    removeParamValue("ToLocation");
                                    setSelectedLocation(0);
                                }
                            }}
                            elementAttr={{ dataField: "FilterNumber" }}
                        />

                    </div>
                </div>
                <DataGrid
                    ref={gridRef}
                    height={245}
                    className={"lookup-datagrid"}
                    showBorders={true}
                    dataSource={returnDropDownList(type)}
                    scrolling={{ columnRenderingMode: "standard", showScrollbar: "onHover" }}
                    columnChooser={{ enabled: false }}
                    allowColumnResizing={true}
                    allowColumnReordering={true}
                    hoverStateEnabled={true}
                    paging={{ enabled: false }}
                    keyExpr='code'
                    focusedRowEnabled={true}
                    focusedRowKey={selectedKeys}
                    selectedRowKeys={selectedKeys}
                    onSelectionChanged={(e) => dataGridOnSelectionChanged(e, type)}
                    onKeyDown={(e) => {
                        // if(e.event.key === "Enter"){
                        //     setDropdownOpened(false);
                        // }
                        if (e.event.key === "ArrowUp") {
                            // If focus is one the first row then brings focus back to the search box
                            if (currentFocusIndex.current === 0) {
                                if (type === "Transaction") {
                                    removeParamValue("TransactionList");
                                } else if (type === "Customer") {
                                    removeParamValue("CustomerList");
                                } else if (type === "Supplier") {
                                    removeParamValue("SupplierList");
                                } else if (type === "Location") {
                                    removeParamValue("LocationList");
                                }
                            }
                            refreshLookupData(type);
                        }
                    }}
                    onFocusedRowChanging={(e) => {
                        if (e.event === null) {
                            e.cancel = true;
                        } else if (e.event.key === undefined) {
                            e.cancel = true;
                        }
                    }}

                    onContentReady={(element) => {
						const scrollable = element.component.getScrollable();
						if (scrollable !== undefined) {
							scrollable.on("scroll", function (e) {
								if (e.reachedBottom) {
									if (pagingIndex.current["loading"] === false) {
										// console.log("page", pagingIndex.current["page"]);
										pagingIndex.current["loading"] = true;
										updateDropDownList("", type, pagingIndex.current["page"], true);
									
									}
								}
							});
						}
					}}
                >
                    <Column
                        dataField='code'
                        elementAttr={{ dataField: type === "Stock" ? "StockCode" : type === "Type" ? "TypeCode" : type === "StockGroup" ? "StockGroupCode" : "TransactionCode" }}
                        caption={type === "Stock" ? "Stock Code" : type === "Type" ? "Type Code" : type === "StockGroup" ? "Stock Group Code" : "Transaction Code"}
                        visible={true}
                        alignment='left'
                        allowGrouping={false}
                    />

                    <Column
                        dataField='name'
                        elementAttr={{ dataField: type === "Stock" ? "StockName" : type === "Type" ? "TypeName" : type === "StockGroup" ? "StockGroupName" : "TransactionName" }}
                        caption={type === "Stock" ? "Stock Name" : type === "Type" ? "Type Name" : type === "StockGroup" ? "Stock Group Name" : "Transaction Name"}
                        visible={true}
                        alignment='left'
                        allowGrouping={false}
                    />

                    <Selection mode='multiple' showSelectAll={true} selectAllMode='page' showCheckBoxesMode={"always"} />
                    <Toolbar visible={false} />
                </DataGrid>


            </div>
        );
    }


    const dataGridOnSelectionChanged = (e, type) => {
        if (type === "Transaction") {
            updateParamValue("TransactionList", e.selectedRowKeys, e.selectedRowsData);
        } else if (type === "Customer") {
            updateParamValue("CustomerList", e.selectedRowKeys, e.selectedRowsData);
        } else if (type === "Supplier") {
            updateParamValue("SupplierList", e.selectedRowKeys, e.selectedRowsData);
        } else if (type === "Location") {
            updateParamValue("LocationList", e.selectedRowKeys, e.selectedRowsData);
        }

    };

    const returnDropDownList = (type) => {
        if (type === "Transaction") {
            return dropDownList.transactionList;
        } else if (type === "Customer") {
            return dropDownList.customerList;
        } else if (type === "Supplier") {
            return dropDownList.supplierList;
        } else if (type === "Location") {
            return dropDownList.locationList;
        }
    };

    const filterTypes = [
        {
            ID: 0,
            Type: "No Filter"
        },
        {
            ID: 1,
            Type: "Filter By Range"
        },
        {
            ID: 2,
            Type: "Filter By Multi-Select"
        }
    ];
    const scTranfilterTypes = [
        {
            ID: 0,
            Type: "No Filter"
        },

        {
            ID: 1,
            Type: "Filter By Multi-Select"
        }
    ];
	const arrayToString = (arr) => {
		if (Array.isArray(arr)) {
			return arr.join(" ");
		} else {
			return arr;
		}
	};
    const returnDropDownListURL = (type) => {

        if (type === "Stock") {
            return "api/Utilities/GetStock";
        } else if (type === "Type") {
            return "api/Utilities/GetItemTypes";
        } else if (type === "StockGroup") {
            return "api/Utilities/GetStockGroups";
        } else if (type === "Transaction") {
            return "api/Utilities/GetSCTransactionType";
        } else if (type === "Customer") {
            return "api/Utilities/GetCustomers";
        } else if (type === "Supplier") {
            return "api/Utilities/GetSuppliers";
        } else if (type === "Location") {
            return "api/Utilities/GetLocations";
        }

    };

    const updateDropDownList = (paramValue, type, page, paging) => {


        const typeToArrayNameMap = {
            Stock: "stockList",
            Customer: "customerList",
            Supplier: "supplierList",
            Transaction: "transactionList"
        };
        var value = "";
        if (!paging) {
			page = 0;
		}

        if (paramValue !== undefined) {
            value = paramValue;
        }

        const arrayName = typeToArrayNameMap[type];
        const query = value.length === 0 ? `${paramValue}` : `${paramValue} ${arrayToString(returnSelectedKeys(type))}`;



        baseapi
            .httpget(returnDropDownListURL(type), { q: query, singleReturn: true, page: page })
            .then((response) => {
                const data = response.data;
                setDropDownList((prevState) => {
                    const existingArray = prevState[arrayName] || [];
                    const newData = data.filter((newItem) => !existingArray.some((existingItem) => existingItem.id === newItem.id));
                    return {
                        ...prevState,
                        [arrayName]: [...existingArray, ...newData]
                    };
                });
            })
            .catch(() => {
                throw "Network error";
            });
    };

    return (
        <div>
            <Popup id='stockFilterMessagePopUp' visible={visible} width={650} showTitle={false} hideOnOutsideClick={true} height={400} shading={true} onHiding={() => onClose()}>
                <div className='stockFilterMessage-popup-items'>
                    <div className='stockFilterMessage-popup-header'>
                        <div className='stockFilterMessage-popup-cross'>
                            <Button icon='close' stylingMode='text' onClick={() => onClose()} />
                        </div>
                    </div>
                </div>

                <div className='stockFilterMessage-popup-content'>
                    <div style={{ paddingLeft: '10px', paddingTop: '25px' }}>
                        <b>Filter By</b>
                    </div>



                    <div className='popup-group-form-item'>
                        <div className='popup-group-form-label'> Item Location : </div>
                        {selectedLocation === 0 && (
                            <SelectBox
                                width={"400px"}
                                dataSource={filterTypes}
                                value={selectedLocation}
                                displayExpr={"Type"}
                                valueExpr={"ID"}
                                elementAttr={{ dataField: "FilterNumber" }}
                                onValueChanged={(e) => {
                                    valueOnChange(e, "Location");
                                }}
                            />
                        )}
                        {selectedLocation === 1 && (
                            <div onClick={() => setOpenLocationRange(true)}>
                                <TextBox
                                    value={returnSelectedValue("FromLocation") || returnSelectedValue("ToLocation") ? `${returnSelectedValue("FromLocation")} to ${returnSelectedValue("ToLocation")}` : "No Range Selected Yet"}

                                    id='Location'
                                    style={{ marginRight: "10px" }}
                                    width={"400px"}>
                                    <Popover
                                        target='#Location'
                                        showEvent={"click"}
                                        // hideEvent="mouseleave"
                                        position='bottom'
                                        visible={openLocationRange}
                                        onHiding={(e) => popFunction(e, "Location")}
                                        dragEnabled={false}
                                        hideOnOutsideClick={true}
                                        width={500}
                                        height={125}>
                                        <div>
                                            <div className='popup-form-item-container2'>
                                                <div>
                                                    <label>From</label>
                                                    <CustomizedLookup
                                                        ref={fromLocationLookupRef}
                                                        dataSource={fromLocationList.filter((item, index) => {
                                                         let toIndex = toLocationList.indexOf(toLocationList.find(e=>e.code == paramValue.find(e=>e.ParameterName == 'ToLocation')?.ParameterValue));   
                                                         if (toIndex == -1) {
                                                            return true;
                                                         }
                                                           return index <=  toIndex;
                                                        }
                                                        )}
                                                        displayExpr={"code"}
                                                        valueExpr={"code"}
                                                        value={returnSelectedValue("FromLocation")}
                                                        OnCustomSelectionChanged={(e) => {
                                                            updateParamValue("FromLocation", e.value.code, e.value.id);
                                                        }}
                                                        dataSourceURL={"api/Utilities/GetLocations"}
                                                        onDataSourceChanged={setFromLocationList}
                                                        sortByColumn={false}
                                                    >
                                                        <Column dataField='code' elementAttr={{ dataField: "code" }} visible={true} caption='Location Code' alignment='left' allowGrouping={false} />
                                                        <Column dataField='name' elementAttr={{ dataField: "name" }} visible={true} caption='Location Name' alignment='left' allowGrouping={false} />
                                                    </CustomizedLookup>
                                                </div>
                                                <div>
                                                    <label>To</label>
                                                    <CustomizedLookup
                                                        ref={toLocationLookupRef}
                                                        dataSource={toLocationList.filter((item, index) => index >= fromLocationList.indexOf(fromLocationList.find(e=>e.code == paramValue.find(e=>e.ParameterName == 'FromLocation')?.ParameterValue)))}
                                                        displayExpr={"code"}
                                                        valueExpr={"code"}
                                                        value={returnSelectedValue("ToLocation")}
                                                        OnCustomSelectionChanged={(e) => {
                                                            updateParamValue("ToLocation", e.value.code, e.value.id);
                                                        }}
                                                        sortByColumn={false}
                                                        dataSourceURL={"api/Utilities/GetLocations"}
                                                        onDataSourceChanged={setToLocationList}>
                                                        <Column dataField='code' elementAttr={{ dataField: "code" }} visible={true} caption='Location Code' alignment='left' allowGrouping={false} />
                                                        <Column dataField='name' elementAttr={{ dataField: "name" }} visible={true} caption='Location Name' alignment='left' allowGrouping={false} />
                                                    </CustomizedLookup>
                                                </div>
                                            </div>
                                            <div>
                                                <Button
                                                    style={{ position: "absolute", top: "65%", left: "75%" }}
                                                    text='Clear Filter'
                                                    stylingMode='text'
                                                    type='default'
                                                    onClick={() => {
                                                        removeParamValue("LocationList");
                                                        removeParamValue("FromLocation");
                                                        removeParamValue("ToLocation");
                                                        setSelectedLocation(0);
                                                    }}
                                                    elementAttr={{ dataField: "FilterNumber" }}
                                                />
                                            </div>
                                        </div>
                                    </Popover>
                                </TextBox>
                            </div>
                        )}
                        {selectedLocation === 2 && (
                            <div>
                                <TextBox
                                    value={returnSelectedKeys("Location").length !== 0 ? `${returnSelectedKeys("Location").length} Selected  (${returnSelectedKeys("Location")})` : `${returnSelectedKeys("Location").length} Selected`}
                                    style={{ marginTop: "-35px" }}
                                    height={0}
                                    showClearButton={false}
                                    width={"400px"}>
                                    <DropDownBox
                                        ref={dropdownRef}
                                        valueExpr='id'
                                        deferRendering={false}
                                        displayExpr='code'
                                        showClearButton={false}
                                        contentRender={(e) => DataGridMultiRender("Location")}
                                        defaultOpened={true}
                                        width={"400px"}
                                        dropDownOptions={{ width: "auto" }}
                                    />
                                </TextBox>
                            </div>
                        )}
                    </div>


                    <div className='popup-group-form-item'>
                        <div className='popup-group-form-label'> Transaction Type : </div>
                        {selectedTransaction === 0 && (
                            <SelectBox
                                width={"400px"}
                                dataSource={scTranfilterTypes}
                                value={selectedTransaction}
                                displayExpr={"Type"}
                                valueExpr={"ID"}
                                elementAttr={{ dataField: "FilterNumber" }}
                                onValueChanged={(e) => {
                                    valueOnChange(e, "Transaction");
                                }}
                            />
                        )}
                        {selectedTransaction === 1 && (

                            <div>
                                <TextBox
                                    value={returnSelectedKeys("Transaction").length !== 0 ? `${returnSelectedKeys("Transaction").length} Selected  (${returnSelectedKeys("Transaction")})` : `${returnSelectedKeys("Transaction").length} Selected`}
                                    style={{ marginTop: "-35px" }}
                                    height={0}
                                    showClearButton={false}
                                    width={"400px"}>
                                    <DropDownBox
                                        // onClosed={(e) => closeDropDownBox(e, "Transaction")}
                                        ref={dropdownRef}
                                        valueExpr='id'
                                        deferRendering={false}
                                        displayExpr='code'
                                        showClearButton={false}
                                        contentRender={(e) => DataGridMultiRender("Transaction")}
                                        defaultOpened={true}
                                        width={"400px"}
                                        dropDownOptions={{ width: "auto" }}
                                    />
                                </TextBox>
                            </div>
                        )}
                        {selectedTransaction === 2 && (
                            <div>
                                <TextBox
                                    value={returnSelectedKeys("Transaction").length !== 0 ? `${returnSelectedKeys("Transaction").length} Selected  (${returnSelectedKeys("Transaction")})` : `${returnSelectedKeys("Transaction").length} Selected`}
                                    style={{ marginTop: "-35px" }}
                                    height={0}
                                    showClearButton={false}
                                    width={"400px"}>
                                    <DropDownBox
                                        // onClosed={(e) => closeDropDownBox(e, "Transaction")}
                                        ref={dropdownRef}
                                        valueExpr='id'
                                        deferRendering={false}
                                        displayExpr='code'
                                        showClearButton={false}
                                        contentRender={(e) => DataGridMultiRender("Transaction")}
                                        defaultOpened={true}
                                        width={"400px"}
                                        dropDownOptions={{ width: "auto" }}
                                    />
                                </TextBox>
                            </div>
                        )}
                    </div>


                    <div className='popup-group-form-item'>
                        <div className='popup-group-form-label'> Customer : </div>
                        {selectedCustomer === 0 && (
                            <SelectBox
                                width={"400px"}
                                dataSource={filterTypes}
                                value={selectedCustomer}
                                displayExpr={"Type"}
                                valueExpr={"ID"}
                                elementAttr={{ dataField: "FilterNumber" }}
                                onValueChanged={(e) => {
                                    valueOnChange(e, "Customer");
                                }}
                            />
                        )}
                        {selectedCustomer === 1 && (
                            <div onClick={() => setOpenCustomerRange(true)}>
                                <TextBox
                                    value={returnSelectedValue("FromCustomer") || returnSelectedValue("ToCustomer") ? `${returnSelectedValue("FromCustomer")} to ${returnSelectedValue("ToCustomer")}` : "No Range Selected Yet"}
                                    // useMaskBehavior={true} // Enable mask behavior
                                    // mask="cccccc" // Define the input mask
                                    id='Customer'
                                    style={{ marginRight: "10px" }}
                                    width={"400px"}>
                                    <Popover
                                        target='#Customer'
                                        showEvent={"click"}
                                        // hideEvent="mouseleave"
                                        position='bottom'
                                        visible={openCustomerRange}
                                        onHiding={(e) => popFunction(e, "Customer")}
                                        dragEnabled={false}
                                        hideOnOutsideClick={true}
                                        width={500}
                                        height={125}>
                                        <div>
                                            <div className='popup-form-item-container2'>
                                                <div>
                                                    <label>From</label>
                                                    <CustomizedLookup
                                                        ref={fromCustomerLookupRef}
                                                        dataSource={fromCustomerList.filter((item, index) => {
                                                         let toIndex = toCustomerList.indexOf(toCustomerList.find(e=>e.code == paramValue.find(e=>e.ParameterName == 'ToCustomer')?.ParameterValue));   
                                                         if (toIndex == -1) {
                                                            return true;
                                                         }
                                                           return index <=  toIndex;
                                                        }
                                                        )}
                                                        displayExpr={"code"}
                                                        valueExpr={"code"}
                                                        value={returnSelectedValue("FromCustomer")}
                                                        OnCustomSelectionChanged={(e) => {
                                                            updateParamValue("FromCustomer", e.value.code, e.value.id);
                                                        }}
                                                        dataSourceURL={"api/Utilities/GetCustomers"}
                                                        displayText={returnSelectedValue("FromCustomer")}
                                                        sortByColumn={false}
                                                        onDataSourceChanged={setFromCustomerList}>
                                                        <Column dataField='code' elementAttr={{ dataField: "CustomerCode" }} visible={true} caption='Customer Code' alignment='left' allowGrouping={false} />
                                                        <Column dataField='name' elementAttr={{ dataField: "CustomerName" }} visible={true} caption='Customer Name' alignment='left' allowGrouping={false} />
                                                        <Column dataField='forexCode' elementAttr={{ dataField: "CustomerForexCode" }} visible={true} caption='Currency' alignment='left' allowGrouping={false} />
                                                    </CustomizedLookup>
                                                </div>
                                                <div>
                                                    <label>To</label>
                                                    <CustomizedLookup
                                                        ref={toCustomerLookupRef}
                                                        dataSource={toCustomerList.filter((item, index) => index >= fromCustomerList.indexOf(fromCustomerList.find(e=>e.code == paramValue.find(e=>e.ParameterName == 'FromCustomer')?.ParameterValue)))}
                                                        displayExpr={"code"}
                                                        valueExpr={"code"}
                                                        value={returnSelectedValue("ToCustomer")}
                                                        OnCustomSelectionChanged={(e) => {
                                                            updateParamValue("ToCustomer", e.value.code, e.value.id);
                                                        }}
                                                        dataSourceURL={"api/Utilities/GetCustomers"}
                                                        displayText={returnSelectedValue("ToCustomer")}
                                                        sortByColumn={false}
                                                        onDataSourceChanged={setToCustomerList}>
                                                        <Column dataField='code' elementAttr={{ dataField: "CustomerCode" }} visible={true} caption='Customer Code' alignment='left' allowGrouping={false} />
                                                        <Column dataField='name' elementAttr={{ dataField: "CustomerName" }} visible={true} caption='Customer Name' alignment='left' allowGrouping={false} />
                                                        <Column dataField='forexCode' elementAttr={{ dataField: "CustomerForexCode" }} visible={true} caption='Currency' alignment='left' allowGrouping={false} />
                                                    </CustomizedLookup>
                                                </div>
                                            </div>
                                            <div>
                                                <Button
                                                    style={{ position: "absolute", top: "65%", left: "75%" }}
                                                    text='Clear Filter'
                                                    stylingMode='text'
                                                    type='default'
                                                    onClick={() => {
                                                        removeParamValue("CustomerList");
                                                        removeParamValue("FromCustomer");
                                                        removeParamValue("ToCustomer");
                                                        setSelectedCustomer(0);
                                                    }}
                                                    elementAttr={{ dataField: "FilterNumber" }}
                                                />
                                            </div>
                                        </div>
                                    </Popover>
                                </TextBox>
                            </div>
                        )}
                        {selectedCustomer === 2 && (
                            <div>
                                <TextBox
                                    value={returnSelectedKeys("Customer").length !== 0 ? `${returnSelectedKeys("Customer").length} Selected  (${returnSelectedKeys("Customer")})` : `${returnSelectedKeys("Customer").length} Selected`}
                                    style={{ marginTop: "-35px" }}
                                    height={0}
                                    showClearButton={false}
                                    width={"400px"}>
                                    <DropDownBox
                                        // onClosed={(e) => closeDropDownBox(e, "Customer")}
                                        ref={dropdownRef}
                                        // opened={openCustSpecific}
                                        valueExpr='id'
                                        deferRendering={false}
                                        displayExpr='code'
                                        showClearButton={false}
                                        contentRender={(e) => DataGridMultiRender("Customer")}
                                        defaultOpened={true}
                                        width={"400px"}
                                        dropDownOptions={{ width: "auto" }}
                                    />
                                </TextBox>
                            </div>
                        )}
                    </div>

                    <div className='popup-group-form-item'>
                        <div className='popup-group-form-label'>Supplier :</div>
                        {selectedSupplier === 0 && (
                            <SelectBox
                                width={"400px"}
                                dataSource={filterTypes}
                                value={selectedSupplier}
                                displayExpr={"Type"}
                                valueExpr={"ID"}
                                elementAttr={{ dataField: "FilterNumber" }}
                                onValueChanged={(e) => {
                                    valueOnChange(e, "Supplier");
                                }}
                            />
                        )}
                        {selectedSupplier === 1 && (
                            <div onClick={() => setOpenSupplierRange(true)}>
                                <TextBox
                                    value={returnSelectedValue("FromSupplier") || returnSelectedValue("ToSupplier") ? `${returnSelectedValue("FromSupplier")} to ${returnSelectedValue("ToSupplier")}` : "No Range Selected Yet"}
                                    // useMaskBehavior={true} // Enable mask behavior
                                    // mask="cccccc" // Define the input mask
                                    id='Supplier'
                                    style={{ marginRight: "10px" }}
                                    width={"400px"}>
                                    <Popover
                                        target='#Supplier'
                                        showEvent={"click"}
                                        // hideEvent="mouseleave"
                                        position='bottom'
                                        visible={openSupplierRange}
                                        onHiding={(e) => popFunction(e, "Supplier")}
                                        dragEnabled={false}
                                        hideOnOutsideClick={true}
                                        width={500}
                                        height={125}>
                                        <div>
                                            <div className='popup-form-item-container2'>
                                                <div>
                                                    <label>From</label>
                                                    <CustomizedLookup
                                                        ref={fromSupplierLookupRef}
                                                        dataSource={fromSupplierList.filter((item, index) => {
                                                         let toIndex = toSupplierList.indexOf(toSupplierList.find(e=>e.code == paramValue.find(e=>e.ParameterName == 'ToSupplier')?.ParameterValue));   
                                                         if (toIndex == -1) {
                                                            return true;
                                                         }
                                                           return index <=  toIndex;
                                                        }
                                                        )}
                                                        displayExpr={"code"}
                                                        valueExpr={"code"}
                                                        value={returnSelectedValue("FromSupplier")}
                                                        OnCustomSelectionChanged={(e) => {
                                                            updateParamValue("FromSupplier", e.value.code,e.value.id);
                                                        }}
                                                        sortByColumn={false}
                                                        dataSourceURL={"api/Utilities/GetSuppliers"}
                                                        displayText={returnSelectedValue("FromSupplier")}
                                                        onDataSourceChanged={setFromSupplierList}>
                                                        <Column dataField='code' elementAttr={{ dataField: "SupplierCode" }} visible={true} caption='Supplier Code' alignment='left' allowGrouping={false} />
                                                        <Column dataField='name' elementAttr={{ dataField: "SupplierName" }} visible={true} caption='Supplier Name' alignment='left' allowGrouping={false} />
                                                        <Column dataField='forexCode' elementAttr={{ dataField: "SupplierForexCode" }} visible={true} caption='Currency' alignment='left' allowGrouping={false} />
                                                    </CustomizedLookup>
                                                </div>
                                                <div>
                                                    <label>To</label>
                                                    <CustomizedLookup
                                                        ref={toSupplierLookupRef}
                                                        className={"ar-listing-datagrid"}
                                                        dataSource={toSupplierList.filter((item, index) => index >= fromSupplierList.indexOf(fromSupplierList.find(e=>e.code == paramValue.find(e=>e.ParameterName == 'FromSupplier')?.ParameterValue)))}
                                                        displayExpr={"code"}
                                                        valueExpr={"code"}
                                                        sortByColumn={false}
                                                        value={returnSelectedValue("ToSupplier")}
                                                        OnCustomSelectionChanged={(e) => {
                                                            updateParamValue("ToSupplier", e.value.code,e.value.id);
                                                        }}
                                                        dataSourceURL={"api/Utilities/GetSuppliers"}
                                                        displayText={returnSelectedValue("ToSupplier")}
                                                        onDataSourceChanged={setToSupplierList}>
                                                        <Column dataField='code' elementAttr={{ dataField: "SupplierCode" }} visible={true} caption='Supplier Code' alignment='left' allowGrouping={false} />
                                                        <Column dataField='name' elementAttr={{ dataField: "SupplierName" }} visible={true} caption='Supplier Name' alignment='left' allowGrouping={false} />
                                                        <Column dataField='forexCode' elementAttr={{ dataField: "SupplierForexCode" }} visible={true} caption='Currency' alignment='left' allowGrouping={false} />
                                                    </CustomizedLookup>
                                                </div>
                                            </div>
                                            <div>
                                                <Button
                                                    style={{ position: "absolute", top: "65%", left: "75%" }}
                                                    text='Clear Filter'
                                                    stylingMode='text'
                                                    type='default'
                                                    onClick={() => {
                                                        removeParamValue("SupplierList");
                                                        removeParamValue("FromSupplier");
                                                        removeParamValue("ToSupplier");
                                                        setSelectedSupplier(0);
                                                    }}
                                                    elementAttr={{ dataField: "FilterNumber" }}
                                                />
                                            </div>
                                        </div>
                                    </Popover>
                                </TextBox>
                            </div>
                        )}
                        {selectedSupplier === 2 && (
                            <div>
                                <TextBox
                                    value={returnSelectedKeys("Supplier").length !== 0 ? `${returnSelectedKeys("Supplier").length} Selected  (${returnSelectedKeys("Supplier")})` : `${returnSelectedKeys("Supplier").length} Selected`}
                                    style={{ marginTop: "-35px" }}
                                    height={0}
                                    showClearButton={false}
                                    width={"400px"}>
                                    <DropDownBox
                                        // onClosed={(e) => closeDropDownBox(e, "Supplier")}
                                        ref={dropdownRef}
                                        valueExpr='id'
                                        deferRendering={false}
                                        displayExpr='code'
                                        showClearButton={false}
                                        contentRender={(e) => DataGridMultiRender("Supplier")}
                                        defaultOpened={true}
                                        width={"400px"}
                                        dropDownOptions={{ width: "auto" }}
                                    />
                                </TextBox>
                            </div>
                        )}
                    </div>

                </div>


                <div className='stockFilterMessage-popup-footer'>
                    <Button type='success' stylingMode='contained' text='Confirm' onClick={() => handleSubmit()} className='button-custom' />
                    <Button type='success' stylingMode='outlined' text='Cancel' onClick={() => onClose()} className='button-custom' />
                </div>


            </Popup>

            <ErrorPopUpForm ref={popupMessageRef} />
        </div>
    );
})

export default AdvancedFilter;