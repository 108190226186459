import { useState, useEffect, useRef, useCallback } from "react";
import PopupForm from "../../../components/popup-form/PopupFormV2";
import baseapi from "../../../api/baseapi";
import utils from "../../../utils/common";
import { Column, Summary, TotalItem } from "devextreme-react/data-grid";
import { invoiceReportNames, uomOptions } from "../../../utils/lookupstore";
import { NumberBox } from "devextreme-react/number-box";
import { CheckBox } from "devextreme-react/check-box";
import { DateBox } from "devextreme-react/date-box";
import { TextBox } from "devextreme-react/text-box";
import { TextArea } from "devextreme-react";
import { loading, closeLoading } from "../../../utils/common";
import CustomizedLookup from "../../../components/lookup/Lookup";
import CustomizedGridLookup from "../../../components/lookup/GridLookup";
import { moduleCalculation, ParentValueCalculation, processDecimalRowUpData } from "../../../utils/module-calculation";
import ChildrenDatagrid from "../../../components/children-datagrid/ChildrenDatagrid";
import { Validator, RequiredRule as ValidatorRequired } from "devextreme-react/validator";
import AsyncTextBox from "../../../components/async-text-box/AsyncTextBox";
import ErrorPopUpForm from "../../../components/popup-form/ErrorPopupForm";
import DataGridNumberAsyncBox from "../../../components/datagrid-async-box/DataGridNumberAsyncBox";
import AsyncDateBox from "../../../components/async-date-box/AsyncDateBox";
import { DocumentSettings } from "../../../utils/documentSettings";
import DocumentNumberLookup from "../../../components/lookup/DocumentNoLookup";
export default function SalesInvoiceMainForm(props) {
	const [formValue, setFormValue] = useState({});
	const [isEditing, setIsEditing] = useState(false);
	const [startValidation, setStartValidation] = useState(0);
	const formRef = useRef(null);
	const tempForm = useRef(null);
	const formID = useRef(null);
	const formRead = useRef(false);
	const [lockedForm, setLockedForm] = useState(false);
	const [locationList, setLocationList] = useState([]);
	const childrenGridRef = useRef(null);
	const customerLookupRef = useRef(null);
	const salesmanLookupRef = useRef(null);
	const forexLookupRef = useRef(null);
	const areaLookupRef = useRef(null);
	const [gridLookupOpen, setGridLookupOpen] = useState(false);
	const projectLookupRef = useRef(null);
	const [reportDocumentNo, setReportDocumentNo] = useState(null);
	const [stockCodeDisabled, setStockCodeDisabled] = useState(false);
	const [defaultValue, setDefaultValue] = useState({});
	const popupMessageRef = useRef(null);
	const [isTransferred, setIsTransferred] = useState(false);
	useEffect(() => {
		if (!utils.isNullOrEmpty(formValue["DocumentNo"])) {
			setReportDocumentNo(formValue["DocumentNo"]);
		}
	}, [formValue["DocumentNo"]]);

	useEffect(() => {
		if (props.formID !== null) {
			formID.current = props.formID;
			utils.popupFormOpen(formRef);

			if (formID.current !== "new") {
				setIsEditing(true);
			} else {
				setIsEditing(false);
			}
		}
	}, [props.formID]);

	useEffect(() => {
		if (formRead.current) {
			let copied = { ...tempForm.current };
			const copiedDetails = utils.childrenGridGetSource(childrenGridRef);
			var balance = 0;

			for (var i = 0; i < Object.keys(copiedDetails).length; i++) {
				if (copiedDetails[i]["NettAmount"] === undefined) {
					balance += parseFloat(copiedDetails[i]["ForexAmount"]);
				} else {
					balance += parseFloat(copiedDetails[i]["NettAmount"]);
				}
			}

			// Perform calculations and updates for children data.
			if (copiedDetails.length > 0) {
				const newChildrenData = utils.roundUpObjectProperties(moduleCalculation(copiedDetails, copied));

				// Perform calculations for parent data.
				const calculationResult = ParentValueCalculation(newChildrenData, copied);

				// Set copied data as calculation result, also set the MainLocalAmount field.
				copied = calculationResult.parent;
				// Set the updated data back to the grid
				utils.childrenGridSetSource(childrenGridRef, newChildrenData);
			}

			copied["MainLocalAmount"] = balance * formValue["LocalRate"];

			tempForm.current = copied;

			setFormValue(tempForm.current);
		}
	}, [formValue["LocalRate"]])

	function handleSubmit(e) {
		if (!utils.isNullOrEmpty(e.validData)) {
			const validData = e.validData;

			// Set document number prefix
			DocumentSettings.setDocumentPrefix(formValue["DocumentPrefix"]);

			const submittedForm = {
				Parent: formValue,
				Children: validData["Children"]
			};

			loading(`Saving ${props.title}...`);

			baseapi
				.httppost(
					utils.extendUrlVar("/api/SalesInvoiceMain/save", {
						id: formID.current || "",
						del: false
					}),
					submittedForm
				)
				.then((response) => {
					const { data } = response;
					utils.popupFormSuccessfulSubmit(formRef, data);
					utils.popupFormSetErrorForm(formRef, {
						visible: true,
						message: data.message,
						type: data.status ? "Success" : "Danger",
						action: data.action
					});
					if (data.status) {
						if (typeof props.onSubmitSuccess === 'function') {
							props.onSubmitSuccess();
						}
					}
					if (data.docNo) setReportDocumentNo(data.docNo);
				});

		}
	}

	function onInitialized(e) {
		if (formID.current === "new") loading(`Loading New ${props.title}...`);
		else loading(`Loading Existing ${props.title}...`);


		// Clear previous children
		baseapi.httpget("/api/SalesInvoiceMain/Get", { id: formID.current || "new", documentNumberPrefix: DocumentSettings.getDocumentPrefix() || "" }).then((response) => {
			const { data } = response;

			utils.popupFormAfterInitialization(formRef, props, data);

			//Set Default Values
			if (!utils.isNullOrEmpty(data.defaultValues)) {
				setDefaultValue(data.defaultValues);
			}

			tempForm.current = data.model.Parent;

			if (formID.current === "new") {
				//If there is transfer data
				if (!utils.isNullOrEmpty(props.transferData)) {
					const transferData = props.transferData;
					const parent = transferData["Parent"];
					setIsTransferred(true);

					if (parent.IsFullTransferred || parent.IsPartialTransferred) {
						tempForm.current = transferData["Parent"];
						tempForm.current["DocumentNo"] = data.model.Parent["DocumentNo"];
						tempForm.current["DocumentDate"] = data.model.Parent["DocumentDate"];
						tempForm.current["IssueBy"] = data.model.Parent["IssueBy"];
						tempForm.current["TransactionTypeID"] = data.model.Parent["TransactionTypeID"];
						tempForm.current["DocumentPrefix"] = data.model.Parent["DocumentPrefix"];
					}

					//Assignment of default value
					tempForm.current["TransferType"] = transferData["Parent"]["TransferType"];
					tempForm.current["IsFullTransferred"] = transferData["Parent"]["IsFullTransferred"];
					tempForm.current["IsPartialTransferred"] = transferData["Parent"]["IsPartialTransferred"];
					tempForm.current["NextDocNo"] = data.model.Parent["NextDocNo"];
					tempForm.current["DocumentTypeID"] = data.model.Parent["DocumentTypeID"];

					if (!utils.isNullOrEmpty(transferData["Children"])) {
						//Assign children chart account id based on stock group id
						if (Array.isArray(transferData["Children"]) && Array.isArray(transferData["stockGroupDetails"])) {
							for (var i = 0; i < transferData["Children"].length; i++) {
								const record = transferData["Children"][i];
								const foundStockGroup = transferData["stockGroupDetails"].find(c => c.ID === record.ID);

								if (!utils.isNullOrEmpty(foundStockGroup)) {
									record["ChartAccountID"] = foundStockGroup.SalesCodeID;
									record["ChartAccountCode"] = foundStockGroup.SalesCode;
								}

								if (utils.isNullOrEmpty(record["LocationID"])) {
									record["LocationID"] = utils.popupFormGetDefaultValues(formRef)["LocationID"];
									record["LocationCode"] = utils.popupFormGetDefaultValues(formRef)["LocationCode"];
								}
							}
						}
						// Perform business logic calculations on children data.
						const fomulatedChildren = moduleCalculation(transferData["Children"], tempForm.current);

						// Perform business logic calculations on parent data.
						tempForm.current = ParentValueCalculation(transferData["Children"], tempForm.current).parent;

						// Set the children grid data source to the formulated
						// children data.
						utils.childrenGridSetSource(childrenGridRef, fomulatedChildren);
					}

					utils.popupFormSetErrorForm(formRef, {
						visible: true,
						message: transferData["status"] ? "Data Transfered Successful" : "Data Transfered Unsuccessful",
						type: transferData["status"] ? "Success" : "Danger"
					});

					//Clear transfer data
					if (!utils.isNullOrEmpty(props.clearTransfer)) {
						props.clearTransfer(null);
					}
				} else {
					// Add a new row to the children data grid if new document
					// is not a transfer.
					utils.childrenGridAddRow(childrenGridRef);
				}
			} else {
				// console.log("Form", data)

				// Set the children grid data source to the response Children object.
				utils.childrenGridSetSource(childrenGridRef, data.model.Children);
			}

			setFormValue(tempForm.current);
			closeLoading();
			formRead.current = true;
		});
	}

	const onRowUpdating = (e) => {
		const oldValue = { ...e.oldData };
		const newValue = e.newData;
		const currentRow = Object.assign(oldValue, newValue);
		const parentValue = { ...formValue };
		if (!utils.checkBalanceQuantity(e.oldData, e.newData, formRef)) {
			return;
		}
		e.newData = moduleCalculation(currentRow, parentValue, utils.popupFormGetDefaultValues(formRef)["QuantityFormat"], utils.popupFormGetDefaultValues(formRef)["UnitCostFormat"], utils.popupFormGetDefaultValues(formRef)["UnitPriceFormat"]);
	};

	const onRowUpdated = (e, children = null) => {
		const childrenDetails = children !== null ? children : utils.childrenGridGetSource(childrenGridRef);
		const ParentValue = { ...tempForm.current };
		const calculationResult = ParentValueCalculation(childrenDetails, ParentValue);

		tempForm.current = calculationResult.parent;
		setFormValue(tempForm.current);
		utils.childrenGridSetSource(childrenGridRef, calculationResult.children);
	};

	function valueOnChange(e, receivedDataField = null) {
		try {
			const dataField = receivedDataField !== null ? receivedDataField : e.element.getAttribute("dataField");
			// console.log("I am in value changed", e, "data field", dataField)
			const copied = { ...tempForm.current };
			copied[dataField] = e.value;
			tempForm.current = copied;
			setFormValue(tempForm.current);

			if (!utils.isNullOrEmpty(e.value)) {
				if (dataField === "CustomerID" && formRead.current) {
					baseapi.httpget(`api/Customer/Get`, { id: e.value }).then((response) => {
						const { data } = response;

						const copied = { ...tempForm.current };
						copied["CustomerName"] = data.model.Name;
						copied["CustomerCode"] = data.model.Code;
						copied["Address"] = data.model.CustomerAddress;
						copied["SalesmanID"] = data.model.SalesmanID;
						copied["SalesmanCode"] = data.model.SalesmanCode;
						copied["ForexID"] = data.model.ForexID;
						copied["CreditTermsID"] = data.model.CreditTermID;
						copied["CreditTermsCode"] = data.model.CreditTermCode;
						copied["CreditLimit"] = data.model.CreditLimit;
						copied["OutstandingBalance"] = data.model.OutstandingBalance;
						copied["CreditTermsDays"] = data.model.CreditTermDays;
						copied["AreaID"] = data.model.AreaID;
						copied["AreaCode"] = data.model.AreaCode;

						baseapi
							.httpget(`/api/Forex/Get`, { id: data.model.ForexID })
							.then((response) => {
								const { data } = response;
								copied["LocalRate"] = data.model.LocalRate;
								copied["ForexRate"] = data.model.ForexRate;
								copied["ForexCode"] = data.model.Code

								tempForm.current = copied;
								setFormValue(tempForm.current);
							})
							.catch((error) => {
								console.log(error);
							});
					});
				} else if (dataField === "ForexID" && formRead.current) {
					baseapi
						.httpget(`/api/Forex/Get`, { id: e.value })
						.then((response) => {
							const { data } = response;
							const copied = { ...tempForm.current };
							copied["LocalRate"] = data.model.LocalRate;
							copied["ForexRate"] = data.model.ForexRate;
							copied["ForexCode"] = data.model.Code

							tempForm.current = copied;
							setFormValue(tempForm.current);
						})
						.catch((error) => {
							console.log(error);
						});
				} else if (dataField == "CreditTermsID" && formRead.current) {
					copied[dataField] = e.value;
					tempForm.current = copied;

					setFormValue(tempForm.current);

					baseapi.httpget("/api/CreditTerms/Get", { id: e.value }).then((res) => {
						const { data } = res;
						const copied = { ...tempForm.current };
						copied["CreditTermsDays"] = data.model.Day;

						tempForm.current = copied;
						setFormValue(tempForm.current);
					});
				}
			}
		} catch (err) {
			console.log(err);
		}
	}

	const onInitNew = () => {
		return {
			Line: utils.childrenGridLength(childrenGridRef) + 1,
			Quantity: 0,
			UnitPrice: 0,
			NettPrice: 0,
			NettAmount: 0,
			ItemDiscountAmt: 0,
			ProjectID: utils.popupFormGetDefaultValues(formRef)["ProjectID"],
			ProjectCode: utils.popupFormGetDefaultValues(formRef)["ProjectCode"],
			ChartAccountID: utils.popupFormGetDefaultValues(formRef)["ChartAccountID"],
			ChartAccountCode: utils.popupFormGetDefaultValues(formRef)["ChartAccountCode"],
			DetailLN: 0,
			Point: 0,
			LocationID: utils.popupFormGetDefaultValues(formRef)["LocationID"],
			LocationCode: utils.popupFormGetDefaultValues(formRef)["LocationCode"],
			PrintPrice: false,
			Confirmation: false,
			Show: false,
			BOM: false,
			ProductionNo: "",
			Details: false,
			GroupUp: 0,
			GroupAmt: 0,
			BaseRate: 0.0,
			PriceDisplay: false,
			DeliveryNo: "",
			DODate: "2023/12/1",
		};
	};

	// Function to run when closing the form.
	const clearForm = () => {
		props.closePopup(null);
		setIsTransferred(false);
		formRead.current = false;
		tempForm.current = {};
		setFormValue(tempForm.current);
		setReportDocumentNo(null);
	};

	const gridOnSelectionChanged = (e, columnName) => {
		setGridLookupOpen(false);
		const currentFocusCellIndex = utils.childrenGridGetFocusCellIndex(childrenGridRef);
		const currentRecords = utils.childrenGridGetSource(childrenGridRef);
		const rowIndex = currentFocusCellIndex.rowIndex;
		const key = currentFocusCellIndex.rowID;
		const recordIndex = currentRecords.findIndex(record => record.ID === key);
		const selectedItem = e.selectedItem;

		const dataSource = e.dataSource;
		currentRecords[recordIndex][columnName] = e.value;

		if (columnName === "StockID") {
			currentRecords[recordIndex]["StockCode"] = selectedItem?.code;
			currentRecords[recordIndex]["Description"] = selectedItem?.name;
			currentRecords[recordIndex]["Description2"] = selectedItem?.description2;
			currentRecords[recordIndex]["MoreDescription"] = selectedItem?.moreDescription;
			currentRecords[recordIndex]["UnitPrice"] = selectedItem?.price1;
			currentRecords[recordIndex]["UOMID"] = selectedItem?.uomID;
			currentRecords[recordIndex]["UOMCode"] = selectedItem?.uomCode;
			currentRecords[recordIndex]["ChartAccountID"] = selectedItem?.salesChartAccountID;
			currentRecords[recordIndex]["ChartAccountCode"] = selectedItem?.salesChartAccountCode;
		} else if (columnName === "TaxCodeID") {
			currentRecords[recordIndex]["TaxRate"] = selectedItem?.rate ?? 0;
			currentRecords[recordIndex]["TaxCode"] = selectedItem?.code;
		} else if (columnName === "ProjectID") {
			currentRecords[recordIndex]["ProjectCode"] = selectedItem?.code;
		} else if (columnName === "ChartAccountID") {
			currentRecords[recordIndex]["ChartAccountCode"] = selectedItem?.code;
		}
		else if (columnName === "LocationID") {
			currentRecords[recordIndex]["LocationCode"] = selectedItem?.code;
		}

		e["key"] = key;
		e["data"] = currentRecords[recordIndex];
		onRowUpdated(e, currentRecords);
	};

	// Format display text for the totals.
	const calculateDataGridTotal = useCallback((data) => {
		return data.valueText.replace("Sum: ", "");
	}, []);

	const onFocusedCellChanged = (e) => {
		const rowData = e.rowData;

		if (!utils.isNullOrEmpty(rowData["IsTransferred"]) && rowData["IsTransferred"] === true) {
			setStockCodeDisabled(true);
		} else {
			setStockCodeDisabled(false);
		}
	};

	return (
		<div>
			<PopupForm
				ref={formRef}
				childrenGridRef={childrenGridRef}
				ReportDocumentNo={reportDocumentNo}
				onClosing={clearForm}
				fullScreen={false}
				width={"95%"}
				height={"90%"}
				disableButtons={lockedForm === true}
				headerClassName={"sales-module-form-title-bg popup-form-title"}
				creditChecking={false}
				apiURL={props.apiURL}
				title={props.title}
				lockFormOnChanged={setLockedForm}
				onShown={onInitialized}
				onSuccessfulSubmit={({ stay }) => {
					if (stay) onInitialized();
				}}
				requiredColumns={{
					Children: ["StockID", "ChartAccountID", "LocationID", "ProjectID"]
				}}
				filterRules={{
					Children: [
						{ name: "StockID", rules: [{ name: "Required" }] },
						{ name: "Description", rules: [{ name: "Required" }] },
						{ name: "Description2", rules: [{ name: "Required" }] },
						{ name: "Quantity", rules: [{ name: "Min", value: 1 }] }
					]
				}}
				copyFields={{
					Parent: formValue,
					Children: utils.childrenGridGetSource(childrenGridRef)
				}}
				onPasteForm={(e) => {
					tempForm.current = e.Parent;
					setFormValue(e.Parent);
				}}
				copyStorage={"SalesInvoiceMainCopiedData"}
				formData={formValue}
				setFormValue={(newValues) => {
					tempForm.current = { ...tempForm.current, ...newValues };
					setFormValue((prevState) => ({ ...prevState, ...newValues }));
				}}
				defaultSelectedReport={"Wdt2wi2WxzI%3d"}
				reportURL={invoiceReportNames.url}
				onValidating={setStartValidation}
				startValidation={startValidation}
				isEditing={isEditing}
				lookupValidateRef={[customerLookupRef, forexLookupRef, projectLookupRef, areaLookupRef, salesmanLookupRef]}
				onSaving={handleSubmit}
				cancelDocumentCustomization={
					<span className='popup-form-header-cancel'>
						<CheckBox
							value={formValue["Cancelled"]}
							elementAttr={{ dataField: "Cancelled" }}
							onValueChanged={(e) => {
								valueOnChange(e);
							}}
							alignment='left'
							width={"12px"}
							height={"12px"}
							iconSize={"12px"}
							readOnly={lockedForm === true}
							className='popup-header-cancel-input'
						/>

						<span className='popup-header-cancel-text'>Cancelled</span>
					</span>
				}>
				<div className='popup-form-main-content'>
					<div className='sales-module-parent-section custom-form-grid-section1'>
						<div className=''>
							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Customer Code </div>

								<div className='popup-group-form-input'>
									<CustomizedLookup
										ref={customerLookupRef}
										className={"ar-listing-datagrid"}
										displayExpr={"code"}
										valueExpr={"id"}
										value={formValue["CustomerID"]}
										onSelectionChanged={(e) => {
											valueOnChange(e, "CustomerID");
										}}
										startValidation={startValidation !== 0}
										required={true}
										requiredMessage={"Customer Code is required!"}
										dataSourceURL={"api/Utilities/GetCustomers"}
										displayText={formValue["CustomerCode"]}
										readOnly={lockedForm === true}
										disabled={isTransferred}
									>
										<Column dataField='code'></Column>
										<Column dataField='name' caption={"Customer Name"}></Column>
										<Column dataField='forexCode' caption='Currency'></Column>
									</CustomizedLookup>
								</div>
							</div>

							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Customer Name </div>

								<div className='popup-group-form-input'>
									<TextBox
										value={formValue["CustomerName"]}
										elementAttr={{ dataField: "CustomerName" }}
										onValueChanged={(e) => {
											valueOnChange(e);
										}}
										alignment='left'
										width={"auto"}
										readOnly={lockedForm === true}>
										<Validator>{startValidation !== 0 && <ValidatorRequired message={"Customer Name is required!"} />}</Validator>
									</TextBox>
								</div>
							</div>

							<div className='popup-group-form-item group-form-item-align-top'>
								<div className='popup-group-form-label'>Address </div>

								<div className='popup-group-form-input'>
									<TextArea
										value={formValue["Address"]}
										elementAttr={{ dataField: "Address" }}
										onValueChanged={(e) => {
											valueOnChange(e);
										}}
										alignment='left'
										width={"auto"}
										height={100}
										readOnly={lockedForm === true}>
										{/* <Validator>{startValidation !== 0 && <ValidatorRequired message={"Delivery Address is required!"} />}</Validator> */}
									</TextArea>
								</div>
							</div>
						</div>

						<div className='popup-form-item-container2'>
							<div className='popup-group-form-item'>
								<div className='popup-group-form-label next-doc-label'>Invoice No </div>

								<div className='popup-group-form-input'>
									<div className='next-doc-display'>Next Doc {formValue["NextDocNo"]}</div>
									<DocumentNumberLookup
										formValue={formValue}
										setFormValue={setFormValue}
										tempForm={tempForm}
										formID={formID}
										lockedForm={lockedForm}
										valueOnChange={valueOnChange}
									/>
								</div>
							</div>

							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Date </div>

								<div className='popup-group-form-input'>
									<AsyncDateBox
										value={formValue["DocumentDate"]}
										elementAttr={{ dataField: "DocumentDate" }}
										onValueChanged={(e) => {
											valueOnChange(e);
										}}
										alignment='left'
										width={"auto"}
										displayFormat='dd/MM/yyyy'
										dateSerializationFormat='yyyy-MM-dd'
										useMaskBehavior={true}
										readOnly={lockedForm === true}
										asyncURL={"api/Utilities/CheckAfterFiscalYear"}
										asyncMessage={"Date cannot set before current fiscal year"}
										asyncProperty={"inputDate"}
									/>
								</div>
							</div>

							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Project </div>

								<div className='popup-group-form-input' id='customized-lookup1'>
									<CustomizedLookup
										ref={projectLookupRef}
										className={"ar-listing-datagrid"}
										displayExpr={"code"}
										valueExpr={"id"}
										value={formValue["ProjectID"]}
										onSelectionChanged={(e) => {
											valueOnChange(e, "ProjectID");
										}}
										startValidation={startValidation !== 0}
										required={true}
										requiredMessage={"Project is required!"}
										dataSourceURL={"api/Utilities/GetProjects"}
										readOnly={lockedForm === true}
										displayText={formValue["ProjectCode"]}
									>
										<Column dataField='code'></Column>
										<Column dataField='name' caption={"Project Name"}></Column>
									</CustomizedLookup>
								</div>
							</div>

							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>D/O No </div>

								<div className='popup-group-form-input'>
									<TextBox
										value={formValue["DONo"]}
										elementAttr={{ dataField: "DONo" }}
										onValueChanged={(e) => {
											valueOnChange(e);
										}}
										alignment='left'
										width={"auto"}
										readOnly={lockedForm === true}
									/>
								</div>
							</div>

							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Remarks 1 </div>

								<div className='popup-group-form-input'>
									<TextBox
										value={formValue["Remarks"]}
										elementAttr={{ dataField: "Remarks" }}
										onValueChanged={(e) => {
											valueOnChange(e);
										}}
										alignment='left'
										width={"auto"}
										readOnly={lockedForm === true}
									/>
								</div>
							</div>

							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Remarks 2 </div>

								<div className='popup-group-form-input'>
									<TextBox
										value={formValue["Remarks2"]}
										elementAttr={{ dataField: "Remarks2" }}
										onValueChanged={(e) => {
											valueOnChange(e);
										}}
										alignment='left'
										width={"auto"}
										readOnly={lockedForm === true}
									/>
								</div>
							</div>

							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Salesman </div>

								<div className='popup-group-form-input'>
									<CustomizedLookup
										ref={salesmanLookupRef}
										className={"ar-listing-datagrid"}
										displayExpr={"code"}
										valueExpr={"id"}
										value={formValue["SalesmanID"]}
										onSelectionChanged={(e) => {
											valueOnChange(e, "SalesmanID");
										}}
										startValidation={startValidation !== 0}
										required={true}
										requiredMessage={"Salesman is required!"}
										dataSourceURL={"api/Utilities/GetSalesmans"}
										readOnly={lockedForm === true}
										displayText={formValue["SalesmanCode"]}
									>
										<Column dataField='code'></Column>
										<Column dataField='name'></Column>
									</CustomizedLookup>
								</div>
							</div>

							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Area </div>

								<div className='popup-group-form-input'>
									<CustomizedLookup
										ref={areaLookupRef}
										className={"ar-listing-datagrid"}
										displayExpr={"code"}
										valueExpr={"id"}
										value={formValue["AreaID"]}
										onSelectionChanged={(e) => {
											valueOnChange(e, "AreaID");
										}}
										displayText={formValue["AreaCode"]}
										dataSourceURL={"api/Utilities/GetAreas"}
										readOnly={lockedForm === true}
										startValidation={startValidation !== 0}
										required={true}
										requiredMessage={"Area is required!"}
									>
										<Column dataField='code'></Column>
										<Column dataField='name'></Column>
									</CustomizedLookup>
								</div>
							</div>

							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Terms </div>

								<div className='popup-group-form-input'>
									<CustomizedLookup
										className={"ar-listing-datagrid"}
										displayExpr={"code"}
										valueExpr={"id"}
										value={formValue["CreditTermsID"]}
										onSelectionChanged={(e) => {
											valueOnChange(e, "CreditTermsID");
										}}
										startValidation={startValidation !== 0}
										required={true}
										requiredMessage={"Credit Term is required!"}
										displayText={formValue["CreditTermsCode"]}
										dataSourceURL={"api/Utilities/GetCreditTerms"}
										readOnly={lockedForm === true}
									>
										<Column dataField='code'></Column>
										<Column dataField='name' caption='Days'></Column>
									</CustomizedLookup>
								</div>
							</div>

							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Issue By </div>

								<div className='popup-group-form-input'>
									<TextBox
										value={formValue["IssueBy"]}
										elementAttr={{ dataField: "IssueBy" }}
										onValueChanged={(e) => {
											valueOnChange(e);
										}}
										alignment='left'
										width={"auto"}
										readOnly={true}
									/>
								</div>
							</div>

							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Forex </div>

								<div className='popup-group-form-input'>
									<CustomizedLookup
										ref={forexLookupRef}
										className={"ar-listing-datagrid"}
										displayExpr={"code"}
										valueExpr={"id"}
										value={formValue["ForexID"]}
										displayText={formValue["ForexCode"]}
										onSelectionChanged={(e) => {
											valueOnChange(e, "ForexID");
										}}
										startValidation={startValidation !== 0}
										required={true}
										requiredMessage={"Forex is required!"}
										disabled={true}
										dataSourceURL={"api/Utilities/GetForexs"}
									>
										<Column dataField='code'></Column>
										<Column dataField='name'></Column>
									</CustomizedLookup>
								</div>
							</div>

							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Local Rate </div>

								<div className='popup-group-form-input'>
									<NumberBox
										value={formValue["LocalRate"]}
										elementAttr={{ dataField: "LocalRate" }}
										onValueChanged={(e) => {
											valueOnChange(e);
										}}
										alignment='left'
										width={"auto"}
										format={utils.getDecimalFormat(defaultValue["LocalRateFixCheck"], defaultValue["LocalRateFormat"])}
										readOnly={lockedForm === true || formValue["ForexID"] === defaultValue["ForexID"]}>
										<Validator>{startValidation !== 0 && <ValidatorRequired message={"Local Rate is required!"} />}</Validator>
									</NumberBox>
								</div>
							</div>
						</div>
					</div>

					<div className='children-datagrid-container sales-module-children-datagrid' style={{ padding: "0 0" }}>
						<ChildrenDatagrid
							ref={childrenGridRef}
							name={"Children"}
							defaultColumnValues={onInitNew}
							lookupFields={["StockID", "ProjectID", "ChartAccountID", "TaxCodeID"]}
							allowColumnReordering={true}
							allowColumnResizing={true}
							keyExpr='ID'
							showBorders={true}
							onRowUpdating={onRowUpdating}
							onRowUpdated={onRowUpdated}
							onRowRemoved={onRowUpdated}
							gridLookupOpen={setGridLookupOpen}
							readOnly={lockedForm === true}
							storageName={"SalesInvoiceChildrenGrid"}
							onParentValueChanged={valueOnChange}
							defaultDescription={formValue["Description"]}
							disabled={lockedForm === true}
							onFocusedCellChanged={onFocusedCellChanged}
							height={"auto"}>
							<Column dataField='Line' dataType='number' editorOptions={{ readOnly: true }} caption={"No"} />

							<Column
								visible={true}
								dataField='StockID'
								dataType='string'
								cellRender={(e) => {
									const data = e.data;
									return data.StockCode;
								}}
								editCellComponent={(component) => (
									<CustomizedGridLookup
										{...component}
										opened={gridLookupOpen}
										className={"ar-listing-datagrid"}
										onSelectionChanged={(e) => gridOnSelectionChanged(e, "StockID")}
										dataSourceURL={"/api/Utilities/GetStock"}
										disabled={stockCodeDisabled}
									>
										<Column dataField='code'></Column>
										<Column dataField='name' caption={"Description"} width={"300px"}></Column>
									</CustomizedGridLookup>
								)}
							/>

							<Column dataField='Description' />

							<Column dataField='Description2' />

							<Column visible={true} dataField='MoreDescription' dataType='string' isHtmlEditor={true} />

							<Column
								visible={true}
								dataField='ProjectID'
								dataType='string'
								cellRender={(e) => {
									const data = e.data;
									return data.ProjectCode;
								}}
								editCellComponent={(component) => (
									<CustomizedGridLookup
										{...component}
										opened={gridLookupOpen}
										className={"ar-listing-datagrid"}
										onSelectionChanged={(e) => gridOnSelectionChanged(e, "ProjectID")}
										dataSourceURL={"/api/Utilities/GetProjects"}
									>
										<Column dataField='code'></Column>
										<Column dataField='name' caption={"Description"} width={"300px"}></Column>
									</CustomizedGridLookup>
								)}></Column>

							<Column
								visible={true}
								dataField='Quantity'
								dataType='number'
								format={utils.getDecimalFormat(defaultValue["QuantityFixCheck"], defaultValue["QuantityFormat"])}
							/>

							<Column
								visible={true}
								dataField='UOMID'
								cellRender={(e) => {
									const data = e.data;
									return data.UOMCode;
								}}
								calculateCellValue={(data) => data?.UOMCode}
								editorOptions={{ readOnly: true }}
							/>

							<Column visible={true} dataField='ItemDiscount' dataType='number' />

							<Column visible={true} dataField='UnitPrice' dataType='number' format={utils.getDecimalFormat(defaultValue["UnitPriceFixCheck"], defaultValue["UnitPriceFormat"])} />

							<Column visible={true} dataField='NettPrice' dataType='number' format={{ type: 'fixedPoint', precision: 2 }} editorOptions={{ readOnly: true }} />

							<Column
								visible={true}
								dataField='TaxCodeID'
								cellRender={(e) => {
									const data = e.data;
									return data.TaxCode;
								}}
								editCellComponent={(component) => (
									<CustomizedGridLookup
										{...component}
										opened={gridLookupOpen}
										className={"ar-listing-datagrid"}
										onSelectionChanged={(e) => gridOnSelectionChanged(e, "TaxCodeID")}
										dataSourceURL={"api/Utilities/GetGSTRate"}
									>
										<Column dataField='code'></Column>
										<Column dataField='name' caption={"Description"} width={"300px"}></Column>
									</CustomizedGridLookup>
								)}></Column>

							<Column visible={true} dataField='TaxRate' width={"auto"} dataType='number' editorOptions={{ readOnly: true }} />

							<Column visible={true} dataField='TaxInclusive' dataType='boolean' value={false} />

							<Column visible={true} dataField='ForexTaxable' dataType='number' editorOptions={{ readOnly: true }} format='#,##0.00' />

							<Column visible={true} dataField='ForexTaxAmount' dataType='number' editorOptions={{ readOnly: true }} format='#,##0.00' />

							<Column visible={true} dataField='ForexTaxablePlusTax' dataType='number' editorOptions={{ readOnly: true }} format='#,##0.00' />

							<Column
								visible={true}
								dataField='ChartAccountID'
								dataType='string'
								caption='Chart Account'
								cellRender={(e) => {
									const data = e.data;
									return data.ChartAccountCode;
								}}
								editCellComponent={(component) => (
									<CustomizedGridLookup
										{...component}
										opened={gridLookupOpen}
										className={"ar-listing-datagrid"}
										onSelectionChanged={(e) => gridOnSelectionChanged(e, "ChartAccountID")}
										dataSourceURL={"api/Utilities/GetGLChartAccounts"}
										mergeParams={{ chartAccountType: "Sales" }}
									>
										<Column dataField='code'></Column>
										<Column dataField='name' caption={"Description"} width={"300px"}></Column>
									</CustomizedGridLookup>
								)}></Column>

							<Column visible={true} dataField='ImportDocumentNo' caption={"From Doc No"} />

							<Column visible={false} dataField='ItemDiscountAmt' dataType='number' />

							<Column
								visible={false}
								dataField='LocationID'
								caption="Location"
								dataType='string'
								cellRender={(e) => {
									const data = e.data;
									return data.LocationCode;
								}}
								editCellComponent={(component) => (
									<CustomizedGridLookup
										{...component}
										opened={gridLookupOpen}
										className={"ar-listing-datagrid"}
										onSelectionChanged={(e) => gridOnSelectionChanged(e, "LocationID")}
										dataSourceURL={"api/Utilities/GetLocations"}
										onDataSourceChanged={setLocationList}
										sourceList={locationList}>
										<Column dataField='code'></Column>
										<Column dataField='name' caption={"Description"} width={"300px"}></Column>
									</CustomizedGridLookup>
								)}></Column>

							<Column visible={false} dataField='PartNo' />

							<Column visible={false} dataField='BrandCode' />

							<Column visible={false} dataField='LinePrint' />

							<Column visible={false} dataField='PrintPrice' dataType='boolean' />

							<Column visible={false} dataField='Confirmation' dataType='boolean' />

							<Column visible={false} dataField='Show' dataType='boolean' />

							<Column visible={false} dataField='SalesType' />

							<Column visible={false} dataField='PriceCheckID' />

							<Column visible={false} dataField='SalesCode' />

							<Column visible={false} dataField='OrderType' />

							<Column visible={false} dataField='BOM' dataType='boolean' />

							<Column visible={false} dataField='ProductionNo' />

							<Column visible={false} dataField='Details' dataType='boolean' />

							<Column visible={false} dataField='UniCost' dataType='number' format='#,##0.00' />

							<Column visible={false} dataField='ModelCode' />

							<Column visible={false} dataField='BinShelfNo' />

							<Column visible={false} dataField='SerialNo' />

							<Column visible={false} dataField='Project' />

							<Column visible={false} dataField='GroupNo' />

							<Column visible={false} dataField='GroupUp' dataType='number' format='#,##0.00' />

							<Column visible={false} dataField='GroupAmt' dataType='number' caption='Group Amount' format='#,##0.00' />

							<Column visible={false} dataField='BaseRate' dataType='number' format='#,##0.00' />

							<Column visible={false} dataField='PriceDisplay' dataType='boolean' />

							<Column visible={false} dataField='ForexTaxAmountOrignal' dataType='number' format='#,##0.00' />

							<Column visible={false} dataField='ForexTaxAdjust' dataType='number' format='#,##0.00' />

							<Column visible={false} dataField='ForexAmount' dataType='number' format='#,##0.00' />

							<Column visible={false} dataField='LocalTaxable' dataType='number' format='#,##0.00' />

							<Column visible={false} dataField='LocalTaxAmount' dataType='number' format='#,##0.00' />

							<Column visible={false} dataField='LocalTaxAmountOrignal' dataType='number' format='#,##0.00' />

							<Column visible={false} dataField='LocalTaxAdjust' dataType='number' editorOptions={{ readOnly: true }} format='#,##0.00' />

							<Column visible={false} dataField='LocalTaxablePlusTax' dataType='number' format='#,##0.00' />

							<Column visible={false} dataField='NettAmount' dataType='number' format='#,##0.00' />

							<Column visible={false} dataField='LocalAmount' dataType='number' format='#,##0.00' />

							<Summary recalculateWhileEditing={true}>
								<TotalItem column='Quantity' summaryType='sum' customizeText={calculateDataGridTotal} />

								<TotalItem column='ForexAmount' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

								<TotalItem column='ForexTaxable' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

								<TotalItem column='ForexTaxAmountOrignal' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

								<TotalItem column='ForexTaxAdjust' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

								<TotalItem column='ForexTaxablePlusTax' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

								<TotalItem column='LocalTaxable' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

								<TotalItem column='LocalTaxAmount' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

								<TotalItem column='LocalTaxAmountOrignal' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

								<TotalItem column='LocalTaxAdjust' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

								<TotalItem column='LocalTaxablePlusTax' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

								<TotalItem column='NettLocalAmount' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

								<TotalItem column='LocalAmount' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

								<TotalItem column='NettPrice' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

								<TotalItem column='ForexTaxAmount' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

								<TotalItem column='TotalAmount' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

								<TotalItem column='UniCost' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

								<TotalItem column='NettAmount' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

								<TotalItem column='GroupUp' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

								<TotalItem column='GroupAmt' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

								<TotalItem column='BaseRate' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

								<TotalItem column='LocalAmount' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />
							</Summary>
						</ChildrenDatagrid>
					</div>

					<div className='sales-module-total-section custom-form-grid-section2'>
						<div className='popup-group-form-item'>
							<div className='popup-group-form-label'>Local Total </div>

							<div className='popup-group-form-input' style={{ width: "150px" }}>
								<NumberBox
									value={formValue["MainLocalAmount"]}
									elementAttr={{ dataField: "MainLocalAmount" }}
									onValueChanged={(e) => {
										valueOnChange(e);
									}}
									alignment='left'
									width={"auto"}
									readOnly={true}
									format='#,##0.00'>
									{/* <Validator
                            >
                                <ValidatorRequired message={"Local Amount is required!"} />
                            </Validator> */}
								</NumberBox>
							</div>
						</div>

						<div className='popup-form-item-container2 custom-form-item-container2'>
							<div></div>

							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Total </div>

								<div className='popup-group-form-input'>
									<NumberBox
										value={formValue["MainForexAmount"]}
										elementAttr={{ dataField: "MainForexAmount" }}
										onValueChanged={(e) => {
											valueOnChange(e);
										}}
										alignment='left'
										width={"auto"}
										readOnly={true}
										format='#,##0.00'>
										<Validator>{startValidation !== 0 && <ValidatorRequired message={"Company Name is required!"} />}</Validator>
									</NumberBox>
								</div>
							</div>

							<div className='popup-group-form-item'>
								<div className='popup-group-form-label' style={{ width: "80px" }}>
									Rounding{" "}
								</div>

								<div className='popup-group-form-input' style={{ paddingBottom: "7px" }}>
									<CheckBox
										value={formValue["Rounding"]}
										elementAttr={{ dataField: "Rounding" }}
										onValueChanged={(e) => {
											valueOnChange(e);
										}}
										alignment='left'
										width={"auto"}
										readOnly={(lockedForm === true) === true}
									/>
								</div>
							</div>

							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Outstanding </div>

								<div className='popup-group-form-input'>
									<NumberBox
										value={formValue["OutstandingAmount"]}
										elementAttr={{ dataField: "OutstandingAmount" }}
										onValueChanged={(e) => {
											valueOnChange(e);
										}}
										alignment='left'
										width={"auto"}
										readOnly={true}
										format='#,##0.00'>
										{/* <Validator
                                >
                                    <ValidatorRequired message={"Outstanding is required!"} />
                                </Validator> */}
									</NumberBox>
								</div>
							</div>
						</div>
					</div>
				</div>
			</PopupForm>

			<ErrorPopUpForm ref={popupMessageRef} />
		</div>
	);
}
