import { useState, useRef, useEffect, forwardRef, useImperativeHandle, useCallback } from "react";
import { TextBox, DateBox, NumberBox } from "devextreme-react";
import AsyncTextBox from "../../../../components/async-text-box/AsyncTextBox";
import CustomizedLookup from "../../../../components/lookup/Lookup";
import CustomizedGridLookup from "../../../../components/lookup/GridLookup";
import utils from "../../../../utils/common";
import DataGrid, { Column, KeyboardNavigation, ColumnChooser, Editing, Summary, Lookup, TotalItem, Toolbar, Item as ToolbarItem } from "devextreme-react/data-grid";
import { Validator, RequiredRule as ValidatorRequired } from "devextreme-react/validator";
import { Button } from "devextreme-react";
import ChildrenDatagrid from "../../../../components/children-datagrid/ChildrenDatagrid";
import { moduleCalculation, ParentValueCalculation } from "../../../../utils/module-calculation";
import baseapi from "../../../../api/baseapi";
import AsyncDateBox from "../../../../components/async-date-box/AsyncDateBox";
import { DocumentSettings } from "../../../../utils/documentSettings";
import DocumentNumberLookup from "../../../../components/lookup/DocumentNoLookup";
export default forwardRef(function PaymentVoucherForm(props, ref) {
	const [formValue, setFormValue] = useState({});
	const [gridLookupOpen, setGridLookupOpen] = useState(false);
	const [isEditing, setIsEditing] = useState(false);
	const [bankBalance, setBankBalance] = useState(0);
	const [bankID, setBankID] = useState("");

	const tempForm = useRef(null);

	useEffect(() => {
		if (props.onValueChanged !== undefined) {
			props.onValueChanged({ parent: formValue });
		}
	}, [formValue]);

	useImperativeHandle(ref, () => ({
		setValue(value) {
			if (value.BankID !== null && value.BankID !== undefined) {
				setBankID(value.BankID);
			} else {
				setBankID("");
				setBankBalance(0);
			}
			tempForm.current = value;
			setFormValue(tempForm.current);
		},
		setChildren(value) {
			utils.childrenGridSetSource(props.gridRef, value);
		},
		editingForm(value) {
			setIsEditing(value);
		}
	}));

	useEffect(() => {
		if (bankID !== "") {
			baseapi.httpget(`/api/CheckBankBalance/list?chartAccountID=${bankID}`).then((response) => {
				const { data } = response;
				setBankBalance(data.result);
			});
		}
	}, [bankID]);

	function valueOnChange(e, receivedDataField = null) {
		// console.log("I am in value changed", e, "data field", e.element.getAttribute("dataField"))
		try {
			if (e.value !== undefined) {
				const dataField = receivedDataField !== null ? receivedDataField : e.element.getAttribute("dataField");
				const copied = { ...tempForm.current };
				copied[dataField] = e.value;

				if (dataField === "Description" && utils.childrenGridLength(props.gridRef) > 0) {
					tempForm.current = copied;
					setFormValue(tempForm.current);
					updateCalculation({ descriptionChanged: true });
				} else if (dataField === "ForexID" && utils.childrenGridLength(props.gridRef) > 0) {
					// console.log(e.value);
					baseapi.httpget(`/api/Forex/Get`, { id: e.value }).then((response) => {
						const { data } = response;
						// console.log(data);
						copied["LocalRate"] = data.model.LocalRate;
						copied["ForexRate"] = data.model.ForexRate;
						copied["MainLocalAmount"] = data.model.LocalRate * copied["MainLocalAmount"];

						tempForm.current = copied;
						setFormValue(tempForm.current);
					});
				} else if (dataField === "LocalRate" && utils.childrenGridLength(props.gridRef) > 0) {
					tempForm.current = copied;
					setFormValue(tempForm.current);
					updateCalculation({});
				} else {
					tempForm.current = copied;
					setFormValue(tempForm.current);
				}
			}
		} catch (err) {
			console.log(err);
		}
	}

	const onRowUpdating = (e) => {
		const oldValue = { ...e.oldData };
		const newValue = e.newData;
		const currentRow = Object.assign(oldValue, newValue);
		const parentValue = { ...formValue };
		e.newData = utils.roundUpObjectProperties(moduleCalculation(currentRow, parentValue));
	};

	const onRowUpdated = (e, children = null) => {
		const childrenDetails = children !== null ? children : utils.childrenGridGetSource(props.gridRef);
		const ParentValue = { ...tempForm.current };
		for (var i = 0; i < childrenDetails.length; i++) {
			childrenDetails[i]["Description"] = utils.isNullOrEmpty(childrenDetails[i]["Description"]) ? ParentValue["Description"] : childrenDetails[i]["Description"];
		}
		const calculationResult = ParentValueCalculation(childrenDetails, ParentValue);
		tempForm.current = calculationResult.parent;
		setFormValue(tempForm.current);
		utils.childrenGridSetSource(props.gridRef, calculationResult.children);
	};

	const updateCalculation = ({ descriptionChanged = null, children = null }) => {
		const ParentValue = { ...tempForm.current };
		const childrenCopied = children ? children : utils.childrenGridGetSource(props.gridRef);
		const array = [];

		for (var i = 0; i < childrenCopied.length; i++) {
			let rowData = childrenCopied[i];

			rowData = utils.roundUpObjectProperties(moduleCalculation(rowData, tempForm.current));
			rowData["Description"] = utils.isNullOrEmpty(rowData["Description"]) || descriptionChanged ? tempForm.current["Description"] : rowData["Description"];

			array.push(rowData);
		}

		// ParentValue["TotalLocalTaxAmount"] = TotalDebitTaxAmount;
		// ParentValue["TotalLocalTaxable"] = TotalTaxAmount;
		// ParentValue["MainLocalAmount"] = localTotalAmount;

		tempForm.current = ParentValue;
		setFormValue(tempForm.current);
		utils.childrenGridSetSource(props.gridRef, array);
	};

	const gridOnSelectionChanged = (e, columnName) => {
		setGridLookupOpen(false);
		const currentFocusCellIndex = utils.childrenGridGetFocusCellIndex(props.gridRef);
		const currentRecords = utils.childrenGridGetSource(props.gridRef);
		const rowIndex = currentFocusCellIndex.rowIndex;
		const key = currentFocusCellIndex.rowID;
		const recordIndex = currentRecords.findIndex(record => record.ID === key)
		const selectedItem = e.selectedItem;
		currentRecords[recordIndex][columnName] = e.value;

		if (columnName === "ChartAccountID") {
			currentRecords[recordIndex]["GLDescription"] = selectedItem?.name;
			currentRecords[recordIndex]["ChartAccountCode"] = selectedItem?.code;
		} else if (columnName === "TaxCodeID") {
			currentRecords[recordIndex]["TaxRate"] = selectedItem?.rate ?? 0;
			currentRecords[recordIndex]["TaxCode"] = selectedItem?.code;
		} else if (columnName === "ProjectID") {
			currentRecords[recordIndex]["ProjectCode"] = selectedItem?.code;
		}

		e["key"] = key;
		e["data"] = currentRecords[recordIndex];
		onRowUpdated(e, currentRecords);
	};

	const onInitNew = () => {
		return {
			Line: utils.childrenGridLength(props.gridRef) + 1,
			ProjectID: utils.popupFormGetDefaultValues(props.formRef)["ProjectID"],
			ProjectCode: utils.popupFormGetDefaultValues(props.formRef)["ProjectCode"],
			ForexAmount: 0
		};
	};

	const calculateDataGridTotal = useCallback((data) => {
		return data.valueText.replace(`Sum: ${data.value}`, utils.numberToCurrency(data.value));
	}, []);

	return (
		<div className='popup-form-main-content'>
			<div className='custom-form-grid-section4'>
				<div className='popup-form-item-container1'>
					<div className='popup-group-form-item'>
						<div className='popup-group-form-label'>Pay To </div>

						<div className='popup-group-form-input'>
							<CustomizedLookup
								ref={props.lookupRef.payToLookupRef}
								className={"ar-listing-datagrid"}
								displayExpr={"name"}
								valueExpr={"name"}
								value={formValue["PayTo"]}
								onSelectionChanged={(e) => {
									valueOnChange(e, "PayTo");
								}}
								startValidation={props.startValidation !== 0}
								required={true}
								requiredMessage={"Pay To is required!"}
								disabled={props.lockedForm === true}
								dataSourceURL={"api/Utilities/GetGLPayTo"}
								acceptCustomValue={true}
							>
								<Column dataField='name' caption={"Customer Name"}></Column>
							</CustomizedLookup>
						</div>
					</div>

					<div className='popup-group-form-item group-form-item-align-top'>
						<div className='popup-group-form-label align-top-item-label'>Cash / Bank </div>

						<div className='popup-group-form-input'>
							<CustomizedLookup
								ref={props.lookupRef.bankLookupRef}
								className={"ar-listing-datagrid"}
								displayExpr={"name"}
								valueExpr={"id"}
								value={formValue["BankID"]}
								onSelectionChanged={(e) => {
									valueOnChange(e, "BankID");
									setBankID(e.value);
								}}
								startValidation={props.startValidation !== 0}
								required={true}
								requiredMessage={"Cash / Bank is required!"}
								disabled={props.lockedForm === true}
								dataSourceURL={"api/Utilities/GetGLBKAndCSAccount"}
								displayText={formValue["BankName"]}
							>
								<Column dataField='code'></Column>
								<Column dataField='name' caption={"Bank Name"}></Column>
							</CustomizedLookup>
							<div className='next-doc-display'>Bank Balance : {utils.numberToCurrency(bankBalance)}</div>
						</div>
					</div>

					<div className='popup-group-form-item'>
						<div className='popup-group-form-label'>Project </div>

						<div className='popup-group-form-input' id='customized-lookup1'>
							<CustomizedLookup
								ref={props.lookupRef.projectLookupRef}
								className={"ar-listing-datagrid"}
								displayExpr={"code"}
								valueExpr={"id"}
								value={formValue["ProjectID"]}
								onSelectionChanged={(e) => {
									valueOnChange(e, "ProjectID");
								}}
								startValidation={props.startValidation !== 0}
								required={true}
								requiredMessage={"Project is required!"}
								dataSourceURL={"api/Utilities/GetProjects"}
								disabled={props.lockedForm === true}
								displayText={formValue["ProjectCode"]}>
								<Column dataField='code'></Column>
								<Column dataField='name' caption={"Project Name"}></Column>
							</CustomizedLookup>
						</div>
					</div>
				</div>

				<div className='popup-form-item-container1'>
					<div className='popup-form-item-container2'>
						<div className='popup-group-form-item'>
							<div className='popup-group-form-label next-doc-label'>Voucher No </div>

							<div className='popup-group-form-input'>
								<div className='next-doc-display'>Next Doc {formValue["NextDocNo"]}</div>
								<DocumentNumberLookup
									formValue={formValue}
									setFormValue={setFormValue}
									tempForm={tempForm}
									formID={props.formID}
									lockedForm={props.lockedForm}
									valueOnChange={valueOnChange}
									isEditing = {isEditing}
								/>
							</div>
						</div>

						<div className='popup-group-form-item'>
							<div className='popup-group-form-label'>Date </div>

							<div className='popup-group-form-input'>
								<AsyncDateBox
									value={formValue["DocumentDate"]}
									elementAttr={{ dataField: "DocumentDate" }}
									onValueChanged={(e) => {
										valueOnChange(e);
									}}
									alignment='left'
									width={"auto"}
									displayFormat='dd/MM/yyyy'
									dateSerializationFormat='yyyy-MM-dd'
									useMaskBehavior={true}
									readOnly={props.lockedForm === true}
									asyncURL={"api/Utilities/CheckAfterFiscalYear"}
									asyncMessage={"Date cannot set before current fiscal year"}
									asyncProperty={"inputDate"}
								/>
							</div>
						</div>
					</div>

					<div className='popup-form-item-container1'>
						<div className='popup-group-form-item'>
							<div className='popup-group-form-label'>Description </div>

							<div className='popup-group-form-input'>
								<TextBox
									value={formValue["Description"]}
									elementAttr={{ dataField: "Description" }}
									onValueChanged={(e) => {
										valueOnChange(e, "Description");
									}}
									alignment='left'
									width={"auto"}
									disabled={props.lockedForm === true}>
									<Validator>{props.startValidation !== 0 && <ValidatorRequired message={"Description is required!"} />}</Validator>
								</TextBox>
							</div>
						</div>
					</div>

					<div className='popup-form-item-container2'>
						<div className='popup-group-form-item group-form-item-align-top'>
							<div className='popup-group-form-label align-top-item-label'>Cheque No </div>

							<div className='popup-group-form-input'>
								<TextBox
									value={formValue["ChequeNo"]}
									elementAttr={{ dataField: "ChequeNo" }}
									onValueChanged={(e) => {
										valueOnChange(e, "ChequeNo");
									}}
									alignment='left'
									width={"auto"}
									disabled={props.lockedForm === true}
								/>
							</div>
						</div>

						<div className='popup-group-form-item group-form-item-align-top'>
							<div className='popup-group-form-label align-top-item-label'>Bank Charge </div>

							<div className='popup-group-form-input'>
								<NumberBox
									value={formValue["BankCharge"]}
									elementAttr={{ dataField: "BankCharge" }}
									onValueChanged={(e) => {
										valueOnChange(e, "BankCharge");
									}}
									alignment='left'
									width={"auto"}
									disabled={props.lockedForm === true}
									format='#,##0.00'>
									<Validator>{props.startValidation !== 0 && <ValidatorRequired message={"Bank Charge is required!"} />}</Validator>
								</NumberBox>
							</div>
						</div>
					</div>

					{/* <div className='popup-form-item-container2'>
						<div className='popup-group-form-item'>
							<div className='popup-group-form-label'>Salesman: </div>

							<div className='popup-group-form-input'>
								<CustomizedLookup
									ref={props.lookupRef.salesmanLookupRef}
									className={"ar-listing-datagrid"}
									displayExpr={"code"}
									valueExpr={"id"}
									value={formValue["SalesmanID"]}
									onSelectionChanged={(e) => {
										valueOnChange(e, "SalesmanID");
									}}
									dataSourceURL={"api/Utilities/GetSalesmans"}
									displayText={formValue["SalesmanCode"]}
									readOnly={props.lockedForm === true}
									startValidation={props.startValidation !== 0}
									required={true}
									requiredMessage={"Salesman is required!"}>
									<Column dataField='code'></Column>
									<Column dataField='name'></Column>
								</CustomizedLookup>
							</div>
						</div>

						<div className='popup-group-form-item'>
							<div className='popup-group-form-label'>Area: </div>

							<div className='popup-group-form-input'>
								<CustomizedLookup
									ref={props.lookupRef.areaLookupRef}
									className={"ar-listing-datagrid"}
									displayExpr={"code"}
									valueExpr={"id"}
									value={formValue["AreaID"]}
									onSelectionChanged={(e) => {
										valueOnChange(e, "AreaID");
									}}
									displayText={formValue["AreaCode"]}
									dataSourceURL={"api/Utilities/GetAreas"}
									readOnly={props.lockedForm === true}
									startValidation={props.startValidation !== 0}
									required={true}
									requiredMessage={"Area is required!"}
								>
									<Column dataField='code'></Column>
									<Column dataField='name'></Column>
								</CustomizedLookup>
							</div>
						</div>
					</div> */}

					<div className='popup-form-item-container2'>
						<div className='popup-group-form-item'>
							<div className='popup-group-form-label'>Forex Code </div>

							<div className='popup-group-form-input'>
								<CustomizedLookup
									ref={props.lookupRef.forexLookupRef}
									className={"ar-listing-datagrid"}
									displayExpr={"code"}
									valueExpr={"id"}
									value={formValue["ForexID"]}
									onSelectionChanged={(e) => {
										valueOnChange(e, "ForexID");
									}}
									startValidation={props.startValidation !== 0}
									required={true}
									requiredMessage={"Forex Code is required!"}
									disabled={props.lockedForm === true}
									dataSourceURL={"api/Utilities/GetForexs"}
									displayText={formValue["ForexCode"]}>
									<Column dataField='code'></Column>
									<Column dataField='name' caption={"Customer Name"}></Column>
								</CustomizedLookup>
							</div>
						</div>

						<div className='popup-group-form-item'>
							<div className='popup-group-form-label'>Local Rate </div>

							<div className='popup-group-form-input'>
								<NumberBox
									value={formValue["LocalRate"]}
									elementAttr={{ dataField: "LocalRate" }}
									onValueChanged={(e) => {
										valueOnChange(e, "LocalRate");
									}}
									alignment='left'
									format={utils.getDecimalFormat(props.defaultValue["LocalRateFixCheck"], props.defaultValue["LocalRateFormat"])}
									width={"auto"}
									readOnly={formValue["ForexID"] === props.defaultValue["ForexID"]}>
									<Validator>{props.startValidation !== 0 && <ValidatorRequired message={"Local Rate is required!"} />}</Validator>
								</NumberBox>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className='popup-form-grid-container'>
				<ChildrenDatagrid
					ref={props.gridRef}
					name={"Children"}
					className={"gl-listing-datagrid"}
					keyExpr='ID'
					lookupFields={["ProjectID", "ChartAccountID", "TaxCodeID"]}
					showBorders={true}
					allowColumnReordering={true}
					allowColumnResizing={true}
					defaultColumnValues={onInitNew}
					onRowUpdating={onRowUpdating}
					onRowUpdated={onRowUpdated}
					onRowRemoved={onRowUpdated}
					gridLookupOpen={setGridLookupOpen}
					disabled={props.lockedForm === true}
					onParentValueChanged={valueOnChange}
					storageName={"GLPaymentVoucherChildrenGrid"}
					enabledDescription={false}
					height={"auto"}>
					<Column dataField={"Line"} />
					<Column
						visible={true}
						dataField='ChartAccountID'
						dataType='string'
						cellRender={(e) => {
							const data = e.data;
							return data.ChartAccountCode;
						}}
						editCellComponent={(component) => (
							<CustomizedGridLookup
								{...component}
								opened={gridLookupOpen}
								className={"ar-listing-datagrid"}
								onSelectionChanged={(e) => gridOnSelectionChanged(e, "ChartAccountID")}
								dataSourceURL={"api/Utilities/GetGLChartAccounts"}
								mergeParams={{ chartAccountType: "GL" }}
							>
								<Column dataField='code'></Column>
								<Column dataField='name' caption={"Description"} width={"300px"}></Column>
							</CustomizedGridLookup>
						)}></Column>
					<Column dataField={"Description"} />
					<Column dataField={"GLDescription"} allowEditing={false} />
					<Column
						visible={true}
						dataField='ProjectID'
						dataType='string'
						cellRender={(e) => {
							const data = e.data;
							return data.ProjectCode;
						}}
						editCellComponent={(component) => (
							<CustomizedGridLookup
								{...component}
								opened={gridLookupOpen}
								className={"ar-listing-datagrid"}
								onSelectionChanged={(e) => gridOnSelectionChanged(e, "ProjectID")}
								dataSourceURL={"/api/Utilities/GetProjects"}
							>
								<Column dataField='code'></Column>
								<Column dataField='name' caption={"Description"} width={"300px"}></Column>
							</CustomizedGridLookup>
						)}
						editorOptions={{ readOnly: true }}></Column>
					<Column dataField={"ForexAmount"} />
					<Column dataField={"LocalAmount"} allowEditing={false} />
					<Column
						visible={true}
						dataField='TaxCodeID'
						caption='Tax Code'
						cellRender={(e) => {
							const data = e.data;
							return data.TaxCode;
						}}
						editCellComponent={(component) => (
							<CustomizedGridLookup
								{...component}
								opened={gridLookupOpen}
								className={"workshop-listing-datagrid"}
								onSelectionChanged={(e) => gridOnSelectionChanged(e, "TaxCodeID")}
								dataSourceURL={"api/Utilities/GetGSTRate"}
							>
								<Column dataField='code'></Column>
								<Column dataField='name' caption={"Description"} width={"300px"}></Column>
							</CustomizedGridLookup>
						)}></Column>
					<Column visible={true} dataField='TaxRate' dataType='number' />
					<Column visible={true} dataField='TaxInclusive' dataType='boolean' />
					<Column visible={true} dataField='ForexTaxAmount' />
					<Column visible={true} dataField='ForexTaxablePlusTax' />
					<Summary recalculateWhileEditing={true}>
						<TotalItem column='ForexAmount' summaryType='sum' customizeText={calculateDataGridTotal} />

						<TotalItem column='LocalAmount' summaryType='sum' customizeText={calculateDataGridTotal} />

						<TotalItem column='ForexTaxAmount' summaryType='sum' customizeText={calculateDataGridTotal} />

						<TotalItem column='ForexTaxablePlusTax' summaryType='sum' customizeText={calculateDataGridTotal} />
					</Summary>
				</ChildrenDatagrid>
			</div>

			{/* <div className='popup-form-total-container'>
				<div className="popup-form-item-container1">
                    <div className="popup-group-form-item form-item-justify-right">
                        <div className='popup-group-form-label'>Total: </div>

                        <div className="popup-group-form-input" style={{width: "250px"}}>
                            <NumberBox
                                format="#,##0.00"
                                value={formValue["OutstandingAmount"]}                                                                                                                     
                                elementAttr={{dataField : "OutstandingAmount"}} 
                                onValueChanged={(e) => {valueOnChange(e)}} 
                                alignment="left"
                                width={"auto"}
                                disabled={true}
                                text={0}
                            >
                            </NumberBox>
                        </div>
                    </div>  
                </div>

                <div className="popup-form-item-container1">
                    <div className="popup-group-form-item form-item-justify-right">
                        <div className='popup-group-form-label'>Total Forex: </div>

                        <div className="popup-group-form-input" style={{width: "250px"}}>
                            <NumberBox
                                format="#,##0.00"
                                value={formValue["OutstandingAmount"]}                                                                                                                     
                                elementAttr={{dataField : "OutstandingAmount"}} 
                                onValueChanged={(e) => {valueOnChange(e)}} 
                                alignment="left"
                                width={"auto"}
                                disabled={true}
                                text={0}
                            >
                            </NumberBox>
                        </div>
                    </div>  
                </div>

                <div className="popup-form-item-container1">
                    <div className="popup-group-form-item form-item-justify-right">
                        <div className='popup-group-form-label'>Total Tax Amount: </div>

                        <div className="popup-group-form-input" style={{width: "250px"}}>
                            <NumberBox
                                format="#,##0.00"
                                value={formValue["OutstandingAmount"]}                                                                                                                     
                                elementAttr={{dataField : "OutstandingAmount"}} 
                                onValueChanged={(e) => {valueOnChange(e)}} 
                                alignment="left"
                                width={"auto"}
                                disabled={true}
                                text={0}
                            >
                            </NumberBox>
                        </div>
                    </div>  
                </div>

				<div className='popup-form-item-container1'>
					<div className='popup-group-form-item form-item-justify-right'>
						<div className='popup-group-form-label'>Total Taxable: </div>

						<div className='popup-group-form-input' style={{ width: "250px" }}>
							<NumberBox
								format='#,##0.00'
								value={formValue["TotalTaxable"]}
								elementAttr={{ dataField: "TotalTaxable" }}
								onValueChanged={(e) => {
									valueOnChange(e);
								}}
								alignment='left'
								width={"auto"}
								disabled={true}
								text={0}></NumberBox>
						</div>
					</div>
				</div>
			</div> */}
		</div>
	);
});
