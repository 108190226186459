import Container from "../../../components/container";
import DataGrid, { Column, Button as ButtonColumn, Editing } from "devextreme-react/data-grid";
import { useState, useEffect, useRef } from "react";
import baseapi from "../../../api/baseapi";
import utils, { setData } from "../../../utils/common";
import { loading, closeLoading } from "../../../utils/common";
import PathIndicator from "../../../components/path-indicator/PathIndicator";
import { Button } from "devextreme-react/button";
import { TextBox, NumberBox, SelectBox, DropDownBox, Popover } from "devextreme-react";
import { DateBox } from "devextreme-react/date-box";
import CustomizedLookup from "../../../components/lookup/Lookup";
import { glChartAccountOptions } from "../../../utils/lookupstore";
import ErrorPopUpForm from "../../../components/popup-form/ErrorPopupForm";

export default function StockValue() {
	const [listingDataSource, setListingDataSource] = useState([]);
	const [glChartAccountList, setGLChartAccountList] = useState([]);
	const [details, setDetails] = useState([]);
	const [projectID, setProjectID] = useState("53OsK3yBsKs%3d");
	const [year, setYear] = useState(new Date().getFullYear());
	const [cancelVisible, setCancelVisible] = useState(false);
	const [disableSave, setDisableSave] = useState(true);
	const [enableUpdate, setEnableUpdate] = useState(false);
	const [editVisible, setEditVisible] = useState(true);
	const [projectList, setProjectList] = useState(null);
	const [openingChart, setOpeningChart] = useState(null);
	const [openChartID, setOpenChartID] = useState("jk4cEXLObk8%3d");
	const [openChartName, setOpenChartName] = useState("");
	const [closingChart, setClosingChart] = useState(null);
	const [closeChartID, setCloseChartID] = useState("RNlLWq00S1M%3d");
	const [closeChartName, setCloseChartName] = useState("");
	const [balanceChart, setBalanceChart] = useState(null);
	const [balanceChartID, setBalanceChartID] = useState("wgpQ0lrh1hs%3d");
	const [balanceChartName, setBalanceChartName] = useState("");
	const [id, setID] = useState("");
	const [monthIndex, setMonthIndex] = useState(0);
	const popupMessageRef = useRef(null);
	const [projectDisable, setProjectDisable] = useState(false);
	const [yearDisable, setYearDisable] = useState(false);

	const handleEdit = () => {
		setEnableUpdate(true);
		setDisableSave(false);
		setCancelVisible(true);
		setEditVisible(false);
		setProjectDisable(true);
		setYearDisable(true);
	};

	const handleSubmit = () => {
		// console.log(details);
		loading("Updating Stock Value...");
		baseapi.httppost(utils.extendUrlVar(`/api/GLStockValue/save?id=${id}`), details).then((res) => {
			// console.log(res);
			const { data } = res;

			if (data.status) {
				setDisableSave(true);
				setEnableUpdate(false);
				setCancelVisible(false);
				setEditVisible(true);
				setProjectDisable(false);
				setYearDisable(false);
				refresh();
			}
			utils.displayPopupMessage(popupMessageRef, {
				visible: true,
				message: data.message,
				type: data.status ? "Success" : "Danger",
				action: data.action
			});
			closeLoading();
		});
	};

	const handleCancel = () => {
		setDetails([]);
		setDisableSave(true);
		setEnableUpdate(false);
		setCancelVisible(false);
		setEditVisible(true);
		setMonthIndex(0);
		setProjectDisable(false);
		setYearDisable(false);
	};

	const defaultDisplay = [
		{ MonthName: "January", MonthValue: 0, MonthRemark: "", Index: 1, ModifiedDate: utils.dateboxFormat(new Date()) },
		{ MonthName: "February", MonthValue: 0, MonthRemark: "", Index: 2, ModifiedDate: utils.dateboxFormat(new Date()) },
		{ MonthName: "March", MonthValue: 0, MonthRemark: "", Index: 3, ModifiedDate: utils.dateboxFormat(new Date()) },
		{ MonthName: "April", MonthValue: 0, MonthRemark: "", Index: 4, ModifiedDate: utils.dateboxFormat(new Date()) },
		{ MonthName: "May", MonthValue: 0, MonthRemark: "", Index: 5, ModifiedDate: utils.dateboxFormat(new Date()) },
		{ MonthName: "June", MonthValue: 0, MonthRemark: "", Index: 6, ModifiedDate: utils.dateboxFormat(new Date()) },
		{ MonthName: "July", MonthValue: 0, MonthRemark: "", Index: 7, ModifiedDate: utils.dateboxFormat(new Date()) },
		{ MonthName: "August", MonthValue: 0, MonthRemark: "", Index: 8, ModifiedDate: utils.dateboxFormat(new Date()) },
		{ MonthName: "September", MonthValue: 0, MonthRemark: "", Index: 9, ModifiedDate: utils.dateboxFormat(new Date()) },
		{ MonthName: "October", MonthValue: 0, MonthRemark: "", Index: 10, ModifiedDate: utils.dateboxFormat(new Date()) },
		{ MonthName: "November", MonthValue: 0, MonthRemark: "", Index: 11, ModifiedDate: utils.dateboxFormat(new Date()) },
		{ MonthName: "December", MonthValue: 0, MonthRemark: "", Index: 12, ModifiedDate: utils.dateboxFormat(new Date()) }
	];

	const refresh = () => {
		baseapi
			.httpget(`/api/GLStockValue/list?projectID=${projectID}&year=${year}`)
			.then((response) => {
				const { data } = response;
				if (data !== null) {
					// console.log(data.items);
					if (data.items.length != 0) {
						setID("");
						// console.log(data.items[0].Months);
						setListingDataSource(data.items[0].Months);
						setDetails(() => {
							const combinedObj = data.items[0].Months.reduce((acc, item) => {
								const tempObj = {
									ID: data.items[0].id,
									OpeningStock: openChartID,
									ClosingStock: closeChartID,
									StockAC: balanceChartID,
									Year: year,
									ProjectID: projectID,
									[item.MonthName]: item.MonthValue,
									["Remark" + item.Index]: item.MonthRemark,
									MonthName: item.MonthName,
									ModifiedDate: item.ModifiedDate
								};
								Object.assign(acc, tempObj);
								return acc;
							}, {});
							return combinedObj;
						});
						setID(data.items[0].id);
					} else {
						// console.log(defaultDisplay);
						setListingDataSource(defaultDisplay);
						setDetails(() => {
							const combinedObj = defaultDisplay.reduce((acc, item) => {
								const tempObj = {
									ID: "new",
									OpeningStock: openChartID,
									ClosingStock: closeChartID,
									StockAC: balanceChartID,
									Year: year,
									ProjectID: projectID,
									[item.MonthName]: item.MonthValue,
									["Remark" + item.Index]: item.MonthRemark,
									MonthName: item.MonthName,
									ModifiedDate: item.ModifiedDate
								};
								Object.assign(acc, tempObj);
								return acc;
							}, {});
							return combinedObj;
						});
						setID("new");
					}
				}
			})
			.catch((e) => {
				throw e;
			});
	};

	useEffect(() => {
		glChartAccountOptions.getAll().then((list) => {
			var { data } = list;
			setGLChartAccountList(data);
			// console.log(data.length);
			for (var i = 0; i < data.length; i++) {
				if (openChartID === data[i].id) setOpenChartName(data[i].name);
				if (closeChartID === data[i].id) setCloseChartName(data[i].name);
				if (balanceChartID === data[i].id) setBalanceChartName(data[i].name);
			}
		});
	}, []);

	useEffect(() => {
		baseapi
			.httpget(`/api/GLStockValue/list?projectID=${projectID}&year=${year}`)
			.then((response) => {
				const { data } = response;
				if (data !== null) {
					// console.log(data.items);
					if (data.items.length != 0) {
						setID("");
						// console.log(data.items[0].Months);
						setListingDataSource(data.items[0].Months);
						setDetails(() => {
							const combinedObj = data.items[0].Months.reduce((acc, item) => {
								const tempObj = {
									ID: data.items[0].id,
									OpeningStock: openChartID,
									ClosingStock: closeChartID,
									StockAC: balanceChartID,
									Year: year,
									ProjectID: projectID,
									[item.MonthName]: item.MonthValue,
									["Remark" + item.Index]: item.MonthRemark,
									MonthName: item.MonthName,
									ModifiedDate: item.ModifiedDate
								};
								Object.assign(acc, tempObj);
								return acc;
							}, {});
							return combinedObj;
						});
						setID(data.items[0].id);
					} else {
						// console.log(defaultDisplay);
						setListingDataSource(defaultDisplay);
						setDetails(() => {
							const combinedObj = defaultDisplay.reduce((acc, item) => {
								// console.log(item);
								const tempObj = {
									ID: "new",
									OpeningStock: openChartID,
									ClosingStock: closeChartID,
									StockAC: balanceChartID,
									Year: year,
									ProjectID: projectID,
									[item.MonthName]: item.MonthValue,
									["Remark" + item.Index]: item.MonthRemark,
									MonthName: item.MonthName,
									ModifiedDate: item.ModifiedDate
								};
								Object.assign(acc, tempObj);
								return acc;
							}, {});
							return combinedObj;
						});
						setID("new");
					}
				}
			})
			.catch((e) => {
				throw e;
			});
	}, [year, projectID]);

	useEffect(() => {
		var openChartName = glChartAccountList.find((acc) => acc.id === openChartID)?.name;
		setOpenChartName(openChartName);
	}, [openChartID]);

	useEffect(() => {
		var closeChartName = glChartAccountList.find((acc) => acc.id === closeChartID)?.name;
		setCloseChartName(closeChartName);
	}, [closeChartID]);

	useEffect(() => {
		var balanceChartName = glChartAccountList.find((acc) => acc.id === balanceChartID)?.name;
		setBalanceChartName(balanceChartName);
	}, [balanceChartID]);

	const handleValueChange = (e) => {
		const newYear = e.value ? new Date(e.value).getFullYear() : new Date().getFullYear();
		setYear(newYear);
	};

	return (
		<div>
			<Container>
				<div className='listing-page-title-container' id='stock-value-listing'>
					<span className='datagrid-customized-title'>
						<PathIndicator />
					</span>
				</div>
				<div className='popup-group-form-bottomGroup-stockValue'>
					<div className='popup-group-form-item-stockValue' style={{ width: "55px" }}>
						Project
					</div>
					<div className='popup-group-form-item-stockValue' id='project-value'>
						<CustomizedLookup
							value={projectID}
							displayText={"P0"}
							disabled={projectDisable}
							className={"ar-listing-datagrid ProjectCode"}
							dataSourceURL={"api/Utilities/GetProjects"}
							dropdownClassName={"listing-page-searching-lookup"}
							onDataSourceChanged={setProjectList}
							onSelectionChanged={(e) => setProjectID(e.value)}
							dataSource={projectList}
							displayExpr={"code"}
							valueExpr={"id"}
							height={"36px"}>
							<Column dataField='code'></Column>
							<Column dataField='name' caption={"Model"}></Column>
						</CustomizedLookup>
					</div>
					<div className='popup-group-form-item-stockValue' style={{ width: "40px" }}>
						Year
					</div>
					<div className='popup-group-form-item-stockValue'>
						<DateBox
							className='Year'
							type='date'
							pickerType='calendar'
							disabled={yearDisable}
							displayFormat='yyyy'
							value={new Date(year, 0, 1)} // Set the value as the start of the year
							onValueChanged={handleValueChange}
							calendarOptions={{
								minZoomLevel: "century",
								maxZoomLevel: "decade",
								zoomLevel: "decade"
							}}
							openOnFieldClick={true}
						/>
					</div>
				</div>
				<div className='popup-form-item-container3-stockValue'>
					<div className='popup-group-form-topGroup-stockValue'>
						<div className='popup-group-form-group-stockValue'>
							<div className='popup-group-form-item-stockValue' id='stockValueTitle'>
								<b>P&L</b>&nbsp;&nbsp;&nbsp;Opening Stock A/C
							</div>
							<div className='popup-group-form-item-stockValue' id='chartCode'>
								<CustomizedLookup
									value={openChartID}
									displayText={"60000"}
									className={"ar-listing-datagrid ChartCode"}
									dataSourceURL={"api/Utilities/GetGLOpeningStockAccount"}
									dropdownClassName={"listing-page-searching-lookup"}
									onDataSourceChanged={setOpeningChart}
									onSelectionChanged={(e) => setOpenChartID(e.value)}
									dataSource={openingChart}
									displayExpr={"code"}
									valueExpr={"id"}>
									<Column dataField='code'></Column>
									<Column dataField='name'></Column>
								</CustomizedLookup>
							</div>
							<div className='popup-group-form-item-stockValue'>
								<TextBox className='ChartDesc' value={openChartName} readOnly={true} />
							</div>
						</div>

						<div className='popup-group-form-group-stockValue'>
							<div className='popup-group-form-item-stockValue' id='stockValueTitle'>
								<b>P&L</b>&nbsp;&nbsp;&nbsp;Closing Stock A/C
							</div>
							<div className='popup-group-form-item-stockValue' id='chartCode'>
								<CustomizedLookup
									value={closeChartID}
									displayText={"65000"}
									className={"ar-listing-datagrid ChartCode"}
									dataSourceURL={"api/Utilities/GetGLClosingStockAccount"}
									dropdownClassName={"listing-page-searching-lookup"}
									onDataSourceChanged={setClosingChart}
									onSelectionChanged={(e) => setCloseChartID(e.value)}
									dataSource={closingChart}
									displayExpr={"code"}
									valueExpr={"id"}
									height={"36px"}>
									<Column dataField='code'></Column>
									<Column dataField='name'></Column>
								</CustomizedLookup>
							</div>
							<div className='popup-group-form-item-stockValue'>
								<TextBox className='ChartDesc' value={closeChartName} readOnly={true} />
							</div>
						</div>

						<div className='popup-group-form-group-stockValue'>
							<div className='popup-group-form-item-stockValue' id='stockValueTitle'>
								<b>Balance Sheet</b>&nbsp;&nbsp;&nbsp;Stock A/C
							</div>
							<div className='popup-group-form-item-stockValue' id='chartCode'>
								<CustomizedLookup
									value={balanceChartID}
									displayText={"20000"}
									className={"ar-listing-datagrid ChartCode"}
									dataSourceURL={"api/Utilities/GetGLBalanceStockAccount"}
									dropdownClassName={"listing-page-searching-lookup"}
									onDataSourceChanged={setBalanceChart}
									onSelectionChanged={(e) => setBalanceChartID(e.value)}
									dataSource={balanceChart}
									displayExpr={"code"}
									valueExpr={"id"}
									height={"36px"}>
									<Column dataField='code'></Column>
									<Column dataField='name'></Column>
								</CustomizedLookup>
							</div>
							<div className='popup-group-form-item-stockValue'>
								<TextBox className='ChartDesc' value={balanceChartName} readOnly={true} />
							</div>
						</div>
					</div>
				</div>

				<div className='popup-form-item-container3-stockValue'>
					<div className='popup-group-form-group-stockValue' id='bottom-form-group'>
						<h4 style={{ marginTop: "10px", marginBottom: "-15px" }}>Closing Stock Value</h4>
					</div>
				</div>

				<DataGrid
					className={"gl-listing-datagrid StockValue"}
					showRowLines={true}
					showBorders={true}
					dataSource={listingDataSource}
					listingTitle={"Stock Value"}
					storageName={"GLStockValueListing"}
					sorting={false}
					onEditorPreparing={(e) => {
						// console.log(e);
						setMonthIndex(e.row.dataIndex + 1);
						// if (e.row.data.ChartAccountCode === "" || !e.row.data.CanEdit) {
						// 	e.editorOptions.readOnly = true;
						// }
						// setOpeningBalanceID(e.row.data.GLOpeningBalanceID);
					}}
					onOptionChanged={(e) => {
						// console.log(e.name);
						if (e.name === "editing" && e.fullName === "editing.changes") {
							// console.log(details);
							setDetails((prevDetails) => {
								const newDetails = { ...prevDetails };
								// console.log(newDetails);
								for (var i = 0; i < e.value.length; i++) {
									// console.log(e.value[i]);
									const monthName = e.value[i].key.MonthName;
									const remarkUpdated = e.value[i].data.MonthRemark;
									const valueUpdated = e.value[i].data.MonthValue;

									newDetails[monthName] = valueUpdated ?? 0;
									newDetails["Remark" + monthIndex] = remarkUpdated ?? "";
								}
								// console.log(newDetails);
								return newDetails;
							});
						}
					}}>
					<Column dataField='MonthName' width={"200px"} caption={"Month"} editorOptions={{ readOnly: true }} />
					<Column dataField='MonthValue' width={"400px"} caption={"Stock Value"} alignment={"center"} format={{ type: "fixedPoint", precision: 2 }} dataType={"number"} />
					<Column dataField='MonthRemark' caption={"Remark"} width={"700px"} />
					<Editing allowUpdating={enableUpdate} mode='batch' />
				</DataGrid>

				<div style={{ textAlign: "right", marginTop: "20px" }}>
					<Button visible={cancelVisible} text='Cancel' type='danger' style={{ marginRight: "10px" }} onClick={() => handleCancel()} />
					<Button visible={editVisible} text='Edit' type='success' onClick={() => handleEdit()} id='listing-edit-btn'/>
					<Button text='Save' type='success' disabled={disableSave} onClick={() => handleSubmit()} />
				</div>
			</Container>

			<ErrorPopUpForm ref={popupMessageRef} />
		</div>
	);
}
