import React from 'react';

const TransferStatusCell = ({ hasPartiallyTransferred, hasFullyTransferred }) => {
  if (hasFullyTransferred) {
    return <span style={{ color: "#62C060" }}>Transferred</span>;
  }

  if (hasPartiallyTransferred) {
    return <span style={{ color: "#FFA944" }}>Partial</span>;
  }

  return null; // Show empty text when both are false
};

export default TransferStatusCell;
