import React, { useEffect, useState } from 'react';
import { Column, Lookup, } from 'devextreme-react/data-grid';
import { forexOptions } from '../../../utils/lookupstore';
import SalesQuotationForm from './form';
import Container from '../../../components/container';
import Listing from '../../../components/listing/newListing';
import utils from '../../../utils/common';
import TransferStatusCell from '../../../components/transfer-status-cell/TransferStatusCell';


export default function SalesQuotationListing() {

    const apiURL = `/api/SalesQuotation`;
    const [currentEditID, setCurrentEditID] = useState(null);
    function handleEdit(e) {
        const id = e.row.key;
        setCurrentEditID(id);
    }

    return <Container>
        <div id='normal-listing'>
            <Listing
                className="sales-listing-datagrid"
                apiURL={apiURL}
                listingURL="/api/SalesQuotation/list"
                sortColumn={"DocumentDate"}
                sortOrder={"desc"}
                listingTitle={"Sales Quotation"}
                storageName={"SalesQuotationListingColumnWidth"}
                onAddClick={setCurrentEditID}
                handleEdit={handleEdit}
            >
                <Column dataField="DocumentDate" format="dd/MM/yyyy" dataType={"date"} />
                <Column dataField="DocumentNo" />
                <Column dataField="CustomerCode" />
                <Column dataField="CustomerName" />
                <Column dataField="ForexCode" caption="Forex" />
                <Column dataField="MainForexAmount" caption={"Amount"} format={{ type: "fixedPoint", precision: 2 }} />
                <Column dataField="MainLocalAmount" caption={"Local Amount"} format={{ type: "fixedPoint", precision: 2 }} />
                <Column dataField='OutstandingAmount' format={{ type: "fixedPoint", precision: 2 }} />
                <Column
                    dataField="TransferStatus"
                    caption="Status"
                    cellRender={(cellData) => (
                        <TransferStatusCell
                            hasPartiallyTransferred={cellData.data.HasPartiallyTransferred}
                            hasFullyTransferred={cellData.data.HasFullyTransferred}
                        />
                    )}
                />
            </Listing>
        </div>

        <SalesQuotationForm
            formID={currentEditID}
            closePopup={setCurrentEditID}
            apiURL={apiURL}
            title={"Sales Quotation"}
        />

    </Container>;
}