import React, { useEffect, useState } from 'react';
import { Column, Lookup, } from 'devextreme-react/data-grid';
import { forexOptions } from '../../../utils/lookupstore';
import SalesDebitNoteForm from './form';
import Container from '../../../components/container';
import Listing from '../../../components/listing/newListing';
import EInvoiceMain from "../../../components/e-invoice/EInvoiceMain";

export default function SalesDebitNoteListing() {
    const [currentDetailID, setCurrentDetailID] = useState(null);

    const apiURL = `/api/SalesDebitNote`;
    const [currentEditID, setCurrentEditID] = useState(null);

    function handleEdit(e) {
        const id = e.row.key;
        setCurrentEditID(id);
    }

    function handleEInvoice(e) {
        setCurrentDetailID(e.row.data.DocumentNo);
    }

    return <Container>
        <div id='normal-listing'>
            <Listing
                className="sales-listing-datagrid"
                apiURL={apiURL}
                einvoiceshow={true}
                handleEInvoice={handleEInvoice}
                listingURL="/api/SalesDebitNote/list"
                sortColumn={"DocumentDate"}
                sortOrder={"desc"}
                listingTitle={"Debit Note"}
                storageName={"SalesDebitNoteListingColumnWidth"}
                onAddClick={setCurrentEditID}
                handleEdit={handleEdit}
            >
                <Column dataField="DocumentDate" format="dd/MM/yyyy" dataType={"date"} />
                <Column dataField="DocumentNo" />
                <Column dataField="CustomerCode" />
                <Column dataField="CustomerName" />
                <Column dataField="ForexCode" caption="Forex" />
                <Column dataField="MainForexAmount" caption={"Amount"} format={{ type: "fixedPoint", precision: 2 }} />
                <Column dataField="MainLocalAmount" caption={"Local Amount"} format={{ type: "fixedPoint", precision: 2 }} />
                <Column dataField="OutstandingAmount" caption={"Outstanding Amount"} format={{ type: "fixedPoint", precision: 2 }} />
            </Listing>
        </div>

        <SalesDebitNoteForm
            formID={currentEditID}
            closePopup={setCurrentEditID}
            apiURL={apiURL}
            title={"Debit Note"}
        />

        <EInvoiceMain
            formID={currentDetailID}
            closePopup={setCurrentDetailID}
            apiURL={apiURL}
            invoiceType="03-SD"
            title={"E-Invoice Main"}
        />

    </Container>;
}