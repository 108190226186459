import React, { useState, useEffect, useRef } from 'react';
import { Column, Lookup } from 'devextreme-react/data-grid';
import Container from '../../../components/container';
import Listing from '../../../components/listing/newListing';
import { customerOptions, workshopRefInv, workshopRegistration, workshopTypeOfService } from '../../../utils/lookupstore';
import JobSheetForm from './form';
import DropDownButton from 'devextreme-react/drop-down-button';
import { Button } from 'devextreme-react/button';
import { useNavigate } from "react-router-dom";
import { saveToLS } from '../../../utils/localstorage';
import baseapi from '../../../api/baseapi';
import { loading, done } from '../../../utils/common';
import TransferForm from "../transfer/transfer";
import utils from "../../../utils/common";
export default function JobSheetListing() {

    const navigate = useNavigate();
    const apiURL = `/api/WKJobsheet`;
    const [currentEditID, setCurrentEditID] = useState(null);
    const [inputType, setInputType] = useState(null);
    const [lockForm, setLockForm] = useState(false);
    const transformRef = useRef(null);
    const [transfer, setTransfer] = useState(false);

    function handleEdit(e) {
        const id = e.row.key;
        const data = e.row.data;

        if (data.WKInvoiceID !== "i1tRx0pzXa0%3d") {
            setLockForm(true);
        }
        else {
            setLockForm(false);
        }
        setCurrentEditID(id);
    }


    const addToInvoice = (e) => {
        const rowData = e.data;
        
        // Check if FromInvoiceDocumentNo is empty or null
        if (!rowData.FromInvoiceDocumentNo) {
            return (
                <Button
                    height={"22px"}
                    icon='export'
                    text='Add to Invoice'
                    onClick={() => {
                        loading("Adding To Invoice...");
    
                        baseapi.httpget('/api/WKJobsheet/Get', { id: rowData["id"] })
                            .then(response => {
                                const { data } = response;
                                const model = {
                                    Parent: data.model.Parent,
                                    Children: data.model.WKJobsheetDetails.map(child => ({
                                        ...child,
                                        ImportDocumentNo: rowData.DocumentNo
                                    })),
                                };
                                setCurrentEditID(null);
                                saveToLS("WorkshopJobSheetAddToInvoice", model);
                                navigate(`/workshopinvoice?addToInvoice=${e.data.id}`);
                            });
                    }}
                />
            );
        }
    
        // If FromInvoiceDocumentNo is not empty, return null to render nothing
        return null;
    };
    const transferFormData = () => {
        setCurrentEditID("new");
        if (transformRef.current) {
            transformRef.current.onClosing();
        }
        setTransfer(true);

    };

    const onItemClick = (e) => {
        const data = e.itemData;
        if (data.name === "Transfer From Invoice") {
            utils.transformOpen(transformRef);
        }
    }
    
    return <Container>
        <div id='normal-listing'>
            <Listing
                apiURL={apiURL}
                listingURL="/api/WKJobsheet/list"
                className={"workshop-listing-datagrid"}
                sortColumn={"DocumentDate"}
                sortOrder={"desc"}
                listingTitle={"Job Sheet"}
                storageName={"WorkshopJobSheetListing"}
                customizedAddButton={
                    <DropDownButton
                        className="workshop-listing-page-add-btn customized-listing-add-btn"
                        splitButton={true}
                        useSelectMode={false}
                        icon='add'
                        text="Add"
                        items={[{ id: 1, name: 'Transfer From Invoice' }]}
                        displayExpr="name"
                        keyExpr="id"
                        width={"100px"}
                        dropDownOptions={
                            { width: "160px" }
                        }
                        onButtonClick={() => { setCurrentEditID("new"); setInputType(null); setLockForm(false); }}
                        onItemClick={onItemClick}
                    />
                }
                disabledSortColumn={"AddToInvoice"}
                handleEdit={handleEdit}
            >
                <Column dataField="DocumentDate" format="dd/MM/yyyy" dataType={"date"} />
                <Column dataField="DocumentNo" />
                <Column dataField="RegNo" caption={"Reg No"} />
                <Column dataField="ModelCode" />
                <Column dataField="CustomerCode" />
                <Column dataField="CustomerName" />
                <Column dataField="TypeOfServiceCode" caption="Type Of Service" />
                <Column dataField="MainForexAmount" caption="Amount" format={{ type: "fixedPoint", precision: 2 }} />
                <Column dataField="AddToInvoice" allowSorting={false} cellRender={addToInvoice} />
                <Column dataField="FromInvoiceDocumentNo" caption={"Refer Invoice"} />
             
            </Listing>
        </div>

        <JobSheetForm
            formID={currentEditID}
            closePopup={setCurrentEditID}
            inputType={inputType}
            lockedForm={lockForm}
            transfer={transfer}
            clearTransfer={setTransfer}
        />

        <TransferForm
            ref={transformRef}
            title={"Transfer From Invoice"}
            transferData={transferFormData}
            filterUrl={`api/Utilities/GetWKRegistrationFilter?isInvoice=${true}`}
            //filterUrl={`api/Utilities/GetWKRegistration`}
            apiUrl={"api/WKInvoice/ItemFilter"}
            localStorage={"WorkshopInvoiceAddToJobSheet"}

        />
    </Container>;
}