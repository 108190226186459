
import { useEffect, useRef, useState, useCallback, useMemo } from "react";
import { debounce } from 'lodash';
import StockReceivedForm from './form';
import Container from '../../../components/container';
import Listing from '../../../components/listing/newListing';
import utils from '../../../utils/common';
import PathIndicator from "../../../components/path-indicator/PathIndicator";
import baseapi from "../../../api/baseapi";
import { loading, closeLoading, fetchDefaultValues } from "../../../utils/common";
import { NumberBox } from "devextreme-react/number-box";
import { CheckBox } from "devextreme-react/check-box";
import { DateBox } from "devextreme-react/date-box";
import { TextBox } from "devextreme-react/text-box";
import { TextArea } from "devextreme-react";
import ArrayStore from "devextreme/data/array_store";
import DataSource from "devextreme/data/data_source";
import { Button } from "devextreme-react/button";
import CustomizedLookup from "../../../components/lookup/Lookup";
import DataGrid, { LoadPanel, Column, Editing, Selection, Scrolling, Lookup, KeyboardNavigation, ColumnChooser, Summary, TotalItem, Sorting, Pager, Paging, Item as ToolbarItem } from "devextreme-react/data-grid";
import MoreInfor from "./moreInfor";
import AdvancedFilter from './advancedFilter';
import StockAdjustmentForm from "../../../pages/Stock-Module/adjustment/form";
import StockIssueForm from "../../../pages/Stock-Module/issue/form";
import StockReceiveForm from "../../../pages/Stock-Module/received/form";
import StockTakeForm from "../../../pages/Stock-Module/take/form";
import StockTransferForm from "../../../pages/Stock-Module/transfer/form";
import StockWriteOff from "../../../pages/Stock-Module/write-off/form";
import StockTransferFrom from "../../../pages/Stock-Module/transfer/form";
import StockWriteOffForm from "../../../pages/Stock-Module/write-off/form";
import DOForm from "../../../pages/Sales-Module/delivery-order/form";
import INVForm from "../../../pages/Sales-Module/invoice-main/form";
import CashSales from "../../../pages/Sales-Module/cash-sales/form";
import SalesDebitNote from "../../../pages/Sales-Module/debit-note/form";
import SalesCreditNote from "../../../pages/Sales-Module/credit-note/form";
import PurchaseReceive from "../../../pages/Purchase-Module/received/form";
import PurchaseInvoice from "../../../pages/Purchase-Module/invoice/form";
import CashPurchase from "../../../pages/Purchase-Module/cash-purchase/form";
import PurchaseDebitNote from "../../../pages/Purchase-Module/debit-note/form";
import ReturnPurchase from "../../../pages/Purchase-Module/purchase-return/form";
import CustomStore from "devextreme/data/custom_store";
import WorkshopInvoiceForm from "../../Workshop-Module/Invoice/form";
import ErrorPopUpForm from "../../../components/popup-form/ErrorPopupForm";
export default function StockAnalysisListing() {

    const apiURL = `/api/StockDetailAnalysis`;
    const [formValue, setFormValue] = useState({});
    const [lockForm, setLockForm] = useState(false);
    const [currentEditID, setCurrentEditID] = useState(null);
    const [currentPrefix, setCurrentEditPrefix] = useState(null);
    const [stockList, setStockList] = useState([]);
    const stockLookupRef = useRef(null);
    const formID = useRef(null);
    const searchBoxRef = useRef(null);
    const [displayMoreInfor, setDisplayMoreInfor] = useState(false);
    const [listingFirstDynamicPriceSource, setListingFirstDynamicPriceSource] = useState([]);
    const [listingLastDynamicPriceSource, setListingLastDynamicPriceSource] = useState([]);
    const [listingStockShelfSource, setListingStockShelfSource] = useState([]);
    const [listingStockAlternateSource, setListingStockAlternateSource] = useState([]);
    const [transferData, setTransferData] = useState(null);
    const fromDate = useRef(null);
    const toDate = useRef(null);
    const query = useRef(null);
    const transactionDataGrid = useRef(null);
    const advancedFilterRef = useRef(null);
    const advanceFilter = useRef(null);
    const [loadingTransactions, setLoadingTransactions] = useState(true);
    const disabledSortColumn = [];
    const sortColumn = useRef("DocumentDate");
    const sortOdr = useRef(true);
    const pageSize = useRef(100);
    const selectedPage = useRef(1);
    const gridRef = useRef(null);
    const stockIDRef = useRef(null);
    const filterRef = useRef(null);
    const filterValueRef = useRef(null);
    const clearFilterButton = useRef(null);
    const [currentForm, setCurrentForm] = useState(null);
    const isLoadingRef = useRef(false);
    const [gridDataSource, setGridDataSource] = useState([]);
    const [allGridDataSource, setAllGridDataSource] = useState([]);
    const [querySearch, setQuerySearch] = useState('');
    const typingTimeoutRef = useRef(null); // Ref to store the timeout
    const popupMessageRef = useRef(null);
    const [defaultValue, setDefaultValue] = useState({});

    const handleValueChanged = (e) => {
        const newValue = e.value;
        setQuerySearch(newValue);

        query.current = e.value;
        const searchText = newValue ? newValue.split(" ").pop() : "";
        gridRef.current.instance.searchByText(searchText);

        // Clear the previous timeout
        if (typingTimeoutRef.current) {
            clearTimeout(typingTimeoutRef.current);
        }

        typingTimeoutRef.current = setTimeout(() => {
            refresh();
        }, 600);
    };


    const refresh = async (param = {}) => {
        if (!isLoadingRef.current) {
            isLoadingRef.current = true;
            loading("Loading Latest Stock Details...");
            let currentRequest = await baseapi.httpget("/api/StockDetailAnalysis/GetStockCartDetail?stockCode=" + stockIDRef.current + '&page=' + 1 + '&rows=' + 1000000);
            setAllGridDataSource(currentRequest.data.items);
            setGridDataSource(currentRequest.data.items);
            closeLoading();
        } else {

            if (fromDate.current || toDate.current || filterValueRef.current?.length > 0) {
                let dateResult = allGridDataSource.filter(e =>
                    (!fromDate.current || e.Date >= fromDate.current) &&
                    (!toDate.current || e.Date <= toDate.current) &&
                    (!filterValueRef.current?.some(e => e.ParameterName == 'LocationList') || (
                        filterValueRef.current.some(t =>
                            (t.ParameterName == 'LocationList' && (t.ParameterValue.includes(e.FromLocation) || t.ParameterValue.includes(e.ToLocation) || t.ParameterValue.includes(e.Location)))

                        )
                    )
                    ));
                if (filterValueRef.current?.some(e => e.ParameterName == 'TransactionList')) {
                    if (dateResult?.length > 0) {
                        dateResult = dateResult.filter(e => filterValueRef.current.some(t => t.ParameterName == 'TransactionList' && t.ParameterValue.includes(e.Type)))
                    } else if (!(fromDate.current || toDate.current) && !filterValueRef.current?.some(e => e.ParameterName == 'LocationList')) {
                        dateResult = allGridDataSource.filter(e => filterValueRef.current.some(t => t.ParameterName == 'TransactionList' && t.ParameterValue.includes(e.Type)))
                    }
                }
                if (filterValueRef.current?.some(e => e.ParameterName == 'CustomerList' || e.ParameterName == 'SupplierList')) {
                    if (dateResult?.length > 0) {
                        dateResult = dateResult.filter(e => filterValueRef.current.some(t => (((t.ParameterName == 'CustomerList' && t.ParameterIDValue.includes(e.Description)) || (t.ParameterName == 'SupplierList' && t.ParameterIDValue.includes(e.Description))))));
                    } else if (!(fromDate.current || toDate.current) && !filterValueRef.current?.some(e => e.ParameterName == 'LocationList')) {
                        dateResult = allGridDataSource.filter(e => filterValueRef.current.some(t => (((t.ParameterName == 'CustomerList' && t.ParameterIDValue.includes(e.Description)) || (t.ParameterName == 'SupplierList' && t.ParameterIDValue.includes(e.Description))))));
                    }
                }

                setGridDataSource(dateResult);
            } else {
                setGridDataSource(allGridDataSource);
            }
        }



    };
    const handleFormSubmitSuccess = () => {
        isLoadingRef.current = false;
        refresh();

        utils.displayPopupMessage(popupMessageRef, {
            visible: true,
            message: "Data has been saved successfully.",
            type: "Success"
        });
    };



    function valueOnChange(e, TakeDataField = null) {
        try {
            if (!utils.isNullOrEmpty(e.value)) {
                const dataField = TakeDataField !== null ? TakeDataField : e.element.getAttribute("dataField");

                if (dataField === "StockID") {
                    if (stockIDRef.current !== e.value) {

                        // Start loading
                        setLoadingTransactions(true);
                        stockIDRef.current = e.value;
                        if (transactionDataGrid.current) {
                            transactionDataGrid.current.instance.beginCustomLoading();
                        }


                        loading("Loading Stock Analysis")
                        formID.current = e.value;


                        baseapi.httpget("/api/StockDetailAnalysis/Get?id=" + e.value).then((response) => {
                            const { data } = response;
                            setFormValue(data.model.Parent);
                            setListingStockShelfSource(data.model.StockShelfChildren);
                            setListingStockAlternateSource(data.model.StockAlternateChildren);


                        });

                        baseapi.httpget("/api/StockDetailAnalysis/GetFirstLastDynamicFields?id=" + e.value)
                            .then((response) => {
                                const { data } = response;
                                setListingFirstDynamicPriceSource(data.FirstDynamicFields);
                                setListingLastDynamicPriceSource(data.LastDynamicFields);
                            })
                            .catch((e) => {
                                console.error(e);
                            });
                        isLoadingRef.current = false;

                        refresh();

                    }
                }
            }
        } catch (err) {
            console.error(err);


        }
    }

    const handleShowFilter = () => {

        if (advancedFilterRef.current) {
            advancedFilterRef.current.show();
        }
    };

    const handleRowClick = (data) => {

        setCurrentEditID(null);
        setCurrentEditPrefix(null);
        loading();
        setCurrentForm(null);
        setTimeout(() => {
            closeLoading();
        }, 300);
        setTimeout(() => {
            setCurrentEditID(data.DocumentID);
            setCurrentEditPrefix(data.TransactionTypeID);
        }, 0);


    };


    //handle pagination 
    const remoteOperations = {
        groupPaging: false,
        paging: true,
        filtering: true,
        sorting: true,
        grouping: false,
        summary: true
    };

    const onCellClick = (e) => {
        const column = e.column;
        const rowType = e.rowType;

        if (rowType === "header" && column.name !== "buttons" && !disabledSortColumn.includes(column.name)) {
            if (column.name === sortColumn.current) {
                sortOdr.current = !sortOdr.current;
            } else {
                sortOdr.current = true;
            }

            sortColumn.current = column.name;
            refresh();
        }
    };

    const [sorting, setSorting] = useState([]);

    const handleSortChanged = (e) => {
        const sortColumns = e.component.getDataSource().sort();

        const isDateSorted = sortColumns?.some((sort) => sort.selector === "Date");

        if (isDateSorted) {
            const dateSort = sortColumns.find((sort) => sort.selector === "Date");

            setGridDataSource(gridDataSource.sort((a, b) => {
                const dateA = new Date(a.Date);
                const dateB = new Date(b.Date);

                if (!dateSort.desc) {
                    if (dateA > dateB) return -1;
                    if (dateA < dateB) return 1;
                    return b.SCT_ID - a.SCT_ID;
                } else {
                    if (dateA < dateB) return -1;
                    if (dateA > dateB) return 1;
                    return a.SCT_ID - b.SCT_ID;
                }
            })
            );
        }
    };

    useEffect(() => {
        const getDefaultValues = async () => {
            const data = await fetchDefaultValues();
            if (data) {
                setDefaultValue(data);
            }
        };

        getDefaultValues();
    }, []);
    return <Container>

        <div>
            <div className="listing-container">

                <div className="sub-container">
                    <div className='listing-page-title-container'>
                        <span className='datagrid-customized-title'>
                            <PathIndicator />
                        </span>
                    </div>
                    <div className='popup-form-main-content' id="stock-detail-analysis-content">
                        <div className='custom-form-grid-section-50-50'>
                            <div className='popup-form-item-container2' style={{ marginTop: '5px' }}>
                                <div className='popup-group-form-item-stock-detail'>
                                    <div className='popup-group-form-label'>Stock Code </div>
                                    <div className='popup-group-form-input'>
                                        <CustomizedLookup
                                            ref={stockLookupRef}
                                            className={"ar-listing-datagrid"}
                                            dataSource={stockList}
                                            displayExpr={"code"}
                                            valueExpr={"id"}
                                            value={formValue["StockID"]}
                                            onSelectionChanged={(e) => {
                                                valueOnChange(e, "StockID");
                                            }}
                                            required={true}
                                            requiredMessage={"Stock is required!"}

                                            dataSourceURL={"api/Utilities/GetStock"}
                                            onDataSourceChanged={setStockList}
                                            >
                                            <Column dataField='code'></Column>
                                            <Column dataField='name'></Column>
                                        </CustomizedLookup>
                                    </div>
                                </div>

                                <div className='popup-group-form-item-stock-detail'>
                                    <div className='popup-group-form-label'>Stock Group </div>
                                    <div className='popup-group-form-input'>
                                        <TextBox
                                            value={formValue["StockGroupCode"]}
                                            elementAttr={{ dataField: "StockGroupCode" }}
                                            onValueChanged={(e) => valueOnChange(e)}
                                            alignment='left'
                                            width={"auto"}
                                            readOnly={true}
                                        >
                                        </TextBox>
                                    </div>
                                </div>


                            </div>
                            <div className='popup-form-item-container2'>
                                <div className='popup-group-form-item-stock-detail'>
                                    <div className='popup-group-form-label'>UOM </div>
                                    <div className='popup-group-form-input'>
                                        <TextBox
                                            value={formValue["UOMCode"]}
                                            elementAttr={{ dataField: "UOMCode" }}
                                            onValueChanged={(e) => valueOnChange(e)}
                                            alignment='left'
                                            width={"auto"}
                                            readOnly={true}
                                        >
                                        </TextBox>
                                    </div>
                                </div>
                                <div className='popup-group-form-item-stock-detail'>
                                    <div className='popup-group-form-label'>Tot. Net Qty</div>

                                    <div className='popup-group-form-input' id='customized-lookup1'>
                                        <NumberBox
                                            value={formValue["NetQuantity"]}
                                            elementAttr={{ dataField: "NetQuantity" }}
                                            onValueChanged={(e) => {
                                                valueOnChange(e);
                                            }}
                                            alignment='left'
                                            width={"auto"}
                                            format="#,##0.##"
                                            readOnly={true}
                                        >

                                        </NumberBox>
                                    </div>
                                </div>

                            </div>

                            <div className='popup-form-item-container'>
                                <div className='popup-group-form-item-stock-detail-description'>
                                    <div className='popup-group-form-label'>Description </div>
                                    <div className='popup-group-form-input'>
                                        <TextBox
                                            value={formValue["Description"]}
                                            elementAttr={{ dataField: "Description" }}
                                            onValueChanged={(e) => valueOnChange(e)}
                                            alignment='left'
                                            width={"auto"}
                                            readOnly={true}
                                        >
                                        </TextBox>
                                    </div>
                                </div>


                            </div>
                            <div className='popup-form-item-container2'>
                                <div className='popup-group-form-item-stock-detail'>
                                    <div className='popup-group-form-label'>Standard Cost </div>

                                    <div className='popup-group-form-input' id='customized-lookup1'>
                                        <NumberBox
                                            value={formValue["StandardCost"]}
                                            elementAttr={{ dataField: "StandardCost" }}
                                            onValueChanged={(e) => {
                                                valueOnChange(e);
                                            }}
                                            alignment='left'
                                            width={"auto"}
                                            format="#,##0.00"
                                            readOnly={true}
                                        >

                                        </NumberBox>
                                    </div>
                                </div>
                                <div className='popup-group-form-item-stock-detail'>
                                    <div className='popup-group-form-label'>Average Cost </div>

                                    <div className='popup-group-form-input' id='customized-lookup1'>
                                        <NumberBox
                                            value={formValue["AverageCost"]}
                                            elementAttr={{ dataField: "AverageCost" }}
                                            alignment='left'
                                            width={"auto"}
                                            format="#,##0.00"
                                            readOnly={true}
                                        >

                                        </NumberBox>
                                    </div>
                                </div>


                            </div>

                            <div className='popup-form-item-container'>
                                <div className='popup-group-form-item-stock-detail-description'>
                                    <div className='popup-group-form-label'>Last Purchase </div>
                                    <div className='popup-group-form-input'>
                                        <TextBox
                                            value={formValue["LastPurchase"]}
                                            elementAttr={{ dataField: "LastPurchase" }}
                                            onValueChanged={(e) => valueOnChange(e)}
                                            alignment='left'
                                            width={"auto"}
                                            readOnly={true}
                                        >
                                        </TextBox>
                                    </div>
                                </div>


                            </div>

                            <div className='popup-form-item-container2'>
                                <div className='popup-group-form-item-stock-detail'>
                                    <div className='popup-group-form-label'>P/O Qty </div>

                                    <div className='popup-group-form-input' id='customized-lookup1'>
                                        <NumberBox
                                            value={formValue["POQty"]}
                                            elementAttr={{ dataField: "POQty" }}

                                            alignment='left'
                                            width={"auto"}
                                            format="#,##0.##"
                                            readOnly={true}
                                        >

                                        </NumberBox>
                                    </div>
                                </div>
                                <div className='popup-group-form-item-stock-detail'>
                                    <div className='popup-group-form-label'>S/O Qty</div>

                                    <div className='popup-group-form-input' id='customized-lookup1'>
                                        <NumberBox
                                            value={formValue["SOQty"]}
                                            elementAttr={{ dataField: "SOQty" }}
                                            alignment='left'
                                            width={"auto"}
                                            format="#,##0.##"
                                            readOnly={true}
                                        >

                                        </NumberBox>
                                    </div>
                                </div>


                            </div>

                            <div className='popup-form-item-container'>
                                <div className='popup-group-form-item-stock-detail-description'>
                                    <div className='popup-group-form-label'>Last Sales </div>
                                    <div className='popup-group-form-input'>
                                        <TextBox
                                            value={formValue["LastSales"]}
                                            elementAttr={{ dataField: "LastSales" }}
                                            onValueChanged={(e) => valueOnChange(e)}
                                            alignment='left'
                                            width={"auto"}
                                            readOnly={true}
                                        >
                                        </TextBox>
                                    </div>
                                </div>


                            </div>


                            <div className='popup-form-item-container2'>
                                <div className='popup-group-form-item-stock-detail'>
                                    <div className='popup-group-form-label'>Min Level</div>

                                    <div className='popup-group-form-input' id='customized-lookup1'>
                                        <NumberBox
                                            value={formValue["MinLevel"]}
                                            elementAttr={{ dataField: "MinLevel" }}
                                            onValueChanged={(e) => {
                                                valueOnChange(e);
                                            }}
                                            alignment='left'
                                            width={"auto"}
                                            readOnly={true}
                                        >

                                        </NumberBox>
                                    </div>
                                </div>
                                <div className='popup-group-form-item-stock-detail'>
                                    <div className='popup-group-form-label'>Max Level</div>

                                    <div className='popup-group-form-input' id='customized-lookup1'>
                                        <NumberBox
                                            value={formValue["MaxLevel"]}
                                            elementAttr={{ dataField: "MaxLevel" }}
                                            onValueChanged={(e) => {
                                                valueOnChange(e);
                                            }}
                                            alignment='left'
                                            width={"auto"}
                                            readOnly={true}
                                        >

                                        </NumberBox>
                                    </div>
                                </div>


                            </div>

                        </div>

                        <div
                            style={{ margin: '5px 5px 0 0px', textAlign: 'right', color: '#46CF64', textDecoration: 'underline', cursor: 'pointer' }}
                            onClick={() => setDisplayMoreInfor(true)}
                        >
                            More Info
                        </div>
                        <div className='inventory-module-parent-without-margin-bottom-section custom-form-grid-section-50-50' style={{ margin: '10px 0 0 0' }} id="stock-details-analysis-middle-section">

                            <div className='popup-group-form-item' id="stock-details-analysis-middle-section-1">
                                <div className='inventory-module-border-bottom' style={{ padding: "0 0" }}>
                                    <DataGrid
                                        dataSource={listingFirstDynamicPriceSource}
                                        sorting={false}
                                    >
                                        <Column dataField='DisplayName' caption={""} editorOptions={{ readOnly: true }}/>
                                        <Column dataField='Price' caption={"Amount"} alignment={"center"} format={{ type: "fixedPoint", precision: 2 }} dataType={"number"} editorOptions={{ readOnly: true }} />
                                        <Editing allowUpdating={true} allowDeleting={false} mode='cell' />
                                    </DataGrid>

                                </div>
                                <hr style={{ margin: "0px 0", border: "none", borderRight: "1px solid #ccc" }} />
                                <div className='inventory-module-border-bottom' style={{ padding: "0 0" }}>
                                    <DataGrid
                                        dataSource={listingLastDynamicPriceSource}
                                        sorting={false}
                                    >
                                        <Column dataField='DisplayName' caption={""} editorOptions={{ readOnly: true }} />
                                        <Column dataField='Price' caption={"Amount"} alignment={"center"} format={{ type: "fixedPoint", precision: 2 }} dataType={"number"} editorOptions={{ readOnly: true }} />
                                        <Editing allowUpdating={true} allowDeleting={false} mode='cell' />
                                    </DataGrid>

                                </div>
                            </div>
                            <div className='popup-group-form-item'>
                                <div className='inventory-module-border-bottom' style={{ padding: "0 0" }} id="stock-details-analysis-middle-section-2">
                                    <DataGrid
                                        dataSource={listingStockShelfSource}
                                        sorting={false}
                                        scrolling={{ mode: "Standard" }}
                                        height={148}
                                    >
                                        <Column dataField='NO' caption="No" dataType='number' editorOptions={{ disabled: true }} />
                                        <Column dataField='ShelfCode' caption={"Shelf No"} editorOptions={{ readOnly: true }} />
                                        <Column dataField='LocationCode' caption={"Location"} editorOptions={{ readOnly: true }} />
                                        <Column dataField='NetQuantity' caption="Net Qty" dataType='number' editorOptions={{ disabled: true }} />
                                        <Editing allowUpdating={true} allowDeleting={false} mode='cell' />
                                    </DataGrid>
                                </div>
                            </div>
                        </div>

                        <div className='custom-form-grid-section-50-50' style={{ marginTop: '10px' }}>
                            <div className='popup-form-item-container2'>
                                <div className='popup-group-form-item-stock-detail'>
                                    <div className='popup-group-form-label'>From Date</div>

                                    <div className='popup-group-form-input'>
                                        <DateBox
                                            displayFormat='dd/MM/yyyy'
                                            dateSerializationFormat='yyyy-MM-dd'
                                            useMaskBehavior={true}
                                            type='date'
                                            showClearButton={true}
                                            value={fromDate.current}
                                            max={toDate.current}
                                            onValueChanged={(e) => {
                                                fromDate.current = e.value;
                                                refresh();

                                            }}
                                        />
                                    </div>

                                </div>
                                <div className='popup-group-form-item-stock-detail'>
                                    <div className='popup-group-form-label'>To Date</div>

                                    <div className='popup-group-form-input'>
                                        <DateBox
                                            min={fromDate.current}
                                            displayFormat='dd/MM/yyyy'
                                            dateSerializationFormat='yyyy-MM-dd'
                                            useMaskBehavior={true}
                                            type='date'
                                            showClearButton={true}
                                            value={toDate.current}
                                            onValueChanged={(e) => {
                                                toDate.current = e.value;
                                                refresh();

                                            }}
                                        />
                                    </div>

                                </div>



                            </div>
                            <div className='custom-form-grid-section-50-50 '>

                                <div style={{ margin: '2.5px 10px' }}>
                                    <TextBox
                                        width={"100%"}
                                        height={"36px"}
                                        ref={searchBoxRef}
                                        className='listing-page-search-box'
                                        placeholder='Search...'
                                        valueChangeEvent='keyup'
                                        value={querySearch}
                                        onValueChanged={handleValueChanged}
                                    />

                                </div>




                                <div className='popup-group-form-item-stock-detail'>

                                    <Button
                                        width={"auto"}
                                        id='submit-link'
                                        splitButton={true}
                                        useSelectMode={true}
                                        text='Clear Filter'
                                        displayExpr='text'
                                        keyExpr='id'
                                        elementAttr={{ class: "popup-form-submit-btn" }}
                                        style={{ backgroundColor: "#46CF64", borderRadius: "4px", marginRight: "5px" }}
                                        stylingMode='text'
                                        onClick={() => {
                                            query.current = null;
                                            fromDate.current = null;
                                            toDate.current = null;
                                            advanceFilter.current && (advanceFilter.current = null);
                                            filterRef.current = null;
                                            filterValueRef.current = null;
                                            if (clearFilterButton.current) {
                                                clearFilterButton.current();
                                            }
                                            setQuerySearch('');
                                            refresh();
                                        }}

                                    />

                                    <Button
                                        width={"auto"}
                                        id='submit-link'
                                        splitButton={true}
                                        useSelectMode={true}
                                        text='Filter By '
                                        displayExpr='text'
                                        keyExpr='id'
                                        style={{ backgroundColor: "#EFEFEF", borderRadius: "4px", marginRight: "5px", color: "black" }}// Set the font color to black }}
                                        stylingMode='text'
                                        onClick={() => handleShowFilter()}
                                    />
                                </div>


                            </div>

                        </div>

                        <div style={{ width: '100%' }}>

                            <div className='inventory-module-border-bottom' style={{ padding: "0 0" }}>

                                <DataGrid
                                    id="id"
                                    ref={gridRef}
                                    //dataSource={listingTransactionSource}
                                    dataSource={
                                        new DataSource({
                                            store: new ArrayStore({
                                                data: gridDataSource,
                                                key: 'DocumentNo',
                                            })
                                        })
                                    }
                                    onInitialized={(e) => {
                                        const dataSource = e.component.getDataSource();

                                        if (dataSource) {
                                            dataSource.sort([{ selector: 'Date', desc: false }, { selector: 'SCT_ID', desc: false }]);
                                            dataSource.reload();
                                        }
                                    }}
                                    sorting={{ mode: "multiple", showSortIndexes: false, sort: sorting }}

                                    onOptionChanged={(e) => {
                                        if (e.name === "columns") {
                                            handleSortChanged(e);
                                        }
                                    }}
                                    showBorders={true}
                                    width={"100%"}
                                    allowColumnReordering={true}
                                    allowColumnResizing={true}
                                    rowHeight={50}
                                    hoverStateEnabled={true}
                                    remoteOperations={remoteOperations}
                                    columnResizingMode='widget'
                                    onCellClick={onCellClick}
                                    rowAlternationEnabled={false}
                                    columnAutoWidth={true}
                                    errorRowEnabled={false}
                                    loadPanel={{ enabled: true }}
                                    height={400}
                                    focusedRowEnabled={false}
                                >
                                    {/* <LoadPanel
                                            height={100}
                                            width={250}
                                            showIndicator={true}
                                            showPane={true}
                                            message="Loading..."

                                        /> */}
                                    <Sorting mode="multiple" />
                                    <Column dataField='DocNo' width="120px" caption="Document No" editorOptions={{ disabled: true }} cellRender={(e) => {
                                        const data = e.data;
                                        const id = parseInt(data.DocumentID);

                                        return (
                                            <span
                                                className='clickable-span-tag'
                                                onClick={() => handleRowClick(data)}>
                                                {e.displayValue}
                                            </span>
                                        )

                                    }} />
                                    <Column dataField='Date' caption={"Date"} editorOptions={{ readOnly: true }} format="dd/MM/yyyy" dataType={"date"} width="100px" />
                                    <Column dataField='Type' caption={"Type"} editorOptions={{ readOnly: true }} width="70px" />
                                    <Column dataField='Description' caption="Customer/Supplier/Remarks" editorOptions={{ disabled: true }} width="280px" />
                                    <Column dataField='InQuantity' caption="In" dataType="number" editorOptions={{ disabled: true }} width="80px" format={utils.getDecimalFormat(defaultValue["QuantityFixCheck"], defaultValue["QuantityFormat"])} />
                                    <Column dataField='OutQuantity' caption="Out" dataType="number" editorOptions={{ disabled: true }} width="80px" format={utils.getDecimalFormat(defaultValue["QuantityFixCheck"], defaultValue["QuantityFormat"])} />
                                    <Column dataField='BalanceQuantity' caption="Balance Quantity" dataType="number" editorOptions={{ disabled: true }} width="120px" format={utils.getDecimalFormat(defaultValue["QuantityFixCheck"], defaultValue["QuantityFormat"])} />
                                    <Column
                                        dataField='CostPrice'
                                        caption="Cost/Price"
                                        editorOptions={{ disabled: true }}
                                        width="90px"
                                        cellRender={(e) => {
                                            const isUnitCost = e.data.UnitPrice === 0.00;
                                            const precision = isUnitCost ? defaultValue["UnitCostFormat"] : defaultValue["UnitPriceFormat"];
                                            const isFixed = isUnitCost ? defaultValue["UnitCostFixCheck"] : defaultValue["UnitPriceFixCheck"];
                                            const textColor = isUnitCost ? 'blue' : 'inherit'; // Set text color to blue if UnitCost is not 0.00
                                            // Format the value using the calculated format
                                            const formattedValue = new Intl.NumberFormat(undefined, {
                                                minimumFractionDigits: isFixed ? precision : 0,
                                                maximumFractionDigits: precision
                                            }).format(e.value);

                                            return (
                                                <span style={{ color: textColor }}>
                                                    {formattedValue}
                                                </span>
                                            );
                                        }}
                                    />


                                    <Column dataField='Location' caption="Location" width="90px" />
                                    <Column dataField='FromLocation' caption="From Location" width="90px" />
                                    <Column dataField='ToLocation' caption="To Location" width="90px" />
                                    <Column dataField='StockDescription' caption="Stock Description" editorOptions={{ disabled: true }} width="280px" />
                                    <Column dataField='CombinedColumn' caption="Stock Description" editorOptions={{ disabled: true }} visible={false} />

                                    <Scrolling columnRenderingMode='standard' showScrollbar={"onHover"} />
                                    <Pager allowedPageSizes={[10, 25, 50, 100]} showPageSizeSelector={true} visible={true} displayMode={"full"} showInfo={true} />
                                    <Paging
                                        defaultPageSize={100}
                                        onPageSizeChange={(e) => {
                                            pageSize.current = e;
                                            refresh();
                                        }}
                                        onPageIndexChange={(e) => {
                                            selectedPage.current = e + 1;
                                            refresh();
                                        }}
                                    />
                                </DataGrid>



                            </div>
                        </div>
                    </div>
                </div>

                {displayMoreInfor && (
                    <MoreInfor
                        visible={displayMoreInfor}
                        formValue={formValue} // Pass formValue as a prop
                        listingStockAlternateSource={listingStockAlternateSource}
                        onValueChanged={(e) => {
                            setDisplayMoreInfor(e.value);
                        }}

                    />
                )}

                <AdvancedFilter
                    ref={advancedFilterRef}
                    ReturnResult={(e) => {
                        if (stockIDRef.current) {
                            filterRef.current = true;
                            filterValueRef.current = e.data;
                            refresh();
                        }

                        clearFilterButton.current = e.clearFilter;

                    }}
                />

                {currentEditID && currentPrefix && (
                    <div>

                        <div>
                            {(currentPrefix === "89") && <StockReceiveForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={lockForm} onSubmitSuccess={handleFormSubmitSuccess} />}
                            {(currentPrefix === "94") && <StockIssueForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={lockForm} onSubmitSuccess={handleFormSubmitSuccess} />}
                            {(currentPrefix === "96") && <StockAdjustmentForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={lockForm} onSubmitSuccess={handleFormSubmitSuccess} />}
                            {(currentPrefix === "108") && <StockTransferForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={lockForm} onSubmitSuccess={handleFormSubmitSuccess} />}
                            {(currentPrefix === "107") && <StockWriteOffForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={lockForm} onSubmitSuccess={handleFormSubmitSuccess} />}
                            {(currentPrefix === "101") && <StockTakeForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={true} onSubmitSuccess={handleFormSubmitSuccess} />}
                            {(currentPrefix === "114") && <WorkshopInvoiceForm formID={currentEditID} title={"Invoice Entry"} apiURL={"/api/WKInvoice"} onSubmitSuccess={handleFormSubmitSuccess} />}

                            {(currentPrefix === "27") && <INVForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={lockForm} onSubmitSuccess={handleFormSubmitSuccess} title={"Invoice Entry"} apiURL="/api/SalesInvoiceMain" transferData={transferData} clearTransfer={setTransferData} />}
                            {(currentPrefix === "23") && <DOForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={lockForm} onSubmitSuccess={handleFormSubmitSuccess} title={"Delivery Order"} apiURL="/api/SalesDeliveryOrder" />}
                            {(currentPrefix === "113") && <CashSales formID={currentEditID} closePopup={setCurrentEditID} lockedForm={lockForm} onSubmitSuccess={handleFormSubmitSuccess} title={"Cash Sales Entry"} apiURL="/api/SalesCashSales" />}
                            {(currentPrefix === "97") && <SalesDebitNote formID={currentEditID} closePopup={setCurrentEditID} lockedForm={lockForm} onSubmitSuccess={handleFormSubmitSuccess} title={"Debit Note"} apiURL="/api/SalesDebitNote" />}
                            {(currentPrefix === "98") && <SalesCreditNote formID={currentEditID} closePopup={setCurrentEditID} lockedForm={lockForm} onSubmitSuccess={handleFormSubmitSuccess} title={"Credit Note"} apiURL="/api/SalesCreditNote" />}

                            {(currentPrefix === "109") && <PurchaseReceive formID={currentEditID} closePopup={setCurrentEditID} lockedForm={lockForm} onSubmitSuccess={handleFormSubmitSuccess} title={"Purchase Received"} apiURL="/api/PurchaseReceived" clearTransfer={setTransferData} />}
                            {(currentPrefix === "110") && <PurchaseInvoice formID={currentEditID} closePopup={setCurrentEditID} lockedForm={lockForm} onSubmitSuccess={handleFormSubmitSuccess} apiURL="/api/PurchaseInvoice" title={"Purchase Invoice"} />}
                            {(currentPrefix === "111") && <CashPurchase formID={currentEditID} closePopup={setCurrentEditID} lockedForm={lockForm} onSubmitSuccess={handleFormSubmitSuccess} apiURL="/api/PurchaseCashPurchase" title={"Cash Purcahse"} />}
                            {(currentPrefix === "103") && <PurchaseDebitNote formID={currentEditID} closePopup={setCurrentEditID} lockedForm={lockForm} onSubmitSuccess={handleFormSubmitSuccess} apiURL="/api/PurchaseDebitNote" title={"Debit Note"} />}
                            {(currentPrefix === "47") && <ReturnPurchase formID={currentEditID} closePopup={setCurrentEditID} lockedForm={lockForm} onSubmitSuccess={handleFormSubmitSuccess} apiURL="/api/PurchaseReturn" title={"Purchase Return"} />}
                        </div>

                    </div>
                )}
            </div>
        </div>
        <ErrorPopUpForm ref={popupMessageRef} />
    </Container>



}