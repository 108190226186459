import React, { useEffect, useState, } from 'react';
import { Column, Lookup, } from 'devextreme-react/data-grid';
import { forexOptions } from '../../../utils/lookupstore';
import Container from '../../../components/container';
import APDebitNoteForm from './form';
import PurchaseDebitNoteForm from '../../Purchase-Module/debit-note/form';
import Listing from '../../../components/listing/newListing';
import utils from '../../../utils/common';
import EInvoiceMain from "../../../components/e-invoice/EInvoiceMain";

export default function APDebitNoteListing() {
    const apiURL = `/api/APDebitNote`;
    const nonIntegerRegex = /[a-zA-Z-]+/g;
    
    const [invType, setInvType] = useState(null);
    const [currentDetailID, setCurrentDetailID] = useState(null);
    const [currentEditID, setCurrentEditID] = useState(null);
    const [currentEditID2, setCurrentEditID2] = useState(null);

    function handleEInvoice(e) {
        setInvType('13');
        setCurrentDetailID(e.row.data.DocumentNo);
    }
    function handleEdit(e) {
        const rowData = e.row.data;
        let valueToCheck = rowData.TransactionTypeCode.trim().match(nonIntegerRegex);
        
        if (rowData.hasOwnProperty("OriginalDocumentID") && rowData.OriginalDocumentID) {
            valueToCheck = rowData.OriginalTransactionTypeCode.trim().match(nonIntegerRegex);
        } else {
            valueToCheck = rowData.TransactionTypeCode.trim().match(nonIntegerRegex);
        }

        valueToCheck = valueToCheck ? valueToCheck.join("") : "";

        utils.transactionCodeList.find((item) => {
            if (item.TransType === valueToCheck) {
                if (item.DocumentType === "Purchase Debit Note") {
                    setCurrentEditID2(rowData.OriginalDocumentID);
                } else {
                    setCurrentEditID(rowData.id);
                }
            }
        })
    }

    const closePopup = (id) => {
        setCurrentEditID(id);
        setCurrentEditID2(id);
        setCurrentDetailID(id);
    }


    return <Container>
        <div id='normal-listing'>
            <Listing
                className="ap-listing-datagrid"
                apiURL={apiURL}
                listingURL="/api/APDebitNote/list"
                sortColumn={"DocumentDate"}
                sortOrder={"desc"}
                listingTitle={"AP Debit Note"}
                storageName={"APDebitNoteListingColumnWidth"}
                einvoiceshow={true}
                onAddClick={setCurrentEditID}
                handleEdit={handleEdit}
                handleEInvoice={handleEInvoice}
            >
                <Column dataField="DocumentNo" />
                <Column dataField="DocumentDate" format="dd/MM/yyyy" dataType={"date"} />
                <Column dataField="SupplierCode" />
                <Column dataField="SupplierName" />
                <Column dataField="ForexCode" caption="Forex" />
                <Column dataField="MainForexAmount" caption={"Amount"} format={{ type: "fixedPoint", precision: 2 }} />
                <Column dataField="MainLocalAmount" caption={"Local Amount"} format={{ type: "fixedPoint", precision: 2 }} />
                <Column dataField="OutstandingAmount" caption={"U/A Amount"} format={{ type: "fixedPoint", precision: 2 }} />
            </Listing>
        </div>

        {currentEditID !== null && (
            <APDebitNoteForm
                formID={currentEditID}
                closePopup={closePopup}
                title={"A/P Debit Note"}
                apiURL={apiURL}
            />
        )}

        {currentDetailID !== null && (
            <EInvoiceMain
                formID={currentDetailID}
                closePopup={closePopup}
                apiURL={apiURL}
                title={"E-Invoice Main"}
                invoiceType={invType}
            />
        )}

        {currentEditID2 !== null && (
            <PurchaseDebitNoteForm
                formID={currentEditID2}
                closePopup={closePopup}
                title={"Purchase Debit"}
                apiURL={`/api/PurchaseDebitNote`}
            />
        )}
    </Container>
}