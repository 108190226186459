import React, { useEffect, useState, useRef } from 'react';
import { Column, Lookup, } from 'devextreme-react/data-grid';
import { forexOptions } from '../../../utils/lookupstore';
import SalesOrderForm from './form';
import Container from '../../../components/container';
import Listing from '../../../components/listing/newListing';
import utils from '../../../utils/common';
import DropDownButton from 'devextreme-react/drop-down-button';
import Transform from '../transform/Transform';
import TransferStatusCell from '../../../components/transfer-status-cell/TransferStatusCell';

export default function SalesOrderListing() {

    const apiURL = `/api/SalesOrder`;
    const [currentEditID, setCurrentEditID] = useState(null);
    const [transformTitle, setTransformTitle] = useState(null);
    const [transferData, setTransferData] = useState(null);
    const transformRef = useRef(null);

    function handleEdit(e) {
        const id = e.row.key;
        setCurrentEditID(id);
    }

    const onItemClick = (e) => {
        const { action } = e.itemData;
        setTransformTitle(action);
        utils.transformOpen(transformRef);
    };

    const transferFormData = (data) => {
        setCurrentEditID("new");
        setTransferData(data);
    };

    const closePopup = (value) => {
        setCurrentEditID(value);
        setTransferData(null);
    };

    return <Container>
        <div id='normal-listing'>
            <Listing
                className="sales-listing-datagrid"
                apiURL={apiURL}
                listingURL="/api/SalesOrder/list"
                sortColumn={"DocumentDate"}
                sortOrder={"desc"}
                listingTitle={"Sales Order"}
                storageName={"SalesOrderListingColumnWidth"}
                onAddClick={setCurrentEditID}
                handleEdit={handleEdit}
                customizedAddButton={
                    <DropDownButton
                        className="customized-listing-add-btn sales-listing-btn"
                        splitButton={true}
                        useSelectMode={false}
                        icon='add'
                        text="Add"
                        items={[
                            { id: 1, action: 'Transfer from Quotation' },
                            // { id: 2, action: 'Transfer from Sales Order' }
                        ]}
                        displayExpr="action"
                        keyExpr="id"
                        width={"100px"}
                        dropDownOptions={
                            { width: "180px" }
                        }
                        onItemClick={onItemClick}
                        onButtonClick={() => {
                            setCurrentEditID("new");
                        }}
                    />
                }
            >
                <Column dataField="DocumentDate" format="dd/MM/yyyy" dataType={"date"} />
                <Column dataField="DocumentNo" />
                <Column dataField="CustomerCode" />
                <Column dataField="CustomerName" />
                <Column dataField="ForexCode" caption="Forex" />
                <Column dataField="MainForexAmount" caption={"Amount"} format={{ type: "fixedPoint", precision: 2 }} />
                <Column dataField="MainLocalAmount" caption={"Local Amount"} format={{ type: "fixedPoint", precision: 2 }} />
                <Column dataField='OutstandingAmount' format={{ type: "fixedPoint", precision: 2 }} />
                <Column
                    dataField="TransferStatus"
                    caption="Status"
                    cellRender={(cellData) => (
                        <TransferStatusCell
                            hasPartiallyTransferred={cellData.data.HasPartiallyTransferred}
                            hasFullyTransferred={cellData.data.HasFullyTransferred}
                        />
                    )}
                />
            </Listing>
        </div>

        <SalesOrderForm
            formID={currentEditID}
            closePopup={closePopup}
            apiURL={apiURL}
            title={"Sales Order"}
            transferData={transferData}
            clearTransfer={setTransferData}
        />

        <Transform
            ref={transformRef}
            title={transformTitle}
            apiPath={{
                "Transfer from Quotation": {
                    FullDocumentListing: "api/SalesTransfer/QuotationFullDocument",
                    PartialDocumentListing: "api/SalesTransfer/QuotationPartial",
                    FullDocumentTransfer: "api/SalesTransfer/QuotationFullTransfer",
                    PartialDocumentTransfer: "api/SalesTransfer/QuotationPartialTransfer",
                    FilterPath: "api/SalesTransfer/QuotationFilter"
                }
            }}
            transferData={transferFormData}
        />

    </Container>;
}