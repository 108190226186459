import { useRef, useState, useEffect, useCallback } from "react";
import Container from "../../components/container";
import { ledgerListing, projectOptions, salesmanOptions, areaOptions, glChartAccountOptions, customerOptions, supplierOptions } from "../../utils/lookupstore";
import { Button } from "devextreme-react";
import CustomStore from "devextreme/data/custom_store";
import baseapi from "../../api/baseapi";
import { saveToLS, getFromLS } from "../../utils/localstorage";
import { DataGrid, Button as Pager, Toolbar, Column, Selection, TotalItem, Summary, GroupPanel, Grouping, GroupItem } from "devextreme-react/data-grid";
import utils, { loading, closeLoading } from "../../utils/common";
import PathIndicator from "../../components/path-indicator/PathIndicator";
import CustomizedLookup from "../../components/lookup/Lookup";
import { TextBox, NumberBox, SelectBox, DropDownBox, Popover, CheckBox } from "devextreme-react";
import { DateBox } from "devextreme-react/date-box";
import { Popup } from "devextreme-react/popup";
import ErrorPopUpForm from "../../components/popup-form/ErrorPopupForm";
import ARCreditNoteForm from "../AR-Module/credit-notes/form";
import ARDebitNoteForm from "../AR-Module/debit-notes/form";
import ARReceiptForm from "../AR-Module/receipt/form";
import ARSalesForm from "../AR-Module/sales/form";
import APCreditNoteForm from "../AP-Module/credit-notes/form";
import APDebitNoteForm from "../AP-Module/debit-notes/form";
import APPaymentForm from "../AP-Module/payment/form";
import APPurchaseForm from "../AP-Module/purchase/form";
import JournalEntryForm from "../GL-Module/journal-entry/form";
import CashBookEntryForm from "../GL-Module/cash-book-entry/form";
import ContraForm from "../contra/form";
import ARRefundForm from "../AR-Module/refund/form";
import APRefundForm from "../AP-Module/refund/form";
import SalesInvoiceMainForm from "../Sales-Module/invoice-main/form";
import SalesCashSalesForm from "../Sales-Module/cash-sales/form";
import PurchaseInvoiceForm from "../Purchase-Module/invoice/form";
import CashPurchaseForm from "../Purchase-Module/cash-purchase/form";
import WorkshopInvoiceForm from "../Workshop-Module/Invoice/form";

export default function GLLedgerListing() {
	const fromProjectLookupRef = useRef(null);
	const toProjectLookupRef = useRef(null);
	const [fromProjectList, setFromProjectList] = useState([]);
	const [toProjectList, setToProjectList] = useState([]);
	const fromSalesmanLookupRef = useRef(null);
	const toSalesmanLookupRef = useRef(null);
	const [fromSalesmanList, setFromSalesmanList] = useState([]);
	const [toSalesmanList, setToSalesmanList] = useState([]);
	const fromAreaLookupRef = useRef(null);
	const toAreaLookupRef = useRef(null);
	const [fromAreaList, setFromAreaList] = useState([]);
	const [toAreaList, setToAreaList] = useState([]);
	const fromChartLookupRef = useRef(null);
	const toChartLookupRef = useRef(null);
	const [fromChartList, setFromChartList] = useState([]);
	const [toChartList, setToChartList] = useState([]);
	const fromCustomerLookupRef = useRef(null);
	const toCustomerLookupRef = useRef(null);
	const [fromCustomerList, setFromCustomerList] = useState([]);
	const [toCustomerList, setToCustomerList] = useState([]);
	const fromSupplierLookupRef = useRef(null);
	const toSupplierLookupRef = useRef(null);
	const [fromSupplierList, setFromSupplierList] = useState([]);
	const [toSupplierList, setToSupplierList] = useState([]);
	const [selectedCust, setSelectedCust] = useState(0);
	const [selectedSupp, setSelectedSupp] = useState(0);
	const [openCustRange, setOpenCustRange] = useState();
	const [openSuppRange, setOpenSuppRange] = useState();
	const [isAR, setIsAR] = useState("null");
	const [storageName, setStorageName] = useState("GLLedgerReportListingColumnWidth");

	const tempForm = useRef(null);
	const [listingDataSource, setListingDataSource] = useState(null);
	const [dropDownList, setDropDownList] = useState({
		reportList: []
	});
	const [originalDropDownList, setOriginalDropDownList] = useState({});
	const userID = JSON.parse(localStorage.getItem("data"));
	const currentToken = localStorage.getItem("Authorization").substring(7);
	const [fromDate, setFromDate] = useState(
		new Date(new Date().setDate(1)).toLocaleDateString("en-US", {
			year: "numeric",
			month: "2-digit",
			day: "2-digit"
		})
	);
	const [toDate, setToDate] = useState(
		new Date(new Date()).toLocaleDateString("en-US", {
			year: "numeric",
			month: "2-digit",
			day: "2-digit"
		})
	);
	const [paramValue, setParamValue] = useState([
		{
			ParameterName: "FromDate",
			ParameterValue: utils.dateboxFormat(fromDate)
		},
		{
			ParameterName: "ToDate",
			ParameterValue: utils.dateboxFormat(toDate)
		},
		{
			ParameterName: "IsZero",
			ParameterValue: false
		},
		{
			ParameterName: "ReportName",
			ParameterValue: "GL - Ledger (Standard)"
		},
		{
			ParameterName: "ReportID",
			ParameterValue: "15cdsGV76JY%3d"
		},
		{
			ParameterName: "LedgerType",
			ParameterValue: "General Ledger"
		}
	]);
	const [openReportPop, setOpenReportPop] = useState(false);
	const [selectedSales, setSelectedSales] = useState(0);
	const [selectedArea, setSelectedArea] = useState(0);
	const [selectedChartAccount, setSelectedChartAccount] = useState(0);
	const [openSalesRange, setOpenSalesRange] = useState();
	const [openAreaRange, setOpenAreaRange] = useState();
	const [openChartAccountRange, setOpenChartAccountRange] = useState();
	const [printRepDisabled, setPrintRepDisabled] = useState(true);
	const [openProjectRange, setOpenProjectRange] = useState();
	const [selectedProject, setSelectedProject] = useState(0);
	const [documentModuleList, setDocumentModuleList] = useState([]);
	const documentModuleLookupRef = useRef(null);
	const [currentEditID, setCurrentEditID] = useState("");
	const [documentDate, setDocumentDate] = useState(null);
	const [documentNo, setDocumentNo] = useState("");
	const [form, setForm] = useState("");
	const [chartAccountGroupIndex, setChartAccountGroupIndex] = useState(undefined);
	const [chartAccountVisible, setChartAccountVisible] = useState(false);
	const [userTypeGroupIndex, setUserTypeGroupIndex] = useState(undefined);
	const [userTypeVisible, setUserTypeVisible] = useState(false);

	const gridRef = useRef(null);
	const searchBoxRef = useRef(null);
	const currentFocusIndex = useRef(null);
	const dropdownRef = useRef(null);
	const popupMessageRef = useRef(null);
	const pagingIndex = useRef({
		loading: false,
		page: 1
	});

	const filterTypes = [
		{
			ID: 0,
			Type: "No Filter"
		},
		{
			ID: 1,
			Type: "Filter By Range"
		},
		{
			ID: 2,
			Type: "Filter By Multi-Select"
		}
	];

	useEffect(() => {
		Promise.allSettled([ledgerListing.store.load(), projectOptions.store.load(), salesmanOptions.store.load(), areaOptions.store.load(), glChartAccountOptions.store.load(), supplierOptions.store.load(), customerOptions.store.load()]).then(
			(lists) => {
				setDropDownList({
					reportList: [{ id: "15cdsGV76JY%3d", code: "GL - Ledger (Standard)" }],
					projectList: lists[1].value.data,
					salesmanList: lists[2].value.data,
					areaList: lists[3].value.data,
					// chartList: lists[4].value.data,
					supplierList: lists[5].value.data,
					customerList: lists[6].value.data
				});

				setOriginalDropDownList({
					reportList: lists[0].value.data,
					projectList: lists[1].value.data,
					salesmanList: lists[2].value.data,
					areaList: lists[3].value.data,
					// chartList: lists[4].value.data,
					supplierList: lists[5].value.data,
					customerList: lists[6].value.data
				});

				// console.log(lists[0].value.data)
			}
		);
	}, []);

	useEffect(() => {
		baseapi
			.httpget("api/Utilities/GetGLChartAccounts", { q: null, singleReturn: false/*, chartAccountType: "GL"*/ })
			.then((response) => {
				const data = response.data;
				setDropDownList((prevState) => ({
					...prevState,
					chartList: [...data]
				}));

				setOriginalDropDownList((prevState) => ({
					...prevState,
					chartList: [...data]
				}));
			})
			.catch(() => {
				throw "Network error";
			});
	}, [selectedChartAccount]);

	const handlePrintBtn = (e) => {
		if (returnSelectedValue("ReportID") !== null) {
			baseapi.httpget(`${utils.ReportURL}/api/Report/getReportDetailsByReportName?reportID=${returnSelectedValue("ReportID")}&accessToken=${currentToken}`).then((response) => {
				const { data } = response;
				data.map((items) => {
					confirmReportParameters(items.ID, items.ViewerAccessURL);
				});
			});
		}
	};

	const confirmReportParameters = async (reportID, ViewerAccessURL) => {
		var documentViewerLink = ViewerAccessURL;

		try {
			// const existingParamArr = [];
			for (const param of paramValue) {
				if (param.ParameterName.includes("List")) {
					documentViewerLink += `&${param.ParameterName}=${listingJoin(param.ParameterValue)}`;
				} else {
					documentViewerLink += `&${param.ParameterName}=${param.ParameterValue}`;
				}
			}
			// console.log(existingParamArr);

			window.open(documentViewerLink, "_blank");
		} catch (error) {
			console.error("Error fetching report parameters:", error);
		}
	};

	function listingJoin (listValue) {
		return listValue.replace("$%", ",");
	}

	useEffect(() => {
		const ledgerTypeParam = paramValue.find((param) => param.ParameterName === "LedgerType");
		setListingDataSource(null);
		if (ledgerTypeParam) {
			// console.log("LedgerType changed:", ledgerTypeParam.ParameterValue);
			if (ledgerTypeParam.ParameterValue.includes("Sales")) {
				setIsAR(true);
				removeParamValue("ChartList");
				removeParamValue("FromChart");
				removeParamValue("ToChart");
				updateParamValue("ReportName", "GL - Sales Ledger (Standard)");
				setStorageName("SalesLedgerReportListingColumnWidth");
				setDropDownList((prevData) => ({
					...prevData,
					reportList: [{ id: "Xj1VDDzhlpw%3d", code: "GL - Sales Ledger (Standard)" }]
				}));
				updateParamValue("ReportID", "Xj1VDDzhlpw%3d");
			} else if (ledgerTypeParam.ParameterValue.includes("Purchase")) {
				setIsAR(false);
				removeParamValue("ChartList");
				removeParamValue("FromChart");
				removeParamValue("ToChart");
				updateParamValue("ReportName", "GL - Purchase Ledger (Standard)");
				setStorageName("PurchaseLedgerReportListingColumnWidth");
				setDropDownList((prevData) => ({
					...prevData,
					reportList: [{ id: "7vkdi91G7dQ%3d", code: "GL - Purchase Ledger (Standard)" }]
				}));
				updateParamValue("ReportID", "7vkdi91G7dQ%3d");
			} else {
				setIsAR("null");
				removeParamValue("CustList");
				removeParamValue("SuppList");
				removeParamValue("FromCust");
				removeParamValue("ToCust");
				removeParamValue("FromSupp");
				removeParamValue("ToSupp");
				updateParamValue("ReportName", "GL - Ledger (Standard)");
				setStorageName("GLLedgerReportListingColumnWidth");
				setDropDownList((prevData) => ({
					...prevData,
					reportList: [{ id: "15cdsGV76JY%3d", code: "GL - Ledger (Standard)" }]
				}));
				updateParamValue("ReportID", "15cdsGV76JY%3d");
			}
		}
	}, [paramValue.find((param) => param.ParameterName === "LedgerType")?.ParameterValue]);

	useEffect(() => {
		if (isAR === "null") {
			setChartAccountGroupIndex(0);
			setChartAccountVisible(true);
			setUserTypeGroupIndex(undefined);
			setUserTypeVisible(false);
		} else {
			setChartAccountGroupIndex(undefined);
			setChartAccountVisible(false);
			setUserTypeGroupIndex(0);
			setUserTypeVisible(true);
		}
	}, [isAR]);

	const onInitialized = () => {
		var reportListing = "";
		if (isAR === true) {
			reportListing = "isAR";
		} else if (isAR === "null") {
			reportListing = "isGL";
		} else if (!isAR) {
			reportListing = "isAP";
		}

		var parameters = `?userID=${userID?.userID}&reportListingType=${reportListing}&reportName=${returnSelectedValue("ReportName")}`;
		loading();

		tempForm.current = [...paramValue];

		tempForm.current.forEach((param) => {
			if (param.ParameterName.includes("List") && Array.isArray(param.ParameterValue)) {
				param.ParameterValue = param.ParameterValue.join("$%");
			}

			if (typeof param.ParameterValue !== "string") {
				param.ParameterValue = String(param.ParameterValue);
			}
		});

		var copied = tempForm.current;
		// console.log(copied);

		baseapi
			.httppost(`${utils.ReportURL}/api/Report/TrialBalanceLedger${parameters}`, copied)
			.then((response) => {
				const { data } = response;
				// console.log(data);
				if (data !== null && data !== undefined) {
					var mainDataArray = [];
					// data.map((e) => {
					// 	console.log(e.DocumentID !== "");
					// });

					for (var i = 0; i < data.length; i++) {
						// if (data[i].DocumentID !== "")
						mainDataArray.push(data[i]);
					}
					setListingDataSource(mainDataArray);

					if (data.length > 0) {
						setPrintRepDisabled(false);
					}

					// tempForm.current.forEach((param) => {
					// 	if (param.ParameterName === "IsZero") {
					// 		param.ParameterValue = param.ParameterValue === "true";
					// 	}
					// });
				}

				if (data.status) {
					utils.displayPopupMessage(popupMessageRef, {
						visible: true,
						message: data.message,
						type: "Danger"
					});
				}

				closeLoading();
			})
			.catch((e) => {
				// closeLoading();
				console.log(e);
			});

		tempForm.current.forEach((param) => {
			if (param.ParameterName === "IsZero") {
				param.ParameterValue = param.ParameterValue === "true";
			}
		});
	};

	useEffect(() => {
		if (pagingIndex.current["loading"] === true && Array.isArray(dropDownList)) {
			pagingIndex.current["page"] += 1;
			pagingIndex.current["loading"] = false;
		}

		// if (Array.isArray(listingDataSource)) {
		// 	previousDataSource.current = [...listingDataSource];
		// }
	}, [dropDownList]);

	function valueOnChange(e, type = null) {
		try {
			if (e.value !== undefined) {
				const dataField = e.element.getAttribute("dataField");
				setPrintRepDisabled(true);
				if (dataField === "FromDate") {
					setFromDate(utils.dateboxFormat(e.value));
				} else if (dataField === "ToDate") {
					setToDate(utils.dateboxFormat(e.value));
				} else if (dataField === "FilterNumber") {
					if (e.value === 1) {
						switch (type) {
							case "Project":
								setSelectedProject(e.value);
								setOpenProjectRange(true);
								break;
							case "Salesman":
								setSelectedSales(e.value);
								setOpenSalesRange(true);
								break;
							case "Area":
								setSelectedArea(e.value);
								setOpenAreaRange(true);
								break;
							case "ChartAccount":
								setSelectedChartAccount(e.value);
								setOpenChartAccountRange(true);
								break;
							case "Customer":
								setSelectedCust(e.value);
								setOpenCustRange(true);
								break;
							case "Supplier":
								setSelectedSupp(e.value);
								setOpenSuppRange(true);
								break;
							default:
								// setSelectedID(0);
								break;
						}
					}
					if (e.value === 2) {
						switch (type) {
							case "Project":
								setSelectedProject(e.value);
								break;
							case "Salesman":
								setSelectedSales(e.value);
								break;
							case "Area":
								setSelectedArea(e.value);
								break;
							case "ChartAccount":
								setSelectedChartAccount(e.value);
								break;
							case "Customer":
								setSelectedCust(e.value);
								break;
							case "Supplier":
								setSelectedSupp(e.value);
								break;
							default:
								// setSelectedID(0);
								break;
						}
					}
				}

				if (dataField !== "FilterNumber") updateParamValue(dataField, e.value);
			}
		} catch (err) {
			console.log(err);
		}
	}

	const updateParamValue = (key, value) => {
		setParamValue((prevParamValue) => {
			const updatedParamValue = [...prevParamValue]; // Create a copy of the previous state array

			// Find the index of the item with the matching key
			const index = updatedParamValue.findIndex((item) => item.ParameterName === key);

			if (index !== -1) {
				// Update the existing item if it exists
				updatedParamValue[index] = { ParameterName: key, ParameterValue: value };
			} else {
				// Add a new item if the key doesn't exist
				updatedParamValue.push({ ParameterName: key, ParameterValue: value });
			}

			return updatedParamValue;
		});
	};

	const removeParamValue = (key) => {
		setParamValue((prevParamValue) => {
			// Filter out the item with the matching ParameterName
			var updatedParamValue = null;
			updatedParamValue = prevParamValue.filter((item) => item.ParameterName !== key);
			return updatedParamValue;
		});
	};

	const returnSelectedKeys = (type) => {
		const paramObject = paramValue.reduce((acc, curr) => {
			acc[curr.ParameterName] = curr.ParameterValue;
			return acc;
		}, {});

		let selectedKeys = [];

		if (type === "Project" && paramObject["ProjectList"]) {
			selectedKeys = ensureArray(paramObject["ProjectList"]);
		} else if (type === "Salesman" && paramObject["SalesList"]) {
			selectedKeys = ensureArray(paramObject["SalesList"]);
		} else if (type === "Area" && paramObject["AreaList"]) {
			selectedKeys = ensureArray(paramObject["AreaList"]);
		} else if (type === "ChartAccount" && paramObject["ChartList"]) {
			selectedKeys = ensureArray(paramObject["ChartList"]);
		} else if (type === "Customer" && paramObject["CustList"]) {
			selectedKeys = ensureArray(paramObject["CustList"]);
		} else if (type === "Supplier" && paramObject["SuppList"]) {
			selectedKeys = ensureArray(paramObject["SuppList"]);
		}

		return selectedKeys;
	};

	const ensureArray = (value) => {
		if (Array.isArray(value)) {
			return value;
		} else {
			return value.split("$%");
		}
	};

	const returnSelectedValue = (type) => {
		const filtered = paramValue.filter((x) => x.ParameterName === type);
		return filtered.length > 0 ? filtered[0].ParameterValue : null;
	};

	const returnDropDownList = (type) => {
		if (type === "Project") {
			return dropDownList.projectList;
		} else if (type === "Salesman") {
			return dropDownList.salesmanList;
		} else if (type === "Area") {
			return dropDownList.areaList;
		} else if (type === "ChartAccount") {
			return dropDownList.chartList;
		} else if (type === "Customer") {
			return dropDownList.customerList;
		} else if (type === "Supplier") {
			return dropDownList.supplierList;
		}
	};

	function DataGridMultiRender(type) {
		let selectedKeys = returnSelectedKeys(type);
		// console.log(type);

		return (
			<div className='customized-lookup-container'>
				<div className='customized-lookup-search-container'>
					<div>
						<TextBox
							ref={searchBoxRef}
							placeholder='Search...'
							width={250}
							valueChangeEvent='keyup'
							showClearButton={true}
							onValueChanged={(e) => {
								gridRef.current.instance.searchByText(e.value);
								updateDropDownList(e.value, type, pagingIndex.current["page"], false);
							}}
							onKeyDown={(e) => {
								// console.log("key down", e.event)
								if (e.event.key === "ArrowDown") {
									gridRef.current.instance.focus();
								}
							}}
						/>
					</div>

					<div className='customized-lookup-btn-section'>
						<Button
							text='Clear'
							onClick={(e) => {
								// if (type === "Project") {
								// 	removeParamValue("ProjectList");
								// } else if (type === "Salesman") {
								// 	removeParamValue("SalesList");
								// } else if (type === "Area") {
								// 	removeParamValue("AreaList");
								// } else if (type === "ChartAccount") {
								// 	removeParamValue("ChartList");
								// } else if (type === "Supplier") {
								// 	removeParamValue("SuppList");
								// } else if (type === "Customer") {
								// 	removeParamValue("CustList");
								// }

								if (searchBoxRef.current !== null) searchBoxRef.current.instance.reset();
								// refreshLookupData(type);
							}}
						/>
					</div>

					<div>
						<Button
							style={{ marginLeft: "5px" }}
							text='Clear Filter'
							stylingMode='text'
							type='default'
							onClick={() => {
								if (type === "Project") {
									removeParamValue("ProjectList");
									removeParamValue("FromProject");
									removeParamValue("ToProject");
									setSelectedProject(0);
								} else if (type === "Salesman") {
									setSelectedSales(0);
									removeParamValue("SalesList");
									removeParamValue("FromSales");
									removeParamValue("ToSales");
								} else if (type === "Area") {
									setSelectedArea(0);
									removeParamValue("AreaList");
									removeParamValue("FromArea");
									removeParamValue("ToArea");
								} else if (type === "ChartAccount") {
									setSelectedChartAccount(0);
									removeParamValue("ChartList");
									removeParamValue("FromChart");
									removeParamValue("ToChart");
								} else if (type === "Customer") {
									setSelectedCust(0);
									removeParamValue("CustList");
									removeParamValue("FromCust");
									removeParamValue("ToCust");
								} else if (type === "Supplier") {
									setSelectedSupp(0);
									removeParamValue("SuppList");
									removeParamValue("FromSupp");
									removeParamValue("ToSupp");
								}
								refreshLookupData(type);
							}}
							elementAttr={{ dataField: "FilterNumber" }}
						/>
					</div>
				</div>
				<DataGrid
					ref={gridRef}
					height={200}
					className={"lookup-datagrid gl-listing-datagrid"}
					showBorders={true}
					dataSource={returnDropDownList(type)}
					scrolling={{ columnRenderingMode: "standard", showScrollbar: "onHover" }}
					columnChooser={{ enabled: false }}
					allowColumnResizing={true}
					allowColumnReordering={true}
					hoverStateEnabled={true}
					paging={{ enabled: false }}
					keyExpr='code'
					focusedRowEnabled={true}
					focusedRowKey={selectedKeys}
					selectedRowKeys={selectedKeys}
					onSelectionChanged={(e) => dataGridOnSelectionChanged(e, type)}
					onContentReady={(element) => {
						const scrollable = element.component.getScrollable();
						if (scrollable !== undefined) {
							scrollable.on("scroll", function (e) {
								if (e.reachedBottom) {
									if (pagingIndex.current["loading"] === false) {
										// console.log("page", pagingIndex.current["page"]);
										pagingIndex.current["loading"] = true;
										updateDropDownList("", type, pagingIndex.current["page"], true);
										// console.log(e);
									}
								}
							});
						}
					}}
					onKeyDown={(e) => {
						// if(e.event.key === "Enter"){
						//     setDropdownOpened(false);
						// }
						if (e.event.key === "ArrowUp") {
							// If focus is one the first row then brings focus back to the search box
							if (currentFocusIndex.current === 0) {
								if (type === "Project") {
									removeParamValue("ProjectList");
								} else if (type === "Salesman") {
									removeParamValue("SalesList");
								} else if (type === "Area") {
									removeParamValue("AreaList");
								} else if (type === "ChartAccount") {
									removeParamValue("ChartList");
								} else if (type === "Supplier") {
									removeParamValue("SuppList");
								} else if (type === "Customer") {
									removeParamValue("CustList");
								}
								refreshLookupData(type);
							}
						}
					}}
					onFocusedRowChanging={(e) => {
						if (e.event === null) {
							e.cancel = true;
						} else if (e.event.key === undefined) {
							e.cancel = true;
						}
					}}
					// onFocusedRowChanged={(e) => {
					//   const data = e.row.data;
					//   currentFocusIndex.current = e.rowIndex;
					//   if(e.event.key === "Enter") {
					//     setSelectedCustKey(data.id);
					//   }
					// }}
				>
					<Column
						dataField='code'
						elementAttr={{
							dataField: type === "Customer" ? "CustomerCode" : type === "Supplier" ? "SupplierCode" : type === "Salesman" ? "SalesmanCode" : type === "Area" ? "AreaCode" : type === "ChartAccount" ? "ChartAccountCode" : "ProjectCode"
						}}
						caption={type === "Customer" ? "Customer Code" : type === "Supplier" ? "Supplier Code" : type === "Salesman" ? "Salesman Code" : type === "Area" ? "Area Code" : type === "ChartAccount" ? "Chart Account Code" : "Project Code"}
						visible={true}
						defaultSortOrder={"asc"}
						alignment='left'
						allowGrouping={false}
					/>

					<Column
						dataField='name'
						elementAttr={{
							dataField: type === "Customer" ? "CustomerName" : type === "Supplier" ? "SupplierName" : type === "Salesman" ? "SalesmanName" : type === "Project" ? "ProjectName" : type === "ChartAccount" ? "ChartAccountName" : "AreaName"
						}}
						caption={type === "Customer" ? "Customer Name" : type === "Supplier" ? "Supplier Name" : type === "Salesman" ? "Salesman Name" : type === "Project" ? "Project Name" : type === "ChartAccount" ? "Chart Account Name" : "Area Name"}
						visible={true}
						alignment='left'
						allowGrouping={false}
					/>

					{(type === "Customer" || type === "Supplier")  && <Column
						dataField='forexCode'
						elementAttr={{ dataField: type === "Customer" ? "CustomerForexCode" : "SupplierForexCode" }}
						caption={"Currency"}
						visible={true}
						alignment='left'
						allowGrouping={false}
					/>}

					<Selection mode='multiple' showSelectAll={true} selectAllMode='page' showCheckBoxesMode={"always"} />
					<Toolbar visible={false} />
				</DataGrid>
			</div>
		);
	}

	const dataGridOnSelectionChanged = (e, type) => {
		if (type === "Project") {
			updateParamValue("ProjectList", e.selectedRowKeys);
		} else if (type === "Salesman") {
			updateParamValue("SalesList", e.selectedRowKeys);
		} else if (type === "Area") {
			updateParamValue("AreaList", e.selectedRowKeys);
		} else if (type === "ChartAccount") {
			updateParamValue("ChartList", e.selectedRowKeys);
		} else if (type === "Customer") {
			updateParamValue("CustList", e.selectedRowKeys);
		} else if (type === "Supplier") {
			updateParamValue("SuppList", e.selectedRowKeys);
		}
	};

	const popFunction = (e, type) => {
		if (e.cancel === false) {
			if (type === "Project") {
				setOpenProjectRange(false);
			} else if (type === "Salesman") {
				setOpenSalesRange(false);
			} else if (type === "Area") {
				setOpenAreaRange(false);
			} else if (type === "ChartAccount") {
				setOpenChartAccountRange(false);
			} else if (type === "Customer") {
				setOpenCustRange(false);
			} else if (type === "Supplier") {
				setOpenSuppRange(false);
			}
		} else {
			if (type === "Project") {
				setOpenProjectRange(true);
			} else if (type === "Salesman") {
				setOpenSalesRange(true);
			} else if (type === "Area") {
				setOpenAreaRange(true);
			} else if (type === "ChartAccount") {
				setOpenChartAccountRange(true);
			} else if (type === "Supplier") {
				setOpenSuppRange(true);
			} else if (type === "Customer") {
				setOpenCustRange(true);
			}
		}
	};

	const returnDropDownListURL = (type) => {
		if (type === "Salesman") {
			return "api/Utilities/GetSalesmans";
		} else if (type === "Area") {
			return "api/Utilities/GetAreas";
		} else if (type === "Project") {
			return "api/Utilities/GetProjects";
		} else if (type === "ChartAccount") {
			return "api/Utilities/GetGLChartAccounts";
		} else if (type === "Supplier") {
			return "api/Utilities/GetSuppliers";
		} else if (type === "Customer") {
			return "api/Utilities/GetCustomers";
		}
	};

	function refreshLookupData(type) {
		if (type === "Salesman") {
			setDropDownList((prevState) => ({
				...prevState,
				salesmanList: [...originalDropDownList.salesmanList]
			}));
		} else if (type === "Area") {
			setDropDownList((prevState) => ({
				...prevState,
				areaList: [...originalDropDownList.areaList]
			}));
		} else if (type === "Project") {
			setDropDownList((prevState) => ({
				...prevState,
				projectList: [...originalDropDownList.projectList]
			}));
		} else if (type === "ChartAccount") {
			setDropDownList((prevState) => ({
				...prevState,
				chartList: [...originalDropDownList.chartList]
			}));
		} else if (type === "Customer") {
			setDropDownList((prevState) => ({
				...prevState,
				customerList: [...originalDropDownList.customerList]
			}));
		} else if (type === "Supplier") {
			setDropDownList((prevState) => ({
				...prevState,
				supplierList: [...originalDropDownList.supplierList]
			}));
		}
	}

	const arrayToString = (arr) => {
		if (Array.isArray(arr)) {
			return arr.join(" ");
		} else {
			return arr;
		}
	};

	const updateDropDownList = (paramValue, type, page, paging) => {
		const typeToArrayNameMap = {
			Customer: "customerList",
			Salesman: "salesmanList",
			Area: "areaList",
			Supplier: "supplierList",
			Project: "projectList",
			ChartAccount: "chartList"
		};
		// console.log(paramValue);
		var value = "";

		if (!paging) {
			page = 0;
		}

		if (paramValue !== undefined) {
			value = paramValue;
		}

		const arrayName = typeToArrayNameMap[type];
		const query = value.length === 0 ? `${paramValue}` : `${paramValue} ${arrayToString(returnSelectedKeys(type))}`;

		if (type === "ChartAccount") {
			baseapi
				.httpget(returnDropDownListURL(type), { q: query, singleReturn: true/*, chartAccountType: "GL"*/, page: page })
				.then((response) => {
					const data = response.data;
					// console.log(data);
					setDropDownList((prevState) => {
						const existingArray = prevState[arrayName] || [];
						const newData = data.filter((newItem) => !existingArray.some((existingItem) => existingItem.id === newItem.id));
						return {
							...prevState,
							[arrayName]: [...existingArray, ...newData]
						};
					});
				})
				.catch(() => {
					throw "Network error";
				});
		} else {
			baseapi
				.httpget(returnDropDownListURL(type), { q: query, singleReturn: true, page: page })
				.then((response) => {
					const data = response.data;
					// console.log(data);
					setDropDownList((prevState) => {
						const existingArray = prevState[arrayName] || [];
						const newData = data.filter((newItem) => !existingArray.some((existingItem) => existingItem.id === newItem.id));
						return {
							...prevState,
							[arrayName]: [...existingArray, ...newData]
						};
					});
				})
				.catch(() => {
					throw "Network error";
				});
		}
	};

	useEffect(() => {
		const toDateObj = new Date(toDate);
		const fromDateObj = new Date(fromDate);

		if (fromDateObj > toDateObj) {
			utils.displayPopupMessage(popupMessageRef, {
				visible: true,
				message: "To Date cannot be earlier than From Date.",
				type: "Warning"
			});

			const setDate = new Date(); // current date

			if (fromDateObj > setDate) {
				setToDate(utils.dateboxFormat(fromDate));
			} else {
				setToDate(utils.dateboxFormat(setDate));
			}
		}
	}, [toDate, fromDate]);
	
	const onRowPrepared = (e) => {
		if (e.rowType === "data") {			
			if(!utils.isNullOrEmpty(e.data["canExpand"])) {
				if(!e.data["canExpand"]) {
					e.cells[0].cellElement.classList.remove('dx-datagrid-expand');  
					  e.cells[0].cellElement.childNodes[0].classList.remove('dx-datagrid-group-closed');  
				}
			}
		}

		if (e.rowType === "header" && storageName !== null) {
			const find = e.columns.find((c) => c.command === "transparent");
			//Ignore the fixed columns
			if (utils.isNullOrEmpty(find)) {
				const columns = e.columns;
				const previousRecord = getFromLS(storageName);

				if (previousRecord === undefined || previousRecord.length !== columns.length) {
					for (var i = 0; i < columns.length; i++) {
						columns[i]["columnWidth"] = null;
					}
				} else {
					for (var i = 0; i < columns.length; i++) {
						columns[i]["columnWidth"] = previousRecord[i]["columnWidth"];
					}
				}

				saveToLS(storageName, columns);
			}
		}
	};

	const onOptionChanged = (e) => {
		if (e.name === "columns" && e.fullName.includes("width") && storageName !== null) {
			//Sample format : columns[0].width
			const columns = getFromLS(storageName);
			const columnIndex = parseInt(e.fullName.substring(8, 9));
			const arrayIndex = columns.findIndex((x) => x.index === columnIndex);

			// Save the new width
			columns[arrayIndex]["columnWidth"] = e.value;
			saveToLS(storageName, columns);
		}
	};

	const columns = [
		{ dataField: "DocumentDate", caption: "Date", dataType: "date", format: "dd/MM/yyyy" },
		{
		  dataField: "DocumentNo",
		  caption: "Doc No",
		  cellRender: (e) => {
			const data = e.data;
			const id = data.DocumentID;
			const nonIntegerRegex = /[a-zA-Z-]+/g;
			if (data.DocumentNo !== undefined) {
			  return (
				<span
				  className="clickable-span-tag"
				  onClick={() => {
						let valueToCheck = "";

						if (data.hasOwnProperty("OriginalDocumentID") && data.OriginalDocumentID) {
							// In AR/AP document listing, for Credit and Debit notes, 
							// we still  open the AR/AP version.
							if (["PPD", "PRE", "SC", "SD"].includes(data.OriginalTransactionTypeCode)) {
								valueToCheck = data.TransactionTypeCode.trim().match(nonIntegerRegex);
								setCurrentEditID(id);
							} else {
								valueToCheck = data.OriginalTransactionTypeCode.trim().match(nonIntegerRegex);
								setCurrentEditID(data.OriginalDocumentID);
							}
						} else {
							valueToCheck = data.TransactionTypeCode.trim().match(nonIntegerRegex);
							setCurrentEditID(id);
						}

						valueToCheck = valueToCheck ? valueToCheck.join("") : "";

						utils.transactionCodeList.find((item) => {
							if (item.TransType === valueToCheck) {
							setForm(item.DocumentType);
							const ledgerTypeParam = paramValue.find(
								(param) => param.ParameterName === "LedgerType"
							);
							if (
								ledgerTypeParam.ParameterValue.includes("Customer") ||
								ledgerTypeParam.ParameterValue.includes("Supplier")
							) {
								setDocumentDate(data.DocumentDate);
								setDocumentNo(data.DocumentNo);
							}
							}
					  });
				  }}
				>
				  {e.displayValue}
				</span>
			  );
			} else {
			  return e.displayValue;
			}
		  },
		},
		{ dataField: "ChartAccount", caption: "Code", groupIndex: chartAccountGroupIndex, visible: chartAccountVisible },
		{ dataField: "userType", caption: "Code", groupIndex: userTypeGroupIndex, visible: userTypeVisible },
		{ dataField: "Description", caption: "Description" },
		{ dataField: "Description2", caption: "Description 2", visible: isAR === "null" },
		{ dataField: "ProjectCode", caption: "Project" },
		{ dataField: "ChequeNo", caption: "Cheque No" },
		{
		  dataField: "Debit",
		  alignment: "center",
		  caption: "Debit",
		  dataType: "number",
		  customizeText: (e) =>
			e.value !== 0
			  ? utils.numberToCurrency(e.value)
			  : returnSelectedValue("IsZero")
			  ? utils.numberToCurrency(e.value)
			  : "-",
		},
		{
		  dataField: "Credit",
		  alignment: "center",
		  caption: "Credit",
		  dataType: "number",
		  customizeText: (e) =>
			e.value !== 0
			  ? utils.numberToCurrency(e.value)
			  : returnSelectedValue("IsZero")
			  ? utils.numberToCurrency(e.value)
			  : "-",
		},
		{
		  dataField: "Balance",
		  alignment: "center",
		  caption: "Balance",
		  dataType: "number",
		  customizeText: (e) =>
			e.value !== 0
			  ? utils.numberToCurrency(e.value)
			  : returnSelectedValue("IsZero")
			  ? utils.numberToCurrency(e.value)
			  : "-",
		},
		...(isAR === undefined
		  ? [{ dataField: "Type", alignment: "center", caption: "Type", dataType: "number" }]
		  : []),
	];

	return (
		<Container>
			<div className='listing-page-title-container'>
				<span className='datagrid-customized-title'>
					<PathIndicator />
				</span>
			</div>
			<Popup visible={openReportPop} width={"auto"} height={"auto"} dragEnabled={false} title='Print Report' showCloseButton={false}>
				<div className='popup-group-form-item'>
					<div className='popup-group-form-label'>Report </div>
					<SelectBox
						value={returnSelectedValue("ReportID")}
						elementAttr={{ dataField: "ReportID" }}
						onValueChanged={(e) => {
							// console.log(e.value);
							updateParamValue("ReportID", e.value);
						}}
						alignment='left'
						width={"450px"}
						dataSource={dropDownList.reportList}
						displayExpr={"code"}
						valueExpr={"id"}
						searchEnabled={true}
						showClearButton={true}
					/>
				</div>
				<div className='popup-group-form-item' style={{ marginTop: "15px", justifyContent: "flex-end" }}>
					<Button text='Cancel' type='normal' width={"auto"} style={{ marginLeft: "20px" }} onClick={(e) => setOpenReportPop(false)} />
					<Button text='Print Report' type='success' width={"auto"} style={{ marginLeft: "20px" }} onClick={(e) => handlePrintBtn(e)} />
				</div>
			</Popup>
			<div style={{ backgroundColor: "#EFEFEF", borderRadius: "15px", width: "85%", padding: "0.7%" }}>
				<div className='popup-form-item-container2'>
					<div className='popup-group-form-item'>
						<div className='popup-group-form-label'>Date</div>
						<DateBox
							type='date'
							value={fromDate}
							elementAttr={{ dataField: "FromDate" }}
							useMaskBehavior={true}
							label='From'
							labelMode='floating'
							displayFormat='dd/MM/yyyy'
							dateSerializationFormat='yyyy-MM-dd'
							onValueChanged={(e) => {
								valueOnChange(e, "FromDate");
							}}
						/>

						<DateBox
							type='date'
							value={toDate}
							elementAttr={{ dataField: "ToDate" }}
							useMaskBehavior={true}
							label='To'
							labelMode='floating'
							displayFormat='dd/MM/yyyy'
							dateSerializationFormat='yyyy-MM-dd'
							onValueChanged={(e) => {
								valueOnChange(e, "ToDate");
							}}
							style={{ marginLeft: "5%" }}
						/>
					</div>

					<div className='popup-group-form-item'>
						<div className='popup-group-form-label'	style={{ marginTop: "7px" }}> Project </div>
						{selectedProject === 0 && (
							<SelectBox
								width={"250px"}
								dataSource={filterTypes}
								value={selectedProject}
								displayExpr={"Type"}
								valueExpr={"ID"}
								elementAttr={{ dataField: "FilterNumber" }}
								onValueChanged={(e) => {
									valueOnChange(e, "Project");
								}}
							/>
						)}
						{selectedProject === 1 && (
							<div onClick={() => setOpenProjectRange(true)}>
								<TextBox
									value={returnSelectedValue("FromProject") || returnSelectedValue("ToProject") ? `${returnSelectedValue("FromProject")} to ${returnSelectedValue("ToProject")}` : "No Range Selected Yet"}
									// useMaskBehavior={true} // Enable mask behavior
									// mask="cccccc" // Define the input mask
									id='Project'
									style={{ marginRight: "10px" }}
									width={"250px"}>
									<Popover
										target='#Project'
										showEvent={"click"}
										// hideEvent="mouseleave"
										position='bottom'
										visible={openProjectRange}
										onHiding={(e) => popFunction(e, "Project")}
										dragEnabled={false}
										hideOnOutsideClick={true}
										width={500}
										height={125}>
										<div>
											<div className='popup-form-item-container2'>
												<div>
													<label>From</label>
													<CustomizedLookup
														ref={fromProjectLookupRef}
														className={"ar-listing-datagrid"}
														dataSource={fromProjectList}
														displayExpr={"code"}
														valueExpr={"code"}
														value={returnSelectedValue("FromProject")}
														onSelectionChanged={(e) => {
															updateParamValue("FromProject", e.value);
														}}
														dataSourceURL={"api/Utilities/GetProjects"}
														displayText={returnSelectedValue("FromProject")}
														onDataSourceChanged={setFromProjectList}>
														<Column dataField='code' elementAttr={{ dataField: "ProjectCode" }} visible={true} caption='Project Code' alignment='left' allowGrouping={false} />
														<Column dataField='name' elementAttr={{ dataField: "ProjectName" }} visible={true} caption='Project Name' alignment='left' allowGrouping={false} />
													</CustomizedLookup>
												</div>
												<div>
													<label>To</label>

													<CustomizedLookup
														ref={toProjectLookupRef}
														className={"ar-listing-datagrid"}
														dataSource={toProjectList}
														displayExpr={"code"}
														valueExpr={"code"}
														value={returnSelectedValue("ToProject")}
														displayText={returnSelectedValue("ToProject")}
														onSelectionChanged={(e) => {
															updateParamValue("ToProject", e.value);
														}}
														dataSourceURL={"api/Utilities/GetProjects"}
														onDataSourceChanged={setToProjectList}>
														<Column dataField='code' elementAttr={{ dataField: "ProjectCode" }} visible={true} caption='Project Code' alignment='left' allowGrouping={false} />
														<Column dataField='name' elementAttr={{ dataField: "ProjectName" }} visible={true} caption='Project Name' alignment='left' allowGrouping={false} />
													</CustomizedLookup>
												</div>
											</div>
											<div>
												<Button
													style={{ position: "absolute", top: "65%", left: "75%" }}
													text='Clear Filter'
													stylingMode='text'
													type='default'
													onClick={() => {
														removeParamValue("ProjectList");
														removeParamValue("FromProject");
														removeParamValue("ToProject");
														setSelectedProject(0);
													}}
													elementAttr={{ dataField: "FilterNumber" }}
												/>
											</div>
										</div>
									</Popover>
								</TextBox>
							</div>
						)}
						{selectedProject === 2 && (
							<div>
								<TextBox
									value={returnSelectedKeys("Project").length !== 0 ? `${returnSelectedKeys("Project").length} Selected  (${returnSelectedKeys("Project")})` : `${returnSelectedKeys("Project").length} Selected`}
									style={{ marginTop: "-35px" }}
									height={0}
									showClearButton={false}
									width={"250px"}>
									<DropDownBox
										// onClosed={(e) => closeDropDownBox(e, "Project")}
										ref={dropdownRef}
										// opened={openCustSpecific}
										valueExpr='id'
										deferRendering={false}
										displayExpr='code'
										showClearButton={false}
										contentRender={(e) => DataGridMultiRender("Project")}
										defaultOpened={true}
										style={{ marginTop: "-2.5px" }}
										width={"250px"}
										dropDownOptions={{ width: "auto" }}
									/>
								</TextBox>
							</div>
						)}
					</div>
				</div>

				<div className='popup-form-item-container2'>
					<div className='popup-group-form-item'>
						<div className='popup-group-form-label'> Ledger Type </div>
						<CustomizedLookup
							ref={documentModuleLookupRef}
							elementAttr={{ dataField: "LedgerType" }}
							// value={returnSelectedValue("LedgerType")}
							dataSource={documentModuleList}
							displayExpr={"code"}
							valueExpr={"code"}
							width={"250px"}
							onSelectionChanged={(e) => {
								// console.log(e.value);
								if (e.value === null) {
									removeParamValue("LedgerType");
								} else {
									updateParamValue("LedgerType", e.value);
								}
								setPrintRepDisabled(true);
							}}
							value={"General Ledger"}
							dataSourceURL={`api/Utilities/GetLedgerReports`}
							displayText={"General Ledger"}
							onDataSourceChanged={setDocumentModuleList}>
							<Column dataField='code' caption={"Report Name"}></Column>
						</CustomizedLookup>
					</div>
					<div className='popup-group-form-item'>
						<div className='popup-group-form-label'	style={{ marginTop: "7px" }}> Salesman </div>
						{selectedSales === 0 && (
							<SelectBox
								width={"250px"}
								dataSource={filterTypes}
								value={selectedSales}
								displayExpr={"Type"}
								valueExpr={"ID"}
								elementAttr={{ dataField: "FilterNumber" }}
								onValueChanged={(e) => {
									valueOnChange(e, "Salesman");
								}}
							/>
						)}
						{selectedSales === 1 && (
							<div onClick={() => setOpenSalesRange(true)}>
								<TextBox
									value={returnSelectedValue("FromSales") || returnSelectedValue("ToSales") ? `${returnSelectedValue("FromSales")} to ${returnSelectedValue("ToSales")}` : "No Range Selected Yet"}
									// useMaskBehavior={true} // Enable mask behavior
									// mask="cccccc" // Define the input mask
									id='Salesman'
									style={{ marginRight: "10px" }}
									width={"250px"}>
									<Popover
										target='#Salesman'
										showEvent={"click"}
										// hideEvent="mouseleave"
										position='bottom'
										visible={openSalesRange}
										onHiding={(e) => popFunction(e, "Salesman")}
										dragEnabled={false}
										hideOnOutsideClick={true}
										width={500}
										height={125}>
										<div>
											<div className='popup-form-item-container2'>
												<div>
													<label>From</label>
													<CustomizedLookup
														ref={fromSalesmanLookupRef}
														className={"ar-listing-datagrid"}
														dataSource={fromSalesmanList}
														displayExpr={"code"}
														valueExpr={"code"}
														value={returnSelectedValue("FromSales")}
														onSelectionChanged={(e) => {
															updateParamValue("FromSales", e.value);
														}}
														dataSourceURL={"api/Utilities/GetSalesmans"}
														displayText={returnSelectedValue("FromSales")}
														onDataSourceChanged={setFromSalesmanList}>
														<Column dataField='code' elementAttr={{ dataField: "SalesmanCode" }} visible={true} caption='Salesman Code' alignment='left' allowGrouping={false} />
														<Column dataField='name' elementAttr={{ dataField: "SalesmanName" }} visible={true} caption='Salesman Name' alignment='left' allowGrouping={false} />
													</CustomizedLookup>
												</div>
												<div>
													<label>To</label>

													<CustomizedLookup
														ref={toSalesmanLookupRef}
														className={"ar-listing-datagrid"}
														dataSource={toSalesmanList}
														displayExpr={"code"}
														valueExpr={"code"}
														value={returnSelectedValue("ToSales")}
														onSelectionChanged={(e) => {
															updateParamValue("ToSales", e.value);
														}}
														dataSourceURL={"api/Utilities/GetSalesmans"}
														displayText={returnSelectedValue("ToSales")}
														onDataSourceChanged={setToSalesmanList}>
														<Column dataField='code' elementAttr={{ dataField: "SalesmanCode" }} visible={true} caption='Salesman Code' alignment='left' allowGrouping={false} />
														<Column dataField='name' elementAttr={{ dataField: "SalesmanName" }} visible={true} caption='Salesman Name' alignment='left' allowGrouping={false} />
													</CustomizedLookup>
												</div>
											</div>
											<div>
												<Button
													style={{ position: "absolute", top: "65%", left: "75%" }}
													text='Clear Filter'
													stylingMode='text'
													type='default'
													onClick={() => {
														removeParamValue("SalesList");
														removeParamValue("FromSales");
														removeParamValue("ToSales");
														setSelectedSales(0);
													}}
													elementAttr={{ dataField: "FilterNumber" }}
												/>
											</div>
										</div>
									</Popover>
								</TextBox>
							</div>
						)}
						{selectedSales === 2 && (
							<div>
								<TextBox
									value={returnSelectedKeys("Salesman").length !== 0 ? `${returnSelectedKeys("Salesman").length} Selected  (${returnSelectedKeys("Salesman")})` : `${returnSelectedKeys("Salesman").length} Selected`}
									style={{ marginTop: "-35px" }}
									height={0}
									showClearButton={false}
									width={"250px"}>
									<DropDownBox
										ref={dropdownRef}
										// opened={openSalesSpecific}
										// onClosed={(e) => closeDropDownBox(e, "Salesman")}
										valueExpr='id'
										deferRendering={false}
										displayExpr='code'
										showClearButton={false}
										contentRender={() => DataGridMultiRender("Salesman")}
										defaultOpened={true}
										width={"250px"}
										style={{ marginTop: "-2.5px" }}
										dropDownOptions={{ width: "auto" }}
									/>
								</TextBox>
							</div>
						)}
					</div>
				</div>

				<div className='popup-form-item-container2'>
					{isAR === "null" && (
						<div className='popup-group-form-item'>
							<div className='popup-group-form-label'	style={{ marginTop: "7px" }}> Chart Account </div>
							{selectedChartAccount === 0 && (
								<SelectBox
									width={"250px"}
									dataSource={filterTypes}
									value={selectedChartAccount}
									displayExpr={"Type"}
									valueExpr={"ID"}
									elementAttr={{ dataField: "FilterNumber" }}
									onValueChanged={(e) => {
										valueOnChange(e, "ChartAccount");
									}}
								/>
							)}
							{selectedChartAccount === 1 && (
								<div onClick={() => setOpenChartAccountRange(true)}>
									<TextBox
										value={returnSelectedValue("FromChart") || returnSelectedValue("ToChart") ? `${returnSelectedValue("FromChart")} to ${returnSelectedValue("ToChart")}` : "No Range Selected Yet"}
										// useMaskBehavior={true} // Enable mask behavior
										// mask="cccccc" // Define the input mask
										id='ChartAccount'
										style={{ marginRight: "10px" }}
										width={"250px"}>
										<Popover
											target='#ChartAccount'
											showEvent={"click"}
											// hideEvent="mouseleave"
											position='bottom'
											visible={openChartAccountRange}
											onHiding={(e) => popFunction(e, "ChartAccount")}
											dragEnabled={false}
											hideOnOutsideClick={true}
											width={500}
											height={125}>
											<div>
												<div className='popup-form-item-container2'>
													<div>
														<label>From</label>
														<CustomizedLookup
															ref={fromChartLookupRef}
															className={"ar-listing-datagrid"}
															dataSource={fromChartList}
															displayExpr={"code"}
															valueExpr={"code"}
															value={returnSelectedValue("FromChart")}
															onSelectionChanged={(e) => {
																updateParamValue("FromChart", e.value);
															}}
															dataSourceURL={`api/Utilities/GetGLChartAccounts`}
															// mergeParams={{ chartAccountType: "GL" }}
															displayText={returnSelectedValue("FromChart")}
															onDataSourceChanged={setFromChartList}>
															<Column dataField='code' elementAttr={{ dataField: "ChartCode" }} visible={true} caption='Chart Code' alignment='left' allowGrouping={false} />
															<Column dataField='name' elementAttr={{ dataField: "ChartName" }} visible={true} caption='Chart Name' alignment='left' allowGrouping={false} />
														</CustomizedLookup>
													</div>
													<div>
														<label>To</label>

														<CustomizedLookup
															ref={toChartLookupRef}
															className={"ar-listing-datagrid"}
															dataSource={toChartList}
															displayExpr={"code"}
															valueExpr={"code"}
															value={returnSelectedValue("ToChart")}
															onSelectionChanged={(e) => {
																updateParamValue("ToChart", e.value);
															}}
															dataSourceURL={`api/Utilities/GetGLChartAccounts`}
															// mergeParams={{ chartAccountType: "GL" }}
															displayText={returnSelectedValue("ToChart")}
															onDataSourceChanged={setToChartList}>
															<Column dataField='code' elementAttr={{ dataField: "ChartCode" }} visible={true} caption='Chart Code' alignment='left' allowGrouping={false} />
															<Column dataField='name' elementAttr={{ dataField: "ChartName" }} visible={true} caption='Chart Name' alignment='left' allowGrouping={false} />
														</CustomizedLookup>
													</div>
												</div>
												<div>
													<Button
														style={{ position: "absolute", top: "65%", left: "75%" }}
														text='Clear Filter'
														stylingMode='text'
														type='default'
														onClick={() => {
															removeParamValue("ChartList");
															removeParamValue("FromChart");
															removeParamValue("ToChart");
															setSelectedChartAccount(0);
														}}
														elementAttr={{ dataField: "FilterNumber" }}
													/>
												</div>
											</div>
										</Popover>
									</TextBox>
								</div>
							)}
							{selectedChartAccount === 2 && (
								<div>
									<TextBox
										value={returnSelectedKeys("ChartAccount").length !== 0 ? `${returnSelectedKeys("ChartAccount").length} Selected  (${returnSelectedKeys("ChartAccount")})` : `${returnSelectedKeys("ChartAccount").length} Selected`}
										style={{ marginTop: "-35px" }}
										height={0}
										showClearButton={false}
										width={"250px"}>
										<DropDownBox
											ref={dropdownRef}
											// opened={openSalesSpecific}
											// onClosed={(e) => closeDropDownBox(e, "Salesman")}
											valueExpr='id'
											deferRendering={false}
											displayExpr='code'
											showClearButton={false}
											contentRender={() => DataGridMultiRender("ChartAccount")}
											defaultOpened={true}
											width={"250px"}
											style={{ marginTop: "-2.5px" }}
											dropDownOptions={{ width: "auto" }}
										/>
									</TextBox>
								</div>
							)}
						</div>
					)}

					{isAR && isAR !== "null" && (
						<div className='popup-group-form-item'>
							<div className='popup-group-form-label'	style={{ marginTop: "7px" }}> Customer </div>
							{selectedCust === 0 && (
								<SelectBox
									width={"250px"}
									dataSource={filterTypes}
									value={selectedCust}
									displayExpr={"Type"}
									valueExpr={"ID"}
									elementAttr={{ dataField: "FilterNumber" }}
									onValueChanged={(e) => {
										valueOnChange(e, "Customer");
									}}
								/>
							)}
							{selectedCust === 1 && (
								<div onClick={() => setOpenCustRange(true)}>
									<TextBox
										value={returnSelectedValue("FromCust") || returnSelectedValue("ToCust") ? `${returnSelectedValue("FromCust")} to ${returnSelectedValue("ToCust")}` : "No Range Selected Yet"}
										// useMaskBehavior={true} // Enable mask behavior
										// mask="cccccc" // Define the input mask
										id='Customer'
										style={{ marginRight: "10px" }}
										width={"250px"}>
										<Popover
											target='#Customer'
											showEvent={"click"}
											// hideEvent="mouseleave"
											position='bottom'
											visible={openCustRange}
											onHiding={(e) => popFunction(e, "Customer")}
											dragEnabled={false}
											hideOnOutsideClick={true}
											width={500}
											height={125}>
											<div>
												<div className='popup-form-item-container2'>
													<div>
														<label>From</label>
														<CustomizedLookup
															ref={fromCustomerLookupRef}
															className={"ar-listing-datagrid"}
															dataSource={fromCustomerList}
															displayExpr={"code"}
															valueExpr={"code"}
															value={returnSelectedValue("FromCust")}
															onSelectionChanged={(e) => {
																updateParamValue("FromCust", e.value);
															}}
															dataSourceURL={"api/Utilities/GetCustomers"}
															displayText={returnSelectedValue("FromCust")}
															onDataSourceChanged={setFromCustomerList}>
															<Column dataField='code' elementAttr={{ dataField: "CustomerCode" }} visible={true} caption='Customer Code' alignment='left' allowGrouping={false} />
															<Column dataField='name' elementAttr={{ dataField: "CustomerName" }} visible={true} caption='Customer Name' alignment='left' allowGrouping={false} />
															<Column dataField='forexCode' elementAttr={{ dataField: "CustomerForexCode" }} visible={true} caption='Currency' alignment='left' allowGrouping={false} />
														</CustomizedLookup>
													</div>
													<div>
														<label>To</label>

														<CustomizedLookup
															ref={toCustomerLookupRef}
															className={"ar-listing-datagrid"}
															dataSource={toCustomerList}
															displayExpr={"code"}
															valueExpr={"code"}
															value={returnSelectedValue("ToCust")}
															onSelectionChanged={(e) => {
																updateParamValue("ToCust", e.value);
															}}
															dataSourceURL={"api/Utilities/GetCustomers"}
															displayText={returnSelectedValue("ToCust")}
															onDataSourceChanged={setToCustomerList}>
															<Column dataField='code' elementAttr={{ dataField: "CustomerCode" }} visible={true} caption='Customer Code' alignment='left' allowGrouping={false} />
															<Column dataField='name' elementAttr={{ dataField: "CustomerName" }} visible={true} caption='Customer Name' alignment='left' allowGrouping={false} />
															<Column dataField='forexCode' elementAttr={{ dataField: "CustomerForexCode" }} visible={true} caption='Currency' alignment='left' allowGrouping={false} />
														</CustomizedLookup>
													</div>
												</div>
												<div>
													<Button
														style={{ position: "absolute", top: "65%", left: "75%" }}
														text='Clear Filter'
														stylingMode='text'
														type='default'
														onClick={() => {
															removeParamValue("CustList");
															removeParamValue("FromCust");
															removeParamValue("ToCust");
															setSelectedCust(0);
														}}
														elementAttr={{ dataField: "FilterNumber" }}
													/>
												</div>
											</div>
										</Popover>
									</TextBox>
								</div>
							)}
							{selectedCust === 2 && (
								<div>
									<TextBox
										value={returnSelectedKeys("Customer").length !== 0 ? `${returnSelectedKeys("Customer").length} Selected  (${returnSelectedKeys("Customer")})` : `${returnSelectedKeys("Customer").length} Selected`}
										style={{ marginTop: "-35px" }}
										height={0}
										showClearButton={false}
										width={"250px"}>
										<DropDownBox
											// onClosed={(e) => closeDropDownBox(e, "Customer")}
											ref={dropdownRef}
											// opened={openCustSpecific}
											valueExpr='id'
											deferRendering={false}
											displayExpr='code'
											showClearButton={false}
											contentRender={(e) => DataGridMultiRender("Customer")}
											defaultOpened={true}
											width={"250px"}
											style={{ marginTop: "-2.5px" }}
											dropDownOptions={{ width: "auto" }}
										/>
									</TextBox>
								</div>
							)}
						</div>
					)}

					{!isAR && isAR !== "null" && (
						<div className='popup-group-form-item'>
							<div className='popup-group-form-label'	style={{ marginTop: "7px" }}> Supplier </div>
							{selectedSupp === 0 && (
								<SelectBox
									width={"250px"}
									dataSource={filterTypes}
									value={selectedSupp}
									displayExpr={"Type"}
									valueExpr={"ID"}
									elementAttr={{ dataField: "FilterNumber" }}
									onValueChanged={(e) => {
										valueOnChange(e, "Supplier");
									}}
								/>
							)}
							{selectedSupp === 1 && (
								<div onClick={() => setOpenSuppRange(true)}>
									<TextBox
										value={returnSelectedValue("FromSupp") || returnSelectedValue("ToSupp") ? `${returnSelectedValue("FromSupp")} to ${returnSelectedValue("ToSupp")}` : "No Range Selected Yet"}
										// useMaskBehavior={true} // Enable mask behavior
										// mask="cccccc" // Define the input mask
										id='Supplier'
										style={{ marginRight: "10px" }}
										width={"250px"}>
										<Popover
											target='#Supplier'
											showEvent={"click"}
											// hideEvent="mouseleave"
											position='bottom'
											visible={openSuppRange}
											onHiding={(e) => popFunction(e, "Supplier")}
											dragEnabled={false}
											hideOnOutsideClick={true}
											width={500}
											height={125}>
											<div>
												<div className='popup-form-item-container2'>
													<div>
														<label>From</label>
														<CustomizedLookup
															ref={fromSupplierLookupRef}
															className={"ar-listing-datagrid"}
															dataSource={fromSupplierList}
															displayExpr={"code"}
															valueExpr={"code"}
															value={returnSelectedValue("FromSupp")}
															onSelectionChanged={(e) => {
																updateParamValue("FromSupp", e.value);
															}}
															dataSourceURL={"api/Utilities/GetSuppliers"}
															displayText={returnSelectedValue("FromSupp")}
															onDataSourceChanged={setFromSupplierList}>
															<Column dataField='code' elementAttr={{ dataField: "SupplierCode" }} visible={true} caption='Supplier Code' alignment='left' allowGrouping={false} />
															<Column dataField='name' elementAttr={{ dataField: "SupplierName" }} visible={true} caption='Supplier Name' alignment='left' allowGrouping={false} />
															<Column dataField='forexCode' elementAttr={{ dataField: "SupplierForexCode" }} visible={true} caption='Currency' alignment='left' allowGrouping={false} />
														</CustomizedLookup>
													</div>
													<div>
														<label>To</label>

														<CustomizedLookup
															ref={toSupplierLookupRef}
															className={"ar-listing-datagrid"}
															dataSource={toSupplierList}
															displayExpr={"code"}
															valueExpr={"code"}
															value={returnSelectedValue("ToSupp")}
															onSelectionChanged={(e) => {
																updateParamValue("ToSupp", e.value);
															}}
															dataSourceURL={"api/Utilities/GetSuppliers"}
															displayText={returnSelectedValue("ToSupp")}
															onDataSourceChanged={setToSupplierList}>
															<Column dataField='code' elementAttr={{ dataField: "SupplierCode" }} visible={true} caption='Supplier Code' alignment='left' allowGrouping={false} />
															<Column dataField='name' elementAttr={{ dataField: "SupplierName" }} visible={true} caption='Supplier Name' alignment='left' allowGrouping={false} />
															<Column dataField='forexCode' elementAttr={{ dataField: "SupplierForexCode" }} visible={true} caption='Currency' alignment='left' allowGrouping={false} />
														</CustomizedLookup>
													</div>
												</div>
												<div>
													<Button
														style={{ position: "absolute", top: "65%", left: "75%" }}
														text='Clear Filter'
														stylingMode='text'
														type='default'
														onClick={() => {
															setSelectedSupp(0);
															removeParamValue("SuppList");
															removeParamValue("FromSupp");
															removeParamValue("ToSupp");
														}}
														elementAttr={{ dataField: "FilterNumber" }}
													/>
												</div>
											</div>
										</Popover>
									</TextBox>
								</div>
							)}
							{selectedSupp === 2 && (
								<div>
									<TextBox
										value={returnSelectedKeys("Supplier").length !== 0 ? `${returnSelectedKeys("Supplier").length} Selected  (${returnSelectedKeys("Supplier")})` : `${returnSelectedKeys("Supplier").length} Selected`}
										style={{ marginTop: "-35px" }}
										height={0}
										showClearButton={false}
										width={"250px"}>
										<DropDownBox
											// onClosed={(e) => closeDropDownBox(e, "Supplier")}
											ref={dropdownRef}
											valueExpr='id'
											deferRendering={false}
											displayExpr='code'
											showClearButton={false}
											contentRender={(e) => DataGridMultiRender("Supplier")}
											defaultOpened={true}
											style={{ marginTop: "-2.5px" }}
											width={"250px"}
											dropDownOptions={{ width: "auto" }}
										/>
									</TextBox>
								</div>
							)}
						</div>
					)}

					<div className='popup-group-form-item'>
						<div className='popup-group-form-label'	style={{ marginTop: "7px" }}> Area </div>
						{selectedArea === 0 && (
							<SelectBox
								width={"250px"}
								dataSource={filterTypes}
								value={selectedArea}
								displayExpr={"Type"}
								valueExpr={"ID"}
								elementAttr={{ dataField: "FilterNumber" }}
								onValueChanged={(e) => {
									valueOnChange(e, "Area");
								}}
							/>
						)}
						{selectedArea === 1 && (
							<div onClick={() => setOpenAreaRange(true)}>
								<TextBox
									value={returnSelectedValue("FromArea") || returnSelectedValue("ToArea") ? `${returnSelectedValue("FromArea")} to ${returnSelectedValue("ToArea")}` : "No Range Selected Yet"}
									// useMaskBehavior={true} // Enable mask behavior
									// mask="cccccc" // Define the input mask
									id='Area'
									style={{ marginRight: "10px" }}
									width={"250px"}>
									<Popover
										target='#Area'
										showEvent={"click"}
										// hideEvent="mouseleave"
										position='bottom'
										visible={openAreaRange}
										onHiding={(e) => popFunction(e, "Area")}
										dragEnabled={false}
										hideOnOutsideClick={true}
										width={500}
										height={125}>
										<div>
											<div className='popup-form-item-container2'>
												<div>
													<label>From</label>
													<CustomizedLookup
														ref={fromAreaLookupRef}
														className={"ar-listing-datagrid"}
														dataSource={fromAreaList}
														displayExpr={"code"}
														valueExpr={"code"}
														value={returnSelectedValue("FromArea")}
														onSelectionChanged={(e) => {
															updateParamValue("FromArea", e.value);
														}}
														dataSourceURL={"api/Utilities/GetAreas"}
														displayText={returnSelectedValue("FromArea")}
														onDataSourceChanged={setFromAreaList}>
														<Column dataField='code' elementAttr={{ dataField: "AreaCode" }} visible={true} caption='Area Code' alignment='left' allowGrouping={false} />
														<Column dataField='name' elementAttr={{ dataField: "AreaName" }} visible={true} caption='Area Name' alignment='left' allowGrouping={false} />
													</CustomizedLookup>
												</div>
												<div>
													<label>To</label>

													<CustomizedLookup
														ref={toAreaLookupRef}
														className={"ar-listing-datagrid"}
														dataSource={toAreaList}
														displayExpr={"code"}
														valueExpr={"code"}
														value={returnSelectedValue("ToArea")}
														onSelectionChanged={(e) => {
															updateParamValue("ToArea", e.value);
														}}
														dataSourceURL={"api/Utilities/GetAreas"}
														displayText={returnSelectedValue("ToArea")}
														onDataSourceChanged={setToAreaList}>
														<Column dataField='code' elementAttr={{ dataField: "AreaCode" }} visible={true} caption='Area Code' alignment='left' allowGrouping={false} />
														<Column dataField='name' elementAttr={{ dataField: "AreaName" }} visible={true} caption='Area Name' alignment='left' allowGrouping={false} />
													</CustomizedLookup>
												</div>
											</div>
											<div>
												<Button
													style={{ position: "absolute", top: "65%", left: "75%" }}
													text='Clear Filter'
													stylingMode='text'
													type='default'
													onClick={() => {
														removeParamValue("AreaList");
														removeParamValue("FromArea");
														removeParamValue("ToArea");
														setSelectedArea(0);
													}}
													elementAttr={{ dataField: "FilterNumber" }}
												/>
											</div>
										</div>
									</Popover>
								</TextBox>
							</div>
						)}
						{selectedArea === 2 && (
							<div>
								<TextBox
									value={returnSelectedKeys("Area").length !== 0 ? `${returnSelectedKeys("Area").length} Selected  (${returnSelectedKeys("Area")})` : `${returnSelectedKeys("Area").length} Selected`}
									style={{ marginTop: "-35px" }}
									height={0}
									showClearButton={false}
									width={"250px"}>
									<DropDownBox
										ref={dropdownRef}
										// opened={openAreaSpecific}
										// onClosed={(e) => closeDropDownBox(e, "Area")}
										valueExpr='id'
										deferRendering={false}
										displayExpr='code'
										showClearButton={false}
										contentRender={() => DataGridMultiRender("Area")}
										defaultOpened={true}
										width={"250px"}
										style={{ marginTop: "-2.5px" }}
										dropDownOptions={{ width: "auto" }}
									/>
								</TextBox>
							</div>
						)}
					</div>
				</div>

				<div className='popup-form-item-container2'>
					<div className='popup-group-form-item' style={{ alignItems: "center" }}>
						<CheckBox
							value={returnSelectedValue("IsZero")}
							defaultValue={false}
							text='Show Zero Balance'
							onValueChanged={(e) => {
								updateParamValue("IsZero", e.value);
							}}
						/>
					</div>
				</div>
			</div>

			<div className='popup-group-form-item' id='report-listing-btn-group'>
				<Button text='Inquiry' type='success' width={"auto"} onClick={(e) => onInitialized(e)} />
				<Button text='Print Report' disabled={printRepDisabled} type='success' stylingMode={"outlined"} width={"auto"} style={{ marginLeft: "20px" }} onClick={(e) => setOpenReportPop(true)} />
			</div>

			<DataGrid dataSource={listingDataSource} keyExpr={"id"} allowColumnReordering={true} showBorders={true} paging={false} allowColumnResizing={true} 
				onRowPrepared={(e) => onRowPrepared(e)}
				onOptionChanged={onOptionChanged}
			>
				<GroupPanel visible={false} />
				<Grouping autoExpandAll={true} />

				{Array.isArray(columns) && columns.map((column) => {
					if (column && column.dataField) {
						const columnWidth = !utils.isNullOrEmpty(column.width) ? column.width : utils.getColumnWidth(column.dataField, storageName) ?? undefined;
						const columnIndex = utils.getColumnVisibleIndex(column.dataField, storageName) ?? 0;
						const columnEditorOptions = column.editorOptions || {};

						// Dynamic grid properties
						const gridProps = {
							...column, // Spread existing properties
							width: columnWidth,
							visibleIndex: columnIndex,
							editorOptions: columnEditorOptions,
						};

						// Default handling for all other columns
						return <Column key={column.dataField} {...gridProps} />;
						}
						return null; // Handle invalid columns
					})
				}
				<Summary>
					<GroupItem
						column='DocumentDate'
						customizeText={(e) => {
							return "Total";
						}}
						showInGroupFooter={true}
					/>
					<GroupItem
						column='Debit'
						summaryType='sum'
						customizeText={(e) => {
							return utils.numberToCurrency(e.value);
						}}
						showInGroupFooter={true}
					/>
					<GroupItem
						column='Credit'
						summaryType='sum'
						customizeText={(e) => {
							return utils.numberToCurrency(e.value);
						}}
						showInGroupFooter={true}
					/>
				</Summary>
			</DataGrid>

			<ErrorPopUpForm ref={popupMessageRef} />
			<div>
				{(form === "Sales" || form === "Invoice") && <ARSalesForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Invoice Entry"} />}
				{form === "ARDebitNote" && <ARDebitNoteForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Debit Note Entry"} />}
				{form === "Receipt" && <ARReceiptForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Receipt Entry"} />}
				{form === "ARCreditNote" && <ARCreditNoteForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Credit Note Entry"} />}
				{form === "Sales Invoice" && <SalesInvoiceMainForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Invoice Entry"} />}
				{form === "CashSales" && <SalesCashSalesForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Cash Sales Entry"} />}
				{form === "Purchase Invoice" && <PurchaseInvoiceForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Invoice Entry"} />}
				{form === "CashPurchase" && <CashPurchaseForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={true} title={"Cash Purchase Entry"} />}
				{form === "Purchase" && <APPurchaseForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Purchase Entry"} />}
				{form === "APDebitNote" && <APDebitNoteForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Debit Note Entry"} />}
				{form === "Payment" && <APPaymentForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Payment Entry"} />}
				{form === "APCreditNote" && <APCreditNoteForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Credit Note Entry"} />}
				{form === "Journal" && <JournalEntryForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Journal Entry"} />}
				{form === "GLPayment" && <CashBookEntryForm formType={"Payment Voucher"} formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Payment Voucher"} />}
				{form === "GLReceipt" && <CashBookEntryForm formType={"Official Receipt"} formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Official Receipt"} />}
				{form === "Contra" && <ContraForm formType={"Customer"} formID={currentEditID} closePopup={setCurrentEditID} openedBefore={true} documentNo={documentNo} documentDate={documentDate} />}
				{form === "APContra" && <ContraForm formType={"Supplier"} formID={currentEditID} closePopup={setCurrentEditID} openedBefore={true} documentNo={documentNo} documentDate={documentDate} />}
				{form === "ARRefund" && <ARRefundForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Refund Entry"} />}
				{form === "APRefund" && <APRefundForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Refund Entry"} />}
				{form === "WKInvoice" && <WorkshopInvoiceForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Invoice Entry"} />}
			</div>
		</Container>
	);
}
