import React, { useState, useEffect } from "react";
import { Column, TotalItem, Summary } from "devextreme-react/data-grid";
import Listing from "../../components/listing/report-component/reportsListing";
import utils from "../../utils/common";
import { customerAgeing, supplierAgeing } from "../../utils/lookupstore";

export default function AgeingListing() {
	const [listingTitle, setListingTitle] = useState("");
	const [isAR, setIsAR] = useState(false);
	const [columnWidth, setColumnWidth] = useState("");
	const [reportName, setReportName] = useState("");
	const [dropDownList, setDropDownList] = useState({
		reportList: []
	});

	// Detail section columns.
	const columns = [
		{ dataField: "DocNo", caption: "Doc No" },
		{ dataField: "Date", caption: "Date", dataType: "date", format: "dd/MM/yyyy" },
		{ dataField: "Term", caption: "Term" },
		{ dataField: "ForexCode", caption: "Forex" },
		{ dataField: "LocalRate", format: { type: "fixedPoint", precision: 2 } },
		{ dataField: "ProjectCode", caption: "Project" },
		{ dataField: "_0_Month", caption: "Current Mth", format: { type: "fixedPoint", precision: 2 } },
		{ dataField: "_1_Month", caption: "1 Month", format: { type: "fixedPoint", precision: 2 } },
		{ dataField: "_2_Month", caption: "2 Month", format: { type: "fixedPoint", precision: 2 } },
		{ dataField: "_3_Month", caption: "3 Month", format: { type: "fixedPoint", precision: 2 } },
		{ dataField: "_4_Month", caption: "4 Month", format: { type: "fixedPoint", precision: 2 } },
		{ dataField: "Over_5_Month", caption: "> 5 Month", format: { type: "fixedPoint", precision: 2 } },
		{ dataField: "Balance", format: { type: "fixedPoint", precision: 2 } },
		{ dataField: "TransactionTypeCode", caption: "Type", width: "50" }
	];

	// Sets the listing title.
	useEffect(() => {
		const pathname = window.location.pathname;

		const lastIndex = pathname.lastIndexOf("/");
		const lastSegment = lastIndex !== -1 ? pathname.substring(lastIndex + 1) : pathname;
		// console.log(lastSegment);
		if (lastSegment === "ARAgeingListing") {
			setIsAR(true);
			setListingTitle("Customer Ageing Report");
			setColumnWidth("ARAgeingReportListingColumnWidth");
		} else if (lastSegment === "APAgeingListing") {
			setIsAR(false);
			setListingTitle("Supplier Ageing Report");
			setColumnWidth("APAgeingReportListingColumnWidth");
		}
	}, []);

	// Sets the Print Report popup dropdown options.
	// Sets report name which is used as parameter when fetching
	// data to populate the report/listing data grid.
	useEffect(() => {
		Promise.allSettled([supplierAgeing.store.load(), customerAgeing.store.load()]).then((lists) => {
			setDropDownList({
				reportList: !isAR ? lists[0].value.data : lists[1].value.data
			});
		});
		if (isAR) {
			setReportName("Customer Ageing - Detail");
			setColumnWidth("ARAgeingReportListingColumnWidth");
		} else {
			setReportName("Supplier Ageing - 12 Month");
			setColumnWidth("APAgeingReportListingColumnWidth");
		}
	}, [isAR]);

	// useEffect(() => {
	// 	console.log(dropDownList.reportList);
	// 	console.log(reportName);
	// }, [dropDownList]);

	return (
		<div>
			<Listing disableDetails={true} storageName={columnWidth} className='ar-listing-datagrid' isAgeing={true} isAR={isAR} keyDetail={"Code"} reportName={reportName} listingTitle={listingTitle} reportList={dropDownList.reportList} detailColumns={columns} summaryDetail={[]}>
				{/* <Paging defaultPageSize={15} /> */}
				<Column dataField='Code' />
				<Column dataField='Type' caption={isAR ? "Debtor" : "Creditor"} />
				<Column dataField='ChartAccountCode' caption={"Control A/C"} />
				<Column dataField='Forex' />
				<Column dataField='Total_0_Month' caption={"Current Mth"} format={{ type: "fixedPoint", precision: 2 }} dataType={"number"} />
				<Column dataField='Total_1_Month' caption={"1 Month"} format={{ type: "fixedPoint", precision: 2 }} dataType={"number"} />
				<Column dataField='Total_2_Month' caption={"2 Month"} format={{ type: "fixedPoint", precision: 2 }} dataType={"number"} />
				<Column dataField='Total_3_Month' caption={"3 Month"} format={{ type: "fixedPoint", precision: 2 }} dataType={"number"} />
				<Column dataField='Total_4_Month' caption={"4 Month"} format={{ type: "fixedPoint", precision: 2 }} dataType={"number"} />
				<Column dataField='Total_Over_5_Month' caption={"> 5 Month"} format={{ type: "fixedPoint", precision: 2 }} dataType={"number"} />
				<Column dataField='Total_Balance' caption={"Balance"} format={{ type: "fixedPoint", precision: 2 }} dataType={"number"} />
				<Summary>
					<TotalItem
						key='detailCode'
						column='Code'
						summaryType='count'
						customizeText={(e) => {
							return "Count: " + e.value;
						}}
					/>

					<TotalItem
						column='Total_Balance'
						summaryType='sum'
						customizeText={(e) => {
							return "Total: " + utils.numberToCurrency(e.value);
						}}
					/>
				</Summary>
			</Listing>
		</div>
	);
}
