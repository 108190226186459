import { useEffect, useRef, useState, forwardRef, useImperativeHandle } from "react";
import baseapi from "../../api/baseapi";
import DataGrid, { Editing, Toolbar, Item as ToolbarItem, Popup, CheckBox } from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import { Button } from "devextreme-react/button";
import { TextBox } from "devextreme-react";
import DropDownBox from "devextreme-react/drop-down-box";
import utils, { loading, closeLoading } from "../../utils/common";
import ValidationGroup from "devextreme-react/validation-group";
import PopupForm from "../popup-form/PopupFormV2";
import { Validator, RequiredRule as ValidatorRequired } from "devextreme-react/validator";
import Container from "../container";
import ErrorPopUpForm from "../popup-form/ErrorPopupForm";
import TypeOfServiceForm from "../../pages/Workshop-Module/type-of-service/form";
import { isNullOrEmptyString } from "@devexpress/analytics-core/analytics-internal";

export default forwardRef(function CustomizedLookup(props, ref) {
	const [selectedKey, setSelectedKey] = useState(null);
	const [nextDocValue, setNextDocValue] = useState(null);
	const [tempSelection, setTempSelection] = useState(null);
	const [customTempSelection, setCustomTempSelection] = useState(null);
	const [dropdownOpened, setDropdownOpened] = useState(false);
	const [customDataSource, setCustomDataSource] = useState([]);
	const [searchFocusIndex, setSearchFocusIndex] = useState(-1);
	const [displayText, setDisplayText] = useState(null);
	const [valueExpr, setValueExpr] = useState("id");
	const gridRef = useRef(null);
	const searchBoxRef = useRef(null);
	const dropdownRef = useRef(null);
	const currentFocusIndex = useRef(null);
	const previousDataSource = useRef([]);
	const pagingIndex = useRef({
		loading: false,
		page: 1
	});
	const [renderAddRows, setRenderAddRows] = useState(null);

	// Set the field to access value from.
	useEffect(() => {
		if (!utils.isNullOrEmpty(props.valueExpr)) {
			setValueExpr(props.valueExpr);
		}
	}, [props.valueExpr]);

	useEffect(() => {
		if (selectedKey === null) {
			if (searchBoxRef.current !== null) searchBoxRef.current.instance.focus();
		}

		//Trigger on value changed
		onValueChanged();
	}, [selectedKey]);

	// Set the data source if provided, which will power the
	// DataGrid options.
	useEffect(() => {
		if (!utils.isNullOrEmpty(props.dataSource)) {
			setCustomDataSource(props.dataSource);
		}
	}, [props.dataSource]);

	useEffect(() => {
		if (pagingIndex.current["loading"] === true && Array.isArray(customDataSource)) {
			pagingIndex.current["page"] += 1;
			pagingIndex.current["loading"] = false;
		}

		if (Array.isArray(customDataSource)) {
			previousDataSource.current = [...customDataSource];
		}
	}, [customDataSource]);

	// 
	useEffect(() => {
		// Check if the "value" prop exists and is not null or undefined
		if (props.hasOwnProperty("value")) {
			setSelectedKey(props.value);

			// Only call setNextDocValue when props.value is null or undefined
			if (utils.isNullOrEmpty(props.value)) {
				setNextDocValue(null);
			}
		
			
			if (props.isEdit !== undefined && props.isEdit) {
			
				setNextDocValue(props.value);
			}
		}
	}, [props.value]);
	  
	useEffect(() => {
		if (!utils.isNullOrEmpty(props.displayText)) {
			setDisplayText(props.displayText);
		} else {
			setDisplayText(null);
		}
	}, [props.displayText]);

	useEffect(() => {
		// Prefetch the data source if enabled.
		if (props.preread === true) {
			if (props.dataSourceURL !== undefined) {
				baseapi
					.httpget(props.dataSourceURL, utils.mergeObject({ q: null, singleReturn: false }, props.mergeParams))
					.then((response) => {
						const data = response.data;
						updateDataSource(data);
					})
					.catch(() => {
						throw "Network error";
					});
			}
		}
	}, [props.preread]);

	useEffect(() => {
		if (!dropdownOpened) {
			//Clear the searching value
			if (searchBoxRef.current !== null) {
				searchBoxRef.current.instance.option("value", "");
				setSearchFocusIndex(-1);
				setTimeout(() => {
					if (dropdownRef.current !== null) dropdownRef.current.instance.focus();
				}, 200);
			}
		} else {
			//Set display text to null
			// Clear the data when opening the dropdown
			if (props.refreshDataSource === true) {
				setCustomDataSource([]);
				baseapi
					.httpget(props.dataSourceURL, utils.mergeObject({ q: selectedKey, singleReturn: false }, props.mergeParams))
					.then((response) => {
						const data = response.data;
						updateDataSource(data);
					})
					.catch(() => {
						throw "Network error";
					});
			}
			setDisplayText(null);

			// Fetch from dataSourceURL if the data source is empty.
			if (utils.isNullOrEmpty(customDataSource) || (utils.isEmptyArray(customDataSource) && !utils.isNullOrEmpty(props.dataSourceURL))) {
				baseapi
					.httpget(props.dataSourceURL, utils.mergeObject({ q: selectedKey, singleReturn: false }, props.mergeParams))
					.then((response) => {
						const data = response.data;
						updateDataSource(data);
					})
					.catch(() => {
						throw "Network error";
					});
			} else if (!foundInDataSource(selectedKey) && !utils.isNullOrEmpty(props.dataSourceURL) && !utils.isNullOrEmpty(selectedKey)) {
				//Keep updating the missing records
				baseapi
					.httpget(props.dataSourceURL, utils.mergeObject({ q: selectedKey, singleReturn: true }, props.mergeParams))
					.then((response) => {
						const data = response.data;
						if (Array.isArray(data) && data.length > 0) {
							updateDataSource(data);
						}
					})
					.catch(() => {
						throw "Network error";
					});
			}
		}
	}, [dropdownOpened]);

	useEffect(() => {
		if (dropdownRef.current !== undefined) {
			//Solve the datagrid disabled issues
			const instance = dropdownRef.current.instance;
			instance.option("dropDownOptions").disabled = false;
		}
	}, []);

	// Updates the customDataSource ref, which powers the DataGrid options.
	const updateDataSource = (givenSource) => {
		const currentSource = previousDataSource.current;
		if (Array.isArray(givenSource) && Array.isArray(currentSource)) {
			// Combine arrays
			const combinedArray = [...currentSource, ...givenSource];

			// Filter out duplicates based on the 'valueExpr' property
			const uniqueArray = combinedArray.filter((item, index, self) => index === self.findIndex((t) => t[valueExpr] === item[valueExpr]));

			if (!utils.arrayEqual(currentSource, uniqueArray)) {
				const sortedArray = utils.sortArrayByProp(uniqueArray, utils.isNullOrEmpty(props.sortByColumn) ? props.displayExpr : props.sortByColumn);
				setCustomDataSource(sortedArray);

				//Trigger onDataSourceChanged
				if (!utils.isNullOrEmpty(props.onDataSourceChanged)) {
					props.onDataSourceChanged(sortedArray);
				}
			}
		}
	};

	// Check whether the value is found in the data source.
	const foundInDataSource = (value) => {
		const copiedArr = [...customDataSource];
		const foundInOriginal = copiedArr.find((c) => c[valueExpr] === value);

		if (foundInOriginal === undefined) {
			return false;
		} else {
			return true;
		}
	};

	const onValueChanged = () => {
		if (props.onValueChanged !== undefined) {
			props.onValueChanged({ value: selectedKey });
		}
	};

	// Function to call when an option has been selected
	// by clicking the cell or the "Enter" keystroke.
	const onSelectionChanged = (changedValue) => {
		var value = null;
		if (utils.isObject(changedValue)) {
			value = changedValue[valueExpr];
		} else {
			value = changedValue;
		}

		//if document number is empty then only props.onSelectionChnaged
		if (!utils.isNullOrEmpty(props.onSelectionChanged) && utils.isNullOrEmpty(props.documentNumber) && !props.documentNumber) {
			props.onSelectionChanged({ value: value, selectedItem: changedValue });
		}

		setSelectedKey(value);
	};

	//handle next document number
	const onNextDocSelectionChanged = (changedValue) => {
		var value = null;
		if (utils.isObject(changedValue)) {
			value = changedValue[valueExpr];
		} else {
			value = changedValue;
		}

		if (!utils.isNullOrEmpty(props.nextDocSelectionChanged)) {
			props.nextDocSelectionChanged({ value: value, selectedItem: changedValue });
		}
	};


	const OnCustomSelectionChanged = (changedValue, isCustomValue = false) => {
		if (props.OnCustomSelectionChanged !== undefined) {
			props.OnCustomSelectionChanged({ value: changedValue });
		}
	};

	const clearValue = () => {
		onSelectionChanged(null);
		OnCustomSelectionChanged(null);
		if (searchBoxRef.current !== null) searchBoxRef.current.instance.reset();
	};
	//handle add button popup form
	const handleAddButtonClick = () => {
		setRenderAddRows(null);
		setTimeout(() => {
			setRenderAddRows(<PopupWrapper form={props.popupForm} key={Date.now()} />);
		}, 0);
	};
	const PopupWrapper = ({ form }) => {
		return <div>{form}</div>;
	};

	useImperativeHandle(ref, () => ({
		resetPagingIndex: () => {
			if (pagingIndex.current) {
				pagingIndex.current.page = 1;
				pagingIndex.current.loading = false;
			}
		}
	}));
	const DataGridRender = () => {
		return (
			<div className='customized-lookup-container'>
				<div className='customized-lookup-search-container'>
					<div>
						<TextBox
							ref={searchBoxRef}
							placeholder='Search...'
							width={160}
							valueChangeEvent='keyup'
							onValueChanged={(e) => {
								gridRef.current.instance.searchByText(e.value);
								setSearchFocusIndex(0);

								if (!utils.isNullOrEmpty(props.dataSourceURL)) {
									if (!utils.isNullOrEmpty(e.value)) {
										baseapi
											.httpget(props.dataSourceURL, utils.mergeObject({ q: e.value, singleReturn: true }, props.mergeParams))
											.then((response) => {
												const data = response.data;
												updateDataSource(data);
											})
											.catch(() => {
												throw "Network error";
											});
									}
								}
							}}
							onKeyDown={(e) => {
								// console.log("key down", e.event)
								if (e.event.key === "ArrowDown") {
									gridRef.current.instance.focus();
								}
							}}
						/>
					</div>

					{props.allowAdd === true && (
						<div className='customized-lookup-btn-section'>
							<Button
								icon='add'
								onClick={handleAddButtonClick}
							/>
						</div>
					)}

					<div className='customized-lookup-btn-section'>
						<Button text='Clear' onClick={clearValue} />
					</div>
				</div>
				<DataGrid
					ref={gridRef}
					className={"lookup-datagrid ".concat(!utils.isNullOrEmpty(props.className) ? props.className : "")}
					disabled={false}
					height={!utils.isNullOrEmpty(props.gridHeight) ? props.gridHeight : "200px"}
					showBorders={true}
					dataSource={customDataSource}
					columnChooser={{ enabled: false }}
					allowColumnResizing={true}
					allowColumnReordering={true}
					hoverStateEnabled={true}
					columnAutoWidth={!utils.isNullOrEmpty(props.gridColumnAutoWidth) ? props.gridColumnAutoWidth : true}
					noDataText={!utils.isNullOrEmpty(props.noDataText) ? props.noDataText : "No data"}
					paging={{ enabled: false }}
					keyExpr={!utils.isNullOrEmpty(props.valueExpr) ? props.valueExpr : "id"}
					scrolling={{ columnRenderingMode: "standard", showScrollbar: "onHover" }}
					focusedRowEnabled={true}
					focusedRowIndex={searchFocusIndex}
					onKeyDown={(e) => {
						if (e.event.key === "Enter") {
							onSelectionChanged(tempSelection);
							setDropdownOpened(false);
							OnCustomSelectionChanged(customTempSelection);
							//next document changed
							onNextDocSelectionChanged(tempSelection);
						}
						if (e.event.key === "ArrowUp") {
							// If focus is one the first row then brings focus back to the search box
							if (currentFocusIndex.current === 0) {
								setTimeout(() => {
									searchBoxRef.current.instance.focus();
								}, 50);
							}
						}
					}}
					onFocusedRowChanging={(e) => {
						if (e.event === null) {
							e.cancel = true;
						} else if (e.event.key === undefined) {
							e.cancel = true;
						}
					}}
					onFocusedRowChanged={(e) => {
						const data = e.row.data;
						currentFocusIndex.current = e.rowIndex;
						setSearchFocusIndex(e.rowIndex);
						// onSelectionChanged(data[valueExpr]);
						setTempSelection(data);
						setCustomTempSelection(data);
					}}
					onCellClick={(e) => {
						if (e.rowType === "data") {
							const data = e.data;
							onSelectionChanged(data);
							setDropdownOpened(false);
							OnCustomSelectionChanged(data);
							//next document changed
							onNextDocSelectionChanged(data);
						}
					}}
					onRowPrepared={(e) => {
						const key = e.key;
						if (key === selectedKey) {
							e.rowElement.classList.add("lookup-selected-highlight");
						}
					}}
					onContentReady={(element) => {
						const scrollable = element.component.getScrollable();
						scrollable.on("scroll", function (e) {
							if (e.reachedBottom) {
								if (pagingIndex.current["loading"] === false) {
									pagingIndex.current["loading"] = true;
									baseapi
										.httpget(props.dataSourceURL, utils.mergeObject({ q: selectedKey, singleReturn: false, page: pagingIndex.current["page"] }, props.mergeParams))
										.then((response) => {
											const data = response.data;
											updateDataSource(data);
										})
										.catch(() => {
											throw "Network error";
										});
								}
							}
						});
					}}>
					<Toolbar visible={false} />
					{props.children}

					{props.allowAdd === true && renderAddRows}
				</DataGrid>
			</div>
		);
	};

	const FieldRender = () => {
		return <TextBox value={displayText} readOnly={true} />;
	};

	return (
		<DropDownBox
			ref={dropdownRef}
			onEnterKey={(e) => {
				setDropdownOpened(true);
			}}
			onOptionChanged={(e) => {
				if (e.name === "opened") {
					setDropdownOpened(e.value);
				}
			}}
			className={props.dropdownClassName}
			height={props.height}
			width={props.width !== undefined ? props.width : "auto"}
			opened={dropdownOpened}
			dropDownOptions={{ width: "auto" }}
			dataSource={customDataSource}
			value={!utils.isNullOrEmpty(props.documentNumber) && props.documentNumber ? nextDocValue : selectedKey}
			displayExpr={!utils.isNullOrEmpty(props.displayExpr) ? props.displayExpr : "id"}
			valueExpr={valueExpr}
			contentRender={DataGridRender}
			disabled={props.disabled}
			acceptCustomValue={props.acceptCustomValue}
			onValueChanged={(e) => {
				if (!utils.isNullOrEmpty(props.documentNumber) && props.documentNumber) {
					//if having props document number then only trigger the to update document number 
					setNextDocValue(e.value, true);
					if (!utils.isNullOrEmpty(props.onSelectionChanged) && props.acceptCustomValue === true) {
						props.onSelectionChanged({ value: e.value });
					}
				}
				else if (props.acceptCustomValue === true) {
					onSelectionChanged(e.value, true);
				}
			}}
			placeholder= {props.placeholder}
			fieldRender={displayText === null ? null : FieldRender}
			readOnly={props.readOnly}
			openOnFieldClick={props.documentNumber !== undefined ? !props.documentNumber : true}

		>
			<Validator>{props.required && props.startValidation && <ValidatorRequired message={props.requiredMessage !== undefined ? props.requiredMessage : "This field is required!"} />}</Validator>
		</DropDownBox>
	);
});