import React, { useEffect, useState, useRef } from "react";
import utils from "../../utils/common";
import './TabPanel.scss';

export default function TabPanel(props){
    const [dataSource, setDataSource] = useState([]);
    const [tabActive, setTabActive] = useState(0);

    useEffect(() => {
        if(!utils.isNullOrEmpty(props.dataSource) && Array.isArray(props.dataSource)){
            if(!utils.arrayHasProperty(props.dataSource, "key") && !utils.arrayHasProperty(props.dataSource, "id")){
                const newArray = utils.arrayAddKey(props.dataSource);
                setDataSource(newArray);
            }
        }
    }, [props.dataSource]);

    // useEffect(() => {
    //     console.log("data source", dataSource)
    // }, [dataSource])

    const onSelectionChanged = (tab, index) => {
        setTabActive(index);

        if(!utils.isNullOrEmpty(props.onSelectedIndexChange)){
            props.onSelectedIndexChange(index);
        }
    };

    return <div>
        <div className="tab">
            {
                dataSource.map((tab, index) => {
                    return <button 
                        className={tabActive == index ? "tablinks active" : "tablinks"} 
                        key={tab.key} 
                        onClick={() => onSelectionChanged(tab, index)}>
                        {tab.link}
                    </button>
                })
            }
        </div>

        <div className="tabcontent">
            {!utils.isNullOrEmpty(props.itemRender) ? props.itemRender(tabActive) : null }
        </div>
    </div>;
}