import { useState, useMemo, useEffect, useRef } from "react";
import Listing from "../../../components/listing/newListing";
import Container from "../../../components/container";
import { Column, Button as ButtonColumn } from "devextreme-react/data-grid";
import { Button, Popup, TextBox } from "devextreme-react";
import utils from "../../../utils/common";
import FiscalYearForm from "./form";
import DropDownButton from "devextreme-react/drop-down-button";
import PathIndicator from "../../../components/path-indicator/PathIndicator";
import { DateBox } from "devextreme-react/date-box";
import "../tools.scss";
import baseapi from "../../../api/baseapi";
import { loading, closeLoading } from "../../../utils/common";
import ErrorPopUpForm from "../../../components/popup-form/ErrorPopupForm";

export default function FiscalYearListing() {
	const apiURL = `/api/FiscalYear`;
	const [currentEditID, setCurrentEditID] = useState(null);
	const [lockForm, setLockForm] = useState(false);
	const [year, setYear] = useState("");
	const [editActual, setEditActual] = useState(false);
	const [actualDataDate, setActualDataDate] = useState();
	const popupMessageRef = useRef(null);

	useEffect(() => {
		var userID = JSON.parse(localStorage.getItem("data"))?.userID;

		baseapi.httpget(`/api/FiscalYear/GetActualDataStartDate?userID=${userID}`).then((res) => {
			setActualDataDate(res.data.value);
		});
	}, []);

	function handleEdit(e) {
		const id = e ? e.row.data.id : 0;
		setYear("Edit Fiscal Year");

		setCurrentEditID(id);
	}

	function getBackgroundColorClass() {
		return btnBackgroundColorClass["gl-listing-datagrid"];
	}

	const btnBackgroundColorClass = useMemo(() => {
		return {
			"gl-listing-datagrid": "gl-listing-btn1",
			"ar-listing-datagrid": "ar-listing-btn",
			"ap-listing-datagrid": "ap-listing-btn",
			"sales-listing-datagrid": "sales-listing-btn",
			"stock-listing-datagrid": "stock-listing-btn",
			"workshop-listing-datagrid": "workshop-listing-btn"
		};
	});

	const handleIconEdit = () => {
		setEditActual(true);
	};

	const handleSubmitActual = () => {
		var userID = JSON.parse(localStorage.getItem("data"))?.userID;
		var dateFormatted = utils.dateFormatter(actualDataDate);

		loading("Saving Actual Data Start Date");
		if (dateFormatted !== null) {
			baseapi.httppost(utils.extendUrlVar(`/api/FiscalYear/SaveActualDataStartDate?userID=${userID}&dateTime=${dateFormatted}`)).then((res) => {
				const { data } = res;
				if (data.status) {
					setEditActual(false);
				}
				utils.displayPopupMessage(popupMessageRef, {
					visible: true,
					message: data.message,
					type: data.status ? "Success" : "Danger",
					action: data.action
				});
				closeLoading();
			});
		}
	};

	const onValueChange = (e) => {
		try {
			if (e.value !== undefined) {
				const dataField = e.element.getAttribute("dataField");

				if (dataField === "FiscalYear") {
					setActualDataDate(e.value);
				}
			}
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<div>
			<Container>
				<div className='listing-page-title-container'>
					<span className='datagrid-customized-title'>
						<PathIndicator />
					</span>
				</div>

				<div className='fiscalYear-settings'>
					{/* <div className='fiscalYear-settings-datebox'>
						<div className='databox-label'>
							<b>Actual Data Start Date</b>
						</div>
						<DateBox width={150} displayFormat={"dd/MM/yyyy"} dateSerializationFormat='yyyy-MM-dd' readOnly={true} value={actualDataDate} /> <i className='dx-icon dx-icon-edit fiscalYear' onClick={handleIconEdit}></i>
					</div> */}

					<div className='fiscalYear-settings-addNews'>
						{/* <Button
							text='New Previous Fiscal Year'
							className={`listing-page-add-btn FiscalYear ${getBackgroundColorClass()}`}
							icon='plus'
							onClick={() => {
								setCurrentEditID("new");
								setLockForm(false);
								setYear("New Previous Fiscal Year");
							}}
						/> */}
						<Button
							text='New Next Fiscal Year'
							className={`listing-page-add-btn FiscalYear ${getBackgroundColorClass()}`}
							icon='plus'
							// style={{ marginLeft: 15 }}
							onClick={() => {
								setCurrentEditID("new");
								setLockForm(false);
								setYear("New Next Fiscal Year");
							}}
						/>
					</div>
				</div>
				<Listing
					handleEdit={handleEdit}
					listingOnly={true}
					apiURL={apiURL}
					dateFilter={false}
					listingTitle={"Fiscal Year"}
					listingURL='/api/FiscalYear/list'
					className={"tools-listing-datagrid"}
					sortColumn={"FiscalYearName"}
					storageName={"FiscalYear"}
					onAddClick={setCurrentEditID}>
					<Column dataField={"FiscalYearName"} defaultSortIndex={0} defaultSortOrder='asc' />
					<Column dataField={"StartDate"} format='dd/MM/yyyy' dataType={"date"} />
					<Column dataField={"EndDate"} format='dd/MM/yyyy' dataType={"date"} />
					<Column dataField={"CurrentYear"} visible={false} />
				</Listing>

				<FiscalYearForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={lockForm} title={year} />

				<Popup
					visible={editActual}
					shading={true}
					hideOnOutsideClick={false}
					resizeEnabled={false}
					title='Actual Date Settings'
					width={"auto"}
					height={"auto"}
					onHiding={() => {
						setEditActual(false);
					}}>
					<div>
						<div style={{ marginBottom: "5px" }}>Actual Data Start Date</div>
						<div>
							<DateBox displayFormat={"dd/MM/yyyy"} dateSerializationFormat='yyyy-MM-dd' value={actualDataDate} useMaskBehavior={true} elementAttr={{ dataField: "FiscalYear" }} onValueChanged={(e) => onValueChange(e)} />
						</div>

						<div style={{ marginTop: "10px", textAlign: "right" }}>
							<Button text='Cancel' type='danger' style={{ marginRight: "10px" }} onClick={() => setEditActual(false)} />
							<Button text='Save' type='success' onClick={() => handleSubmitActual()} />
						</div>
					</div>
				</Popup>
			</Container>

			<ErrorPopUpForm ref={popupMessageRef} />
		</div>
	);
}
