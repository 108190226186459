
import { useEffect, useRef, useState, forwardRef, useCallback, useMemo } from "react";

import StockReceivedForm from './StockReportListing';
import Container from '../../../components/container';
import Listing from '../../../components/listing/newListing';
import utils from '../../../utils/common';
import PathIndicator from "../../../components/path-indicator/PathIndicator";
import baseapi from "../../../api/baseapi";
import { loading, closeLoading, fetchDefaultValues } from "../../../utils/common";
import { NumberBox } from "devextreme-react/number-box";
import { CheckBox } from "devextreme-react/check-box";
import { DateBox } from "devextreme-react/date-box";
import { TextBox } from "devextreme-react/text-box";
import { TextArea, SelectBox, DropDownBox, Popover } from "devextreme-react";
import { Button } from "devextreme-react/button";
import CustomizedLookup from "../../../components/lookup/Lookup";
import ArrayStore from "devextreme/data/array_store";
import DataSource from "devextreme/data/data_source";
import { stockOptions, stockGroupOptions, itemTypeOptions, brandOptions, categoryOptions, workshopModel, locationOptions } from "../../../utils/lookupstore";
import { DataGrid, Button as Pager, Toolbar, Column, Selection, MasterDetail, Paging, Summary, TotalItem, Sorting, } from "devextreme-react/data-grid";
import StockAdjustmentForm from "../adjustment/form";
import StockIssueForm from "../issue/form";
import StockReceiveForm from "../received/form";
import StockTakeForm from "../take/form";
import StockTransferForm from "../transfer/form";
import StockWriteOff from "../write-off/form";
import StockTransferFrom from "../transfer/form";
import StockWriteOffForm from "../write-off/form";
import StockReportListing from "./StockReportListing";

import DOForm from "../../../pages/Sales-Module/delivery-order/form";
import INVForm from "../../../pages/Sales-Module/invoice-main/form";
import CashSales from "../../../pages/Sales-Module/cash-sales/form";
import SalesDebitNote from "../../../pages/Sales-Module/debit-note/form";
import SalesCreditNote from "../../../pages/Sales-Module/credit-note/form";


import PurchaseReceive from "../../../pages/Purchase-Module/received/form";
import PurchaseInvoice from "../../../pages/Purchase-Module/invoice/form";
import CashPurchase from "../../../pages/Purchase-Module/cash-purchase/form";
import PurchaseDebitNote from "../../../pages/Purchase-Module/debit-note/form";
import ReturnPurchase from "../../../pages/Purchase-Module/purchase-return/form";
import WorkshopInvoiceForm from "../../Workshop-Module/Invoice/form";
import ErrorPopUpForm from "../../../components/popup-form/ErrorPopupForm";

export default function StockCardListing() {
    const [fromDate, setFromDate] = useState(
        new Date(new Date().setDate(1)).toLocaleDateString("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit"
        })
    );
    const [toDate, setToDate] = useState(
        new Date(new Date()).toLocaleDateString("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit"
        })
    );

    const pageSize = useRef(1000000);
    const selectedPage = useRef(1);
    const stockCode = useRef(0);
    const detailComp = useRef(null);
    const [mainDataSource, setMainDataSource] = useState([]);
    const [detailDataSource, setDetailDataSource] = useState(null);
    const [isRef, setIsRef] = useState(false);
    const [lockForm, setLockForm] = useState(false);
    const [currentEditID, setCurrentEditID] = useState(null);
    const [currentPrefix, setCurrentEditPrefix] = useState(null);
    const formID = useRef(null);
    const [listingTransactionSource, setListingTransactionSource] = useState([]);
    const [originalListingTransactionSource, setOriginalListingTransactionSource] = useState([]);
    const stockReportListingRef = useRef(null);
    const [currentForm, setCurrentForm] = useState(null);
    const popupMessageRef = useRef(null);
    const [defaultValue, setDefaultValue] = useState({});
    function handleEdit(e) {
        const id = e.row.key;
        setLockForm(utils.isLockedForm(e));
        setCurrentEditID(id);
    }
    const handleFormSubmitSuccess = () => {
        detailComp.current?.component.collapseAll(-1);
        utils.displayPopupMessage(popupMessageRef, {
            visible: true,
            message: "Data has been saved successfully.",
            type: "Success"
        });
    };

    const handleRowClick = (data) => {
        setCurrentEditID(null);
        setCurrentEditPrefix(null);
        loading();
        setCurrentForm(null);
        setTimeout(() => {

            closeLoading();
        }, 300);
        setTimeout(() => {
            setCurrentEditID(data.DocumentID);
            setCurrentEditPrefix(data.TransactionTypeID);
        }, 0);
        // Using a setTimeout with a short delay to ensure state is updated correctly

    };


    const getTasks = async () => {
        loading("Fetching Filter Result...");
        setDetailDataSource([]);
        let result = await baseapi.httpget("/api/StockDetailAnalysis/GetStockCartDetail?stockCode=" + stockCode.current + '&page=' + selectedPage.current + '&rows=' + pageSize.current + '&fromDate=' + fromDate + '&toDate=' + toDate + '&isStockCardDetail=true');
        closeLoading();
        const { data } = result;
        setDetailDataSource(data.items);
        setIsRef(true);


    }
    const handleSortChanged = (e) => {
        const component = e.component.getDataSource()
        const sortColumns = component.sort();
        const isDateSorted = sortColumns?.some((sort) => sort.selector === "Date");
        if (isDateSorted) {
            const dateSort = sortColumns.find((sort) => sort.selector === "Date");
            console.log(detailDataSource.sort((a, b) => {
                const dateA = new Date(a.Date);
                const dateB = new Date(b.Date);
                if (!dateSort.desc) {
                    if (dateA > dateB) return -1;
                    if (dateA < dateB) return 1;
                    return b.SCT_ID - a.SCT_ID;
                } else {
                    if (dateA < dateB) return -1;
                    if (dateA > dateB) return 1;
                    return a.SCT_ID - b.SCT_ID;
                }
            }));
            if (!dateSort.desc) {
                component.sort([{ selector: 'Date', desc: true }, { selector: 'SCT_ID', desc: true }]);
            } else {
                component.sort([{ selector: 'Date', desc: false }, { selector: 'SCT_ID', desc: false }]);
            }
            component.reload();
        }

    };

    useEffect(() => {
        const getDefaultValues = async () => {
            const data = await fetchDefaultValues();
            if (data) {
                setDefaultValue(data);
            }
        };

        getDefaultValues();
    }, []);
    const dataTemplate = (props) => {

        return (
            <div>
                <DataGrid
                    dataSource={new DataSource({
                        store: new ArrayStore({
                            data: detailDataSource,
                            key: 'DocNo'
                        })
                    })}
                    onInitialized={(e) => {
                        const dataSource = e.component.getDataSource();

                        if (dataSource) {

                            dataSource.reload();
                        }
                    }}
                    sorting={{ mode: "multiple", showSortIndexes: false }}
                    showBorders={true}
                    columnAutoWidth={true}
                    paging={{ enabled: true }}
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    // searchPanel={{ visible: true }}
                    onOptionChanged={(e) => {
                        if (e.name === "columns") {
                            handleSortChanged(e);
                        }
                    }}
                >
                    <Sorting mode="multiple" cssClass="stock-card-sorting-datagrid"/>
                    <Column dataField='DocNo' editorOptions={{ disabled: true }} cellRender={(e) => {
                        const data = e.data;
                        const id = parseInt(data.DocumentID);

                        return (
                            <span
                                className='clickable-span-tag'
                                onClick={() => handleRowClick(data)}>
                                {e.displayValue}
                            </span>
                        )

                    }} />
                    <Column dataField='Date' caption={"Date"} dataType="date" format="dd/MM/yyyy" />
                    <Column dataField='Description' editorOptions={{ disabled: true }} width="280px" />
                    <Column dataField='Type' editorOptions={{ disabled: true }} />
                    <Column dataField='Location' editorOptions={{ disabled: true }} />
                    <Column dataField='FromLocation' caption='From' editorOptions={{ disabled: true }} />
                    <Column dataField='ToLocation' caption='To' editorOptions={{ disabled: true }} />
                    <Column dataField='InQuantity' caption="In" dataType="number" editorOptions={{ disabled: true }} width="80px" format={utils.getDecimalFormat(defaultValue["QuantityFixCheck"], defaultValue["QuantityFormat"])} />
                    <Column dataField='OutQuantity' caption="Out" editorOptions={{ disabled: true }} width="80px" format={utils.getDecimalFormat(defaultValue["QuantityFixCheck"], defaultValue["QuantityFormat"])} />
                    <Column dataField='BalanceQuantity' caption="Balance Quantity" editorOptions={{ disabled: true }} width="120px" format={utils.getDecimalFormat(defaultValue["QuantityFixCheck"], defaultValue["QuantityFormat"])} />
                    <Column dataField='Cost' editorOptions={{ disabled: true }} format={utils.getDecimalFormat(defaultValue["UnitCostFixCheck"], defaultValue["UnitCostFormat"])} />
                    <Column dataField='Price' editorOptions={{ disabled: true }} format={utils.getDecimalFormat(defaultValue["UnitPriceFixCheck"], defaultValue["UnitPriceFormat"])} />
                    <Column
                        dataField='AvgCost'
                        editorOptions={{ disabled: true }}
                        format={{ type: 'fixedPoint', precision: 2 }}
                    />

                    <Column
                        dataField='TotalCost'
                        editorOptions={{ disabled: true }}
                        format={{ type: 'fixedPoint', precision: 2 }}

                    />

                    <Summary>
                        <TotalItem
                            column="DocNo"
                            summaryType="count"
                        />
                        <TotalItem
                            column="InQuantity"
                            summaryType="sum"
                            customizeText={(e) => `In: ${e.value}`}
                        >

                        </TotalItem>
                        <TotalItem
                            column="OutQuantity"
                            summaryType="sum"
                            customizeText={(e) => `Out: ${e.value}`}
                        >
                            <TotalItem
                                column="BalanceQuantity"
                                summaryType="custom"
                                customizeText={(e) => `Balance: ${e.value}`}
                            />
                        </TotalItem>

                    </Summary>
                    <Paging defaultPageSize={10}

                        onPageSizeChange={(e) => {
                            pageSize.current = e;
                            getTasks();
                        }}
                        onPageIndexChange={(e) => {
                            selectedPage.current = e + 1;
                            getTasks();
                        }}
                    />


                </DataGrid>
            </div>
        );
    };


    return <Container>
        <div>
            <div className='listing-page-title-container'>
                <span className='datagrid-customized-title'>
                    <PathIndicator />
                </span>
            </div>

            <StockReportListing
                ref={stockReportListingRef}
                reportName={"Stock Card"}
                reportID={"Stock Card"}
                defaultValue={defaultValue}
                ReturnResult={(e) => {

                    setMainDataSource(e.MainData);
                    setDetailDataSource(e.Details.flat());
                    setFromDate(e.FromDate);
                    setToDate(e.ToDate);

                    detailComp.current?.component.collapseAll(-1);
                }}
            />

            <div style={{ marginTop: '20px' }}>
                <div className='popup-group-form-item' id="stock-card-listing-datagrid">

                    <DataGrid width={"100%"}
                        onRowExpanding={(e) => {
                            stockCode.current = e.key.ID;
                            getTasks();
                            detailComp.current = e;
                            e.component.collapseAll(-1);
                        }}

                        dataSource={mainDataSource} showBorders={true} columnAutoWidth={true} paging={{ enabled: true }} sorting={{ mode: "multiple" }} // Enable sorting
                        allowColumnReordering={true}
                        allowColumnResizing={true}
                    >
                        <Column dataField='Code' editorOptions={{ disabled: true }} />
                        <Column dataField='Description' caption="Description" editorOptions={{ disabled: true }} width="280px" />
                        <Column dataField='ItemTypeCode' editorOptions={{ disabled: true }} />
                        <Column dataField='BalanceQuantity' editorOptions={{ disabled: true }} />
                        <Column dataField='AverageCost' editorOptions={{ disabled: true }} format={{ type: 'fixedPoint', precision: 2 }} />
                        <Column dataField='BrandCode' editorOptions={{ disabled: true }} />
                        <Column dataField='CategoryCode' editorOptions={{ disabled: true }} />
                        <Column dataField='ModelCode' editorOptions={{ disabled: true }} />

                        <MasterDetail enabled={true} component={dataTemplate} />

                        <Paging defaultPageSize={10} />
                    </DataGrid>

                </div>
            </div>


            {currentEditID && currentPrefix && (
                <div>


                    {(currentPrefix === "89") && <StockReceiveForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={lockForm} onSubmitSuccess={handleFormSubmitSuccess} />}
                    {(currentPrefix === "94") && <StockIssueForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={lockForm} onSubmitSuccess={handleFormSubmitSuccess} />}
                    {(currentPrefix === "96") && <StockAdjustmentForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={lockForm} onSubmitSuccess={handleFormSubmitSuccess} />}
                    {(currentPrefix === "108") && <StockTransferForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={lockForm} onSubmitSuccess={handleFormSubmitSuccess} />}
                    {(currentPrefix === "107") && <StockWriteOffForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={lockForm} onSubmitSuccess={handleFormSubmitSuccess} />}
                    {(currentPrefix === "101") && <StockTakeForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={true} onSubmitSuccess={handleFormSubmitSuccess} />}

                    {(currentPrefix === "114") && <WorkshopInvoiceForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={true} onSubmitSuccess={handleFormSubmitSuccess} title={"Invoice Entry"} apiURL={"/api/WKInvoice"} />}

                    {(currentPrefix === "27") && <INVForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={lockForm} onSubmitSuccess={handleFormSubmitSuccess} title={"Invoice Entry"} apiURL="/api/SalesInvoiceMain" />}
                    {(currentPrefix === "23") && <DOForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={lockForm} onSubmitSuccess={handleFormSubmitSuccess} title={"Delivery Order"} apiURL="/api/SalesDeliveryOrder" />}
                    {(currentPrefix === "113") && <CashSales formID={currentEditID} closePopup={setCurrentEditID} lockedForm={lockForm} onSubmitSuccess={handleFormSubmitSuccess} title={"Cash Sales Entry"} apiURL="/api/SalesCashSales" />}
                    {(currentPrefix === "97") && <SalesDebitNote formID={currentEditID} closePopup={setCurrentEditID} lockedForm={lockForm} onSubmitSuccess={handleFormSubmitSuccess} title={"Debit Note"} apiURL="/api/SalesDebitNote" />}
                    {(currentPrefix === "98") && <SalesCreditNote formID={currentEditID} closePopup={setCurrentEditID} lockedForm={lockForm} onSubmitSuccess={handleFormSubmitSuccess} title={"Credit Note"} apiURL="/api/SalesCreditNote" />}

                    {(currentPrefix === "109") && <PurchaseReceive formID={currentEditID} closePopup={setCurrentEditID} lockedForm={lockForm} onSubmitSuccess={handleFormSubmitSuccess} title={"Purchase Received"} apiURL="/api/PurchaseReceived" />}
                    {(currentPrefix === "110") && <PurchaseInvoice formID={currentEditID} closePopup={setCurrentEditID} lockedForm={lockForm} onSubmitSuccess={handleFormSubmitSuccess} apiURL="/api/PurchaseInvoice" title={"Purchase Invoice"} />}
                    {(currentPrefix === "111") && <CashPurchase formID={currentEditID} closePopup={setCurrentEditID} lockedForm={lockForm} onSubmitSuccess={handleFormSubmitSuccess} apiURL="/api/PurchaseCashPurchase" title={"Cash Purcahse"} />}
                    {(currentPrefix === "103") && <PurchaseDebitNote formID={currentEditID} closePopup={setCurrentEditID} lockedForm={lockForm} onSubmitSuccess={handleFormSubmitSuccess} apiURL="/api/PurchaseDebitNote" title={"Debit Note"} />}
                    {(currentPrefix === "47") && <ReturnPurchase formID={currentEditID} closePopup={setCurrentEditID} lockedForm={lockForm} onSubmitSuccess={handleFormSubmitSuccess} apiURL="/api/PurchaseReturn" title={"Purchase Return"} />}

                </div>
            )}
        </div>
        <ErrorPopUpForm ref={popupMessageRef} />
    </Container>



}


