import { useEffect, useRef, useState } from "react";
import utils from "../../../utils/common";
import PopupForm from "../../../components/popup-form/PopupFormV2";
import baseapi from "../../../api/baseapi";
import { loading, closeLoading } from "../../../utils/common";
import { CheckBox } from "devextreme-react/check-box";
import { TextBox } from "devextreme-react/text-box";
import { NumberBox } from "devextreme-react/number-box";
import AsyncTextBox from "../../../components/async-text-box/AsyncTextBox";
import CustomizedLookup from "../../../components/lookup/Lookup";
import { Validator, RequiredRule as ValidatorRequired } from "devextreme-react/validator";
import DataGrid, { Column, Editing, Selection, Scrolling, Lookup, KeyboardNavigation, ColumnChooser, Summary, TotalItem, Toolbar, Item as ToolbarItem } from "devextreme-react/data-grid";
export default function StockGroupForm(props) {
	const formID = useRef(null);
	const formRef = useRef(null);
	const formRead = useRef(false);
	const childrenGridRef = useRef(null);
	const tempForm = useRef(null);
	const [formValue, setFormValue] = useState({});
	const [isEditing, setIsEditing] = useState(false);
	const [startValidation, setStartValidation] = useState(0);
	const [salesCodeList, setSalesCodeList] = useState([]);

	useEffect(() => {
		// console.log("Form ID Changed", props.formID);
		if (props.formID !== null) {
			formID.current = props.formID;
			utils.popupFormOpen(formRef);

			if (formID.current !== "new") {
				setIsEditing(true);
			}
			else {
				setIsEditing(false);
			}
		}
	}, [props.formID]);

	function onInitialized(e) {
		if (formID.current === "new") loading("Loading New Stock Group...");
		else loading("Loading Existing Stock Group...");

		baseapi.httpget("/api/StockGroup/Get?id=" + (formID.current || "new")).then((response) => {
			const { data } = response;
			tempForm.current = data.model;
			setFormValue(tempForm.current);
			closeLoading();
			formRead.current = true;
		});
	}

	const clearForm = () => {
		props.closePopup(null);
		formRead.current = false;
		formID.current = null;
		tempForm.current = {};
		setStartValidation(0); // Assuming this prop controls when validation starts
		setFormValue(tempForm.current);
	};

	function valueOnChange(e, receivedDataField = null) {
		try {
			if (e.value !== undefined) {
				const dataField = receivedDataField !== null ? receivedDataField : e.element.getAttribute("dataField");
				const copied = { ...tempForm.current };
				copied[dataField] = e.value;
				tempForm.current = copied;
				setFormValue(tempForm.current);
			}
		} catch (err) {
			console.log(err);
		}
	}

	function handleSubmit({ stay }) {
		loading("Saving Stock Group...");

		const submittedForm = formValue;
		baseapi
			.httppost(
				utils.extendUrlVar("/api/StockGroup/save", {
					id: formID.current || "",
					del: false,
				}),
				submittedForm
			)
			.then((response) => {
				const { data } = response;
				utils.popupFormSuccessfulSubmit(formRef, data);
				utils.popupFormSetErrorForm(formRef, {
					visible: true,
					message: data.message,
					type: data.status ? "Success" : "Warning",
					action: data.action
				});
			});
	}

	return <PopupForm
		ref={formRef}
		childrenGridRef={childrenGridRef}
		onClosing={clearForm}
		fullScreen={false}
		width={"95%"}
		height={"90%"}
		disableButtons={props.lockedForm === true}
		headerClassName={"inventory-module-form-title-bg popup-form-title"}
		creditChecking={false}
		title={"Stock Group"}
		onShown={onInitialized}
		onSuccessfulSubmit={({ stay }) => { if (stay) onInitialized() }}
		copyFields={{
			Parent: formValue,
			Children: utils.childrenGridGetSource(childrenGridRef)
		}}
		onPasteForm={(e) => { tempForm.current = e.Parent; setFormValue(e.Parent) }}
		copyStorage={"StockGroupCopiedData"}
		formData={formValue}
		isEditing={isEditing}
		apiURL={"/api/StockGroup"}
		onValidating={setStartValidation}
		startValidation={startValidation}
		onSaving={handleSubmit}
		cancelDocumentCustomization={
			<span className='popup-form-header-cancel'>
				<CheckBox
					value={formValue["Active"]}
					elementAttr={{ dataField: "Active" }}
					onValueChanged={(e) => {
						valueOnChange(e);
					}}
					alignment='left'
					width={"14px"}
					height={"14px"}
					iconSize={"14px"}
					disabled={props.lockedForm === true}
					className='popup-header-cancel-input'
				/>

				<span className='popup-header-cancel-text'>Active</span>
			</span>
		}>
		<div>
			<div className='inventory-module-border-bottom custom-form-grid-section2 '>
				<div className='stock-group-section-1'>

					<div className='inventory-module-parent-without-margin-bottom-section'>
						<div className='popup-form-item-container2'>
							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Stock Group Code </div>

								<div className='popup-group-form-input'>


									<AsyncTextBox
										value={formValue["Code"]}
										elementAttr={{ dataField: "Code" }}
										onValueChanged={(e) => {
											valueOnChange(e);
										}}
										alignment='left'
										width={"auto"}
										disabled={formID.current !== "new" || props.lockedForm === true}
										startValidation={startValidation !== 0}
										asyncURL={"api/StockGroup/CheckDuplicateCode"}
										requiredMessage={"Code is required!"}
										asyncMessage={"This Code is already taken!"}
									/>
								</div>
							</div>

							<div className='popup-group-form-item' style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'initial', margin: '2.5px 10px' }}>
								<CheckBox
									value={formValue["Default"]}
									elementAttr={{ dataField: "Default" }}
									onValueChanged={(e) => {
										valueOnChange(e);
									}}
									alignment='left'
									width={"auto"}
									disabled={props.lockedForm === true}
								/>
								<div className='popup-group-form-label' style={{ marginRight: '0px', textAlign: 'center', paddingTop: '7px' }}>
									Set As Default
								</div>
							</div>
						</div>
						<div className='popup-group-form-item2'>
							<div className='popup-group-form-label'>Description </div>

							<div className='popup-group-form-input'>
								<TextBox
									value={formValue["Description"]}
									elementAttr={{ dataField: "Description" }}
									onValueChanged={(e) => {
										valueOnChange(e);
									}}
									alignment='left'
									width={"auto"}
									disabled={props.lockedForm === true}>
									<Validator>{startValidation !== 0 && <ValidatorRequired message={"Description is required!"} />}</Validator>
								</TextBox>
							</div>
						</div>

						<div className='popup-group-form-item2'>
							<div className='popup-group-form-label'>Remark </div>

							<div className='popup-group-form-input'>
								<TextBox
									value={formValue["Remark"]}
									elementAttr={{ dataField: "Remark" }}
									onValueChanged={(e) => {
										valueOnChange(e);
									}}
									alignment='left'
									width={"auto"}
									disabled={props.lockedForm === true}>
									{/* <Validator>{startValidation !== 0 && <ValidatorRequired message={"Reorder Quantity is required!"} />}</Validator> */}
								</TextBox>
							</div>
						</div>

					</div>
				</div>

			</div>

			<div className='inventory-module-border-bottom custom-form-grid-section2 '>
				<div className=''>

					<div className='inventory-module-parent-without-background-color-section'>
						<div className='popup-form-item-container2'>
							<div className='popup-group-form-item3'>
								<div className='popup-group-form-label'>Sales Code</div>

								<div className='popup-group-form-input'>
									<CustomizedLookup

										className={"inventory-listing-datagrid"}
										dataSource={salesCodeList}
										displayExpr={"code"}
										valueExpr={"id"}
										value={formValue["SalesCodeID"]}
										onSelectionChanged={(e) => {
											valueOnChange(e, "SalesCodeID");
										}}

										dataSourceURL={"api/Utilities/GetGLChartAccounts"}
										onDataSourceChanged={setSalesCodeList}
										disabled={props.lockedForm === true}
										displayText={formValue["SalesCode"]}>
										<Column dataField='code'></Column>
										<Column dataField='name' caption={"Descripion"}></Column>
									</CustomizedLookup>
								</div>
							</div>
						</div>
						<div className='popup-form-item-container2'>
							<div className='popup-group-form-item2'>
								<div className='popup-group-form-label'>Cash Sales Code</div>

								<div className='popup-group-form-input'>
									<CustomizedLookup

										className={"inventory-listing-datagrid"}
										dataSource={salesCodeList}
										displayExpr={"code"}
										valueExpr={"id"}
										value={formValue["CashSalesCodeID"]}
										onSelectionChanged={(e) => {
											valueOnChange(e, "CashSalesCodeID");
										}}

										dataSourceURL={"api/Utilities/GetGLChartAccounts"}
										onDataSourceChanged={setSalesCodeList}
										disabled={props.lockedForm === true}
										displayText={formValue["CashSalesCode"]}>
										<Column dataField='code'></Column>
										<Column dataField='name' caption={"Descripion"}></Column>
									</CustomizedLookup>
								</div>
							</div>
						</div>
						<div className='popup-form-item-container2'>
							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Sales Return Code</div>

								<div className='popup-group-form-input'>
									<CustomizedLookup

										className={"inventory-listing-datagrid"}
										dataSource={salesCodeList}
										displayExpr={"code"}
										valueExpr={"id"}
										value={formValue["SalesReturnCodeID"]}
										onSelectionChanged={(e) => {
											valueOnChange(e, "SalesReturnCodeID");
										}}

										dataSourceURL={"api/Utilities/GetGLChartAccounts"}
										onDataSourceChanged={setSalesCodeList}
										disabled={props.lockedForm === true}
										displayText={formValue["SalesReturnCode"]}>
										<Column dataField='code'></Column>
										<Column dataField='name' caption={"Descripion"}></Column>
									</CustomizedLookup>
								</div>
							</div>
						</div>
						<div className='popup-form-item-container2'>
							<div className='popup-group-form-item2'>
								<div className='popup-group-form-label'>Purchase Code</div>

								<div className='popup-group-form-input'>
									<CustomizedLookup

										className={"inventory-listing-datagrid"}
										dataSource={salesCodeList}
										displayExpr={"code"}
										valueExpr={"id"}
										value={formValue["PurchaseCodeID"]}
										onSelectionChanged={(e) => {
											valueOnChange(e, "PurchaseCodeID");
										}}

										dataSourceURL={"api/Utilities/GetGLChartAccounts"}
										onDataSourceChanged={setSalesCodeList}
										disabled={props.lockedForm === true}
										displayText={formValue["PurchaseCode"]}>
										<Column dataField='code'></Column>
										<Column dataField='name' caption={"Descripion"}></Column>
									</CustomizedLookup>
								</div>
							</div>
						</div>

						<div className='popup-form-item-container2'>
							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Cash Purchase Code</div>

								<div className='popup-group-form-input'>
									<CustomizedLookup

										className={"inventory-listing-datagrid"}
										dataSource={salesCodeList}
										displayExpr={"code"}
										valueExpr={"id"}
										value={formValue["CashPurchaseCodeID"]}
										onSelectionChanged={(e) => {
											valueOnChange(e, "CashPurchaseCodeID");
										}}

										dataSourceURL={"api/Utilities/GetGLChartAccounts"}
										onDataSourceChanged={setSalesCodeList}
										disabled={props.lockedForm === true}
										displayText={formValue["CashPurchaseCode"]}>
										<Column dataField='code'></Column>
										<Column dataField='name' caption={"Descripion"}></Column>
									</CustomizedLookup>
								</div>
							</div>
						</div>

						<div className='popup-form-item-container2'>
							<div className='popup-group-form-item2'>
								<div className='popup-group-form-label'>Purchase Return Code</div>

								<div className='popup-group-form-input'>
									<CustomizedLookup

										className={"inventory-listing-datagrid"}
										dataSource={salesCodeList}
										displayExpr={"code"}
										valueExpr={"id"}
										value={formValue["PurchaseReturnCodeID"]}
										onSelectionChanged={(e) => {
											valueOnChange(e, "PurchaseReturnCodeID");
										}}

										dataSourceURL={"api/Utilities/GetGLChartAccounts"}
										onDataSourceChanged={setSalesCodeList}
										disabled={props.lockedForm === true}
										displayText={formValue["PurchaseReturnCode"]}>
										<Column dataField='code'></Column>
										<Column dataField='name' caption={"Descripion"}></Column>
									</CustomizedLookup>
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>
		</div>


	</PopupForm>;
}