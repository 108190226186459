import TreeList, { Column, Lookup, Scrolling, Editing } from "devextreme-react/tree-list";
import PathIndicator from "../../../components/path-indicator/PathIndicator";
import { useRef, useState, useEffect, useCallback } from "react";
import Container from "../../../components/container";
import { NumberBox, Button } from "devextreme-react";
import CustomizedLookup from "../../../components/lookup/Lookup";
import CustomStore from "devextreme/data/custom_store";
import baseapi from "../../../api/baseapi";
import utils from "../../../utils/common";
import { loading, closeLoading } from "../../../utils/common";
import ErrorPopupForm from "../../../components/popup-form/ErrorPopupForm";
import  PopupForm  from "../../../components/popup-form/PopupFormV2";
import { TextBox } from "devextreme-react/text-box";
import ChildrenDatagrid from "../../../components/children-datagrid/ChildrenDatagrid";
import { Column as GridColumn, Summary, TotalItem } from "devextreme-react/data-grid";
import { saveToLS } from "../../../utils/localstorage";
import CustomizedGridLookup from "../../../components/lookup/GridLookup";
import ARDebitNoteFormBFY from "../../AR-Module/debit-notes/beforeFiscalYear";
import ARCreditNoteBFY from "../../AR-Module/credit-notes/beforeFiscalYear";
import ARReceiptFormBFY from "../../AR-Module/receipt/beforeFiscalYear";
import ARRefundFormBFY from "../../AR-Module/refund/beforeFiscalYear";
import ARSalesFormBFY from "../../AR-Module/sales/beforeFiscalYear";
import APDebitNoteFormBFY from "../../AP-Module/debit-notes/beforeFiscalYear";
import APCreditNoteFormBFY from "../../AP-Module/credit-notes/beforeFiscalYear";
import APPaymentFormBFY from "../../AP-Module/payment/beforeFiscalYear";
import APRefundFormBFY from "../../AP-Module/refund/beforeFiscalYear";
import APPurchaseFormBFY from "../../AP-Module/purchase/beforeFiscalYear";

export default function OpeningBalanceListing() {
	const treelistRef = useRef(null);
	const popupMessageRef = useRef(null);
	const formRef = useRef(null);
	const childrenGridRef = useRef(null);
	const currentFocusCell = useRef({
		columnIndex: 0,
		rowIndex: 0,
		key : null,
		data: null,
	});
	const submitOpeningBalance = useRef(false);

	const [listingDataSource, setListingDataSource] = useState(null);
	const [projectList, setProjectList] = useState(null);
	const [disableSave, setDisableSave] = useState(true);
	const [projectID, setProjectID] = useState("53OsK3yBsKs%3d");
	const [formValue, setFormValue] = useState({});
	const [enableUpdate, setEnableUpdate] = useState(false);
	const [cancelVisible, setCancelVisible] = useState(false);
	const [editVisible, setEditVisible] = useState(true);
	const [expandAll, setExpandAll] = useState(true);
	const [popupTitle, setPopupTitle] = useState(null);
	const [popupParent, setPopupParent] = useState({});
	const [currentFiscalYear, setCurrentFiscalYear] = useState(null);
	const [gridLookupOpen, setGridLookupOpen] = useState(false);
	const [ARDNEditID, setARDNEditID] = useState(null);
	const [ARCNEditID, setARCNEditID] = useState(null);
	const [ARReceiptEditID, setARReceiptEditID] = useState(null);
	const [ARInvoiceEditID, setARInvoiceEditID] = useState(null);
	const [ARRefundEditID, setARRefundEditID] = useState(null);
	const [APDNEditID, setAPDNEditID] = useState(null);
	const [APCNEditID, setAPCNEditID] = useState(null);
	const [APPaymentEditID, setAPPaymentEditID] = useState(null);
	const [APPurchaseEditID, setAPPurchaseEditID] = useState(null);
	const [APRefundEditID, setAPRefundEditID] = useState(null);
	const [childrenID, setChildrenID] = useState(null);
	const [childrenFormData, setChildrenFormData] = useState({});
	const [transactionTypeDisabled, setTransactionTypeDisabled] = useState(false);
	const deleteURL = {
		RI : `/api/ARSales`,
		RR : `/api/ARReceipt`,
		RRF : `/api/ARRefund`,
		RC : `/api/ARCreditNote`,
		RD : `/api/ARDebitNote`,
		PC: `/api/APCreditNote`,
		PD: `/api/APDebitNote`,
		PP: `/api/APPurchase`,
		PRF: `/api/APRefund`,
		PV: `/api/APPayment`,
	}

	const handleEdit = () => {
		setEnableUpdate(true);
		setDisableSave(false);
		setCancelVisible(true);
		setEditVisible(false);
	};

	const handleSubmit = async () => {
		
		const children = [...treelistRef.current.props.dataSource]
		.filter(c => !c.HasChildren && c.BeforeFiscalYearChildren === 0)
		.filter(c => (utils.isNumber(c.OpeningDebit) || utils.isNumber(c.OpeningCredit)))
		.map(item => ({
			...item,
			OpeningDebit: Number(item.OpeningDebit),
			OpeningCredit : Number(item.OpeningCredit),
		}));

		// for(var i = 0; i < children.length; i++){
		// 	const record = children[i];

		// 	//sum debtors and creditors back to its parent
		// 	if(record["BeforeFiscalYearChildren"] === 1){
		// 		const parentRecord = children.find(c => c.GLChartAccountID === record["ParentID"]);
		// 		const parentIndex = children.findIndex(c => c.GLChartAccountID === record["ParentID"]);

		// 		parentRecord["OpeningDebit"] += record["OpeningDebit"];
		// 		parentRecord["OpeningCredit"] += record["OpeningCredit"];
		// 		children[parentIndex] = parentRecord;
		// 	}
		// }

		const submit = {
			ProjectID: projectID,
			Children: children,
		};

		loading("Saving Opening Balance...");
		baseapi.httppost(utils.extendUrlVar("/api/GLOpeningBalance/save"), submit).then(async (res) => {
			// console.log(res);
			res = res.data;
			if (res.status) {
				utils.displayPopupMessage(popupMessageRef, {
					visible: true,
					message: res.message,
					type: "Success"
				});
				setExpandAll(false);
				setDisableSave(true);
				setEnableUpdate(false);
				setCancelVisible(false);
				setEditVisible(true);
				await refreshTreeList();
			} 
			else {
				utils.displayPopupMessage(popupMessageRef, {
					visible: true,
					message: res.message,
					type: "Warning"
				});
			}
			closeLoading();
		});
	};

	const handleCancel = async () => {
		setDisableSave(true);
		setEnableUpdate(false);
		setCancelVisible(false);
		setEditVisible(true);
		await refreshTreeList();
	};

	const refreshTreeList = async () => {
		setListingDataSource(
			await baseapi
				.httpget(`/api/GLOpeningBalance/list?projectID=${projectID}`)
				.then((response) => {
					const { data } = response;
					//console.log(data.items);
					if (data !== null) {
						const debtors = data.DebtorOpeningBalance;
						const creditors = data.CreditorOpeningBalance;
						const parsedData = data.items.map(item => ({
							...item,
							OpeningDebit: Number(item.OpeningDebit),
							OpeningCredit : Number(item.OpeningCredit),
						}))
						.map(item => {
							if(item.BeforeFiscalYearChildren === 1){
								const foundDebtors = debtors.filter(c => c.GLChartAccountID === item.GLChartAccountID);
								const foundCreditors = creditors.filter(c => c.GLChartAccountID === item.GLChartAccountID);
								
								if(foundDebtors.length > 0){
									return { 
										...item, 
										OpeningDebit: foundDebtors.reduce((sum, debtor) => sum + Number(debtor.OpeningDebit), 0),
										OpeningCredit: foundDebtors.reduce((sum, debtor) => sum + Number(debtor.OpeningCredit), 0)
									};
								}

								if(foundCreditors.length > 0){
									return { 
										...item, 
										OpeningDebit: foundCreditors.reduce((sum, creditor) => sum + Number(creditor.OpeningDebit), 0),
										OpeningCredit: foundCreditors.reduce((sum, creditor) => sum + Number(creditor.OpeningCredit), 0)
									};
								}
							}

							return item; // Return unchanged item
						});

						const filteredItems = parsedData
						.filter(c => 
							!c.HasChildren 
							&& !(c.BeforeFiscalYearChildren === 0 && (c.SpecialAccountType === "CC" || c.SpecialAccountType === "SC"))
						)
						.filter(c => (c.OpeningDebit > 0 || c.OpeningCredit > 0))
						// console.log("dta item", data.items, "filter", filteredItems)
						recalculateTotal(filteredItems);
						
						return parsedData;
					} else {
						return [];
					}
				})
				.catch(() => {
					throw "Network error";
				})
		);
	};

	const getDebtorInfo = (id, formSaving = false) =>{
		loading("Loading Debtor Info...");
		baseapi.httpget("/api/GLOpeningBalance/GetDebtorInfo", { GLChartAccountID: id }).then((response) => {
			const { data } = response;
			var formData = {};

			if(!utils.isNullOrEmpty(data.status) && data.status){
				formData = {...data};
				delete formData["Children"];
				setPopupParent(formData);
				setCurrentFiscalYear(data.FiscalYearDate);

				if(!utils.isNullOrEmpty(data.Children)){
					utils.childrenGridSetSource(childrenGridRef, data.Children);

					if(formSaving){
						refreshOpeningBalance(data.Children);
					}
				}
			}

			closeLoading();
		});
	}

	const getCreditorInfo = (id, formSaving = false) =>{
		loading("Loading Creditor Info...");
		baseapi.httpget("/api/GLOpeningBalance/GetCreditorInfo", { GLChartAccountID: id }).then((response) => {
			const { data } = response;
			var formData = {};

			// console.log("creditor triggered", data.status)

			if(!utils.isNullOrEmpty(data.status) && data.status){
				formData = {...data};
				delete formData["Children"];
				setPopupParent(formData);
				setCurrentFiscalYear(data.FiscalYearDate);

				if(!utils.isNullOrEmpty(data.Children)){
					utils.childrenGridSetSource(childrenGridRef, data.Children);

					if(formSaving){
						refreshOpeningBalance(data.Children);
					}
				}
			}
			closeLoading();
		});
	}

	const recalculateTotal = (dataSource) => {
		if(Array.isArray(dataSource)){
			var totalDebit = 0
			var totalCredit = 0;
			const form = {};

			for(var i = 0; i < dataSource.length; i++){
				if(!utils.isNullOrEmpty(dataSource[i]["OpeningDebit"])){
					totalDebit += dataSource[i]["OpeningDebit"];
				}

				if(!utils.isNullOrEmpty(dataSource[i]["OpeningCredit"])){
					totalCredit += dataSource[i]["OpeningCredit"];
				}
			}

			form["DebitTotal"] = totalDebit;
			form["CreditTotal"] = totalCredit;
			form["OutOfBalance"] = totalDebit - totalCredit;
			setFormValue(form);
		}
	};

	const onEditorPreparing = useCallback((e) => {
		//console.log('onEditorPreparing')
		if (e.row.data.ChartAccountCode === "" || !e.row.data.CanEdit) {
			e.editorOptions.readOnly = true;
		}
	}, []);

	const onRowPrepared = useCallback((e) => {
		//console.log(e);
		//console.log('onRowPrepared'); return;
		if (e.rowType === "data") {
			const thirdColumnCell = e.rowElement.querySelector(".dx-row > td:nth-child(3)");
			const fourthColumnCell = e.rowElement.querySelector(".dx-row > td:nth-child(4)");
			if (!e.node.hasChildren && e.data.CanEdit) {
				thirdColumnCell && thirdColumnCell.classList.add("editableColumns");
				fourthColumnCell && fourthColumnCell.classList.add("editableColumns");
			} else {
				thirdColumnCell && (thirdColumnCell.disabled = true);
				fourthColumnCell && (fourthColumnCell.disabled = true);
			}
		}
	}, []);

	const onOptionChanged = useCallback((e) => {
		if ((e.name === "editing" && e.fullName === "editing.changes") || e.name === "dataSource") {

			const changes = e.value;
			setExpandAll(false);
			var newDetails = null;

			// update parent cell values
			newDetails = [...treelistRef.current.props.dataSource];
			//console.log(newDetails);
			//console.log(listingDataSource);
			const parentIndexMap = new Map(newDetails.map((detail, index) => [detail.GLChartAccountID, index]));
			const rootParentTotalDebitAmounts = {};
			const rootParentTotalCreditAmounts = {};
			const recursiveUpdateParent = (parentId, amount, updateColumn, totalAmounts) => {
				const existingIndex = parentIndexMap.get(parentId);
				// console.log("existing index", existingIndex)
				if (existingIndex !== undefined) {
					const parentDetail = newDetails[existingIndex];
					if (parentDetail) {
						if (!totalAmounts[parentDetail.RootParentID]) {
							totalAmounts[parentDetail.RootParentID] = { debit: 0, credit: 0 };
						}

						const updateDetail = (index, field) => {
							if (parentDetail[field] !== undefined) {
								if (field === "OpeningDebit") {
									newDetails[index] = {
										...newDetails[index],
										["OpeningDebit"]: parseFloat(totalAmounts[parentDetail.RootParentID].debit)
									};
								}
								if (field === "OpeningCredit") {
									newDetails[index] = {
										...newDetails[index],
										["OpeningCredit"]: parseFloat(totalAmounts[parentDetail.RootParentID].credit)
									};
								}
							}
						};

						if (updateColumn === "OpeningDebit") {
							updateDetail(existingIndex, "OpeningDebit");
							totalAmounts[parentDetail.RootParentID].debit += parseFloat(amount);
						}

						if (updateColumn === "OpeningCredit") {
							updateDetail(existingIndex, "OpeningCredit");
							totalAmounts[parentDetail.RootParentID].credit += parseFloat(amount);
						}

						recursiveUpdateParent(parentDetail.ParentID, amount, updateColumn, totalAmounts);
					}
				}
			};

			for (let i = 0; i < changes.length; i++) {
				const change = changes[i];
				const existingIndex2 = parentIndexMap.get(change.key);
				if (existingIndex2 !== undefined) {
					const matchingDetail = newDetails[existingIndex2];
					if (matchingDetail) {
						const updateDetail = (index, field) => {
							if (change.data[field] !== undefined) {
								if (field == "OpeningCredit") {
									if (newDetails[index]["OpeningDebit"]) {
										newDetails[index]["OpeningDebit"] = "";
										delete change.data["OpeningDebit"];
									}
								} else {
									if (newDetails[index]["OpeningCredit"]) {
										newDetails[index]["OpeningCredit"] = "";
										delete change.data["OpeningCredit"];
									}
								}
								newDetails[index][field] = parseFloat(change.data[field] ?? 0);
							}
						};

						updateDetail(existingIndex2, "OpeningDebit");
						updateDetail(existingIndex2, "OpeningCredit");
					}
				}
			}
			for (let i = 0; i < changes.length; i++) {
				const change = changes[i];
				const existingIndex2 = parentIndexMap.get(change.key);
				if (existingIndex2 !== undefined) {
					const matchingDetail = newDetails[existingIndex2];
					if (matchingDetail) {
						if (change.data["OpeningDebit"] !== undefined) {
							recursiveUpdateParent(matchingDetail.ParentID, parseFloat(change.data["OpeningDebit"] ?? 0), "OpeningDebit", rootParentTotalDebitAmounts);
						}
						if (change.data["OpeningCredit"] !== undefined) {
							recursiveUpdateParent(matchingDetail.ParentID, parseFloat(change.data["OpeningCredit"] ?? 0), "OpeningCredit", rootParentTotalCreditAmounts);
						}
					}
				}
			}
			// console.log("changes changed", changes);
			// console.log(newDetails);
			// newDetails = e.name === "dataSource" ? e.value : newDetails;
			recalculateTotal(
				newDetails
				.filter(c => 
					!c.HasChildren 
					&& !(c.BeforeFiscalYearChildren === 0 && (c.SpecialAccountType === "CC" || c.SpecialAccountType === "SC"))
				)
				.filter(c => (utils.isNumber(c.OpeningDebit) || utils.isNumber(c.OpeningCredit)))
			);
			setListingDataSource(newDetails);
		}
	}, []);

	const onNodesInitialized = useCallback((e) => {
		function sumDebitCredit(node) {
			let totalDebit = node.children.length ? 0 : parseFloat(node.data.OpeningDebit) || 0;
			let totalCredit = node.children.length ? 0 : parseFloat(node.data.OpeningCredit) || 0;

			node.children.forEach((child) => {
				const childSums = sumDebitCredit(child);
				totalDebit += childSums.totalDebit;
				totalCredit += childSums.totalCredit;
			});

			node.data.OpeningDebit = totalDebit || "";
			node.data.OpeningCredit = totalCredit || "";

			return { totalDebit, totalCredit };
		}
		e.root.children.forEach((child) => {
			sumDebitCredit(child);
		});

		if(submitOpeningBalance.current){
			const cellData = currentFocusCell.current["data"];
			if(!utils.isNullOrEmpty(cellData) && !utils.isNullOrEmpty(cellData["ParentID"])){
				// saveToLS("Testing", listingDataSource)
				const children = [...treelistRef.current.props.dataSource]
					.filter(c => c.GLChartAccountID === cellData["ParentID"])
					.map(item => ({
						...item,
						OpeningDebit: Number(item.OpeningDebit),
						OpeningCredit : Number(item.OpeningCredit),
					}));

				// console.log("Children", children);

				const submit = {
					ProjectID: projectID,
					Children: children,
				};

				baseapi.httppost(utils.extendUrlVar("/api/GLOpeningBalance/saveOpeningBalance"), submit).then(async (res) => {
					// console.log(res);
					res = res.data;
					if (!res.status) {
						utils.displayPopupMessage(popupMessageRef, {
							visible: true,
							message: res.message,
							type: "Warning"
						});
					} 

					submitOpeningBalance.current = false;
				});
			}
			// console.log("data source changed", listingDataSource)
		}
	}, []);

	const calculateDataGridTotal = useCallback((data) => {
		return data.valueText.replace("Sum: ", "");
	}, []);

	const onCellClick = (e) => {
		const rowType = e.rowType;

		if(rowType === "data"){
			const row = e.row;
			const node = row.node;
			const data = row.data;
			const columnIndex = e.columnIndex;
			const rowIndex = e.rowIndex;
	
			currentFocusCell.current["columnIndex"] = columnIndex;
			currentFocusCell.current["rowIndex"] = rowIndex;
			currentFocusCell.current["key"] = row["key"];
			currentFocusCell.current["data"] = data;
			// console.log("data source", row)
	
			if(enableUpdate){
				if(!node["hasChildren"] && (columnIndex === 2 || columnIndex === 3)){
					if(data["SpecialAccountType"] === "CC" || data["SpecialAccountType"] === "SC"){
						if(data["SpecialAccountType"] === "CC"){
							setPopupTitle("Debtor Opening Balance");
							getDebtorInfo(data["GLChartAccountID"]);
							utils.popupFormOpen(formRef);
				
						}
						else if(data["SpecialAccountType"] === "SC"){
							setPopupTitle("Creditor Opening Balance");
							getCreditorInfo(data["GLChartAccountID"]);
							utils.popupFormOpen(formRef);		
						}
					}
				}
			}
		}
	};

	const onInitNew = () => {
		return {
			DocumentDate : currentFiscalYear,
			DueDate : currentFiscalYear,
			LocalRate: 1,
			OpeningDebit: 0,
			OpeningCredit: 0,
			ForexOpeningDebit: 0,
			ForexOpeningCredit: 0
		};
	};

	const gridOnRowUpdating = (e) => {
		const oldValue = e.oldData;
		const currentRow = Object.assign(oldValue, e.newData);
		var currentField = Object.keys(e.newData)[0];
		
		if(currentField === "LocalRate"){
			currentRow["OpeningDebit"] = utils.multiply(currentRow["ForexOpeningDebit"], currentRow["LocalRate"]);
			currentRow["OpeningCredit"] = utils.multiply(currentRow["ForexOpeningCredit"], currentRow["LocalRate"]);
		}
		else if (currentField === "ForexOpeningDebit") {
			currentRow["ForexOpeningCredit"] = 0;
			currentRow["OpeningCredit"] = 0;
			currentRow["OpeningDebit"] = utils.multiply(currentRow["ForexOpeningDebit"], currentRow["LocalRate"]);
		} else if (currentField === "ForexOpeningCredit") {
			currentRow["ForexOpeningDebit"] = 0;
			currentRow["OpeningDebit"] = 0;
			currentRow["OpeningCredit"] = utils.multiply(currentRow["ForexOpeningCredit"], currentRow["LocalRate"]);
		}

		e.newData = currentRow;
	};

	const gridOnRowRemoving = async (e) => {
		const data = e.data;
		const treelistFocusData = currentFocusCell.current["data"];
		// console.log("data", treelistFocusData)

		if(!utils.isNullOrEmpty(data["TransactionTypeCode"])){
			const url = deleteURL[utils.trim(data["TransactionTypeCode"])];

			if(!utils.isNullOrEmpty(url) && !utils.isNullOrEmpty(data["DocumentID"])){
				let promise = new Promise((resolve, reject) => {
					baseapi.httpdel(url, { id: data["DocumentID"] }).then((response) => {
						const { data } = response;
	
						utils.popupFormSetErrorForm(formRef, {
							visible: true,
							message: data.message,
							type: data.status ? "Success" : "Danger",
						});

						if (data.status) {
							resolve(false);
						}
						else{
							resolve(true);
						}

						if(!utils.isNullOrEmpty(treelistFocusData) && !utils.isNullOrEmpty(treelistFocusData["GLChartAccountID"]) 
							&& !utils.isNullOrEmpty(treelistFocusData["SpecialAccountType"])){
							if(treelistFocusData["SpecialAccountType"] === "CC"){
								getDebtorInfo(treelistFocusData["GLChartAccountID"], true);
							}
							else if(treelistFocusData["SpecialAccountType"] === "SC"){
								getCreditorInfo(treelistFocusData["GLChartAccountID"], true);
							}
						}
					})
				});
				e.cancel = promise;
			}
		}
	};

	const refreshOpeningBalance = (children = []) => {
		const treelistDataSource = [...treelistRef.current.props.dataSource];
		const key = currentFocusCell.current["key"];
		const foundRecord = treelistDataSource.find(c => c.GLChartAccountID === key);
		const foundIndex = treelistDataSource.findIndex(c => c.GLChartAccountID === key);
		var totalDebit = 0;
		var totalCredit = 0;
		
		for(var i = 0; i < children.length; i++){
			const row = children[i];
			totalDebit += row["OpeningDebit"];
			totalCredit += row["OpeningCredit"];
		}

		foundRecord["OpeningDebit"] = totalDebit;
		foundRecord["OpeningCredit"] = totalCredit;
		treelistDataSource[foundIndex] = foundRecord;
		// console.log("total debit", totalDebit, "total credit", totalCredit, "found record", foundRecord)

		if(treelistRef.current){
			submitOpeningBalance.current = true;
			treelistRef.current.instance.option("dataSource", treelistDataSource); //Update data source
			recalculateTotal(
				treelistDataSource
				.filter(c => 
					!c.HasChildren 
					&& !(c.BeforeFiscalYearChildren === 0 && (c.SpecialAccountType === "CC" || c.SpecialAccountType === "SC"))
				)
				.filter(c => (utils.isNumber(c.OpeningDebit) || utils.isNumber(c.OpeningCredit)))
			)
		}
	}

	const clearPopup = () => {
		setPopupParent({});
	}

	const gridOnSelectionChanged = (e, columnName) => {
		setGridLookupOpen(false);
		const currentFocusCellIndex = utils.childrenGridGetFocusCellIndex(childrenGridRef);
		const currentRecords = utils.childrenGridGetSource(childrenGridRef);
		const key = currentFocusCellIndex.rowID;
		const rowIndex = currentRecords.findIndex(c => c.ID === key);
		
		const selectedItem = e.selectedItem;
		const dataSource = e.dataSource;
		currentRecords[rowIndex][columnName] = e.value;
		const childrenID = crypto.randomUUID();

		if (columnName === "TransactionTypeID" && rowIndex !== -1) {
			currentRecords[rowIndex]["CustomerID"] = popupParent["CustomerID"];
			currentRecords[rowIndex]["CustomerCode"] = popupParent["CustomerCode"];
			currentRecords[rowIndex]["CustomerName"] = popupParent["CustomerName"];
			currentRecords[rowIndex]["AreaID"] = popupParent["AreaID"];
			currentRecords[rowIndex]["AreaCode"] = popupParent["AreaCode"];
			currentRecords[rowIndex]["SalesmanID"] = popupParent["SalesmanID"];
			currentRecords[rowIndex]["SalesmanCode"] = popupParent["SalesmanCode"];
			currentRecords[rowIndex]["CreditTermsID"] = popupParent["CreditTermsID"];
			currentRecords[rowIndex]["CreditTermsCode"] = popupParent["CreditTermsCode"];
			currentRecords[rowIndex]["LocalRate"] = popupParent["LocalRate"];
			currentRecords[rowIndex]["ForexRate"] = popupParent["ForexRate"];
			currentRecords[rowIndex]["TransactionTypeCode"] = selectedItem?.code;
			currentRecords[rowIndex]["ChildrenID"] = childrenID;
			currentRecords[rowIndex]["SupplierID"] = popupParent["SupplierID"];
			currentRecords[rowIndex]["SupplierCode"] = popupParent["SupplierCode"];
			currentRecords[rowIndex]["SupplierName"] = popupParent["SupplierName"];
			// console.log("popup parent", popupParent)
			setChildrenID(childrenID);
			setChildrenFormData(currentRecords[rowIndex]);
		}
		// console.log("current reocrds", currentRecords[rowIndex], "row index", rowIndex, "current focus", currentFocusCellIndex)
		setFormID(selectedItem, currentRecords[rowIndex]["DocumentID"]);

		e["key"] = key;
		e["data"] = currentRecords[rowIndex];
		onRowUpdated(e, currentRecords);
	};

	const setFormID = (selectedItem, documentID) => {
		
		if(utils.isNullOrEmpty(documentID)){
			documentID = "new";
		}

		if(utils.isObject(selectedItem)){
			const code = utils.trim(selectedItem.code);

			if(code === "RD"){
				setARDNEditID(documentID);
			}
			else if(code === "RC"){
				setARCNEditID(documentID);
			}
			else if(code === "RR"){
				setARReceiptEditID(documentID);
			}
			else if(code === "RRF"){
				setARRefundEditID(documentID);
			}
			else if(code === "RI"){
				setARInvoiceEditID(documentID);
			}
			else if(code === "PC"){
				setAPCNEditID(documentID);
			}
			else if(code === "PD"){
				setAPDNEditID(documentID);
			}
			else if(code === "PP"){
				setAPPurchaseEditID(documentID);
			}
			else if(code === "PRF"){
				setAPRefundEditID(documentID);
			}
			else if(code === "PV"){
				setAPPaymentEditID(documentID);
			}
		}
	};
	
	const onRowUpdated = (e, children = null) => {
		const childrenDetails = children !== null ? children : utils.childrenGridGetSource(childrenGridRef);
		utils.childrenGridSetSource(childrenGridRef, childrenDetails);
	};

	const formOnSaving = (data) => {
		const treelistFocusData = currentFocusCell.current["data"];
		if(data.isDebtor === true && !utils.isNullOrEmpty(treelistFocusData) && !utils.isNullOrEmpty(treelistFocusData["GLChartAccountID"])){
			getDebtorInfo(treelistFocusData["GLChartAccountID"], true);
		}
		else if(data.isCreditor === true && !utils.isNullOrEmpty(treelistFocusData) && !utils.isNullOrEmpty(treelistFocusData["GLChartAccountID"])){
			getCreditorInfo(treelistFocusData["GLChartAccountID"], true);
		}
	};

	const onFocusedCellChanged = (e) => {
		const currentRecords = utils.childrenGridGetSource(childrenGridRef);
		const dataField = e.dataField;
		const rowIndex = e.rowIndex;
		const data = e.rowData;
		
		// console.log("data", data);
		if(!utils.isNullOrEmpty(data)){
			if(utils.isNullOrEmpty(data["DocumentID"]) && utils.isNullOrEmpty(data["DocumentNo"])){
				setTransactionTypeDisabled(false);
			}
			else{
				setTransactionTypeDisabled(true);
			}

			if(dataField !== "TransactionTypeID"){
				if(utils.isNullOrEmpty(data["ChildrenID"])){
					const childrenID = crypto.randomUUID();
					currentRecords[rowIndex]["ChildrenID"] = childrenID;
					setChildrenID(childrenID);
				}
	
				const foundIndex = currentRecords.findIndex(c => c.ChildrenID === data["ChildrenID"]);
				if(foundIndex !== -1){
					setChildrenFormData(currentRecords[foundIndex]);
				}
	
				if(!utils.isNullOrEmpty(data["TransactionTypeID"]) && !utils.isNullOrEmpty(dataField)){
					const obj = {
						id: data["TransactionTypeID"],
						code: data["TransactionTypeCode"]
					};
	
					setFormID(obj, data["DocumentID"]);
				}
	
				// console.log("on focus cell changed", e, foundIndex);
			}
		}
	};

	useEffect(() => {
		// if (projectID == null) return;
		// console.log("lockDataSource", lockDataSource);
		//console.log("projectID", projectID);
		async function fetchData() {
			await refreshTreeList();
		}
		fetchData();
	}, [projectID]);

	return (
		<Container>
			<div className='opening-balance-container'>
				<div className='listing-page-title-container'>
					<span className='datagrid-customized-title'>
						<PathIndicator />
					</span>
				</div>

				<div className='listing-datebox-container' style={{ display: "grid", gridTemplateColumns: "15% 85%", marginBottom: "10px" }}>
					<CustomizedLookup
						disabled={enableUpdate}
						value={projectID}
						displayText={"P0"}
						className={"ar-listing-datagrid"}
						dataSourceURL={"api/Utilities/GetProjects"}
						dropdownClassName={"listing-page-searching-lookup"}
						onDataSourceChanged={setProjectList}
						onSelectionChanged={(e) => {
							setProjectID(e.value);
							// console.log(e.value);
						}}
						dataSource={projectList}
						displayExpr={"code"}
						valueExpr={"id"}
						height={"36px"}>
						<Column dataField='code'></Column>
						<Column dataField='name' caption={"Model"}></Column>
					</CustomizedLookup>

					<div style={{ textAlign: "right" }}>
						<Button visible={cancelVisible} text='Cancel' type='danger' style={{ marginRight: "10px" }} onClick={() => handleCancel()} />
						<Button visible={editVisible} text='Edit' type='success' style={{ marginRight: "10px", backgroundColor: "#F68627" }} onClick={() => handleEdit()} />
						<Button text='Save' type='success' disabled={disableSave} onClick={() => handleSubmit()} />
					</div>
				</div>
				<TreeList
					className='OpeningBalance'
					ref={treelistRef}
					//visible={projectID !== null}
					dataSource={listingDataSource}
					allowColumnReordering={false}
					allowColumnResizing={false}
					columnAutoWidth={false}
					sorting={{ mode: "none" }}
					columnResizingMode='widget'
					showRowLines={true}
					showBorders={false}
					autoExpandAll={expandAll}
					//repaintChangesOnly={true}
					height={"67vh"}
					width={"100%"}
					keyExpr='GLChartAccountID'
					parentIdExpr='ParentID'
					rootValue={"t2B0SsAZelE%3d"}
					loadPanel={{ enabled: true }}
					onEditorPreparing={onEditorPreparing}
					onRowPrepared={onRowPrepared}
					onOptionChanged={onOptionChanged}
					onNodesInitialized={onNodesInitialized}
					onCellClick={onCellClick}
					hoverStateEnabled={true}
				>
					<Column dataField={"ChartAccountName"} caption={"Account"} editorOptions={{ readOnly: true }}></Column>
					<Column dataField={"ChartAccountCode"} editorOptions={{ readOnly: true }}></Column>
					<Column dataField={"OpeningDebit"} dataType={"number"} format={{ type: "fixedPoint", precision: 2 }}></Column>
					<Column dataField={"OpeningCredit"} dataType={"number"} format={{ type: "fixedPoint", precision: 2 }}></Column>
					<Editing allowUpdating={enableUpdate} mode='batch' />
				</TreeList>

				<div className='opening-balance-sticky'>
					<div className='popup-form-item-container3 item-align-bottom' style={{ padding: "0px 20px", display: "grid", gridTemplateColumns: "38% 12% 26% 24%" }}>
						<div>
							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Out Of Balance:</div>

								<div className='popup-group-form-input' style={{ width: "200px" }}>
									<NumberBox className='OutOfBalance' value={formValue["OutOfBalance"]} readOnly={true} format='#,##0.00' />
								</div>
								{/* <div className='popup-group-form-input'>
									<p hidden={formValue["OutOfBalance"] === 0} className='errorMessage'>
										Amount not balance by {formValue["OutOfBalance"]}
									</p>
								</div> */}
							</div>
						</div>
						<div>
							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Total: </div>
							</div>
						</div>
						<div>
							<div className='popup-group-form-item' style={{ display: "grid", gridTemplateColumns: "29% 71%", margin: "2.5px 10px", alignItems: "flex-end" }}>
								<div className='popup-group-form-label'>DR:</div>

								<div style={{ maxWidth: "270px" }}>
									<NumberBox className='OutOfBalance' value={formValue["DebitTotal"]} readOnly={true} format='#,##0.00' />
								</div>
							</div>
						</div>
						<div>
							<div className='popup-group-form-item' style={{ display: "grid", gridTemplateColumns: "29% 71%", margin: "2.5px 10px", alignItems: "flex-end" }}>
								<div className='popup-group-form-label'>CR:</div>

								<div style={{ maxWidth: "270px" }}>
									<NumberBox className='OutOfBalance' value={formValue["CreditTotal"]} readOnly={true} format='#,##0.00' />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<ErrorPopupForm ref={popupMessageRef} />

			<PopupForm
				ref={formRef}
				width={"60%"}
				height={"60%"}
				shading={true}
				showTitle={false}
				fullScreen={false}
				title={popupTitle}
				childrenGridRef={childrenGridRef}
				creditChecking={false}
				onClosing={clearPopup}
				actionButtonEnabled={false}
				disableButtons={true}
			>
				<div className="popup-form-item-container4">
					<div className='popup-group-form-item-block'>
						<div className='popup-group-form-label'>Account No: </div>

						<div>
							<TextBox
								value={popupParent["AccountNo"]}								
								alignment='left'
								width={"auto"}
								readOnly={true}>
							</TextBox>
						</div>
					</div>

					<div className='popup-group-form-item-block'>
						<div className='popup-group-form-label'>Currency Code: </div>

						<div>
							<TextBox
								value={popupParent["CurrencyCode"]}								
								alignment='left'
								width={"auto"}
								readOnly={true}>
							</TextBox>
						</div>
					</div>
				</div>

				<div className="popup-form-item-container1">
					<div className='popup-group-form-item-block'>
						<div className='popup-group-form-label'>Company Name: </div>

						<div>
							<TextBox
								value={popupParent["CompanyName"]}								
								alignment='left'
								width={"auto"}
								readOnly={true}>
							</TextBox>
						</div>
					</div>
				</div>

				<div className='children-datagrid-container' style={{ padding: "0 0" }}>
					<ChildrenDatagrid
						ref={childrenGridRef}
						enabledDescription={false}
						keyExpr='ID'
						showBorders={true}
						loadPanel={false}
						allowColumnReordering={true}
						allowColumnResizing={true}
						storageName={"GLOpeningBalanceChildrenGrid"}
						onFocusedCellChanged={onFocusedCellChanged}
						onRowUpdating={gridOnRowUpdating}
						onRowRemoving={gridOnRowRemoving}
						disabledCommonColumns={true}
						autoAddRow={false}
						height={"245px"}
					>
						<GridColumn
							visible={true}
							dataField='TransactionTypeID'
                            caption={"Type"}
							dataType='string'
							cellRender={(e) => {
								const data = e.data;
								return data.TransactionTypeCode;
							}}
							editCellComponent={(component) => (
								<CustomizedGridLookup
									{...component}
									opened={gridLookupOpen}
									className={"ar-listing-datagrid"}
									onSelectionChanged={(e) => gridOnSelectionChanged(e, "TransactionTypeID")}
									dataSourceURL={popupTitle === "Debtor Opening Balance" ? "/api/Utilities/GetARTransactionType" : "/api/Utilities/GetAPTransactionType"}
									disabled={transactionTypeDisabled}
								>
									<Column dataField='code'></Column>
									<Column dataField='name' caption={"Description"} width={"300px"}></Column>
								</CustomizedGridLookup>
							)} 
						/>

						<GridColumn 
							dataField={"DocumentDate"} 
							caption={"Date"} 
							dataType={"date"} 
							editorOptions={{ 
								min : "2000-01-01", 
								displayFormat : 'dd/MM/yyyy',
								dateSerializationFormat : 'yyyy-MM-dd',
								useMaskBehavior : true,
								readOnly: true
							}}
							format={'dd/MM/yyyy'}
						/>

						<GridColumn dataField={"DocumentNo"} caption={"Doc No"} editorOptions={{ readOnly : true}} />
						<GridColumn dataField={"Description"} editorOptions={{ readOnly : true}}/>
						<GridColumn 
							dataField={"LocalRate"} 
							format='#,##0.000000'
							dataType={"number"} 
							editorOptions={{ readOnly : true}} 
						/>
						<GridColumn 
							dataField={"ForexOpeningDebit"} 
							caption={"DR"} 
							format={'#,##0.00'}
							dataType={"number"}
							editorOptions={{
								min: 0,
								format: '#,##0.00',
								useMaskBehavior: true,
								readOnly: true
							}}
						/>
						<GridColumn 
							dataField={"ForexOpeningCredit"} 
							caption={"CR"}
							format={'#,##0.00'}
							dataType={"number"}
							editorOptions={{
								min: 0,
								format: '#,##0.00',
								useMaskBehavior: true,
								readOnly: true
							}}
						/>
						<GridColumn 
							dataField={"OpeningDebit"} 
							caption={"Local DR"} 
							format={'#,##0.00'}
							dataType={"number"}
							editorOptions={{
								min: 0,
								format: '#,##0.00',
								useMaskBehavior: true,
								readOnly: true
							}}
						/>

						<GridColumn 
							dataField={"OpeningCredit"} 
							caption={"Local CR"} 
							format={'#,##0.00'}
							dataType={"number"}
							editorOptions={{
								min: 0,
								format: '#,##0.00',
								useMaskBehavior: true,
								readOnly: true
							}}
						/>

						<Summary recalculateWhileEditing={true}>
							<TotalItem column='ForexOpeningDebit' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />
							<TotalItem column='ForexOpeningCredit' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />
							<TotalItem column='OpeningDebit' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />
							<TotalItem column='OpeningCredit' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />
						</Summary>
					</ChildrenDatagrid>
				</div>
			</PopupForm>

			{/* AR FORM */}
			<ARDebitNoteFormBFY 
				formData={childrenFormData}
				childrenID={childrenID}
				closePopup={setARDNEditID}
				formID={ARDNEditID}
				title={"A/R Debit Note"}
				apiURL={`/api/ARDebitNote`}
				onSaving={formOnSaving}
			/>

			<ARCreditNoteBFY 
				formData={childrenFormData}
				childrenID={childrenID}
				closePopup={setARCNEditID}
				formID={ARCNEditID}
				title={"A/R Credit Note"}
				apiURL={`/api/ARCreditNote`}
				onSaving={formOnSaving}
			/>

			<ARReceiptFormBFY 
				formData={childrenFormData}
				childrenID={childrenID}
				closePopup={setARReceiptEditID}
				formID={ARReceiptEditID}
				title={"A/R Receipt"}
				apiURL={`/api/ARReceipt`}
				onSaving={formOnSaving}
			/>

			<ARRefundFormBFY 
				formData={childrenFormData}
				childrenID={childrenID}
				closePopup={setARRefundEditID}
				formID={ARRefundEditID}
				title={"A/R Refund"}
				apiURL={`/api/ARRefund`}
				onSaving={formOnSaving}
			/>

			<ARSalesFormBFY 
				formData={childrenFormData}
				childrenID={childrenID}
				closePopup={setARInvoiceEditID}
				formID={ARInvoiceEditID}
				title={"A/R Invoice"}
				apiURL={`/api/ARSales`}
				onSaving={formOnSaving}
			/>

			{/* AP FORM */}
			<APDebitNoteFormBFY 
				formData={childrenFormData}
				childrenID={childrenID}
				closePopup={setAPDNEditID}
				formID={APDNEditID}
				title={"A/P Debit Note"}
				apiURL={`/api/APDebitNote`}
				onSaving={formOnSaving}
			/>

			<APCreditNoteFormBFY 
				formData={childrenFormData}
				childrenID={childrenID}
				closePopup={setAPCNEditID}
				formID={APCNEditID}
				title={"A/P Credit Note"}
				apiURL={`/api/APCreditNote`}
				onSaving={formOnSaving}
			/>

			<APPaymentFormBFY 
				formData={childrenFormData}
				childrenID={childrenID}
				closePopup={setAPPaymentEditID}
				formID={APPaymentEditID}
				title={"A/P Payment"}
				apiURL={`/api/APPayment`}
				onSaving={formOnSaving}
			/>

			<APRefundFormBFY 
				formData={childrenFormData}
				childrenID={childrenID}
				closePopup={setAPRefundEditID}
				formID={APRefundEditID}
				title={"A/P Refund"}
				apiURL={`/api/APRefund`}
				onSaving={formOnSaving}
			/>

			<APPurchaseFormBFY 
				formData={childrenFormData}
				childrenID={childrenID}
				closePopup={setAPPurchaseEditID}
				formID={APPurchaseEditID}
				title={"A/P Invoice"}
				apiURL={`/api/APPurchase`}
				onSaving={formOnSaving}
			/>
		</Container>
	);
}
