import { useState, useRef, useEffect, forwardRef, useImperativeHandle, useCallback } from "react";
import { TextBox, DateBox, NumberBox } from "devextreme-react";
import AsyncTextBox from "../../../components/async-text-box/AsyncTextBox";
import CustomizedLookup from "../../../components/lookup/Lookup";
import utils from "../../../utils/common";
import DataGrid, { Column, Editing, Selection, Lookup, KeyboardNavigation, ColumnChooser, Summary, TotalItem, Toolbar, Item as ToolbarItem, LoadPanel } from "devextreme-react/data-grid";
import { Validator, RequiredRule as ValidatorRequired, CustomRule } from "devextreme-react/validator";
import { Template } from "devextreme-react/core/template";
import { CheckBox } from "devextreme-react/check-box";
import dayjs from "dayjs";
import baseapi from "../../../api/baseapi";
import { DocumentSettings } from "../../../utils/documentSettings";

export default forwardRef(function SupplierForm(props, ref) {
	const [formValue, setFormValue] = useState({});
	const [startValidation, setStartValidation] = useState(0);
	const [isEditing, setIsEditing] = useState(false);
	const [defaultValue, setDefaultValue] = useState({});
	const tempForm = useRef(null);
	const projectLookupRef = useRef(null);
	const salesmanLookupRef = useRef(null);
	const areaLookupRef = useRef(null);
	const [projectMainList, setProjectMainList] = useState([]);
	const [supplierList, setSupplierList] = useState([]);
	const [forexList, setForexList] = useState([]);
	const [salesmanList, setSalesmanList] = useState([]);
	const [areaList, setAreaList] = useState([]);
	const [offsetForm, setOffsetForm] = useState({ Parent: {}, Children: [] });
	const offsetGridRef = useRef(null);
	const offsetOldValue = useRef(null);
	const offsetCheckboxRef = useRef(null);
	const [offsetGridDataValid, setOffsetGridDataValid] = useState(true)

	useEffect(() => {
		if (props.onValueChanged !== undefined) {
			props.onValueChanged({ parent: formValue });
		}
	}, [formValue]);

	useImperativeHandle(ref, () => ({
		setValue(value) {
			tempForm.current = value;
			setFormValue(tempForm.current);
			// console.log(Object.keys(value).length);
			if (tempForm.current["ID"] !== null && tempForm.current["SupplierID"] !== null && Object.keys(value).length > 0) {
				baseapi.httpget("/api/APContraOffset/Get", { id: tempForm.current["ID"] || "", supplierid: tempForm.current["SupplierID"] }).then((response) => {
					const { data } = response;
					// console.log("data", data);
					const copiedChildren = [...data.model.Children];

					for (var i = 0; i < copiedChildren.length; i++) {
						if (copiedChildren[i]["OffsetAmount"] === 0) {
							copiedChildren[i]["OffsetLogDate"] = "";
						} else {
							copiedChildren[i]["OffsetLogDate"] = copiedChildren[i]["OffsetLogDate"];
						}
					}

					setOffsetForm({
						Parent: null,
						Children: copiedChildren
					});
					setOffsetGridDataValid(true);
				});
			}
		},
		clearForm() {
			setOffsetForm({ Parent: {}, Children: [] });
			setOffsetGridDataValid(true);
		},
		editingForm(value) {
			setIsEditing(value);
		},
		setValidation(value) {
			setStartValidation(value);
		},
		getOffsetGridDataValid() {
			return offsetGridDataValid;
		},
		getOffsetValue() {
			const childrenCopied = offsetGridRef.current.instance.getVisibleRows();
			const offsetChildren = [];

			for (var i = 0; i < childrenCopied.length; i++) {
				const rowData = childrenCopied[i]["data"];
				offsetChildren.push(rowData);
			}
			// console.log(offsetChildren);

			return offsetChildren;
		},
		getLookupRef() { },
		setDefaultValues(value) {
			if (!utils.isNullOrEmpty(value)) {
				setDefaultValue(value);
			} else {
				setDefaultValue(formValue);
			}
		},
	}));

	useEffect(() => {
		if (!utils.isNullOrEmpty(formValue["LocalRate"])) {
			setFormValue((prevValues) => ({
				...prevValues,
				MainLocalAmount: prevValues["LocalRate"] * getTotalReceiptAmount()
			}));
		}
	}, [formValue["LocalRate"]]);

	function valueOnChange(e, receivedDataField = null) {
		// console.log("I am in value changed", e, "data field", e.element.getAttribute("dataField"))
		try {
			const dataField = receivedDataField !== null ? receivedDataField : e.element.getAttribute("dataField");
			const copied = { ...tempForm.current };
			copied[dataField] = e.value;
			tempForm.current = copied;
			setFormValue(tempForm.current);

			if (!utils.isNullOrEmpty(e.value)) {
				if (dataField === "MainForexAmount") {
					copied[dataField] = e.value;
					if (e.value !== e.previousValue) {
						offsetSelectAllChecker({ value: false });
					}
					copied["MainLocalAmount"] = e.value * copied["LocalRate"];
					copied["OutstandingAmount"] = e.value;

					tempForm.current = copied;
					// console.log(tempForm.current);
					setFormValue(tempForm.current);
				} else if (dataField === "SupplierID") {
					baseapi.httpget("/api/APContraOffset/Get?id=" + copied.ID + "&supplierid=" + e.value).then((response) => {
						const { data } = response;
						const copiedChildren = [...data.model.Children];

						for (var i = 0; i < copiedChildren.length; i++) {
							if (copiedChildren[i]["OffsetAmount"] === 0) {
								copiedChildren[i]["OffsetLogDate"] = "";
							} else {
								copiedChildren[i]["OffsetLogDate"] = copiedChildren[i]["OffsetLogDate"];
							}
						}

						setOffsetForm({
							Parent: null,
							Children: copiedChildren
						});
					});
					baseapi.httpget(`api/Supplier/Get`, { id: e.value }).then((response) => {
						// console.log(response.data)
						const { data } = response;

						const copied = { ...tempForm.current };
						// console.log(copied);
						copied["ID"] = props.formID === "<NEW>" ? "<NEW>" : props.formID;
						copied["Terms"] = data.model.Term;
						copied["SupplierName"] = data.model.Name;
						copied["SupplierCode"] = data.model.Code;
						copied["SupplierID"] = data.model.ID;
						copied["ForexID"] = data.model.ForexID;
						copied["SalesmanID"] = data.model.SalesmanID;
						copied["AreaID"] = data.model.AreaID;
						copied["SalesmanCode"] = data.model.SalesmanCode;
						copied["AreaCode"] = data.model.AreaCode;

						baseapi.httpget(`/api/Forex/Get`, { id: data.model.ForexID }).then((response) => {
							const { data } = response;
							// console.log(data)
							copied["LocalRate"] = data.model.LocalRate;
							copied["ForexRate"] = data.model.ForexRate;
							copied["MainLocalAmount"] = data.model.LocalRate * copied["MainForexAmount"];
							copied["ForexCode"] = data.model.Code;

							tempForm.current = copied;
							setFormValue(tempForm.current);
						});
					});
				} else if (dataField === "DocumentNo") {
					copied[dataField] = e.value;

					tempForm.current = copied;
					// console.log(tempForm.current);
					setFormValue(tempForm.current);
				} else if (dataField === "DocumentDate") {
					copied[dataField] = e.value;

					tempForm.current = copied;
					// console.log(tempForm.current);
					setFormValue(tempForm.current);
				} else if (dataField === "ForexID") {
					baseapi
						.httpget(`/api/Forex/Get`, { id: e.value })
						.then((response) => {
							const { data } = response;
							const copied = { ...tempForm.current };
							copied["LocalRate"] = data.model.LocalRate;
							copied["ForexRate"] = data.model.ForexRate;
							copied["MainLocalAmount"] = copied["MainForexAmount"] * data.model.LocalRate;
							tempForm.current = copied;

							setFormValue(tempForm.current);
						})
						.catch((error) => {
							console.log(error);
						});
				} else {
					const copied = { ...tempForm.current };
					copied[dataField] = e.value;

					tempForm.current = copied;
					// console.log(tempForm.current);
					setFormValue(tempForm.current);
				}
			}
		} catch (err) {
			console.log(err);
		}
	}

	const calculateDataGridTotal = useCallback((data) => {
		return data.valueText.replace("Sum: ", "");
	}, []);

	useEffect(() => {
		if (tempForm.current !== null) {
			setOffsetOldValue(offsetForm.Children);
			updateUnappliedAmount(tempForm.current, offsetForm.Children);
		}
	}, [offsetForm.Children]);

	function onRowUpdating(e) {
		const oldData = e.oldData;
		const currentEditingRow = e.newData;

		currentEditingRow["OutstandingAmount"] = parseFloat(oldData["OutstandingAmount"]) - parseFloat(currentEditingRow["OffsetAmount"]) + parseFloat(oldData["OffsetAmount"]);

		if (currentEditingRow.OffsetAmount === 0) {
			currentEditingRow["OffsetLogDate"] = "";
		} else {
			currentEditingRow["OffsetLogDate"] = new Date();
		}

		updateUnappliedAmount();
	}

	const setOffsetOldValue = (value = null) => {
		if (value) {
			offsetOldValue.current = value;
			// saveToLS("AROffsetOldValue", value);
		} else {
			const childrenCopied = offsetGridRef.current.instance.getVisibleRows();
			const data = [];

			for (var i = 0; i < childrenCopied.length; i++) {
				const rowData = childrenCopied[i]["data"];
				data.push(rowData);
			}
			offsetOldValue.current = data;
			// saveToLS("AROffsetOldValue", data);
		}
	};

	const updateUnappliedAmount = (parent = null, children = null) => {
		// Calculation for unapplied amount
		// const copiedForm = { ...tempForm.current };
		// copiedForm["OutstandingAmount"] = getTotalReceiptAmount(parent) - getTotalOffsetAmount(children);
		// tempForm.current = copiedForm;
		// setFormValue(tempForm.current);
		if (offsetGridDataValid) {
			setFormValue((prevValues) => ({
				...prevValues,
				OutstandingAmount: getTotalReceiptAmount(parent) - getTotalOffsetAmount(children)
			}));
		}
	};

	function sortByDate(offsetData) {
		return dayjs(offsetData.DocumentDate);
	}

	const offsetCheckBox = (e) => {
		const rowData = e.data;
		return <CheckBox id='offset-check-btn' onValueChanged={(args) => selectionAutomation(e, args)} defaultValue={rowData.OffsetAmount > 0} />;
	};

	const offsetSelectAllChecker = (e) => {
		const offsetSelectAll = e.value;
		const childrenCopied = offsetGridRef.current.instance.getVisibleRows();
		const totalReceiptAmount = getTotalReceiptAmount();
		var totalOffset = 0;

		if (offsetSelectAll) {
			for (var i = 0; i < childrenCopied.length; i++) {
				const rowData = childrenCopied[i]["data"];
				const checkExceedOutstandingAmount = totalOffset + rowData["OutstandingAmount"] + rowData["OffsetAmount"] > totalReceiptAmount;
				// console.log("select all", totalOffset, totalReceiptAmount, totalOffset + rowData["OutstandingAmount"], checkExceedOutstandingAmount)
				if (totalOffset < totalReceiptAmount) {
					rowData["OffsetLogDate"] = new Date();

					if (!checkExceedOutstandingAmount) {
						rowData["OffsetAmount"] += rowData["OutstandingAmount"];
						rowData["OutstandingAmount"] = 0;
					} else {
						rowData["OutstandingAmount"] += rowData["OffsetAmount"];
						const maximumToOffset = totalReceiptAmount - totalOffset;
						rowData["OffsetAmount"] = maximumToOffset;
						rowData["OutstandingAmount"] -= maximumToOffset;
					}
				}
				else {
					rowData["OffsetLogDate"] = "";
					rowData["OutstandingAmount"] += rowData["OffsetAmount"];
					rowData["OffsetAmount"] = 0;
				}

				//Add the latest offset
				totalOffset += rowData["OffsetAmount"];

				offsetGridRef.current.instance.cellValue(i, "OffsetAmount", rowData.OffsetAmount);
				offsetGridRef.current.instance.cellValue(i, "OutstandingAmount", rowData.OutstandingAmount);
				offsetGridRef.current.instance.cellValue(i, "OffsetLogDate", rowData.OffsetLogDate);
			}
		} else {
			for (var i = 0; i < childrenCopied.length; i++) {
				const rowData = childrenCopied[i]["data"];
				rowData["OutstandingAmount"] += rowData["OffsetAmount"];
				totalOffset -= rowData["OffsetAmount"];
				rowData["OffsetAmount"] = 0;
				rowData["OffsetLogDate"] = "";

				offsetGridRef.current.instance.cellValue(i, "OffsetAmount", rowData.OffsetAmount);
				offsetGridRef.current.instance.cellValue(i, "OutstandingAmount", rowData.OutstandingAmount);
				offsetGridRef.current.instance.cellValue(i, "OffsetLogDate", rowData.OffsetLogDate);
			}
		}

		updateUnappliedAmount();
	};

	const getOffsetChildren = () => {
		const childrenCopied = offsetGridRef.current.instance.getVisibleRows();
		const offsetChildren = [];

		for (var i = 0; i < childrenCopied.length; i++) {
			const rowData = childrenCopied[i]["data"];
			offsetChildren.push(rowData);
		}

		return offsetChildren;
	};

	const selectionAutomation = (selectedRow, args) => {
		const currentValue = args.component.option("value");
		const offsetCopied = getOffsetChildren();
		const rowData = selectedRow.data;
		const foundIndex = offsetCopied.findIndex((row) => row.OffsetDocumentID === rowData.OffsetDocumentID);
		const totalReceiptAmount = getTotalReceiptAmount();

		if (offsetGridDataValid) {
			if (foundIndex !== -1) {
				if (!currentValue) {
					rowData.OutstandingAmount += rowData.OffsetAmount;
					rowData.OffsetAmount = 0;
					rowData.OffsetLogDate = "";
				} else {
					const totalOffsetAmount = getTotalOffsetAmount();
					const PaymentAfterOffset = parseFloat(rowData.OutstandingAmount);
					const TotalReceiptAfterOffset = totalReceiptAmount - totalOffsetAmount;
					const checkExceedOutstandingAmount = PaymentAfterOffset > TotalReceiptAfterOffset;
	
					if (totalOffsetAmount > totalReceiptAmount) {
						return;
					}
	
					// If the amount does not exceed then automatically take the full amount
					if (!checkExceedOutstandingAmount) {
						rowData.OffsetAmount = rowData.OutstandingAmount;
						rowData.OutstandingAmount = 0;
					}
					//If not take the maximum amount
					else {
						const maximumToOffset = totalReceiptAmount - totalOffsetAmount;
						rowData.OffsetAmount += maximumToOffset;
						rowData.OutstandingAmount -= maximumToOffset;
					}
	
					if (rowData.OffsetAmount === 0) {
						rowData.OffsetLogDate = "";
					} else {
						rowData.OffsetLogDate = new Date();
					}
				}
	
				offsetGridRef.current.instance.cellValue(foundIndex, "OffsetAmount", rowData.OffsetAmount);
				offsetGridRef.current.instance.cellValue(foundIndex, "OutstandingAmount", rowData.OutstandingAmount);
				offsetGridRef.current.instance.cellValue(foundIndex, "OffsetLogDate", rowData.OffsetLogDate);
			}
	
			// Calculation for unapplied amount
			const copiedForm = { ...tempForm.current };
			copiedForm["OutstandingAmount"] = totalReceiptAmount - getTotalOffsetAmount();
			tempForm.current = copiedForm;
			setFormValue(tempForm.current);
		}
	};

	const validateOffsetAmount = useCallback((e) => {
		const oldValue = offsetOldValue.current;
		const found = oldValue.find((row) => row.OffsetDocumentID === e.data.OffsetDocumentID);
		const oldOffsetAmount = found.OffsetAmount ?? 0;
		const oldOutstandingAmount = found.OutstandingAmount ?? 0;
		const maximumAmount = oldOutstandingAmount + oldOffsetAmount;

		if (e.value > maximumAmount) {
			return false;
		}

		// Ensure the offset amount is not negative.
		if (e.value < 0) {
			return false;
		}

		if (getTotalOffsetAmount() <= getTotalReceiptAmount()) {
			return true;
		}

		return false;
	});

	const getTotalReceiptAmount = (children = null) => {
		const tempValue = { ...formValue };
		var totalReceiptAmount = 0;
		totalReceiptAmount += parseFloat(tempValue["MainForexAmount"] ?? 0);

		// console.log("totalReceiptAmount", totalReceiptAmount);
		return totalReceiptAmount;
	};

	const getTotalOffsetAmount = (children = null) => {
		const childrenCopied = children === null ? offsetGridRef.current.instance.getVisibleRows() : children;
		var totalOffsetAmount = 0;

		if (children) {
			for (var i = 0; i < childrenCopied.length; i++) {
				const rowData = childrenCopied[i];
				totalOffsetAmount += parseFloat(rowData["OffsetAmount"]);
			}
		} else {
			for (var i = 0; i < childrenCopied.length; i++) {
				const rowData = childrenCopied[i]["data"];
				totalOffsetAmount += parseFloat(rowData["OffsetAmount"]);
			}
		}

		return totalOffsetAmount;
	};

	return (
		<div>
			<div className='contra-custom-form-grid'>
				<div className='popup-form-item-container3'>
					<div className='popup-group-form-item'>
						<div className='popup-group-form-label'>Supplier Code </div>

						<div className='popup-group-form-input'>
							<CustomizedLookup
								className={"ap-listing-datagrid"}
								dataSource={supplierList}
								displayExpr={"code"}
								valueExpr={"id"}
								value={formValue["SupplierID"]}
								onSelectionChanged={(e) => {
									valueOnChange(e, "SupplierID");
								}}
								startValidation={props.startValidation}
								required={true}
								requiredMessage={"Supplier is required!"}
								disabled={
									// props.lockedForm === true &&
									props.formID === "<NEW>" ? false : true
								}
								onDataSourceChanged={setSupplierList}
								dataSourceURL={"api/Utilities/GetSuppliers"}
								displayText={formValue["SupplierCode"]}>
								<Column dataField='code'></Column>
								<Column dataField='name' caption={"Description"}></Column>
								<Column dataField='forexCode' caption='Currency'></Column>
							</CustomizedLookup>
						</div>
					</div>

					<div className='popup-group-form-item'>
						<div className='popup-group-form-label align-top-item-label'>Project </div>

						<div className='popup-group-form-input' id='customized-lookup1'>
							<CustomizedLookup
								ref={projectLookupRef}
								className={"ap-listing-datagrid"}
								dataSource={projectMainList}
								displayExpr={"code"}
								valueExpr={"id"}
								value={formValue["ProjectID"]}
								onSelectionChanged={(e) => {
									valueOnChange(e, "ProjectID");
								}}
								startValidation={startValidation !== 0}
								required={true}
								requiredMessage={"Project is required!"}
								dataSourceURL={"api/Utilities/GetProjects"}
								onDataSourceChanged={setProjectMainList}
								disabled={props.lockedForm === true}
								displayText={formValue["ProjectCode"]}>
								<Column dataField='code'></Column>
								<Column dataField='name' caption={"Project Name"}></Column>
							</CustomizedLookup>
						</div>
					</div>

					<div className='popup-group-form-item'>
						<div className='popup-group-form-label align-top-item-label'>Forex </div>

						<div className='popup-group-form-input'>
							<CustomizedLookup
								className={"ap-listing-datagrid"}
								dataSource={forexList}
								displayExpr={"code"}
								valueExpr={"id"}
								value={formValue["ForexID"]}
								onSelectionChanged={(e) => {
									valueOnChange(e, "ForexID");
								}}
								startValidation={props.startValidation}
								required={true}
								requiredMessage={"Forex Code is required!"}
								onDataSourceChanged={setForexList}
								disabled={true}
								dataSourceURL={"api/Utilities/GetForexs"}
								displayText={formValue["ForexCode"]}>
								<Column dataField='code'></Column>
								<Column dataField='name' caption={"Description"}></Column>
							</CustomizedLookup>
						</div>
					</div>
				</div>

				<div className='popup-form-item-container3'>
					<div className='popup-group-form-item'>
						<div className='popup-group-form-label'>Supplier Name </div>

						<div className='popup-group-form-input'>
							<TextBox
								value={formValue["SupplierName"]}
								elementAttr={{ dataField: "SupplierName" }}
								onValueChanged={(e) => {
									valueOnChange(e);
								}}
								alignment='left'
								width={250}
								disabled={true}
								startValidation={props.startValidation}
							/>
						</div>
					</div>
					<div className='popup-group-form-item'>
						<div className='popup-group-form-label'>Salesman </div>

						<div className='popup-group-form-input'>
							<CustomizedLookup
								ref={salesmanLookupRef}
								className={"ar-listing-datagrid"}
								dataSource={salesmanList}
								displayExpr={"code"}
								valueExpr={"id"}
								value={formValue["SalesmanID"]}
								onSelectionChanged={(e) => {
									valueOnChange(e, "SalesmanID");
								}}
								startValidation={props.startValidation}
								required={true}
								requiredMessage={"Salesman is required!"}
								// disabled={props.lockedForm === true}
								dataSourceURL={"api/Utilities/GetSalesmans"}
								onDataSourceChanged={setSalesmanList}
								displayText={formValue["SalesmanCode"]}>
								<Column dataField='code'></Column>
								<Column dataField='name' caption={"Description"}></Column>
							</CustomizedLookup>
						</div>
					</div>

					<div className='popup-group-form-item'>
						<div className='popup-group-form-label'>Area </div>

						<div className='popup-group-form-input'>
							<CustomizedLookup
								ref={areaLookupRef}
								className={"ap-listing-datagrid"}
								dataSource={areaList}
								displayExpr={"code"}
								valueExpr={"id"}
								value={formValue["AreaID"]}
								onSelectionChanged={(e) => {
									valueOnChange(e, "AreaID");
								}}
								startValidation={startValidation !== 0}
								required={true}
								requiredMessage={"Area is required!"}
								dataSourceURL={"api/Utilities/GetAreas"}
								onDataSourceChanged={setAreaList}
								// disabled={props.lockedForm === true}
								displayText={formValue["AreaCode"]}>
								<Column dataField='code'></Column>
								<Column dataField='name' caption={"Area Name"}></Column>
							</CustomizedLookup>
						</div>
					</div>
				</div>

				<div className='popup-form-item-container3'>
					<div className='popup-group-form-item'>
						<div className='popup-group-form-label next-doc-label'>Contra No </div>

						<div className='popup-group-form-input'>
							<div className='next-doc-display'>Next Doc : {formValue["NextDocNo"]}</div>
							
							<CustomizedLookup
								displayExpr={"DocumentNumber"}
								valueExpr={"DocumentNumber"}
								dropdownClassName="custom-dropdown"
								value={formValue["DocumentNo"]}
								onSelectionChanged={(e) => {
									valueOnChange(e, "DocumentNo");
								}}
								nextDocSelectionChanged={(e) => {
									tempForm.current = {
										...tempForm.current,
										NextDocNo: e.value,
										DocumentPrefix: e.selectedItem?.Prefix || ""
									};
									setFormValue((prevState) => ({
										...prevState,
										NextDocNo: e.value,
										DocumentPrefix: e.selectedItem?.Prefix || ""
									}));
								}}
								documentNumber={true}
								placeholder={"<NEW>"}
								isEdit={isEditing}
								disabled={isEditing || props.lockedForm === true}
								dataSourceURL={DocumentSettings.APIEndpoints.SelectDocNo(formValue["DocumentTypeID"])}
								acceptCustomValue={true}
								refreshDataSource={true}
							>
								<Column dataField='Name' width={DocumentSettings.DocumentTypeWidth.Name}></Column>
								<Column dataField='Format' width={DocumentSettings.DocumentTypeWidth.Format}></Column>
								<Column dataField='RunningNumber' width={DocumentSettings.DocumentTypeWidth.RunningNumber}></Column>
							</CustomizedLookup>
						</div>
					</div>

					<div className='popup-group-form-item'>
						<div className='popup-group-form-label'>Date </div>

						<div className='popup-group-form-input'>
							<DateBox
								value={formValue["DocumentDate"]}
								elementAttr={{ dataField: "DocumentDate" }}
								onValueChanged={(e) => {
									valueOnChange(e);
								}}
								alignment='left'
								width={250}
								displayFormat='dd/MM/yyyy'
								dateSerializationFormat='yyyy-MM-dd'
								useMaskBehavior={true}
								disabled={props.lockedForm === true}>
								<Validator>{props.startValidation && <ValidatorRequired message={"Date is required!"} />}</Validator>
							</DateBox>
						</div>
					</div>
				</div>

				<div className='popup-form-item-container3'>
					<div className='popup-group-form-item group-form-item-align-top'>
						<div className='popup-group-form-label align-top-item-label'>Contra Amount </div>

						<div className='popup-group-form-input'>
							<NumberBox
								value={formValue["MainForexAmount"]}
								elementAttr={{ dataField: "MainForexAmount" }}
								onValueChanged={(e) => {
									valueOnChange(e);
								}}
								alignment='left'
								width={"auto"}
								disabled={props.lockedForm === true}
								format='#,##0.00'>
								<Validator>{props.startValidation && <ValidatorRequired message={"Contra Amount is required!"} />}</Validator>
							</NumberBox>
						</div>
					</div>

					<div className='popup-group-form-item group-form-item-align-top'>
						<div className='popup-group-form-label align-top-item-label'>Local Amount </div>

						<div className='popup-group-form-input'>
							<NumberBox
								value={formValue["MainLocalAmount"]}
								elementAttr={{ dataField: "MainLocalAmount" }}
								onValueChanged={(e) => {
									valueOnChange(e);
								}}
								alignment='left'
								width={"auto"}
								disabled={true}
								format='#,##0.00'></NumberBox>
						</div>
					</div>

					<div className='popup-group-form-item group-form-item-align-top'>
						<div className='popup-group-form-label align-top-item-label'>Local Rate </div>

						<div className='popup-group-form-input'>
							<NumberBox
								value={formValue["LocalRate"]}
								elementAttr={{ dataField: "LocalRate" }}
								onValueChanged={(e) => {
									valueOnChange(e);
								}}
								alignment='left'
								width={"auto"}
								format={utils.getDecimalFormat(defaultValue["LocalRateFixCheck"], defaultValue["LocalRateFormat"])}
								readOnly={formValue["ForexID"] === defaultValue["ForexID"]}
							>
								<Validator>{props.startValidation && <ValidatorRequired message={"Local Rate is required!"} />}</Validator>
							</NumberBox>
						</div>
					</div>
				</div>
			</div>

			<div className='ap-module-border-bottom' style={{ padding: "0 0", marginTop: 50 }}>
				<div className='popup-form-toolbar-section'>
					<div>
						<h3 className="popup-form-offset-section-title">Offset Details</h3>
					</div>
				</div>
				<DataGrid
					ref={offsetGridRef}
					dataSource={offsetForm.Children}
					keyExpr='OffsetDocumentID'
					showBorders={true}
					// onContentReady={offsetChildrenUpdated}
					onRowUpdating={onRowUpdating}
					onRowUpdated={() => setOffsetOldValue()}
					columnAutoWidth={true}
					allowColumnReordering={true}
					allowColumnResizing={true}
					disabled={props.lockedForm === true}
					onRowValidating={(e) => {setOffsetGridDataValid(e.isValid)}}
					onEditCanceled={() => {setOffsetGridDataValid(true)}}
					paging={{ enabled: false }} 
					height={400}>
					<LoadPanel enabled={false} />
					<Editing mode='cell' allowAdding={false} allowDeleting={false} allowUpdating={true} />
					<Selection mode='multiple' showCheckBoxesMode='always' allowSelectAll={false} />
					<Column type={"selection"} width={"50px"} headerCellTemplate={"selectAllChecker"} cellRender={offsetCheckBox}>
						<Template name='selectAllChecker'>
							<CheckBox ref={offsetCheckboxRef} id='offset-check-btn' onValueChanged={offsetSelectAllChecker} />
						</Template>
					</Column>

					<Column dataField='OffsetDocumentNo' width={"250px"} caption='Document No.' allowEditing={false} />
					<Column dataField='DocumentDate' width={"200px"} allowEditing={false} calculateSortValue={sortByDate} caption={"Date"} format='dd/MM/yyyy' dataType={"date"} />
					<Column dataField='MainForexAmount' width={"250px"} caption='Total Amount' allowEditing={false} visible={true} format='#,##0.00' />
					<Column dataField='OutstandingAmount' width={"250px"} caption='Outstanding Amount' allowEditing={false} format='#,##0.00' />
					<Column dataField='OffsetAmount' width={"250px"} caption='Offset Amount' format='#,##0.00'>
						<CustomRule message={"Not enough amount to offset or more than the payment amount!"} validationCallback={validateOffsetAmount} />
					</Column>
					<Column dataField='OffsetLogDate' caption='Offset Log Date' allowEditing={false} allowSorting={false} dataType={"string"} cellRender={utils.offsetLogDateDisplay} />
					<Column dataField='OffsetTypeID' width={"250px"} caption='Type' allowEditing={false} allowSorting={false} />
					<Column dataField='ForexName' allowEditing={false} visible={false} />
					<Column dataField='ForexRate' caption='Rate' dataType='string' allowEditing={false} visible={false} />
					<Column dataField='LocalOffsetAmount' caption='Local Offset' allowEditing={false} visible={false} />
					<ColumnChooser enabled={true} />

					<Summary recalculateWhileEditing={true}>
						<TotalItem column='LocalPaymentAmount' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

						<TotalItem column='OutstandingAmount' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

						<TotalItem column='OffsetAmount' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />
					</Summary>

					<Toolbar>
						<ToolbarItem location='before'>
							<div className='popup-form-toolbar-section'>
								<div className='popup-form-toolbar-section-content'>Description: </div>
							</div>
						</ToolbarItem>

						<ToolbarItem location='before'>
							<div className='popup-form-toolbar-section' style={{ width: "20vw" }}>
								<TextBox
									value={formValue["Description"]}
									elementAttr={{ dataField: "Description" }}
									onValueChanged={(e) => {
										valueOnChange(e);
									}}
									alignment='left'
									width={"auto"}></TextBox>
							</div>
						</ToolbarItem>

						<ToolbarItem location='after'>
							<div className='popup-form-toolbar-section'>
								<div className='popup-form-toolbar-section-content'>Unapplied Amount: </div>
							</div>
						</ToolbarItem>

						<ToolbarItem location='after'>
							<div className='popup-form-toolbar-section' style={{ width: "20vw" }}>
								<NumberBox
									value={formValue["OutstandingAmount"]}
									elementAttr={{
										dataField: "OutstandingAmount",
										class: "outstanding-amount-color"
									}}
									onValueChanged={(e) => {
										valueOnChange(e);
									}}
									alignment='left'
									width={"auto"}
									readOnly={true}
									format='#,##0.00'></NumberBox>
							</div>
						</ToolbarItem>

						<ToolbarItem location='before'></ToolbarItem>

						<ToolbarItem name='columnChooserButton' />
					</Toolbar>
				</DataGrid>
			</div>
		</div>
	);
});
