import React, { useEffect, useState } from 'react';
import { Column, Lookup, } from 'devextreme-react/data-grid';
import { forexOptions } from '../../../utils/lookupstore';
import PurchaseDebitNoteForm from './form';
import Container from '../../../components/container';
import Listing from '../../../components/listing/newListing';
import EInvoiceMain from "../../../components/e-invoice/EInvoiceMain";

export default function PurchaseDebitNoteListing() {

    const apiURL = `/api/PurchaseDebitNote`;
    const [currentEditID, setCurrentEditID] = useState(null);
    const [currentDetailID, setCurrentDetailID] = useState(null);
    function handleEdit(e){
        const id = e.row.key;
        setCurrentEditID(id);
    }

    function handleEInvoice(e) {
		setCurrentDetailID(e.row.data.DocumentNo);
	}
    
    return <Container>
        <div id='normal-listing'>
        <Listing
            className="purchase-listing-datagrid"
            apiURL={apiURL}
            einvoiceshow={true}
            handleEInvoice={handleEInvoice}
            listingURL="/api/PurchaseDebitNote/list"
            sortColumn={"DocumentDate"}
            sortOrder={"desc"}
            listingTitle={"Debit Note"}
            storageName={"PurchaseDebitNoteListingColumnWidth"}
            onAddClick={setCurrentEditID}
            handleEdit={handleEdit}
        >
            <Column dataField="DocumentDate" format="dd/MM/yyyy" dataType={"date"}/>
            <Column dataField="DocumentNo" />
            <Column dataField="SupplierCode" />
            <Column dataField="SupplierName" />
            <Column dataField="ForexCode" caption="Forex"/>
            <Column dataField="MainForexAmount" caption={"Amount"} format={{ type: "fixedPoint", precision: 2}} />
            <Column dataField="MainLocalAmount" caption={"Local Amount"} format={{ type: "fixedPoint", precision: 2}} />
            <Column dataField="OutstandingAmount" caption={"Outstanding Amount"} format={{ type: "fixedPoint", precision: 2}} />
        </Listing>
        </div>

        <PurchaseDebitNoteForm 
            formID={currentEditID}
            closePopup={setCurrentEditID}
            title={"Purchase Debit"}
            apiURL={apiURL}
        />

<EInvoiceMain 
				formID={currentDetailID} 
				closePopup={setCurrentDetailID} 
				apiURL={apiURL} 
                invoiceType="13-PPD"
				title={"E-Invoice Main"} 
			/>
        
    </Container>;
}