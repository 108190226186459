import React, { useState, useEffect, useRef, useCallback } from "react";
import PopupForm from "../../../components/popup-form/PopupFormV2";
import baseapi from "../../../api/baseapi";
import utils from "../../../utils/common";
import { Column, Summary, TotalItem } from "devextreme-react/data-grid";
import { NumberBox } from "devextreme-react/number-box";
import { CheckBox } from "devextreme-react/check-box";
import { TextBox } from "devextreme-react/text-box";
import { loading, closeLoading } from "../../../utils/common";
import CustomizedLookup from "../../../components/lookup/Lookup";
import CustomizedGridLookup from "../../../components/lookup/GridLookup";
import { Validator, RequiredRule as ValidatorRequired } from "devextreme-react/validator";
import AsyncTextBox from "../../../components/async-text-box/AsyncTextBox";
import { salesReportNames } from "../../../utils/lookupstore";
import ChildrenDatagrid from "../../../components/children-datagrid/ChildrenDatagrid";
import { ParentValueCalculation, moduleCalculation } from "../../../utils/module-calculation";
import ErrorPopUpForm from "../../../components/popup-form/ErrorPopupForm";
import AsyncDateBox from "../../../components/async-date-box/AsyncDateBox";
import { DocumentSettings } from "../../../utils/documentSettings";
import DocumentNumberLookup from "../../../components/lookup/DocumentNoLookup";
export default function ARSalesForm(props) {
	const formID = useRef(null);
	const formRef = useRef(null);
	const formRead = useRef(false);
	const childrenGridRef = useRef(null);
	const [lockedForm, setLockedForm] = useState(false);
	const [fiscalYearLocked, setFiscalYearLocked] = useState(false);

	const [defaultValue, setDefaultValue] = useState({});
	const [isEditing, setIsEditing] = useState(false);
	const [startValidation, setStartValidation] = useState(0);
	const [formValue, setFormValue] = useState({});
	const tempForm = useRef(null);
	const [gridLookupOpen, setGridLookupOpen] = useState(false);
	const customerLookupRef = useRef(null);
	const projectLookupRef = useRef(null);
	const areaLookupRef = useRef(null);
	const salesmanLookupRef = useRef(null);
	const forexLookupRef = useRef(null);
	const [custLock, setCustLock] = useState(lockedForm);
	const [reportDocumentNo, setReportDocumentNo] = useState(null);
	const popupMessageRef = useRef(null);

	useEffect(() => {
		if (!utils.isNullOrEmpty(formValue["DocumentNo"])) {
			setReportDocumentNo(formValue["DocumentNo"]);
		}
	}, [formValue["DocumentNo"]]);

	useEffect(() => {
		// console.log("Form ID Changed", props.formID);
		if (props.formID !== null) {
			formID.current = props.formID;
			utils.popupFormOpen(formRef);

			if (formID.current !== "new") {
				setIsEditing(true);
			} else {
				setIsEditing(false);
			}
		}
	}, [props.formID]);

	useEffect(() => {
		if (formRead.current) {
			let copied = { ...tempForm.current };
			const copiedDetails = utils.childrenGridGetSource(childrenGridRef);
			var balance = 0;
	
			for (var i = 0; i < Object.keys(copiedDetails).length; i++) {
				if (copiedDetails[i]["NettAmount"] === undefined) {
					balance += parseFloat(copiedDetails[i]["ForexAmount"]);
				} else {
					balance += parseFloat(copiedDetails[i]["NettAmount"]);
				}
			}
	
			// Perform calculations and updates for children data.
			if (copiedDetails.length > 0) {
				const newChildrenData = utils.roundUpObjectProperties(moduleCalculation(copiedDetails, copied));
	
				// Perform calculations for parent data.
				const calculationResult = ParentValueCalculation(newChildrenData, copied);
	
				// Set copied data as calculation result, also set the MainLocalAmount field.
				copied = calculationResult.parent;
				// Set the updated data back to the grid
				utils.childrenGridSetSource(childrenGridRef, newChildrenData);
			}
	
			copied["MainLocalAmount"] = balance * formValue["LocalRate"];
			
			tempForm.current = copied;
	
			setFormValue(tempForm.current);
		}
	}, [formValue["LocalRate"]])

	function handleSubmit(e) {
		if(!utils.isNullOrEmpty(e.validData)){
			const validData = e.validData;
			
			// Set document number prefix
			DocumentSettings.setDocumentPrefix(formValue["DocumentPrefix"]);

			const submittedForm = {
				Parent: formValue,
				Children: validData["Children"]
			};

			loading(`Saving ${props.title}...`);
			baseapi.httppost(utils.extendUrlVar("/api/ARSales/save", { id: formID.current || "", del: false }), submittedForm).then((response) => {
				const { data } = response;
				utils.popupFormSuccessfulSubmit(formRef, data);
				utils.popupFormSetErrorForm(formRef, {
					visible: true,
					message: data.message,
					type: data.status ? "Success" : "Danger",
					action: data.action
				});
				if (data.docNo) setReportDocumentNo(data.docNo);
			});
		}
	}

	function onInitialized(e) {
		if (formID.current === "new") loading(`Loading New ${props.title}...`);
		else loading(`Loading Existing ${props.title}...`);

		// Clear previous children
		setCustLock(false);

		baseapi.httpget("/api/ARSales/Get", { id: formID.current || "new" , documentNumberPrefix: DocumentSettings.getDocumentPrefix() || "" }).then((response) => {
			const { data } = response;
			utils.popupFormAfterInitialization(formRef, props, data);

			//Set Default Values
			if(!utils.isNullOrEmpty(data.defaultValues)){
				setDefaultValue(data.defaultValues);
			}

			const parentData = data.model.Parent;
			tempForm.current = parentData;

			if (formID.current === "new") {
				utils.childrenGridAddRow(childrenGridRef); // Add row for the first grid
			} else {
				utils.childrenGridSetSource(childrenGridRef, data.model.Children);
			}

			setFormValue(tempForm.current);
			closeLoading();
			formRead.current = true;
		});
	}

	function valueOnChange(e, receivedDataField = null) {
		try {
			const dataField = receivedDataField !== null ? receivedDataField : e.element.getAttribute("dataField");
			const copied = { ...tempForm.current };
			copied[dataField] = e.value;
			tempForm.current = copied;
			setFormValue(tempForm.current);

			if (!utils.isNullOrEmpty(e.value)) {
				if (dataField === "CustomerID" && formRead.current) {
					baseapi.httpget(`api/Customer/Get`, { id: e.value }).then((response) => {
						const { data } = response;

						const copied = { ...tempForm.current };

						copied["CustomerName"] = data.model.Name;
						copied["CustomerCode"] = data.model.Code;
						copied["SalesmanCode"] = data.model.SalesmanCode;
						copied["SalesmanID"] = data.model.SalesmanID;
						copied["AreaID"] = data.model.AreaID;
						copied["AreaCode"] = data.model.AreaCode;
						copied["ForexID"] = data.model.ForexID;
						copied["CreditTermsID"] = data.model.CreditTermID;
						copied["CreditTermsCode"] = data.model.CreditTermCode;
						copied["CreditLimit"] = data.model.CreditLimit;
						copied["OutstandingBalance"] = data.model.OutstandingBalance;
						copied["CreditTermsDays"] = data.model.CreditTermDays;

						// console.log("Forex Response", data.ForexID)
						if (data.model.ForexID) {
							baseapi
								.httpget(`/api/Forex/Get`, { id: data.model.ForexID })
								.then((response) => {
									const { data } = response;
									copied["LocalRate"] = data.model.LocalRate;
									copied["ForexRate"] = data.model.ForexRate;
									copied["ForexCode"] = data.model.Code

									tempForm.current = copied;
									setFormValue(tempForm.current);
								})
								.catch((error) => {
									console.log(error);
								});
						}
					});
				} else if (dataField === "ForexID" && formRead.current) {
					baseapi
						.httpget(`/api/Forex/Get`, { id: e.value })
						.then((response) => {
							const { data } = response;
							const copied = { ...tempForm.current };
							copied["LocalRate"] = data.model.LocalRate;
							copied["ForexRate"] = data.model.ForexRate;
							copied["ForexCode"] = data.model.Code

							tempForm.current = copied;
							setFormValue(tempForm.current);
						})
						.catch((error) => {
							console.log(error);
						});
				} else if (dataField == "CreditTermsID" && formRead.current) {
					copied[dataField] = e.value;
					tempForm.current = copied;

					setFormValue(tempForm.current);

					baseapi.httpget("/api/CreditTerms/Get", { id: e.value }).then((res) => {
						const { data } = res;
						const copied = { ...tempForm.current };		
						copied["CreditTermsDays"] = data.model.Day;

						tempForm.current = copied;
						setFormValue(tempForm.current);
					});
				}
			}
		} catch (err) {
			console.log(err);
		}
	}

	const onRowUpdated = (e, children = null) => {
		const childrenDetails = children !== null ? children : utils.childrenGridGetSource(childrenGridRef);
		const ParentValue = { ...tempForm.current };
		const calculationResult = ParentValueCalculation(childrenDetails, ParentValue);
		tempForm.current = calculationResult.parent;
		setFormValue(tempForm.current);
		utils.childrenGridSetSource(childrenGridRef, calculationResult.children);
	};

	const onInitNew = () => {
		return {
			Line: utils.childrenGridLength(childrenGridRef) + 1,
			ChartAccountID : utils.popupFormGetDefaultValues(formRef)["ChartAccountID"],
			ChartAccountCode: utils.popupFormGetDefaultValues(formRef)["ChartAccountCode"],
			ProjectID: utils.popupFormGetDefaultValues(formRef)["ProjectID"],
			ProjectCode: utils.popupFormGetDefaultValues(formRef)["ProjectCode"],
			BankInDate: new Date(),
			NettAmount: 0,
			NettLocalAmount: 0,
			BankCharges: 0,
			DetailLN: 0,
			Point: 0
		};
	};

	const clearForm = () => {
		props.closePopup(null);
		formRead.current = false;
		formID.current = null;
		tempForm.current = {};
		setFormValue(tempForm.current);
	};

	const gridOnSelectionChanged = (e, columnName) => {
		setGridLookupOpen(false);
		const currentFocusCellIndex = utils.childrenGridGetFocusCellIndex(childrenGridRef);
		const currentRecords = utils.childrenGridGetSource(childrenGridRef);
		const rowIndex = currentFocusCellIndex.rowIndex;
		const key = currentFocusCellIndex.rowID;
		const recordIndex = currentRecords.findIndex(record => record.ID === key)
		const selectedItem = e.selectedItem;

		currentRecords[recordIndex][columnName] = e.value;
		if (columnName === "ChartAccountID") {
			currentRecords[recordIndex]["ChartAccountCode"] = selectedItem?.code;
			currentRecords[recordIndex]["Description"] = selectedItem?.name;
		} else if (columnName === "TaxCodeID") {
			currentRecords[recordIndex]["TaxRate"] = selectedItem?.rate ?? 0;
			currentRecords[recordIndex]["TaxCode"] = selectedItem?.code;
		} else if (columnName === "ProjectID") {
			currentRecords[recordIndex]["ProjectCode"] = selectedItem?.code;
		}

		e["key"] = key;
		e["data"] = currentRecords[recordIndex];
		onRowUpdated(e, currentRecords);
	};

	const onRowUpdating = (e) => {
		const oldValue = { ...e.oldData };
		const newValue = e.newData;
		const currentRow = Object.assign(oldValue, newValue);
		const parentValue = { ...formValue };
		e.newData = utils.roundUpObjectProperties(moduleCalculation(currentRow, parentValue));
	};

	const calculateDataGridTotal = useCallback((data) => {
		return data.valueText.replace("Sum: ", "");
	}, []);

	return (
		<div>
			<PopupForm
				ref={formRef}
				childrenGridRef={childrenGridRef}
				ReportDocumentNo={reportDocumentNo}
				onClosing={clearForm}
				fullScreen={false}
				width={"95%"}
				height={"90%"}
				disableButtons={lockedForm || fiscalYearLocked}
				actionButtonEnabled={!fiscalYearLocked}
				headerClassName={"ar-module-form-title-bg popup-form-title"}
				title={props.title}
				onShown={onInitialized}
				requiredColumns={{
					Children: ["ChartAccountID"]
				}}
				filterRules={{
					Children: [
						{ name: "ChartAccountID", rules: [{ name: "Required" }]},
						{ name: "Description", rules: [{ name: "Required" }] },
						{ name: "ForexAmount", rules: [{ name: "Min", value: 1 }] }
					]
				}}
				onSuccessfulSubmit={({ stay }) => {
					if (stay) onInitialized();
				}}
				copyFields={{
					Parent: formValue,
					Children : utils.childrenGridGetSource(childrenGridRef)
				}}
				onPasteForm={(e) => {
					tempForm.current = e.Parent;
					setFormValue(e.Parent);
				}}
				lockFormOnChanged={setLockedForm}
				isBeforeFiscalYear={setFiscalYearLocked}
				apiURL={props.apiURL}
				isBoth={true}
				copyStorage={"ARSalesCopiedData"}
				formData={formValue}
				setFormValue={(newValues) => {
					tempForm.current = { ...tempForm.current, ...newValues };
					setFormValue((prevState) => ({ ...prevState, ...newValues }));
				}}
				defaultSelectedReport={"4x5FCO9cF2s%3d"}
				reportURL={salesReportNames.url}
				onValidating={setStartValidation}
				startValidation={startValidation}
				isEditing={isEditing}
				lookupValidateRef={[customerLookupRef, forexLookupRef, projectLookupRef, areaLookupRef, salesmanLookupRef]}
				onSaving={handleSubmit}
				cancelDocumentCustomization={
					<span className='popup-form-header-cancel'>
						<CheckBox
							value={formValue["Cancelled"]}
							elementAttr={{ dataField: "Cancelled" }}
							onValueChanged={(e) => {
								valueOnChange(e);
							}}
							alignment='left'
							width={"12px"}
							height={"12px"}
							iconSize={"12px"}
							readOnly={lockedForm || fiscalYearLocked}
							className='popup-header-cancel-input'
						/>

						<span className='popup-header-cancel-text'>Cancelled</span>
					</span>
				}>
				<div className='popup-form-main-content'>
					<div className='ar-module-border-bottom custom-form-grid-section1'>
						<div>
							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Customer Code </div>

								<div className='popup-group-form-input' id='customized-lookup1'>
									<CustomizedLookup
										ref={customerLookupRef}
										className={"ar-listing-datagrid"}
										displayExpr={"code"}
										valueExpr={"id"}
										value={formValue["CustomerID"]}
										onSelectionChanged={(e) => {
											valueOnChange(e, "CustomerID");
										}}
										startValidation={startValidation !== 0}
										required={true}
										requiredMessage={"Customer Code is required!"}
										dataSourceURL={"api/Utilities/GetCustomers"}
										displayText={formValue["CustomerCode"]}
										readOnly={lockedForm || custLock || fiscalYearLocked}
									>
										<Column dataField='code'></Column>
										<Column dataField='name' caption={"Customer Name"}></Column>
										<Column dataField='forexCode' caption='Currency'></Column>
									</CustomizedLookup>
								</div>
							</div>

							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Customer Name </div>

								<div className='popup-group-form-input'>
									<TextBox
										value={formValue["CustomerName"]}
										elementAttr={{ dataField: "CustomerName" }}
										onValueChanged={(e) => {
											valueOnChange(e);
										}}
										alignment='left'
										width={"auto"}
										readOnly={lockedForm || fiscalYearLocked}>
										<Validator>{startValidation !== 0 && <ValidatorRequired message={"Customer Name is required!"} />}</Validator>
									</TextBox>
								</div>
							</div>

							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Project </div>

								<div className='popup-group-form-input' id='customized-lookup1'>
									<CustomizedLookup
										ref={projectLookupRef}
										className={"ar-listing-datagrid"}
										displayExpr={"code"}
										valueExpr={"id"}
										value={formValue["ProjectID"]}
										onSelectionChanged={(e) => {
											valueOnChange(e, "ProjectID");
										}}
										startValidation={startValidation !== 0}
										required={true}
										requiredMessage={"Project is required!"}
										dataSourceURL={"api/Utilities/GetProjects"}
										readOnly={lockedForm || fiscalYearLocked}
										displayText={formValue["ProjectCode"]}
									>
										<Column dataField='code'></Column>
										<Column dataField='name' caption={"Project Name"}></Column>
									</CustomizedLookup>
								</div>
							</div>
						</div>

						<div>
							<div className='popup-form-item-container2'>
								<div className='popup-group-form-item'>
									<div className='popup-group-form-label next-doc-label'>Invoice No </div>

									<div className='popup-group-form-input'>
										<div className='next-doc-display'>Next Doc {formValue["NextDocNo"]}</div>
										<DocumentNumberLookup
											formValue={formValue}
											setFormValue={setFormValue}
											tempForm={tempForm}
											formID={formID}
											lockedForm={lockedForm}
											valueOnChange={valueOnChange}
											fiscalYearLocked={fiscalYearLocked}
										/>
									</div>
								</div>

								<div className='popup-group-form-item'>
									<div className='popup-group-form-label'>Date </div>

									<div className='popup-group-form-input'>
										<AsyncDateBox
											value={formValue["DocumentDate"]}
											elementAttr={{ dataField: "DocumentDate" }}
											onValueChanged={(e) => {
												valueOnChange(e);
											}}
											alignment='left'
											width={"auto"}
											displayFormat='dd/MM/yyyy'
											dateSerializationFormat='yyyy-MM-dd'
											useMaskBehavior={true}
											readOnly={lockedForm}
											disabled={fiscalYearLocked}
											asyncURL={"api/Utilities/CheckAfterFiscalYear"}
											asyncMessage={"Date cannot set before current fiscal year"}
											asyncProperty={"inputDate"}
										/>
									</div>
								</div>

								<div className='popup-group-form-item'>
									<div className='popup-group-form-label'>Ref. No </div>

									<div className='popup-group-form-input'>
										<TextBox
											value={formValue["Remarks"]}
											elementAttr={{ dataField: "Remarks" }}
											onValueChanged={(e) => {
												valueOnChange(e);
											}}
											alignment='left'
											width={"auto"}
											readOnly={lockedForm || fiscalYearLocked}
										/>
									</div>
								</div>

								<div className='popup-group-form-item'>
									<div className='popup-group-form-label'>Terms </div>

									<div className='popup-group-form-input'>
										<CustomizedLookup
											className={"ar-listing-datagrid"}
											displayExpr={"code"}
											valueExpr={"id"}
											value={formValue["CreditTermsID"]}
											onSelectionChanged={(e) => {
												valueOnChange(e, "CreditTermsID");
											}}
											startValidation={startValidation !== 0}
											required={true}
											requiredMessage={"Credit Term is required!"}
											displayText={formValue["CreditTermsCode"]}
											dataSourceURL={"api/Utilities/GetCreditTerms"}
											readOnly={lockedForm || fiscalYearLocked}
										>
											<Column dataField='code'></Column>
											<Column dataField='name' caption='Days'></Column>
										</CustomizedLookup>
									</div>
								</div>

								<div className='popup-group-form-item'>
									<div className='popup-group-form-label'>Salesman </div>

									<div className='popup-group-form-input'>
										<CustomizedLookup
											ref={salesmanLookupRef}
											className={"ar-listing-datagrid"}
											displayExpr={"code"}
											valueExpr={"id"}
											value={formValue["SalesmanID"]}
											onSelectionChanged={(e) => {
												valueOnChange(e, "SalesmanID");
											}}
											dataSourceURL={"api/Utilities/GetSalesmans"}
											readOnly={lockedForm || fiscalYearLocked}
											displayText={formValue["SalesmanCode"]}
											startValidation={startValidation !== 0}
											required={true}
											requiredMessage={"Salesman is required!"}
										>
											<Column dataField='code'></Column>
											<Column dataField='name'></Column>
										</CustomizedLookup>
									</div>
								</div>

								<div className='popup-group-form-item'>
									<div className='popup-group-form-label'>Area </div>

									<div className='popup-group-form-input'>
										<CustomizedLookup
											ref={areaLookupRef}
											className={"ar-listing-datagrid"}
											displayExpr={"code"}
											valueExpr={"id"}
											value={formValue["AreaID"]}
											onSelectionChanged={(e) => {
												valueOnChange(e, "AreaID");
											}}
											displayText={formValue["AreaCode"]}
											dataSourceURL={"api/Utilities/GetAreas"}
											readOnly={lockedForm || fiscalYearLocked}
											startValidation={startValidation !== 0}
											required={true}
											requiredMessage={"Area is required!"}
										>
											<Column dataField='code'></Column>
											<Column dataField='name'></Column>
										</CustomizedLookup>
									</div>
								</div>

								<div className='popup-group-form-item'>
									<div className='popup-group-form-label'>Forex </div>

									<div className='popup-group-form-input'>
										<CustomizedLookup
											ref={forexLookupRef}
											className={"ar-listing-datagrid"}
											displayExpr={"code"}
											valueExpr={"id"}
											value={formValue["ForexID"]}
											displayText={formValue["ForexCode"]}
											onSelectionChanged={(e) => {
												valueOnChange(e, "ForexID");
											}}
											required={true}
											requiredMessage={"Forex is required!"}
											readOnly={true}
											dataSourceURL={"api/Utilities/GetForexs"}
										>
											<Column dataField='code'></Column>
											<Column dataField='name'></Column>
										</CustomizedLookup>
									</div>
								</div>

								<div className='popup-group-form-item'>
									<div className='popup-group-form-label'>Local Rate </div>

									<div className='popup-group-form-input'>
										<NumberBox
											value={formValue["LocalRate"]}
											elementAttr={{ dataField: "LocalRate" }}
											onValueChanged={(e) => {
												valueOnChange(e);
											}}
											alignment='left'
											width={"auto"}
											format={ utils.getDecimalFormat(defaultValue["LocalRateFixCheck"], defaultValue["LocalRateFormat"]) }
											readOnly={lockedForm || fiscalYearLocked || formValue["ForexID"] === defaultValue["ForexID"]}>
											<Validator>
												{startValidation !== 0 && <ValidatorRequired message={"Local Rate is required!"} />}
												{/* <ValidatorRequired message={"Local Rate is required!"} /> */}
											</Validator>
										</NumberBox>
									</div>
								</div>

								<div className='popup-group-form-item'>
									<div className='popup-group-form-label'>Issue By </div>

									<div className='popup-group-form-input'>
										<TextBox
											value={formValue["IssueBy"]}
											elementAttr={{ dataField: "IssueBy" }}
											onValueChanged={(e) => {
												valueOnChange(e);
											}}
											alignment='left'
											width={"auto"}
											readOnly={true}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className='ar-module-border-bottom' style={{ padding: "0 0" }}>
						<ChildrenDatagrid
							ref={childrenGridRef}
							name={"Children"}
							defaultColumnValues={onInitNew}
							lookupFields={["ProjectID", "ChartAccountID", "TaxCodeID"]}
							keyExpr='ID'
							showBorders={true}
							allowColumnReordering={true}
							allowColumnResizing={true}
							onRowUpdating={onRowUpdating}
							onRowUpdated={onRowUpdated}
							onRowRemoved={onRowUpdated}
							disabled={lockedForm || fiscalYearLocked}
							gridLookupOpen={setGridLookupOpen}
							storageName={"ARSalesChildrenGrid"}
							onParentValueChanged={valueOnChange}
							defaultDescription={formValue["Description"]}
							height={"auto"}>
							<Column dataField='Line' dataType='number' editorOptions={{ readOnly: true }} />

							<Column
								visible={true}
								dataField='ChartAccountID'
								dataType='string'
								cellRender={(e) => {
									const data = e.data;							
									return data.ChartAccountCode;
								}}
								editCellComponent={(component) => (
									<CustomizedGridLookup
										{...component}
										opened={gridLookupOpen}
										className={"ar-listing-datagrid"}
										onSelectionChanged={(e) => gridOnSelectionChanged(e, "ChartAccountID")}
										dataSourceURL={"api/Utilities/GetGLChartAccounts"}
										mergeParams={{ chartAccountType: "AR" }}
										>
										<Column dataField='code'></Column>
										<Column dataField='name' caption={"Description"} width={"300px"}></Column>
									</CustomizedGridLookup>
								)}></Column>

							<Column visible={true} dataField='Description' caption={"Description"} />

							<Column
								visible={true}
								dataField='ProjectID'
								dataType='string'
								cellRender={(e) => {
									const data = e.data;
									return data.ProjectCode;
								}}
								editCellComponent={(component) => (
									<CustomizedGridLookup
										{...component}
										opened={gridLookupOpen}
										className={"ar-listing-datagrid"}
										onSelectionChanged={(e) => gridOnSelectionChanged(e, "ProjectID")}
										dataSourceURL={"/api/Utilities/GetProjects"}
									>
										<Column dataField='code'></Column>
										<Column dataField='name' caption={"Description"} width={"300px"}></Column>
									</CustomizedGridLookup>
								)}></Column>

							<Column visible={true} dataField='ForexAmount' dataType='number' format='#,##0.00' />

							<Column
								visible={true}
								dataField='TaxCodeID'
								colSpan={2}
								cellRender={(e) => {
									const data = e.data;					
									return data.TaxCode;
								}}
								editCellComponent={(component) => (
									<CustomizedGridLookup
										{...component}
										opened={gridLookupOpen}
										className={"ar-listing-datagrid"}
										onSelectionChanged={(e) => gridOnSelectionChanged(e, "TaxCodeID")}
										dataSourceURL={"api/Utilities/GetGSTRate"}
									>
										<Column dataField='code'></Column>
										<Column dataField='name' caption={"Description"} width={"300px"}></Column>
									</CustomizedGridLookup>
								)}></Column>

							<Column visible={true} dataField='TaxRate' dataType='number' editorOptions={{ readOnly: true }} />

							<Column visible={true} dataField='TaxInclusive' dataType='boolean' />

							<Column visible={true} dataField='ForexTaxable' dataType='number' format='#,##0.00' editorOptions={{ readOnly: true }} />

							<Column visible={true} dataField='ForexTaxAmount' dataType='number' format='#,##0.00' editorOptions={{ readOnly: true }} />

							<Column visible={true} dataField='NettAmount' dataType='number' format='#,##0.00' editorOptions={{ readOnly: true }} />

							<Column visible={false} dataField='ChequeNo' />

							<Column visible={false} dataField='DetailLN' />

							<Column visible={false} dataField='BankInDate' />

							<Column visible={false} dataField='Point' />

							<Column visible={false} dataField='Project' />

							<Column visible={false} dataField='ForexTaxAmountOrignal' dataType='number' format='#,##0.00' />

							<Column visible={false} dataField='ForexTaxAdjust' dataType='number' format='#,##0.00' />

							<Column visible={false} dataField='ForexTaxablePlusTax' dataType='number' format='#,##0.00' />

							<Column visible={false} dataField='LocalTaxable' dataType='number' format='#,##0.00' />

							<Column visible={false} dataField='LocalTaxAmount' dataType='number' format='#,##0.00' />

							<Column visible={false} dataField='LocalTaxAmountOrignal' dataType='number' format='#,##0.00' />

							<Column visible={false} dataField='LocalTaxAdjust' dataType='number' editorOptions={{ readOnly: true }} format='#,##0.00' />

							<Column visible={false} dataField='LocalTaxablePlusTax' dataType='number' format='#,##0.00' />

							<Column visible={false} dataField='NettLocalAmount' dataType='number' format='#,##0.00' />

							<Column visible={false} dataField='BankChargesProjectID' />

							<Column visible={false} dataField='BankChargesChartAccountID' />

							<Column visible={false} dataField='BankCharges' />

							<Column visible={false} dataField='AccountType' />

							<Column visible={false} dataField='ForexTaxAutoAdjust' />

							<Column visible={false} dataField='LocalTaxAutoAdjust' />

							<Column visible={false} dataField='FinalLocalAmtAdj' />

							<Column visible={false} dataField='LocalAmount' dataType='number' format='#,##0.00' />

							<Summary recalculateWhileEditing={true}>
								<TotalItem column='ForexAmount' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

								<TotalItem column='ForexTaxable' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

								<TotalItem column='ForexTaxAmountOrignal' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

								<TotalItem column='GSTFXADJ' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

								<TotalItem column='ForexTaxAdjust' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

								<TotalItem column='LocalTaxable' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

								<TotalItem column='LocalTaxAmount' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

								<TotalItem column='LocalTaxAmountOrignal' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

								<TotalItem column='LocalTaxAdjust' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

								<TotalItem column='LocalTaxablePlusTax' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

								<TotalItem column='NettLocalAmount' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

								<TotalItem column='LocalAmount' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

								<TotalItem column='NettPrice' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

								<TotalItem column='ForexTaxAmount' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

								<TotalItem column='NettAmount' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />
							</Summary>
						</ChildrenDatagrid>
					</div>

					<div className='ar-module-border-bottom custom-form-grid-section2'>
						<div className='popup-group-form-item'>
							<div className='popup-group-form-label'>Local Total </div>

							<div className='popup-group-form-input' style={{ width: "150px" }}>
								<NumberBox
									format='#,##0.00'
									value={formValue["MainLocalAmount"]}
									elementAttr={{ dataField: "MainLocalAmount" }}
									onValueChanged={(e) => {
										valueOnChange(e);
									}}
									alignment='left'
									width={"auto"}
									readOnly={true}>
									{/* <Validator>
                  	<ValidatorRequired message={"Local Amount is required!"} />
                	</Validator> */}
								</NumberBox>
							</div>
						</div>

						<div className='popup-form-item-container2 custom-form-item-container2'>
							<div></div>
							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Outstanding </div>

								<div className='popup-group-form-input' style={{ width: "150px" }}>
									<NumberBox
										format='#,##0.00'
										value={formValue["OutstandingAmount"]}
										elementAttr={{ dataField: "OutstandingAmount" }}
										onValueChanged={(e) => {
											valueOnChange(e);
										}}
										alignment='left'
										width={"auto"}
										readOnly={true}
										text={0}></NumberBox>
								</div>
							</div>
						</div>
					</div>
				</div>
			</PopupForm>

			<ErrorPopUpForm ref={popupMessageRef} />
		</div>
	);
}
